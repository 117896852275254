import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import WorkRequestFacility from "../../api/WorkRequest/WorkRequestFacility";
import WorkRequestBusinessUnit from "../../api/WorkRequest/WorkRequestBusinessUnit";
import WorkRequestStatus from "../../api/WorkRequest/WorkRequestStatus";
import WorkRequestType from "../../api/WorkRequest/WorkRequestType";
import WorkRequestPriority from "../../api/WorkRequest/WorkRequestPriority";
import FilterMenu from "../../components/FilterMenu";
import { UXDataTable } from "../../components/UXDataTable";
import UserContext from "../../context/UserContext";
import TableAllRequests from "./TableAllRequests";
import TableRequestQueue from "./TableRequestQueue";
import TableMyOpenRequests from "./TableMyOpenRequests";
import Draft from "../../api/LIMS/Draft";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import BillingInfo from "../../api/Admin/BillingInfo";
import { applyFiltersToArray, convertDateFormat, exportToCsv } from "../../global";
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../styles";

const MyDraftCols = [
  {
    field: "draftName",
    headerName: "Draft Name",
    type: "button-text-draft-workRequest",
    path: "/newWorkRequests",
  },
  { field: "draftType", headerName: "Draft Type", type: "label" },
  { field: "draftOwnerEmail", headerName: "Draft Owner", type: "label" },
];

const filterInitialValues = [
  {
    name: null,
    displayName: null,
    operator: null,
    enumValues: [],
    value: "",
  },
];

const ProcessWorkRequests = ({ copyWorkRequest }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState(filterInitialValues);

  const [allRequests, setAllRequests] = useState([]);
  const [filteredAllRequests, setFilteredAllRequests] = useState([]);
  const [isAllRequestsLoading, setIsAllRequestsLoading] = useState(true);

  const [queueRequests, setQueueRequests] = useState([]);
  const [isQueueRequestsLoading, setIsQueueRequestsLoading] = useState(true);

  const [myRequests, setMyRequests] = useState([]);
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;

  const [requestTypes, setRequestTypes] = useState([]);
  const [requestFacilities, setRequestFacilities] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState([]);
  const [requestBusinessUnits, setRequestBusinessUnits] = useState([]);
  const [requestPriorities, setRequestPriorities] = useState([]);

  const [myDrafts, setMyDrafts] = useState(null);
  const [draftsIsLoading, setDraftsIsLoading] = useState(true);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = "Ok";
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesText, setModalMessagesText] = useState("");
  const exportRef = useRef([]);
  const [billingList, setBillingList] = useState([]);

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "integer", enumValues: [] },
    {
      name: "typeName",
      displayName: "Category",
      type: "enum",
      enumValues: requestTypes,
      multiple: true,
    },
    {
      name: "priorityName",
      displayName: "Priority",
      type: "enum",
      enumValues: requestPriorities,
      multiple: true,
    },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "assignedToEmail",
      displayName: "Assigned To",
      type: "string",
      enumValues: [],
    },
    {
      name: "requestedByEmail",
      displayName: "Requested By",
      type: "string",
      enumValues: [],
    },
    {
      name: "createdDate",
      displayName: "Requested Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "requestedCompletionDate",
      displayName: "Requested Completion Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "facilityName",
      displayName: "Facility",
      type: "enum",
      enumValues: requestFacilities,
      multiple: true,
    },
    {
      name: "businessUnitName",
      displayName: "Business Unit",
      type: "enum",
      enumValues: requestBusinessUnits,
      multiple: true,
    },
    {
      name: "statusName",
      displayName: "Status",
      type: "enum",
      enumValues: requestStatuses,
      multiple: true,
    },
    {
      name: "parentWorkRequestID",
      displayName: "Parent WR ID",
      type: "integer",
      enumValues: [],
    },
    {
      name: "createdByEmail",
      displayName: "Submitted By",
      type: "string",
      enumValues: [],
    },
  ]; //.sort((a,b) => a.displayName.localeCompare(b.displayName));

  const isAllRequestsInitLoading =
    isAllRequestsLoading && (!allRequests || allRequests.length === 0);

  useEffect(() => {
    let cancelPromise = false;
    setIsAllRequestsLoading(true);

    function getAllRequestsDataRecr(currPage, allData = []) {
      if (cancelPromise) return allData;
      WorkRequest.getAll(currPage, 20000, true).then((res) => {
        if (res === null || res.length === 0) {
          setIsAllRequestsLoading(false);
          return allData;
        } else {
          allData = [...allData, ...res.filter((f) => f.statusName !== "Draft")];
          currPage++;
          setAllRequests(allData);
          return getAllRequestsDataRecr(currPage, allData);
        }
      });
    }

    WorkRequest.getAll(0, 100, true).then((res) => {
      if (res === null || res.length === 0) {
        setIsAllRequestsLoading(false);
      } else {
        setAllRequests(res); //Load initial limited data for performance
        getAllRequestsDataRecr(0); //Recursively load all data
      }
    });

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const filteredArray = applyFiltersToArray(filters, allRequests);
    setFilteredAllRequests(filteredArray);
  }, [filters, allRequests]);

  function reloadWorkRequest(id) {
    WorkRequest.getById(id).then((result) => {
      if (result) {
        setAllRequests((prevItems) => prevItems.map((item) => (item.id === id ? result : item)));
      }
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    WorkRequest.getMyOpenAssignedWorkRequests(userEmail).then((res) => {
      if (cancelPromise) return;
      setMyRequests(res);
      setIsMyRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [userEmail]);

  function reloadOpenRequests() {
    WorkRequest.getMyOpenAssignedWorkRequests(userEmail).then((res) => {
      setMyRequests(res);
      setIsMyRequestsLoading(false);
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    WorkRequest.getQueueRequests().then((res) => {
      if (cancelPromise) return;
      setQueueRequests(res.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)));
      setIsQueueRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  function reloadQueueRequests() {
    WorkRequest.getQueueRequests().then((res) => {
      setQueueRequests(res.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)));
      setIsQueueRequestsLoading(false);
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    if (myDrafts === null) {
      setDraftsIsLoading(true);

      Draft.getByUserAndType(userEmail, "workRequest").then((res) => {
        if (cancelPromise) return;
        setMyDrafts(res);
        setDraftsIsLoading(false);
      });
    }

    return () => {
      cancelPromise = true;
    };
  }, [userEmail, myDrafts]);

  useEffect(() => {
    let cancelPromise = false;

    WorkRequestType.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestTypes(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.typeName)
          .sort(),
      );
    });

    WorkRequestStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatuses(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.statusName)
          .sort(),
      );
    });

    WorkRequestFacility.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestFacilities(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.facilityName)
          .sort(),
      );
    });

    WorkRequestPriority.getAll().then((res) => {
          if (cancelPromise) return;
          setRequestPriorities(
            res
              .filter((result) => result.isActive === true)
              .map((item) => item.priorityName)
              .sort(),
          );
    });

    WorkRequestBusinessUnit.getAllActive().then((res) => {
      if (cancelPromise) return;
      setRequestBusinessUnits(
        res
          .sort((a, b) => a.businessUnitName.localeCompare(b.businessUnitName))
          .map((item) => item.businessUnitName),
      );
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  const handleChange = (event, newValue) => {
    setFilters(filterInitialValues);
    setTabValue(newValue);
  };

  const RemoveDraft = (draft) => {
    if (draft && draft.id) {
      Draft.delete(draft.id).then((res) => {
        if (res.message === "Success") {
          openModalMessages("Draft Deleted", "Draft successfully deleted!");
          setMyDrafts(null);
        } else {
          openModalMessages(
            "Draft Failed to Delete",
            `${res.message}. Contact support if you feel this is an error.`,
          );
        }
      });
    }
  };

  const menuItemsDrafts = [
    {
      menuType: "text",
      redirectPath: "",
      text: "Delete Draft",
      onClickFunction: RemoveDraft,
    },
  ];

  useEffect(() => {
    let cancelPromise = false;
    BillingInfo.getAll().then((res) => {
      if (cancelPromise) return;

      if (res.message === "Success") {
        setBillingList(res.result.filter((result) => result.isActive === true));
      }
    });
    return () => {
      cancelPromise = true;
    };
  }, []);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        exportRef.current = applyFiltersToArray(filters, myRequests);
        return (
          <TableMyOpenRequests
            filters={filters}
            myRequests={myRequests}
            isDataLoading={isMyRequestsLoading}
            userEmail={userEmail}
            isProcessing={true}
            billingList={billingList}
            reloadWorRequest={reloadOpenRequests}
            copyWorkRequest={copyWorkRequest}
          />
        );
      }
      case 1: {
        exportRef.current = applyFiltersToArray(filters, queueRequests);
        return (
          <TableRequestQueue
            filters={filters}
            myRequests={queueRequests}
            isDataLoading={isQueueRequestsLoading}
            billingList={billingList}
            isProcessing={true}
            reloadWorRequest={reloadQueueRequests}
            copyWorkRequest={copyWorkRequest}
          />
        );
      }
      case 2: {
        exportRef.current = filteredAllRequests;

        return (
          <TableAllRequests
            filters={filters}
            filteredAllRequests={filteredAllRequests}
            isDataLoading={isAllRequestsInitLoading}
            isProcessing={true}
            userEmail={userEmail}
            billingList={billingList}
            reloadWorkRequest={reloadWorkRequest}
            copyWorkRequest={copyWorkRequest}
          />
        );
      }
      case 3: {
        return (
          <UXDataTable
            tableWidth="60%"
            cols={MyDraftCols}
            rows={myDrafts === null ? [] : myDrafts.sort((a, b) => b.draftName - a.draftName)}
            moreOptionsCell={true}
            enablePaging={true}
            noDataMessage={`No work request drafts found for ${userEmail}`}
            menuProps={menuItemsDrafts}
            defaultRowsPerPage={10}
            isDataLoading={draftsIsLoading}
            tableName={"blendDrafts"}
            enableSorting={true}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="ant example">
          <StyledTab label="My Assigned Requests" />
          <StyledTab label="Request Queue" />
          <StyledTab label="All Requests" />
        </GlobalTabCollection>

        <Box display="flex" alignItems="center" marginLeft="auto" marginTop="-.8rem">
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            disabled={tabValue === 2 && isAllRequestsLoading}
            onClick={(e) =>
              exportToCsv(
                exportRef.current.map((item) => {
                  return {
                    ID: item.id,
                    Category: item.typeName,
                    Priority: item.priorityName,
                    Description: item.description,
                    Requester: item.requestedByEmail,
                    "Assigned To": item.assignedToEmail,
                    "Requested Date": convertDateFormat(item.createdDate, false),
                    "Requested Completion": convertDateFormat(item.requestedCompletionDate, false),
                    "Last Acceptable Date": convertDateFormat(
                      item.lastAcceptableCompletionDate,
                      false,
                    ),
                    Facility: item.facilityName,
                    Status: item.statusName
                  };
                }),
                "Work Requests",
              )
            }>
            Export CSV
          </GlobalButton>
          <GlobalButton
            // disabled={tabValue > 1}
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => setFilteringOpen(true)}>
            Filters
          </GlobalButton>

          {!(filters[0].name === null) && (
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => setFilters(filterInitialValues)}>
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      {renderSearchTab()}

      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={() => setFilteringOpen(false)}
        cancelButtonAction={() => setFilteringOpen(false)}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />

      {/* Informational Messages */}
      <ModalMessages
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={() => setModalMessagesOpen(false)}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label>{modalMessagesText}</label>
      </ModalMessages>
    </div>
  );
};

export default ProcessWorkRequests;
