import { TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePickerKeyDownEvent } from "../../../../global";

export const autoCompleteFontSize = 16;

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const SDSFormInput = ({
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  validation,
  placeholder,
  disabled,
  helperText,
  required = false,
  type = "text",
  size = "small",
  margin = "normal",
}) => {
  return (
    <TextField
      placeholder={placeholder ?? label}
      type={"text"}
      value={data[property]}
      disabled={disabled}
      onChange={(event) => {
        const value =
          type === "number" ? event.target.value.replace(/\D/g, "") : event.target.value;
        setData({ ...data, [property]: value });
        if (validation || required) {
          setErrors({
            ...errors,
            [property]:
              !disabled &&
              ((validation !== undefined && !validation(value)) || !value || value === ""),
          });
        }
      }}
      error={errors[property]}
      helperText={helperText}
      size={size}
      margin={margin}
      label={required ? `* ${label}` : label}
      fullWidth
      variant="outlined"
      InputLabelProps={{ shrink: true }}
    />
  );
};

export const SDSFormAutocomplete = ({
  disabled,
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  helperText,
  noOptionsText,
  propertyLabel,
  options = [],
  required = false,
  size = "small",
  margin = "normal",
  styled,
  autoSelect = true,
}) => {
  function AutoCompleteChangeAndValidate(value) {
    if (required) {
      setErrors({
        ...errors,
        [property]: !disabled && (!value || value === ""),
      });
    }
    const copyData = structuredClone(data);

    copyData[property] = value;

    setData(copyData);
  }

  const propertyLabelToUse = propertyLabel ?? property;

  return (
    <StyledAutocomplete
      disabled={disabled}
      disablePortal
      style={{ width: "100%", ...styled }}
      noOptionsText={noOptionsText}
      options={options}
      onChange={(e, value) => AutoCompleteChangeAndValidate(value?.[property])}
      getOptionLabel={(option) =>
        option?.[propertyLabelToUse]
          ? option[propertyLabelToUse]
          : options.length > 0
          ? options.find((f) => f?.[property] === option)?.[propertyLabelToUse]
          : ""
      }
      autoHighlight
      autoSelect={autoSelect}
      value={data?.[property]}
      isOptionEqualToValue={(option, value) => value?.[property] === option?.[property]}
      renderInput={(params) => (
        <TextField
          fullWidth
          {...params}
          variant="outlined"
          size={size}
          margin={margin}
          label={required ? `* ${label}` : label}
          error={errors[property]}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          InputProps={{ ...params.InputProps }}
        />
      )}
    />
  );
};

export const SDSFormDate = ({
  disabled,
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  helperText,
  minDate,
  maxDate,
  required = false,
  size = "small",
  margin = "normal",
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        disabled={disabled}
        size={size}
        inputFormat="MM/dd/yyyy"
        label={required ? `* ${label}` : label}
        onChange={(e) => {
          setData(() => ({
            ...data,
            [property]: e,
          }));
          if (required) {
            setErrors({
              ...errors,
              [property]: !disabled && (!e || e === ""),
            });
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        value={data[property]}
        renderInput={(params) => (
          <StyledShortTextField
            onKeyDown={DatePickerKeyDownEvent}
            {...params}
            margin={margin}
            variant="outlined"
            size={size}
            error={errors[property]}
            helperText={helperText}
            InputLabelProps={{ shrink: true }}
            inputProps={{ ...params.inputProps }}
            InputProps={{ ...params.InputProps }}
            style={{ width: "100%" }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
