import API from "..";

export default class SpecialAnalysis {
  constructor({
    id,
    requestTypeName,
    SpecialAnalysisType,
    statusName,
    SpecialAnalysisStatus,
    description,
    requestedByEmail,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedCompletionDate,
    lastAcceptableCompletionDate,
    assignedToEmail,
    assignedDate,
    completedDate,
    completedByEmail,
    priorityName,
    SpecialAnalysisPriority,
    priorityReason,
    businessUnitName,
    businessUnit,
    comments,
    intendedUseName,
    SpecialAnalysisIntendedUse,
    isNorthAmerica,
    isSouthAmerica,
    isEurope,
    isAfrica,
    isAsiaPacific,
    countrySpecificInfo,
    sdsBlindCodeName,
    blindCodeForCommericalProduct,
    commericalProductDetails,
    blindCodeForExperimentalProduct,
    experimentalDetails,
    blindCodeForNonEM,
    parentProductName,
    isModeledAfterExistingProduct,
    existingProductDetails,
    isNewSDS,
    isCommercialFlagshipProduct,
    ecrNumber,
    syntheticIndicatorName,
    SpecialAnalysisSyntheticIndicator,
    ph6Code,
    sdsShipmentName,
    componentsExist,
    containsNewOrExperimentalComponents,
    existingTechnicalSpec,
    specialAnalysisSamples,
    chargeCode,
    specialRequest,
    workRequestID,
    isCreateOnsiteTransportRequest
  }) {
    this.id = id;
    this.requestTypeName = requestTypeName;
    this.SpecialAnalysisType = SpecialAnalysisType;
    this.statusName = statusName;
    this.SpecialAnalysisStatus = SpecialAnalysisStatus;
    this.description = description;
    this.requestedByEmail = requestedByEmail;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedCompletionDate = requestedCompletionDate;
    this.lastAcceptableCompletionDate = lastAcceptableCompletionDate;
    this.assignedToEmail = assignedToEmail;
    this.assignedDate = assignedDate;
    this.completedDate = completedDate;
    this.completedByEmail = completedByEmail;
    this.priorityName = priorityName;
    this.SpecialAnalysisPriority = SpecialAnalysisPriority;
    this.priorityReason = priorityReason;
    this.businessUnitName = businessUnitName;
    this.businessUnit = businessUnit;
    this.comments = comments;
    this.intendedUseName = intendedUseName;
    this.SpecialAnalysisIntendedUse = SpecialAnalysisIntendedUse;
    this.isNorthAmerica = isNorthAmerica;
    this.isSouthAmerica = isSouthAmerica;
    this.isEurope = isEurope;
    this.isAfrica = isAfrica;
    this.isAsiaPacific = isAsiaPacific;
    this.countrySpecificInfo = countrySpecificInfo;
    this.sdsBlindCodeName = sdsBlindCodeName;
    this.blindCodeForCommericalProduct = blindCodeForCommericalProduct;
    this.commericalProductDetails = commericalProductDetails;
    this.blindCodeForExperimentalProduct = blindCodeForExperimentalProduct;
    this.experimentalDetails = experimentalDetails;
    this.blindCodeForNonEM = blindCodeForNonEM;
    this.parentProductName = parentProductName;
    this.isModeledAfterExistingProduct = isModeledAfterExistingProduct;
    this.existingProductDetails = existingProductDetails;
    this.isNewSDS = isNewSDS;
    this.isCommercialFlagshipProduct = isCommercialFlagshipProduct;
    this.ecrNumber = ecrNumber;
    this.syntheticIndicatorName = syntheticIndicatorName;
    this.SpecialAnalysisSyntheticIndicator = SpecialAnalysisSyntheticIndicator;
    this.ph6Code = ph6Code;
    this.sdsShipmentName = sdsShipmentName;
    this.componentsExist = componentsExist;
    this.containsNewOrExperimentalComponents = containsNewOrExperimentalComponents;
    this.existingTechnicalSpec = existingTechnicalSpec;
    this.specialAnalysisSamples = specialAnalysisSamples;
    this.chargeCode = chargeCode;
    this.specialRequest = specialRequest;
    this.workRequestID = workRequestID;
    this.isCreateOnsiteTransportRequest = isCreateOnsiteTransportRequest;
  }

  static async getAll(page, resultsPerPage) {
    const api = await API();
    const { data } = await api.get(
      `/specialAnalysis/?page=${page}&resultsPerPage=${resultsPerPage}`
    );
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysis(d));
    } else {
      return null;
    }
  }

  static async getMySpecialAnalysis(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/specialAnalysis/myRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysis(d));
    } else {
      return [];
    }
  }

  static async getMyOpenSpecialAnalysis(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/specialAnalysis/myOpenRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysis(d));
    } else {
      return [];
    }
  }

  static async getMyOpenAssignedSpecialAnalysis(userEmail) {
    const api = await API();
    const { data } = await api.get(
      `/specialAnalysis/myOpenAssignedRequests?userEmail=${userEmail}`
    );
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysis(d));
    } else {
      return [];
    }
  }

  static async getQueueRequests() {
    const api = await API();
    const { data } = await api.get("/specialAnalysis/requestQueue");
    if (data.result) {
      return data.result.map((d) => new SpecialAnalysis(d));
    } else {
      return [];
    }
  }

  static async createSpecialAnalysis(info) {
    const api = await API();
    const { data } = await api.post("/specialAnalysis", info);
    return data;
  }

  static async updateSpecialAnalysis(info) {
    const api = await API();
    const { data } = await api.patch(`/specialAnalysis`, info);
    return data;
  }

  static async getById(id) {
    const api = await API();
    const { data } = await api.get(`/specialAnalysis/${id}`);
    if (data.result) {
      return new SpecialAnalysis(data.result);
    } else {
      return null;
    }
  }

  static async quickSearch(searchCriteria) {
    const api = await API();
    const { data } = await api.get(
      `specialAnalysis/quickSearch?searchCriteria=${searchCriteria}`
    );
    return data;
  }

  static async updateComment(id, comments) {
    const api = await API();
    const { data } = await api.patch(
      `/specialAnalysis/${id}/comments?comments=${comments}`
    );
    return data;
  }

  static async updateAssignTo(id, email = '') {
    const api = await API();
    const { data } = await api.patch(
      `/specialAnalysis/${id}/assign?userEmail=${email}`
    );
    return data;
  }

  static async cancelRequest(id) {
    const api = await API();
    const { data } = await api.patch(`/specialAnalysis/${id}/cancel`);
    return data;
  }

  static async getAllWithFilter(
    page,
    resultsPerPage,
    searchValue,
    columnName,
    criteria,
  ) {
    const api = await API();
    const { data } = await api.get("/specialAnalysis", {
      params: {
        page,
        resultsPerPage,
        searchValue,
        columnName,
        criteria,
      },
    });
    if (data && data.result && data.result.length > 0) {
      return data.result;
    }
    return [];
  }

  static async totalCountWithFilter(searchValue, columnName, criteria) {
    const api = await API();
    const { data } = await api.get("/specialAnalysis/count", {
      params: {
        searchValue,
        columnName,
        criteria,
      },
    });
    if (data && data.result) {
      return data.result;
    }
    return 0;
  }

}
