import { useState, useEffect, useContext } from "react";
import {Button, Divider, Drawer, Toolbar, Box, CircularProgress, TextField} from "@mui/material";
import NewTest from "./NewTest";
import AddMethods from "./AddMethods";
import TestSummary from "./TestSummary";
import { formatMidasNumber, ConvertUOMs, hasRole, Roles, BuildTestingPayload } from "../../../global";
import ModalTwoButtons from "../../../components/Modal/ModalTwoButtons";
import ModalThreeButtons from "../../../components/Modal/ModalThreeButtons";
import { GlobalSecondaryButton } from "../../../pages/styles";
import ModalMessages from "../../../components/Modal/ModalSimpleButton";
import Draft from "../../../api/LIMS/Draft";
import {useLocation} from 'react-router-dom';
import PrintInformation from "../../../components/PrintInformation";
import PrintLabel from "../../../api/LIMS/PrintLabel";
import Container from "../../../api/LIMS/Container"
import ContainerType from "../../../api/LIMS/ContainerType";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import UserContext from "../../../context/UserContext";
import Location from "../../../api/Admin/Location";
import ModalNoButton from "../../../components/Modal/ModalNoButton";

const drawerWidth = 250

const defaultSample =
{
  samples:[{sampleName:'', sampleObject:null, methods:[], containerManagementType:"midas", containers:[]}],
  masterMethods:[],
  chargeCode: '',
  returnLocation: {siteName: "Clinton Technical Center", locationName: "DISCARD", isActive: true, canContainSamples: true},
  requesterEmail: '',
}

let defaultErrorChecks = {
  requesterEmail: null,
  chargeCode: null,
  returnLocation: false,
};

const TestManagement = ({ ...props }) => {
  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;

  const hasEMBSIUser = hasRole(Roles.EMBISUser, roles) || hasRole(Roles.Developer, roles)
  const hasPMUser = hasRole(Roles.PMUser, roles)

  const location = useLocation();

  const [draftObj, setDraftObj] = useState(location.state && location.state.draftObject ? location.state.draftObject : null)
  const [samplePageValided, setSamplePageValided] = useState(false);
  const [methodPageValided, setMethodPageValided] = useState(false);

  const [mySampleInfo, setMySampleInfo] = useState(() => PopulateIncomingSampleInfo());
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecks);

  const [containerErrorChecks, setContainerErrorChecks] = useState(PopulateErrorInfo());
  //  const [containerErrorChecks, setContainerErrorChecks] = useState([{ containerErrorInfo: [] }]);

  const [displayScreenValue, setDisplayScreenValue] = useState(0);
  const [navBarTextColor, setNavBarTextColor] = useState(["#1460A6", "#666666","#666666"]);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [isTestSaving, setIsTestSaving] = useState(false)

  const [modalDraftsOpen, setModalDraftsOpen] = useState(false);
  const [modalDraftsButton1Text, setModalDraftsButton1Text] = useState("");
  const [modalDraftsButton2Text, setModalDraftsButton2Text] = useState("");
  const [modalDraftsTitle, setModalDraftsTitle] = useState("");
  const [modalDraftsText, setModalDraftsText] = useState("");

  const [draftSaveName, setDraftSaveName] = useState("");
  const [draftButtonText, setDraftButtonText] = useState(draftObj === null ? "Save as Draft" : "Update Draft");
  const [isDraftSaving, setIsDraftSaving] = useState(false);

  const [printLabelInfo, setPrintLabelInfo] = useState(null);
  const [printInfoOpen, setPrintInfoOpen] = useState(false);

  const [createdContainerInfo, setCreatedContainerInfo] = useState([]);

  const [modalThreeBtnOpen, setModalThreeBtnOpen] = useState(false);
  const [modalThreeBtnButton1Text, setModalThreeBtnButton1Text] = useState('');
  const [modalThreeBtnButton2Text, setModalThreeBtnButton2Text] = useState('');
  const [modalThreeBtnButton3Text, setModalThreeBtnButton3Text] = useState('');
  const [modalThreeBtnTitle, setModalThreeBtnTitle] = useState('');
  const [modalThreeBtnText, setModalThreeBtnText] = useState('');

  const [availableUOMs, setAvailableUOMs] = useState([])
  const [containerTypes, setContainerTypes] = useState([])
  const [availableLocations, setAvailableLocations] = useState([])
  
  const [tabValue, setTabValue] = useState(0)
  const [triggerContainerReload, setTriggerContainerReload] = useState(0)

  const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
  const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
  const [modalNoButtonText, setModalNoButtonText] = useState('');

  function PopulateIncomingSampleInfo () {

    if (draftObj === null)
    {
      const sampleInfo = location.state && location.state.sampleInfo ? 
        {
          samples: location.state.sampleInfo.map(sampleInfo => ({sampleName: sampleInfo.sampleName, sampleObject:sampleInfo, methods:[], containerManagementType:"midas", containers:[]})),
          masterMethods:[],
          chargeCode: '',
          returnLocation: {siteName: "Clinton Technical Center", locationName: "DISCARD", isActive: true, canContainSamples: true},
          requesterEmail: '',
        } 
        : defaultSample       

      return sampleInfo

    } else {
      return location.state.sampleInfo
    }
  }

  function PopulateErrorInfo () {
    var containerErrors = []
    let i = 0

    while (i < mySampleInfo.samples.length) {
      containerErrors.push({containerErrorInfo:[]})
      i++;
    }

    return containerErrors 
  }

  function closeModalNoButton() {
    setModalNoButtonOpen(false);
  }

  function openModalNoButton(title, text) {
    setModalNoButtonOpen(true);
    setModalNoButtonTitle(title);
    setModalNoButtonText(text);
  }

  useEffect(() => {
    let cancelPromise = false

    UnitOfMeasure.getAll().then((res) => {
      if (cancelPromise) return;
      setAvailableUOMs(res.filter((result) => (result.type === "weight" || result.type === "volume") &&result.metricStandardConversion !== null && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)));
    });

    Location.getAll().then((res) => {
      if (cancelPromise) return;
      setAvailableLocations(res.filter((result) => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)));
    });

    ContainerType.getAll().then((res) => {
      if (cancelPromise) return
      setContainerTypes(res.filter(result => result.isActive === true).sort(function(a, b) {
        return (b.sizeOrder != null) - (a.sizeOrder != null) || a.sizeOrder - b.sizeOrder;
      }))
    });

  return () => {
    cancelPromise = true
  }
  }, [])

  useEffect(() => {
    (async () => {
      await LoadContainers();
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerContainerReload])

  async function LoadContainers () {
    let cancelPromise = false
    let newTests = []

    if (displayScreenValue !== 2 || triggerContainerReload === false)
    {
      return
    }

    if (mySampleInfo.samples[tabValue].containerManagementType === "existing") {
      if (mySampleInfo.samples[tabValue].methods.some((result) => result.createFromContainer === null) || mySampleInfo.samples[tabValue].methods.some((result) => result.containerSelectionType === null)) 
      {
        let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo));
        copyMySampleInfo.samples[tabValue].containers = [];
        setMySampleInfo(copyMySampleInfo);

        const copyContainerErrors = JSON.parse(
          JSON.stringify(containerErrorChecks)
        );
        copyContainerErrors[tabValue].containerErrorInfo = [];
        setContainerErrorChecks(copyContainerErrors);

        setTriggerContainerReload(false);
        return;
      }
    } else {
      if (mySampleInfo.samples[tabValue].methods.some((result) => result.createFromContainer === null)) 
      {
        let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo));
        copyMySampleInfo.samples[tabValue].containers = [];
        setMySampleInfo(copyMySampleInfo);

        const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks));
        copyContainerErrors[tabValue].containerErrorInfo = [];
        setContainerErrorChecks(copyContainerErrors);

        setTriggerContainerReload(false);
        return;
      }
    }

    for (const oMethodInfo of mySampleInfo.samples[tabValue].methods)
    {
      let myContainerID = null

      if (oMethodInfo.containerSelectionType === "Existing Container") 
      {
        myContainerID = oMethodInfo.createFromContainer.id;
      }
      
      const tests = await BuildTestingPayload(oMethodInfo, oMethodInfo.createFromContainer, myContainerID, mySampleInfo.chargeCode ? mySampleInfo.chargeCode : null, mySampleInfo.requesterEmail)

      if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string')
      {
        if (Array.isArray(tests))
        {
          openModalMessages("Error Building Tests", tests)        
          return
        } else {
          openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)        
          return
        }
      }

      for (const oTest of tests)
      {
        newTests.push(oTest)  
      }
    }

    var testingInfo = {
      tests: newTests,
      containeringMethod: mySampleInfo.samples[tabValue].containerManagementType,
      requireParentContainer: true
    }

    openModalNoButton("Generating Containers", "Please wait while containers are generated...")

    Container.GetContainersToCreate(JSON.stringify(testingInfo)).then((res) => {
      if (cancelPromise) return

      closeModalNoButton()

      let arrayContainers = []
      let arrayErrors = []
      const copyContainerErrors = JSON.parse(JSON.stringify(containerErrorChecks))

      if (res && res.message === 'Success') 
      {
        res.result.forEach(oContainer => {

          if (oContainer.id === 0)
          {
            //oContainer.ownerEmail = currentUser.username
            oContainer.ownerEmail = mySampleInfo.requesterEmail
            oContainer.locationName = null
            
            //these properties are added to keep track of the min size, in case the user changes volumes
            oContainer._originalAmount = oContainer.currentAmount
            oContainer._originalUoM = oContainer.uom
          }
          
          oContainer.returnLocationName = mySampleInfo.returnLocation?.locationName
          oContainer.returnLocation = oContainer.returnLocationName ? availableLocations.find(obj => obj.locationName === oContainer.returnLocationName) : null

          oContainer.location = oContainer.locationName ? availableLocations.find(obj => obj.locationName === oContainer.locationName) : null
          
          //set up the error checking on the containers          
          arrayContainers.push(oContainer)

          arrayErrors.push({ 
            containerType: false,
            size: false,
            currentAmount: false, 
            uom: false,
            location: oContainer.location === null ? null : false, 
            ownerEmail: oContainer.ownerEmail === null ? null : false
          })
        });

      } else {
        openModalMessages("Error Loading Containers", `There was an error loading the testing/retain containers for the selected tests and containering method. ${res ? res.message : ''}.`)
      }

      let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))

      copyMySampleInfo.samples[tabValue].containers = arrayContainers
      setMySampleInfo(copyMySampleInfo)

      copyContainerErrors[tabValue].containerErrorInfo = arrayErrors
      setContainerErrorChecks(copyContainerErrors)
    });

    setTriggerContainerReload(false)
  }

  function closeModalThreeButtons() {
    setModalThreeBtnOpen(false)
    window.location.href = "/samples"
  }

  function openModalThreeButtons(title, text, buttonText, button2Text, button3Text) {
    setModalThreeBtnButton1Text(buttonText)
    setModalThreeBtnButton2Text(button2Text)
    setModalThreeBtnButton3Text(button3Text)
    setModalThreeBtnOpen(true)
    setModalThreeBtnTitle(title)
    setModalThreeBtnText(text)
  }

  function NavagateToMyTests () 
  {
    window.location.href = "/tests"
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalDrafts() {
    setModalDraftsOpen(false);
  }

  function openModalDrafts(title, text, buttonText, button2Text) {
    setModalDraftsButton1Text(buttonText)
    setModalDraftsButton2Text(button2Text)
    setModalDraftsOpen(true);
    setModalDraftsTitle(title);
    setModalDraftsText(text);
  }

  const SaveNewDraft = (e) => {

    if (draftSaveName.trim() === '' && draftObj === null)
    {
      openModalMessages("Draft Name Error", "You must enter a name to save a new draft!")
      return
    }

    if (isDraftSaving)
    { 
        return
    } else {
        setIsDraftSaving(true)
    }

    if (draftObj !== null) 
    {
      const copyDraftObj = JSON.parse(JSON.stringify(draftObj))

      copyDraftObj.draftJSON = JSON.stringify(mySampleInfo)

      Draft.update(copyDraftObj).then((res) => {
        if (res.message === 'Success') 
        {
          openModalMessages("Update Successful", "Draft Successfully Updated!")
          closeModalDrafts()
        } else {
          openModalMessages("Update Failed", `${res.message}. Contact support if you feel this is an error.`)
        }
        setIsDraftSaving(false)
      });

    } else {
      let newDraft = new Draft({
        draftOwnerEmail: null,
        draftName: draftSaveName,
        draftJSON: JSON.stringify(mySampleInfo),
        draftType: "methods",
        createdDate: null, 
        lastModifiedDate: null
      })
    
      Draft.add(newDraft).then((res) => {
        if (res.message === 'Success')
        {
          openModalMessages("Save Successful", "Draft Successfully Saved!")
          closeModalDrafts()
          setDraftButtonText("Update Draft")
          setDraftObj(res.result)
        } else {
          openModalMessages('Draft Save Failed', `${res.message}. Contact support if you feel this is an error.`);
        }
        setIsDraftSaving(false)
      })
    }
  }

  function CheckForSampleErrors() 
  {
    let errorMessages = []

    //check the method data for completness
    mySampleInfo.samples.forEach((oSample, index) => {
      if (oSample.sampleObject === null && oSample.sampleName.trim() === "") {
        errorMessages.push(`Invalid samples must be removed before adding methods.`);
      }

      if (oSample.sampleObject === null && oSample.sampleName.trim() !== "") {
        errorMessages.push(`${oSample.sampleName} has not been validated as a real MIDAS sample.`);
      }

      if (oSample.sampleObject && (oSample.sampleObject.containers === null ||oSample.sampleObject.containers.length === 0)) 
      {
        errorMessages.push(`${oSample.sampleName} does not have any containers.  Please add containers to continue.`);

      } else if (oSample.sampleObject && oSample.sampleObject.containers.filter((myContainer) => myContainer.containerStatusName === "Confirmed").map((filteredContainer) => filteredContainer).length === 0){
        errorMessages.push(`${oSample.sampleName} does not have any active containers.  Please add containers to continue.`);
      }

      if (oSample.sampleObject && oSample.sampleObject.isTestingAllowed === false) 
      {
        errorMessages.push(`${oSample.sampleName} is configured to not allow testing.  Please update MIDAS Number to allow for testing.`);
      }

      if (oSample.sampleObject.embsiUseOnly && !(hasEMBSIUser))
      {
        errorMessages.push(`${oSample.sampleName} is configured for EMBSI use and you do not have the EMBSIUSER role.`);
      }

      if (oSample.sampleObject.isRestrictedAccess && !(hasPMUser))
      {
        errorMessages.push(`${oSample.sampleName} is configured for Controlled Precious Metals use and you do not have the PMUSER role.`);
      }
    })

    if (errorMessages.length === 0)
    {
      setDisplayScreenValue(1)
      setNavBarTextColor(["#1460A6","#1460A6", "#666666"])
      setSamplePageValided(true)
    } else {
      openModalMessages("Errors Detected", errorMessages, "Ok")
      setSamplePageValided(false)
    }
  }

  function ConfirmTestsSelection ()
  {
    let errorMessages = []

    //check the method data for completness
    mySampleInfo.masterMethods.forEach((oMethod) =>
    {
        if (oMethod.method === null)
        {
          errorMessages.push('You must select a method for all entries.')
        }

        if (oMethod.method.isPackageTest === false)
        {
          if (oMethod.methodFacility === null)
          {
            errorMessages.push('You must select a method facility for all entries.')   
          } else {
            if (oMethod.methodFacility.requiresConditions)
            {
              if (oMethod.conditionOffering === null)
              {
                errorMessages.push(`A condition is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
              } else {
                oMethod.conditionOffering.testConditionSetupValues.forEach((oTCSV, index) => {
                  if (oTCSV.discreteValue === null || oTCSV.discreteValue.trim() === '')
                    {
                      errorMessages.push(`A user defined condition value is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`) 
                    }
                })
              }
            }

            if (oMethod.methodFacility.testFacilityAbv === 'EXT')
            {
              if(oMethod.externalLaboratory === null)
              {
                errorMessages.push(`An external lab selection is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)                  
              }
            }

            if (oMethod.methodFacility.requiresEstimates)
            {
              if (oMethod.estimate.trim() === '')
              {
                errorMessages.push(`An estimate is required for method ${oMethod.method.name} and method facility ${oMethod.methodFacility.testFacilityAbv}`)  
              }
            }
          }

          if (oMethod.testPriority === null)
          {
            errorMessages.push('You must select a test priority for all entries.')
          }

        var today = new Date();
        var formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

        var comparDate = new Date(formattedDate)
        var reqDate = new Date(oMethod.requestedCompletionDate)

        if (oMethod.requestedCompletionDate && reqDate < comparDate) {
          errorMessages.push(`Requested completion date for ${oMethod.method.name} cannot be a past date`);
        }
      }
    });

    if (errorMessages.length === 0)
    {
          let copyMySampleInfo = JSON.parse(JSON.stringify(mySampleInfo))
          
          setMySampleInfo(copyMySampleInfo)

          setMethodPageValided(true)
          setDisplayScreenValue(2)
          setNavBarTextColor(["#1460A6","#1460A6", "#1460A6"])
      
    } else {
          openModalMessages("Method Selection Errors", errorMessages, "Ok")
          setMethodPageValided(false)
    }
  }

  function ConfirmTestSubmissionData ()
  {
    let errorMessages = []

    //check the method data for completness
    if (errorChecks.requesterEmail === true || errorChecks.requesterEmail === null)
    {
      errorMessages.push("A Test Requester is required.");
    }

    if (errorChecks.chargeCode === true || errorChecks.chargeCode === null) 
    {
      errorMessages.push("Please enter a billing number.");
    }

    if (errorChecks.returnLocation === true || errorChecks.returnLocation === null)
    {
      errorMessages.push("Please enter a return location.");
    }

    mySampleInfo.samples.forEach((oSample) => {

      if (oSample.containers.length === 0)
      {
        errorMessages.push(`Containers must be generated on sample ${oSample.sampleName}.`);
      }

      if (oSample.containerManagementType === 'existing')
      {
        oSample.methods.forEach((oMethod) => {
          if (!oMethod.containerSelectionType || oMethod.containerSelectionType === null)
          {
            errorMessages.push(`Please select a container selection for method ${oMethod.method.name} on MIDAS number ${oSample.sampleName}.`);
          }
          if (!oMethod.createFromContainer || oMethod.createFromContainer === null) 
          {
            errorMessages.push(`You must select a container for sample ${oSample.sampleName} and method ${oMethod.method.name}`);
          }
        })

      } else {
        if (!oSample.methods[0].createFromContainer || oSample.methods[0].createFromContainer === null) 
        {
          errorMessages.push(`You must select a parent container for sample ${oSample.sampleName}`);
        }
      }
    })


    containerErrorChecks && containerErrorChecks.forEach((oContainerError, tabIndex) => {

      oContainerError.containerErrorInfo.forEach((oError, index) => {
        if (oError.containerType === true || oError.containerType === null) 
        {
          errorMessages.push(`A Container Type is required on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }

        if (oError.size === true || oError.size === null) 
        {
          errorMessages.push(`A Container Size is required/invalid on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }

        if (oError.currentAmount === true || oError.currentAmount === null)
        {
          errorMessages.push(`A Container Amount is required/invalid on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }

        if (oError.uom === true || oError.uom === null)
        {
          errorMessages.push(`A Container Unit of Measure is required on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }

        if (oError.location === true || oError.location === null)
        {
          errorMessages.push(`A Container Initial Location is required on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }

        if (oError.ownerEmail === true || oError.ownerEmail === null)
        {
          errorMessages.push(`A Container Owner is required on sample ${mySampleInfo.samples[tabIndex].sampleName} on Container ${mySampleInfo.samples[tabIndex].containers[index].containerNumber}.`);
        }
      })
    });

    mySampleInfo.samples.forEach((oSample, index) => {
      oSample.containers.forEach((oContainer, containerIndex) => {

        //check to make sure they didn't reduce lower than the original amount
        //need at least the min for testing
        if (oContainer._originalAmount)
        {
          if (oContainer.currentAmount.toString().trim() !== '' && oContainer.uom !== null)
          {
            if (ConvertUOMs(oSample.sampleObject, oSample.sampleObject.substance, oContainer.currentAmount, oContainer.uom, oContainer._originalUoM) < oContainer._originalAmount)
            {
              errorMessages.push(`The current amount (${oContainer.currentAmount} ${oContainer.uom.uoMName}) of container ${oContainer.containerNumber} on sample ${oSample.sampleName} is less than the necessary amount for testing (${oContainer._originalAmount} ${oContainer._originalUoM.uoMName}).`)
            }
          }
        }
      })
    })

    //need a check to see if the parent container has enough volume to create all the children
    //will do this in the APIs
    if (errorMessages.length === 0) {
      SubmitTestingRequest();
    } else {
      openModalMessages("Containering Errors", errorMessages, "Ok")
    }
  }

  const handleSideBarNav = (newValue) => {
    let intNewValue = parseInt(newValue.currentTarget.value, 10)

    //sample page
    switch (intNewValue){
      case 0:{

        setDisplayScreenValue(intNewValue)

        return(
        setNavBarTextColor(["#1460A6","#666666", "#666666"])
        );
      }

      //method page
      case 1:{
        if (samplePageValided) {

          setDisplayScreenValue(intNewValue)

          return(
            setNavBarTextColor(["#1460A6","#1460A6", "#666666"])
            );
        } else {return null}
      
      }

      //summary page
      case 2:{
        if (samplePageValided && methodPageValided) {

          setDisplayScreenValue(intNewValue)

          return(
            setNavBarTextColor(["#1460A6","#1460A6", "#1460A6"])
            );
        } else {return null}
      }
      
      default: {
        alert(displayScreenValue);
      }
    }
  };

  const SubmitTestingRequest = (e) => {
    let myContainers = [];

    setIsTestSaving(true);

    mySampleInfo.samples.forEach((oSample) => {
      oSample.containers.forEach((oContainer) => {
        myContainers.push(oContainer);
      });
    });

    Container.CreateContainersWithTests(myContainers).then((res) => {
      if (res && res.message === "Success") {
        let arrayDraftsToCreate = []

        res.result.forEach(oContainer => {
          oContainer.tests.forEach(oTest => {

            if (oTest.methodFacility.testFacilityAbv === 'EXT')
            {
              let myShippingAddress = {
                etqid: oTest.externalLaboratory?.shippingAddress?.etqid ? oTest.externalLaboratory?.shippingAddress?.etqid : null, 
                recipientName: oTest.externalLaboratory?.shippingAddress?.recipientName ? oTest.externalLaboratory?.shippingAddress?.recipientName : null,
                company: oTest.externalLaboratory?.shippingAddress?.company ? oTest.externalLaboratory?.shippingAddress?.company : null, 
                department: oTest.externalLaboratory?.shippingAddress?.department ? oTest.externalLaboratory?.shippingAddress?.department : null, 
                address1: oTest.externalLaboratory?.shippingAddress?.address1 ? oTest.externalLaboratory?.shippingAddress?.address1 : null, 
                address2: oTest.externalLaboratory?.shippingAddress?.address2 ? oTest.externalLaboratory?.shippingAddress?.address2 : null, 
                city: oTest.externalLaboratory?.shippingAddress?.city ? oTest.externalLaboratory?.shippingAddress?.city : null, 
                state: oTest.externalLaboratory?.shippingAddress?.state ? oTest.externalLaboratory?.shippingAddress?.state : null, 
                country: oTest.externalLaboratory?.shippingAddress?.country ? oTest.externalLaboratory?.shippingAddress?.country : null, 
                postalCode: oTest.externalLaboratory?.shippingAddress?.postalCode ? oTest.externalLaboratory?.shippingAddress?.postalCode : null, 
                phoneNumber: oTest.externalLaboratory?.shippingAddress?.phoneNumber ? oTest.externalLaboratory?.shippingAddress?.phoneNumber : null, 
                emailAddress: oTest.externalLaboratory?.shippingAddress?.emailAddress ? oTest.externalLaboratory?.shippingAddress?.emailAddress : null, 
                comments: oTest.externalLaboratory?.shippingAddress?.comments ? oTest.externalLaboratory?.shippingAddress?.comments : null
              }

              let defaultShippingRequest = {
                workRequest: null, 
                description: null, 
                requestedDeliveryDate: null, 
                numberOfItems: 0,
                containsSamples: false, 
                carrier: null, 
                printBlindCode: false, 
                attachCOA: false, 
                shippingAddress: myShippingAddress,
                trackingRecords: null, 
                tscaReviews: null, 
                is3PTesting: false, 
                shippingReason: null, 
                shippingComments: null, 
                referencePO: null, 
                isPrepaid: null, 
                shippingItems: []                
              }
            
              //check to see if a shipping request for this address already exists
              //if so, simply add the container/sample to it
              var workingIndex

              if (arrayDraftsToCreate.some(element => element.externalLaboratoryName === oTest.externalLaboratoryName))
              {
                workingIndex = arrayDraftsToCreate.requestData.findIndex(element => element.externalLaboratoryName === oTest.externalLaboratoryName)
            
              } else {
                arrayDraftsToCreate.push({
                  externalLaboratoryName: oTest.externalLaboratoryName, 
                  requestData:null
                })

                workingIndex = arrayDraftsToCreate.findIndex(element => element.externalLaboratoryName === oTest.externalLaboratoryName)   

                arrayDraftsToCreate[workingIndex].requestData = {
                  typeName: "Shipping",
                  workRequestTypeName: null,
                  statusName: 'InProgress',
                  workRequestStatusName: null,
                  facilityName: null,
                  workRequestFacilityName: null,
                  description: '',
                  requestedByEmail: oContainer.ownerEmail,
                  createdByEmail: null,
                  lastModifiedByEmail: null,
                  lastModifiedDate: null,
                  requestedCompletionDate: null,
                  lastAcceptableCompletionDate: null,
                  assignedDate: null,
                  inProgressDate: null,
                  completedDate: null,
                  priorityName: 'Normal',
                  workRequestPriority: null,
                  priorityReason: '',
                  chargeCode: oTest.chargeCode,
                  deliveryLocationName: null,
                  workRequestLocation: null,
                  assignedToEmail: '',
                  testSubmissionJSON: '',
                  projectID: null,
                  project: null,
                  businessUnitName: null,
                  businessUnit: null,
                  workRequestSamples: [],
                  shippingRequest: defaultShippingRequest
                }
              }

              //add the work request samples/containers if they dont already exist
              if (!(arrayDraftsToCreate[workingIndex].requestData.workRequestSamples.some(element => element.sampleName === oContainer.sampleName && element.existingContainerID === oContainer.id)))
              {
                
                arrayDraftsToCreate[workingIndex].requestData.workRequestSamples.push({
                  workRequest: null, 
                  sampleName: oContainer.sampleName,
                  sample: JSON.parse(JSON.stringify(oContainer.sample)),
                  requestedAmount: 0,
                  requestedAmountUoM: null,
                  unitOfMeasure: null, 
                  newContainerID: null, 
                  existingContainerID: oContainer.id,
                  container: null, 
                  requestedLocationName: oContainer.locationName,
                  location: null, 
                  shippingItems: null
                });
              }

              //add to the shipping items collection
              if (!(arrayDraftsToCreate[workingIndex].requestData.shippingRequest.shippingItems.some(element => element.sampleName === oContainer.sampleName)))
              {
                arrayDraftsToCreate[workingIndex].requestData.shippingRequest.shippingItems.push({
                  thisRequest: null, 
                  continerID: null, 
                  thisContainer: null, 
                  description: null, 
                  dotHazardClass: null, 
                  exportValue: null, 
                  flashPointInC: null, 
                  quantity: null, 
                  quantityUoMName: null, 
                  quantityUoM: null, 
                  comments: null, 
                  sampleName: oContainer.sampleName
                });
              }

              arrayDraftsToCreate[workingIndex].requestData.shippingRequest.numberOfItems = arrayDraftsToCreate[workingIndex].requestData.shippingRequest.shippingItems.length;            
            }

          })
        })

        let lstDraftInfo = []

        if (arrayDraftsToCreate.length > 0)
        {
          arrayDraftsToCreate.forEach(element => {

            var d = new Date();
            var dtformat = [`${d.getMonth()+1}${d.getDate()}${d.getFullYear()}`]+'_'+[`${d.getHours()}${d.getMinutes()}${d.getSeconds()}`];
  
            lstDraftInfo.push({
              draftOwnerEmail: null,
              draftName: `ShipTo: ${element.requestData.shippingRequest.shippingAddress.recipientName ? element.requestData.shippingRequest.shippingAddress.recipientName : 'N/A'}_${dtformat}`,
              draftJSON: JSON.stringify(element.requestData),
              draftType: "workRequest",
              createdDate: null,
              lastModifiedDate: null
            });          
          });
      
          Draft.addList(lstDraftInfo).then((resDraft) => {
            if (resDraft.message === 'Success') {
              openModalThreeButtons("Containers with Tests Created!", "Your containers and tests were created successfully and draft work requests were created for external shipping.", "Print Labels", "View My Tests", "View My Samples");

            } else {
              openModalMessages('Draft Save Failed', `${resDraft.message} .  You draft shipping requests failed to save, contact support if this continues.`);
              openModalThreeButtons("Containers with Tests Created!", "Your containers and tests were created successfully.", "Print Labels", "View My Tests", "View My Samples");
            }
          }) 
        } else {
          openModalThreeButtons("Containers with Tests Created!", "Your containers and tests were created successfully.", "Print Labels", "View My Tests", "View My Samples");
        }

        setCreatedContainerInfo(res.result);

        if (draftObj) {
          Draft.delete(draftObj.id).then(() => {});
        }
      } else {
        openModalMessages("Containers/Tests Failed to Save", `${res.message}. Contact support if you feel this is an error.`, "Ok");
      };

      setIsTestSaving(false);
    });
  };

  function openPrintInfo() {
    let arrayPrintInfo = []
    let myContainerNumbers = []

    mySampleInfo.samples.forEach((oSample) => {
      myContainerNumbers = [];
      const createdContainers = createdContainerInfo.filter((c) => c.sampleName === oSample.sampleObject.sampleName);

      createdContainers.forEach ((oContainer) => {
        myContainerNumbers.push(oContainer.containerNumber)        
      })

      let newPrintInfo = new PrintLabel({
        sampleName: oSample.sampleObject.sampleName,
        containerNumbers: myContainerNumbers,
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false
      })
  
      arrayPrintInfo.push(newPrintInfo)
    })

    setPrintLabelInfo(arrayPrintInfo)
    setPrintInfoOpen(true)  
  }

  function closePrintInformation() {
    setPrintInfoOpen(false)
    window.location.href = "/tests"
  }

  const renderCorrectPage = () => {
  switch (displayScreenValue) {
    case 0: {
      return (
        <NewTest
            mySampleInfo={mySampleInfo}
            setMySampleInfo={setMySampleInfo}
            containerErrorChecks={containerErrorChecks}
            setContainerErrorChecks={setContainerErrorChecks}
        ></NewTest>
      );
    }
    case 1: {
      return (
        <AddMethods
           mySampleInfo={mySampleInfo}
           setMySampleInfo={setMySampleInfo}
        ></AddMethods>
      );
    }
    case 2: {
      return (
        <TestSummary
            mySampleInfo={mySampleInfo}
            setMySampleInfo={setMySampleInfo}
            errorChecks={errorChecks}
            setErrorChecks={setErrorChecks}
            availableLocations={availableLocations}
            availableUOMs={availableUOMs}
            containerTypes={containerTypes}
            containerErrorChecks={containerErrorChecks}
            setContainerErrorChecks={setContainerErrorChecks}
            tabValue={tabValue}
            setTabValue={setTabValue}
            setTriggerContainerReload={setTriggerContainerReload}
        ></TestSummary>
      );
    }
  
    default: {
      alert(displayScreenValue);
    }
  }
  };

  const renderNavagationButton = () => {
  switch (displayScreenValue) {
  case 0: {
      return (
          <Button
          name="btnMethods"
          variant='contained'
          style={{ textTransform: "none", marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
          onClick={(e) => CheckForSampleErrors()}
          >Select Methods</Button>
      );
    }
    case 1: {
      return (
        <Button
        name="btnTestSummary"
        variant='contained'
        style={{ textTransform: "none", marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
        onClick={(e) => ConfirmTestsSelection()}
        >Test Summary</Button>
      );
    }

    case 2: {
        return (
          isTestSaving ?
          (<>
              <CircularProgress style={{textTransform: "none",  marginTop:"50px", marginLeft:"15px"}}></CircularProgress>
              <Button  name="btnRegister"
                disabled
                variant='contained'
                style={{ textTransform: "none",  marginTop:"-30px", marginLeft:"10px", marginRight:"10px", width:"60%"}}
                onClick={(e) => ConfirmTestSubmissionData()}
              >Submit Test Request</Button>
          </>
          ):(
              <Button  name="btnRegister"
                variant='contained'
                style={{ textTransform: "none", marginTop:"15px", marginLeft:"15px", marginRight:"10px", width:"90%"}}
                onClick={(e) => ConfirmTestSubmissionData()}
              >Submit Test Request</Button>
          )
        )
      }
  
    default: {
      alert(displayScreenValue);
    }
  }
  }
  
  return (
    <div>
          <span className='pageheader'>Request A New Test</span>
          <Divider className='dividerbar' />
      
          
          <p style={{
              color:"#111112",
              fontSize:"15px",
              fontFamily:"EMprint"
          }}>Add your samples, select your type of test, and your methods. </p>

      <Drawer sx={{ 
          width: 200,
          flexShrink: 0,
           [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          marginTop:"25px",
         }} 
        variant="permanent"
        open
        anchor="right"
      >
        <Box style={{height:"69px"}}>test</Box>
        <Toolbar/>
        <Divider />
  
          <p style={{
               fontFamily:"EMprint",
               fontWeight:"600",
               fontSize:"20px",
               color:"#545459",
               marginBottom:"35px",
               textTransform: 'none',
               textAlign:"left",
               paddingLeft:"20px"
              }}>New Test Summary</p>

          <Button style={{
                color:navBarTextColor[0],
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                textTransform: 'none',
                alignSelf: "flex-start",
                marginTop:"-20px",
                paddingLeft:"20px"
              }}
              value={0}
              onClick={handleSideBarNav}
              >Add Samples</Button>

              {mySampleInfo.samples && mySampleInfo.samples.map((sampleInfo, index) => {

                
                return (
                    <p key = {index} style={{
                    fontFamily:"EMprint",
                    fontWeight:"600",
                    fontSize:"14px",
                    color:"#545459",
                    marginBottom:"5px",
                    textAlign:"left",
                    paddingLeft:"20px",
                    alignSelf: "flex-start",
                   }}>{formatMidasNumber(sampleInfo.sampleName)}</p>
              )})}
            
              <p></p>

          <Button style={{
                color:navBarTextColor[1],
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                textTransform: 'none',
                alignSelf: "flex-start",
                marginTop:"20px",
                paddingLeft:"20px"
              }}
              value={1}
              onClick={handleSideBarNav}
              >Add Methods</Button>
              
              {mySampleInfo.masterMethods.map((methodInfo, index) => {

                    if (!(methodInfo.method === null) && !(methodInfo.method.name === null)) {
                      return (
                          <p key = {index} style={{
                          fontFamily:"EMprint",
                          fontWeight:"600",
                          fontSize:"14px",
                          color:"#545459",
                          marginBottom:"5px",
                          textAlign:"left",
                          paddingLeft:"20px"
                        }}>{methodInfo.method.name}</p>
                        )
                    } else {return null}
                  })}
    
              <p></p>
              
          <Button style={{
                color:navBarTextColor[2],
                fontFamily:"EMprint",
                fontWeight:"600",
                fontSize:"16px",
                alignSelf: "flex-start",
                textTransform: 'none',
                marginTop:"20px",
                paddingLeft:"20px"
              }}
              uppercase='false'
              value={2}
              onClick={handleSideBarNav}
              >Test Summary</Button>


          <div style={{bottom:0, position:"absolute", marginBottom:"75px"}}>
            {renderNavagationButton()}

            <GlobalSecondaryButton
                name="btnSaveDraft"
                variant='contained'
                style={{ textTransform: "none", marginTop:"15px", marginLeft:"15px", marginRight:"10px", backgroundColor:"white", color:"#0C69B0", width:"90%"}}
                onClick={(e) => draftButtonText === "Update Draft" ? SaveNewDraft(e) : openModalDrafts("Save Draft", "Enter a name for your new draft", "Save", "Cancel")}
            >{draftButtonText}</GlobalSecondaryButton>
          </div>
     
  </Drawer>
  {renderCorrectPage()}

        <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>

          {Array.isArray(modalMessagesText) ? (
            modalMessagesText && modalMessagesText.map((text, index) => (
              <div style={{display:"flex"}} key={`mySamples${index}`}>
                  <label>
                    {text}
                  </label>
                </div>
              ))
          ) : (
            <label>
              {modalMessagesText}
            </label>
          )} 

        </ModalMessages>

        <ModalTwoButtons title={modalDraftsTitle} button1Text={modalDraftsButton1Text} button1Action={SaveNewDraft} button2Text={modalDraftsButton2Text} button2Action={closeModalDrafts} open={modalDraftsOpen} setOpen={setModalDraftsOpen}>
          <label>
            {modalDraftsText}
          </label>

          <div></div>

          <TextField
            size="small"
            label = "Draft Name"
            value = {draftSaveName}
            onChange = {e => setDraftSaveName(e.target.value)}
            margin = "normal"
            style={{width:250, marginTop:40}}
            inputProps={{ maxLength: 250 }}
          ></TextField>
             
        </ModalTwoButtons>

        {/* Final Message Box */}
        <ModalThreeButtons title={modalThreeBtnTitle} button1Text={modalThreeBtnButton1Text} button1Action={openPrintInfo} button2Text={modalThreeBtnButton2Text} 
            button2Action={NavagateToMyTests} button3Text={modalThreeBtnButton3Text} button3Action={closeModalThreeButtons} open={modalThreeBtnOpen} setOpen={setModalThreeBtnOpen}>

            <label>
                {modalThreeBtnText}
            </label>
           
        </ModalThreeButtons>

        <PrintInformation 
          open={printInfoOpen} 
          setOpen={setPrintInfoOpen} 
          button2Action={closePrintInformation} 
          button2Text={"View My Tests"}
          printLabelInfo={printLabelInfo}
          isShelfLabel={false}
          > 
        </PrintInformation>

        <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
          <label>
            {modalNoButtonText}
          </label>
        </ModalNoButton>
  </div> 
    
      );
    };
    
export default TestManagement;
