import { useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import { ConvertUOMs, isNumber, RoundNumber } from "../../../../global";
import { StyledAutocomplete } from "../constants.tsx";
import { TextField, Box, Stack, Typography } from "@mui/material";

const ExportValueCalculator = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    shippingItem,
    setCalculatedExportValue
}) => {
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [exportValue, setExportValue] = useState(0);
    const [error, setError] = useState(null);

    const sample = shippingItem.sample;
    const container = sample?.containers?.find(f => f.id === shippingItem.containerID);
    const convertToLiterUom = {
        uoMName: "L",
        type: "volume",
        metricStandardUoMName: "ml",
        metricStandardConversion: 1000,
      };
    const containerAmountL = ConvertUOMs(sample, sample?.substance, container?.currentAmount, container?.uom, convertToLiterUom);
    const isDisabled = error || !selectedProduct;

    function apply() {
        setCalculatedExportValue(exportValue)
        setIsModalClose();
    }

    function productSelectionChangeAndCalculate(value) {
        setSelectedProduct(value);
        if (!value) return;

        if (!isNumber(containerAmountL)) {
            setError(containerAmountL ?? "Invalid sample/container data");
        }
        else {
            const exportValue = containerAmountL * value.price;
            setError(null);
            setExportValue(exportValue);
        }
    }

    return <ModalTwoButtons
        title="Export Value Calculator"
        button1Text={"Apply"}
        button1Action={apply}
        isButton1Disabled={isDisabled}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <div> 
            This calculator will compute an estimated value for the sample <br/>
            based on container size and the selected product type.
        </div>
        <Box sx={{ p: 4 }}>
        <StyledAutocomplete
            disablePortal
            noOptionsText={ "No Product types" }
            options={ExportProductTypes}
            getOptionLabel={(option) =>
                option.name +' - $' + option.price 
            }
            onChange={(e, value) =>
                productSelectionChangeAndCalculate(value)
            }
            autoHighlight
            autoSelect
            value={selectedProduct}
            isOptionEqualToValue={(option, value) =>
                value?.name === option?.name
            }
            style={{ width: "100%" }}
            renderInput={(params) => (
                <TextField
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="* Product Price/Liter"
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                />
            )}
        />
        <Stack direction="row"sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h7" component="div">  Container Size </Typography>
          <Typography variant="h6" component="div"> {
                container?.currentAmount + " " + container?.containerSizeUoM
            }
          </Typography>
        </Stack>
        <Stack direction="row"sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h7" component="div">  Export Value  </Typography>
          <Typography variant="h6" component="div"> {
              !isDisabled &&  "$" + RoundNumber(exportValue, 2) 
            }
          </Typography>
        </Stack>
        <div style={{ color: "red", marginTop: 10, fontSize: "small"}}>
            {error}
        </div>
      </Box>
    </ModalTwoButtons>
}
export default ExportValueCalculator

const ExportProductTypes = [
    { name: 'Asphalt', price: 0.14 },
    { name: 'Aviation\\Flagship', price: 6.37 },
    { name: 'Aviation\\Standard', price: 3.40 },
    { name: 'Base Stock', price: 0.37 },
    { name: 'CVL\\Alliance', price: 1.19 },
    { name: 'CVL\\Flagship', price: 3.10 },
    { name: 'CVL\\Premium', price: 1.10 },
    { name: 'CVL\\Standard', price: 0.96 },
    { name: 'Industrial\\Alliance', price: 0.47 },
    { name: 'Industrial\\Flagship', price: 4.36 },
    { name: 'Industrial\\Premium', price: 1.07 },
    { name: 'Industrial\\Standard', price: 0.80 },
    { name: 'Marine\\Flagship', price: 5.90 },
    { name: 'Marine\\Premium', price: 1.14 },
    { name: 'Marine\\Standard', price: 1.14 },
    { name: 'PVL\\Alliance', price: 1.15 },
    { name: 'PVL\\Flagship', price: 2.28 },
    { name: 'PVL\\Premium', price: 1.12 },
    { name: 'PVL\\Standard', price: 0.90 },
    { name: 'Wax', price: 0.94 },
];
