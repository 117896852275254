import { useEffect, useState } from 'react';
import { List, ListItem, Divider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailAddressTextField from '../../../../components/EmailAddressField';
import { GlobalButton, GlobalSecondaryButton } from '../../../styles';
import SDSRequest from '../../../../api/SDSRequest/SDSRequest';
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";

const recipientTypes = {
  Submission: 'Submission',
  Completed: 'Completed',
};

const SdsNotificationAdmin = () => {
  const [emailsSubmissions, setEmailsSubmissions] = useState([{ emailAddress: '', recipientType: recipientTypes.Submission }]);
  const [errors, setErrors] = useState({});
  const [emailsCompleted, setEmailsCompleted] = useState([{ emailAddress: '', recipientType: recipientTypes.Completed }]);
  const [additionalErrors, setAdditionalErrors] = useState({});

  
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  useEffect(() => {
    const fetchData = async () => {
      await getEmails();
    };

    fetchData();
  }, []);

  const getEmails = async () => {
    const data = await SDSRequest.getSdsNotificationRecipients();

    if (data.result) {
      const submissions = data.result.filter((item) => item.recipientType === recipientTypes.Submission);
      setEmailsSubmissions(submissions);
      const additional = data.result.filter((item) => item.recipientType === recipientTypes.Completed);
      setEmailsCompleted(additional);
    }
  };

  const handleEmailChange = (index, value, setEmailState) => {
    setEmailState((prevEmails) => {
      const newEmails = [...prevEmails];
      newEmails[index].emailAddress = value;
      return newEmails;
    });
  };

  const handleAddEmail = (setEmailState, recipientType = '') => {
    setEmailState((prevEmails) => [...prevEmails, { emailAddress: '', recipientType }]);
  };

  const handleDeleteEmail = (index, setEmailState) => {
    setEmailState((prevEmails) => prevEmails.filter((_, i) => i !== index));
  };

  const handleSaveChanges = async () => {
    const emailsToSave = [...emailsSubmissions, ...emailsCompleted]
      .map((item) => {
        return {
          emailAddress: item.emailAddress,
          recipientType: item.recipientType,
        };
      });

    //const uniqueEmails = new Set(emailsToSave.map(email => email.emailAddress));

    const emailListSubmission = emailsToSave.filter(obj => obj.recipientType ===recipientTypes.Submission).map(email => email.emailAddress);
    const emailListCompleted = emailsToSave.filter(obj => obj.recipientType ===recipientTypes.Completed).map(email => email.emailAddress);

    const uniqueEmailsSubmission = new Set(emailListSubmission.map(email => email));
    const uniqueEmailsCompleted = new Set(emailListCompleted.map(email => email));

    //if (uniqueEmails.size !== emailsToSave.length)  
    if (uniqueEmailsSubmission.size !== emailListSubmission.length || uniqueEmailsCompleted.size !== emailListCompleted.length) 
    {
      openModalMessages('Duplicate Emails', `Duplicate emails found in on of the lists.`)
      return;
    }

    SDSRequest.updateSdsNotificationRecipients(emailsToSave).then((response) => {
      if (response.message === 'Success')
      {
        openModalMessages('Save Complete', `Changes saved successfully!`)
      } else {
        if (response.message)
        {
          openModalMessages('Settings Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
        } else {
          openModalMessages('Settings Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
        }
      }
    })
  }

  const handleCancelChanges = () => {
    getEmails();
  }

  return (
    <div>

      <Typography variant="h5" style={{ marginTop: "1rem" }}>Submission Notifications</Typography>

      <small>Add any user that should be notified when a new SDS request is submitted.</small>
      <List style={{ marginTop: "1rem" }}>
        {emailsSubmissions.map((item, index) => (
          <ListItem key={index}>
            <EmailAddressTextField
              style={{ marginTop: "-8px" }}
              fieldWidth={"500px"}
              fontSize={16}
              validatedUserEmail={item.emailAddress}
              setValidatedUserEmail={(value) => handleEmailChange(index, value, setEmailsSubmissions)}
              setHasErrors={(error) => {
                setErrors((prev) => ({ ...prev, [index]: error }));
              }}
              hasErrors={errors[index] === null ? false : errors[index]}
              labelText="Email"
              showPlusMeButton={true}
              disabled={false}
            />
            <GlobalSecondaryButton
              style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
              variant="contained"
              onClick={() => handleDeleteEmail(index, setEmailsSubmissions)}
              title='Remove this email'
            >
              <DeleteIcon />
            </GlobalSecondaryButton>
          </ListItem>
        ))}
        <ListItem>
          <GlobalButton
            variant="contained"
            onClick={() => handleAddEmail(setEmailsSubmissions, recipientTypes.Submission)}
            title='Add a new email to the list'
          >
            <AddIcon /> Add new
          </GlobalButton>
        </ListItem>
      </List>

      <Divider style={{ margin: "2rem 0" }} />

      <Typography variant="h5">Completed Notifications</Typography>
      <small>Add users, besides the requestor, that need to receive a notification that a SDS request has been completed.</small>

      <List style={{ marginTop: "1rem" }}>
        {emailsCompleted.map((item, index) => (
          <ListItem key={index}>
            <EmailAddressTextField
              style={{ marginTop: "-8px" }}
              fieldWidth={"500px"}
              fontSize={16}
              validatedUserEmail={item.emailAddress}
              setValidatedUserEmail={(value) => handleEmailChange(index, value, setEmailsCompleted)}
              setHasErrors={(error) => {
                setAdditionalErrors((prev) => ({ ...prev, [index]: error }));
              }}
              hasErrors={additionalErrors[index] === null ? false : additionalErrors[index]}
              labelText="Email"
              showPlusMeButton={true}
              disabled={false}
            />
            <GlobalSecondaryButton
              style={{ marginRight: "1rem", marginLeft: "1.5rem" }}
              variant="contained"
              onClick={() => handleDeleteEmail(index, setEmailsCompleted)}
              title='Remove this email'
            >
              <DeleteIcon />
            </GlobalSecondaryButton>
          </ListItem>
        ))}
        <ListItem>
          <GlobalButton
            variant="contained"
            onClick={() => handleAddEmail(setEmailsCompleted, recipientTypes.Completed)}
            title='Add a new email to the list'
          >
            <AddIcon /> Add new
          </GlobalButton>
        </ListItem>
      </List>

      <GlobalButton
        variant="contained"
        style={{ marginTop: "2rem" }}
        title='Save changes'
        onClick={handleSaveChanges}
      >
        Save Changes
      </GlobalButton>

      <GlobalSecondaryButton
        variant="contained"
        style={{ marginTop: "2rem", marginLeft: "1rem" }}
        title='Cancel changes'
        onClick={handleCancelChanges}
      >
        Cancel
      </GlobalSecondaryButton>

      {/* Informational Messages */}
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          {Array.isArray(modalMessagesText) ?
          (modalMessagesText && modalMessagesText.map((text, index) => {
              return (
                  <div style={{display:"flex"}} key={`myErrorChecks${index}`}>
                      <label>
                      {text}
                      </label>
                  </div>
              )
              })
          )
          :
          ( <label>
              {modalMessagesText}
            </label>
          )}      
      </ModalSimpleButton>
    </div>
  );
};

export default SdsNotificationAdmin;