import { Box } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import Project from "../../../../api/Admin/Project";
import { useState } from "react";
import { CustomNetworkError } from "../../../../api/helpers";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { Alert } from "../../../Admin/General/ProjectManagement/components/Alert";

export type ProjectResubmitFormProps = {
    project: Project | undefined;
    onSave?: () => void;
    onCancel: () => void;    
};

export type ProjectResubmitFormCriteria = {
    id: number;
    name: string;
    description: string;
    ownerEmail: string;
    secondaryOwnerEmail: string;
    expirationDate?: Date;
    restrictAtEndOfLife: boolean;
    isActive: boolean;
    ndaAgreementFile?: File;
};

type ProjectResubmitAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void;
};

export const ProjectResubmitForm = ({
    project,
    onSave,
    onCancel
}: ProjectResubmitFormProps) => {
    
    const [processLoading, setProcessLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<ProjectResubmitAlert | undefined>(undefined);

    const handleAlertOkClick = () => {
            onSave?.();
            alert?.onOk?.();
            setAlert(undefined);
            onCancel?.();
    };

    const handleResubmitClick = () =>
    {
        setProcessLoading(true);
        Project.resubmitReviewProjects(project?.id)
        .then((response: Project) => {
            if(response.reviewStatus === "Pending"){
                setAlert(successfullyResubmittedProjectAlert);
            }  
        })
        .catch((err) => {            
            setAlert(errorResubmittingProjectAlert(getNetworkErrorMessage(err)));
        })
        .finally(() => {
            setProcessLoading(false);            
        });
    };

    return(
    <>
        <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} textAlign={"center"}>
            <Box display="flex" flexDirection={"row"}>Do you want to submit the following project for review?</Box>            
            <br></br>
            <Box display="flex" flexDirection={"row"} alignItems="center" justifyContent={"center"} textAlign={"center"} gap={2}>               
                <GlobalButton                        
                    variant='contained'
                    onClick={() => {handleResubmitClick()}}
                >Ok</GlobalButton>                               
                <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => {
                        onCancel();
                }}>
                Cancel
                </GlobalSecondaryButton>
            </Box>           
        </Box>
        <ProgressSpinner display={processLoading} />
        <Alert
            title={alert?.title}
            message={alert?.message}
            details={alert?.details}
            display={alert !== undefined}
            onOk={handleAlertOkClick}
        />
    </>
    );
};

const successfullyResubmittedProjectAlert: ProjectResubmitAlert = {
    title: "Resubmitted",
    message: "Project was successfully resubmitted.",
};

const errorResubmittingProjectAlert = (details: string): ProjectResubmitAlert => ({
    title: "Error",
    message: "There was an error resubmitting project.",
    details: `Reason: ${details}`,
});

const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403)
    {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};
