import { Box, Button, FormControl, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState, useContext, useEffect } from "react";
import GeneralInformation from "./GeneralInformation";
import SafetyInformation from "./SafetyInformation";
import ChemicalAndStructure from "./ChemicalAndStructure";
import RegionSpecificInformation from "./RegionSpecificInformation";
import Substance from "../../../api/LIMS/Substance";
import ModalSimpleButton from '../../../components/Modal/ModalSimpleButton';
import { hasRole, Roles, listDefaultRetentionMonths } from "../../../global";
import SubstanceStructureLookup from "../../../api/LIMS/SubstanceStructureLookup";
import SubstanceSubcomponent from "../../../api/LIMS/SubstanceSubcomponent";
import UserContext from "../../../context/UserContext";
import { GlobalButton } from "../../styles";
import { ChemIDSearchField } from '../../../components/ChemIDSearch/index';

const StyledContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '20px'
}));

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
}));

const StyledBox = styled(Box)(() => ({
  display: 'inline-box',
  marginRight: "25px"
}));

const StyledButton = styled(Button)(() => ({
  widht: '200px',
  textTransform: 'none',
  height: '2.2rem'
}));

const ManageSubstances = ({ ...props }) => {

  let substanceInitial = new Substance({
    chemID: '',
    substanceName: null,
    promotedChemID: null,
    parentChemID: null,
    chemIDCategoryName: null,
    description: null,
    isActive: true,
    isValidated: false,
    isExperimentalCode: false,
    isResearchCode: false,
    ownerEmail: '',
    createdByEmail: null,
    createdDate: '',
    lastModifiedByEmail: '',
    lastModifiedDate: '',
    lastSafetyModifiedByEmail: '',
    lastSafetyModifiedDate: '',
    psimsid: '',
    supplier: null,
    tradename: null,
    densityAtSTPgml: null,
    flashPointC: null,
    flashPointMethod: null,
    boilingPointC: null,
    nfpaf: null,
    nfpar: null,
    nfpah: null,
    hmisf: null,
    hmisr: null,
    hmish: null,
    isChronic: false,
    isPeroxidizable: false,
    isPHSCat1: false,
    isPHSCat2: false,
    isPHSCat3: false,
    isPHSCat4: false,
    isEngineeredNanoMaterial: false,
    smgDefaultRetentionMonths: null,
    specialHandlingName: null,
    jBlendDescription: null,
    jDescription: null,
    jFireSafetyCode: null,
    jShelfLife: null,
    jToxicMaterialCode: null,
    jghsCode: null,
    jsdsCode: null,
    substanceFiles: [],
    isCommerciallyAvailable: false,
    wasPurchased: false,
    commerciallyAvailableNotes: null,
    substanceTypeName: null,
    substanceSubtype1ID: null,
    substanceSubtype2ID: null,
    substanceSubtype3ID: null
  })

  let defaultSampleErrorChecks = {
    chemID: false,
    ownerEmail: null,
    substance: false,
    chemIDCategoryName: false,
    substanceName: false,
    nfpah: false,
    hmish: false,
    nfpaf: false,
    hmisf: false,
    nfpar: false,
    hmisr: false
  }

  const [chemIDTextFieldVal, setChemIDTextFieldVal] = useState(null)

  const [errorChecks, setErrorChecks] = useState(defaultSampleErrorChecks)
  const [currentSubstance, setCurrentSubstance] = useState(substanceInitial)
  const [isNewSubstance, setIsNewSubstance] = useState(true)

  const [modalSimpleButton, setModalSimpleButton] = React.useState(false);
  const modalSimpleButtonText = 'Ok'
  const [modalSimpleTitle, setModalSimpleTitle] = React.useState('');
  const [modalSimpleText, setModalSimpleText] = React.useState('');

  const [listOfStructuresLookup, setListOfStructuresLookup] = React.useState([]);
  const [listSubcomponent, setListSubcomponent] = React.useState([]);

  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;
  //const isDeveloper = false //tesitng purposes
  const isDeveloper = hasRole(Roles.Developer, roles);


  const canEdit = isDeveloper || currentSubstance.ownerEmail === currentUser.username;
  const canEditMaintainer = hasRole(Roles.SubstanceMaintainer, roles) || isDeveloper;
  const canEditSafety = hasRole(Roles.SubstanceSafetyMaintainer, roles) || isDeveloper;
  const canEditSubstanceStructures = hasRole(Roles.SubstanceStructureMaintainer, roles) || isDeveloper;

  const [substanceIsSaving, setSubstanceIsSaving] = useState(false)

  const [expandedPanel, setExpandedPanel] = useState('panel2');

  useEffect(() => {
    setExpandedPanel(null);
    }, [])

  function openModalSimpleButton(title, text) {
    setModalSimpleButton(true);
    setModalSimpleTitle(title);
    setModalSimpleText(text);
  }

  function closeModalSimpleButton() {
    setModalSimpleButton(false);
  }

  function validateFields(current, field) {
    current = current ? current : currentSubstance
    let chemID = errorChecks.chemID;
    let chemIDCategoryName = errorChecks.chemIDCategoryName;
    let ownerEmail = errorChecks.ownerEmail;
    let substanceName = errorChecks.substanceName;
    let nfpah = errorChecks.nfpah;
    let hmish = errorChecks.hmish;
    let nfpaf = errorChecks.nfpaf;
    let hmisf = errorChecks.hmisf;
    let nfpar = errorChecks.nfpar;
    let hmisr = errorChecks.hmisr;

    let error = false;

    if (!field || field === 'chemID') {
      chemID = (current.chemID === '' || current.chemID === null)
      error = (current.chemID === '' || current.chemID === null)
    }

    if (!field || field === 'chemIDCategoryName') {
      chemIDCategoryName = (current.chemIDCategoryName === '' || current.chemIDCategoryName === null)
      error = error ? error : (current.chemIDCategoryName === '' || current.chemIDCategoryName === null)
    }

    if (!field) {
      ownerEmail = (current.ownerEmail === '' || current.ownerEmail === null) ?
        'Required' : null;
      error = error ? error : (current.ownerEmail === '' || current.ownerEmail === null)
    }

    if (!field || field === 'substanceName') {
      substanceName = (current.substanceName === '' || current.substanceName === null)
      error = error ? error : (current.substanceName === '' || current.substanceName === null)
    }

    if (!field || field === 'nfpah') {
      nfpah = (current.nfpah === '' || current.nfpah === null)
      error = error ? error : (current.nfpah === '' || current.nfpah === null)
    }

    if (!field || field === 'hmish') {
      hmish = (current.hmish === '' || current.hmish === null)
      error = error ? error : (current.hmish === '' || current.hmish === null)
    }

    if (!field || field === 'nfpaf') {
      nfpaf = (current.nfpaf === '' || current.nfpaf === null)
      error = error ? error : (current.nfpaf === '' || current.nfpaf === null)
    }
    
    if (!field || field === 'hmisf') {
      hmisf = (current.hmisf === '' || current.hmisf === null)
      error = error ? error : (current.hmisf === '' || current.hmisf === null)
    }

    if (!field || field === 'nfpar') {
      nfpar = (current.nfpar === '' || current.nfpar === null)
      error = error ? error : (current.nfpar === '' || current.nfpar === null)
    }

    if (!field || field === 'hmisr') {
      hmisr = (current.hmisr === '' || current.hmisr === null)
      error = error ? error : (current.hmisr === '' || current.hmisr === null)
    }

    setErrorChecks(() => (
      { ...errorChecks, 
        chemID: chemID, 
        chemIDCategoryName: chemIDCategoryName, 
        ownerEmail: ownerEmail, 
        substanceName: substanceName, 
        nfpah: nfpah,
        hmish: hmish,
        nfpaf: nfpaf,
        hmisf: hmisf,
        nfpar: nfpar,
        hmisr: hmisr
       }))
    return error
  }

  const newSubstance = () => {
    setCurrentSubstance(() => (substanceInitial))
    setIsNewSubstance(true)
    setListOfStructuresLookup([])
    setListSubcomponent([])
    setChemIDTextFieldVal(chemIDTextFieldVal === null ? undefined : null)
  }

  async function hasSubstanceChemdID() {
    let hasSubstance = false
    const response = await Substance.getByChemID(currentSubstance.chemID, true, false);

    if (response && response.message === 'Success') {
      const substance = response.result;
      if (substance?.chemID === currentSubstance.chemID) {
        openModalSimpleButton('Fail', 'Substance already created', 'Ok');
        hasSubstance = true;
      }
        
    }
    return hasSubstance;
  }

  async function save() {
    if (!validateFields()) {
      setExpandedPanel(null);
      if (isNewSubstance) {
        if (await hasSubstanceChemdID()) return;

        setSubstanceIsSaving(true)

        await Substance.create({
          ...currentSubstance,
          chemID: (currentSubstance.chemIDCategoryName === 'RCC' || currentSubstance.chemIDCategoryName === 'EC') ? `${currentSubstance.chemIDCategoryName === 'RCC' ? 'RC' : currentSubstance.chemIDCategoryName}-${currentSubstance.chemID}` : currentSubstance.chemID,
          promotedChemID: currentSubstance.promotedChemID ? currentSubstance.promotedChemID?.chemID : null,
          parentChemID: currentSubstance.parentChemID ? currentSubstance.parentChemID?.chemID : null,
          smgDefaultRetentionMonths: currentSubstance.smgDefaultRetentionMonths !== 'Custom' ? currentSubstance.smgDefaultRetentionMonths : currentSubstance.retentionMonthsCustom,
          structureLookups: listOfStructuresLookup.map(item => {
            return {
              ...item,
              chemID: 0
            }
          }),
          subcomponents: listSubcomponent.map(item => {
            return {
              ...item,
              chemID: 0
            }
          })
        }).then(async (res) => {
          if (res.message === 'Success')
          {
            openModalSimpleButton("Substance Save Successfully", "Substance successfully saved!")
            newSubstance()
            setChemIDTextFieldVal(null)
    
          } else {
            if (res.message)
            {
              openModalSimpleButton("Substance Save Failed", `Your substance failed to save.  ${res.message}. Contact support if you feel this is an error.`);
            } else {
              openModalSimpleButton('Substance Save Failed', `Your substance failed to save.  Unspecified Error, Contact support if you feel this is an error.`);
            }
          }

          setSubstanceIsSaving(false)

        });
      } else {
        setSubstanceIsSaving(true)

        await Substance.update({
          ...currentSubstance,
          promotedChemID: currentSubstance.promotedChemID ? currentSubstance.promotedChemID?.chemID : null,
          parentChemID: currentSubstance.parentChemID ? currentSubstance.parentChemID?.chemID : null,
          smgDefaultRetentionMonths: currentSubstance.smgDefaultRetentionMonths !== 'Custom' ? currentSubstance.smgDefaultRetentionMonths : currentSubstance.retentionMonthsCustom,
          structureLookups: listOfStructuresLookup.map(item => {
            return {
              ...item,
              chemID: currentSubstance.chemID
            }
          }),
          subcomponents: listSubcomponent.map(item => {
            return {
              ...item,
              chemID: currentSubstance.chemID
            }
          })
        }).then(async (res) => {
          if (res.message === 'Success')
          {
            openModalSimpleButton("Substance Updated Successfully", "Substance successfully updated!")
            newSubstance()
            setChemIDTextFieldVal(null)
    
          } else {
            if (res.message)
            {
              openModalSimpleButton("Substance Update Failed", `Your substance failed to save.  ${res.message}. Contact support if you feel this is an error.`);
            } else {
              openModalSimpleButton('Substance Update Failed', `Your substance failed to save.  Unspecified Error, Contact support if you feel this is an error.`);
            }
          }

          setSubstanceIsSaving(false)

        });
      }
    }
    else{      
      setExpandedPanel('panel2');
    }
  }

  function loadSubstanceStructureLookup() {
    if (currentSubstance?.chemID !== '') {
      SubstanceStructureLookup.get(0, currentSubstance?.chemID).then((resp) => {
        setListOfStructuresLookup(resp)
      })
    }
  }

  function loadSubstanceSubcomponent(reload = false) {
    if (currentSubstance?.chemID !== '') {
      SubstanceSubcomponent.get(currentSubstance?.chemID).then((resp) => {
        setListSubcomponent(resp.sort((a, b) => b.componentOrder - a.componentOrder))
        if (reload) onChangeListSubcomponent(resp)
      })
    }
  }

  async function handleCurrentSubstance(value) {
    let transformedSubstance = structuredClone(value)

    transformedSubstance.smgDefaultRetentionMonths = populateRetentionMonthValue(value.smgDefaultRetentionMonths)
    transformedSubstance.retentionMonthsCustom = transformedSubstance.smgDefaultRetentionMonths === 'Custom' ? value.smgDefaultRetentionMonths : null

    if (transformedSubstance?.parentChemID && transformedSubstance?.parentChemID !== "") {
      const parentChemID = await Substance.getByChemID(transformedSubstance?.parentChemID, true, false);
      
      if (parentChemID && parentChemID.result) {
        transformedSubstance = {
          ...transformedSubstance,
          parentChemID: parentChemID.result
        }
      }
    }
    setCurrentSubstance(transformedSubstance)

    setIsNewSubstance(false)

    if (value.chemID && value.chemID !== "") {
      const chemID = value.chemID
      SubstanceStructureLookup.get(0, chemID).then((resp) => {
        setListOfStructuresLookup(resp)
      })
      SubstanceSubcomponent.get(chemID).then((resp) => {
        if (resp.some(i => i.percentageCompositionLower !== null)){
          resp.sort((a, b) => b.componentOrder - a.componentOrder)
        }
        setListSubcomponent(resp)
      })
    }
  }

  function populateRetentionMonthValue (currentRetention) {
    if (currentRetention === null) {
      return null
    }

    if (listDefaultRetentionMonths.includes(currentRetention))
    {
      return currentRetention
    } else {
      return 'Custom'
    }
  }

  async function onChangeListSubcomponent(list) {
    const chemIDs = list.map(item => item.subcomponentChemID);
    
    const propsHRF = [
      'nfpaf',
      'nfpar',
      'nfpah',
      'hmisf',
      'hmisr',
      'hmish',
      'isChronic', 
      'isPeroxidizable',
      'isPHSCat1',
      'isPHSCat2',
      'isPHSCat3',
      'isPHSCat4',
      'isEngineeredNanoMaterial',
      'smgDefaultRetentionMonths'
    ]

    const propsEmpty = getPropsEmptyOrNull(currentSubstance, propsHRF)

    if (chemIDs.length > 0 && (!currentSubstance.isLockedSafety || propsEmpty.length > 0)) {
      const hrfCalculate = await Substance.hrfCalculate(chemIDs);
      if (hrfCalculate) {
        let substance = currentSubstance;
        
        const propsToChange = currentSubstance.isLockedSafety ? propsEmpty : propsHRF;
       
        propsToChange.forEach(prop => {
          substance[prop] = hrfCalculate[prop] ?? substance[prop]
        });
        
        setCurrentSubstance({...substance})
      } 
    }
  }

  function getPropsEmptyOrNull(obj, props) {
    const propsEmptyOrNull = [];
    for (const prop of props) {
      if (obj.hasOwnProperty(prop) && (obj[prop] === '' || obj[prop] === null)) {
        propsEmptyOrNull.push(prop);
      }
    }
    return propsEmptyOrNull;
  }

  return (
    <>
      <StyledContainer>
        <h4>Substance Profile Management</h4>
        <StyledFormControl fullWidth>
          <StyledBox>
            <ChemIDSearchField
              fontSize={16}
              fieldWidth="100%"
              selectedChemIDObject={chemIDTextFieldVal}
              setSelectedChemIDObject={(value) => {
                setChemIDTextFieldVal(value)
                if (value === null || value === "") {
                  newSubstance()
                } else {
                  if (value.promotedSubstance) {
                    value = {
                      ...value.promotedSubstance,
                      chemID: value.chemID,
                      promotedChemID: value.promotedSubstance,
                    }
                    handleCurrentSubstance(value);
                  } else {
                    handleCurrentSubstance(value);
                  }
                }
              }}
              hasErrors={errorChecks.substance}
              setHasErrors={(e) => setErrorChecks(() => ({ ...errorChecks, substance: e }))}
              setPromotedChemID={(promoted, substance) => {
                if (promoted && substance) {
                  const value = {
                    ...promoted,
                    chemID: substance.chemID,
                    promotedChemID: promoted,
                  }
                  handleCurrentSubstance(value);
                }
              }}
              allowInactiveChemIDs={true}
              returnSubstanceStrucureData={true}
            />
          </StyledBox>
          <StyledButton sx={{ width: "10rem" }}
            variant='contained'
            type="submit"
            onClick={newSubstance}
          >New Substance</StyledButton>
        </StyledFormControl>
        <GeneralInformation
          currentSubstance={currentSubstance}
          setCurrentSubstance={setCurrentSubstance}
          substanceInitial={substanceInitial}
          validateFields={validateFields}
          fieldsCheck={errorChecks}
          isNewSubstance={isNewSubstance}
          canEdit={canEdit || canEditMaintainer}
          canEditMaintainer={canEditMaintainer}
          setErrorChecks={setErrorChecks}
        />
        <SafetyInformation
          currentSubstance={currentSubstance}
          setCurrentSubstance={setCurrentSubstance}
          canEdit={canEdit || canEditSafety}
          fieldsCheck={errorChecks}
          validateFields={validateFields}
          expandedPanel = {expandedPanel}
          setExpandedPanel = {setExpandedPanel}
        />
        <ChemicalAndStructure
          currentSubstance={currentSubstance}
          setCurrentSubstance={setCurrentSubstance}
          listOfStructuresLookup={listOfStructuresLookup}
          setListOfStructuresLookup={setListOfStructuresLookup}
          listSubcomponent={listSubcomponent}
          setListSubcomponent={setListSubcomponent}
          isNewSubstance={isNewSubstance}
          openModalSimpleButton={openModalSimpleButton}
          reloadListStructureLookup={loadSubstanceStructureLookup}
          reloadListSubcomponent={loadSubstanceSubcomponent}
          canEdit={canEdit || canEditMaintainer}
          canEditSubstanceStructures={canEditSubstanceStructures}
          onChangeListSubcomponent={onChangeListSubcomponent}
        />
        <RegionSpecificInformation
          currentSubstance={currentSubstance}
          setCurrentSubstance={setCurrentSubstance}
          canEdit={canEdit || canEditMaintainer}
        />
        
        {/* <StyledButton sx={{ marginTop: '20px', width: "10rem" }}
          variant='contained'
          type="submit"
          onClick={() => {
            save()
          }}
        >Save</StyledButton> */}

        <ModalSimpleButton title={modalSimpleTitle} buttonText={modalSimpleButtonText} buttonAction={closeModalSimpleButton} open={modalSimpleButton} setOpen={setModalSimpleButton}>
          <label>
            {modalSimpleText}
          </label>
        </ModalSimpleButton>
      </StyledContainer>

      {substanceIsSaving === true ?
        (
        <>
          <CircularProgress style={{textTransform: "none"}}></CircularProgress>
          <GlobalButton  
              disabled
              name="btnSave"
              variant='contained'
              sx={{ marginTop: '20px', width: "10rem", marginBottom: '25px' }}
          >{"Save Substance"}</GlobalButton>
        </>
        ):(
          <GlobalButton  
              name="btnSave"
              variant='contained'
              sx={{ marginTop: '20px', width: "10rem", marginBottom: '25px' }}
              onClick={(e) => save()}
          >{"Save Substance"}</GlobalButton>
        )
      }
    </>

  );
};

export default ManageSubstances;