import React from "react";
import { TableRow, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  StyledTableBody,
  UXDataTableWithoutBody,
  StyledTableCell,
} from "../../../../components/UXDataTable";
import { SDSRequestContextProvider } from "../../Context/ProcessSDS";
import MoreOptions from "@mui/icons-material/MoreVert";
import { convertDateFormat } from "../../../../global";
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import SubmitSDS from "../SubmitSDS";
import Requests from "../../../../api/SDSRequest/SDSRequest";
import toast from "react-hot-toast";
import { viewFormOrigin } from "../Backlog/constants";
import { requestStatusOptions } from "../constants";
import { SDSRequestRowMenu, SDSRequestRowMenuOption } from "./../SDSRequestRowMenu";

const StyledTableCellClickable = styled(StyledTableCell)(() => ({
  cursor: "pointer",
}));

export function GridRender(props) {
  const {
    showCompletedRequests,
    showCancelledRequests,
    requests,
    loading,
    gridColumns,
    currentUser,
    id,
  } = props;
  const filteredEvents = [];

  requests &&
    requests.forEach((row) => {
      filteredEvents.push(row);
    });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [modalMessagesOpen, setModalMessagesOpen] = React.useState(false);
  const [modalMessageText, setModalMessageText] = React.useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = React.useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = React.useState("Ok");
  const [selectedRequest, setSelectedRequest] = React.useState(null);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("requestStatusName");

  React.useEffect(() => {
    setSelectedRequest();
  }, [showCompletedRequests, showCancelledRequests]);

  React.useEffect(() => {
    if (requests?.length) {
      setOrderBy("requestStatusName");
      setOrder("desc");
    }

    if (id && requests) {
      setSelectedRequest(requests.find((r) => r.id === parseInt(id, 10)));
    }
  }, [requests, id]);

  function reloadData() {
    props.reloadList();
    setSelectedRequest();
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArray = () => {
    return stableSort(filteredEvents, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  };

  function handleOpenMoreOptions(e, request) {
    setMoreOptionsSelected(request);
    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);
    setAnchorEl(null);
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessageText("");
    setModalMessagesButtonText("Ok");
    setModalMessagesTitle("");
  }

  async function updateRequestStatus(request, status) {
    if (request.requestStatusName === status) {
      toast.success(`Request Id ${request.id} is already set as ${status}`);
    }

    handleCloseMoreOptions(null);
    const data = await Requests.updateSDSRequestStatus(request.id, status);
    if (data) {
      toast.success(`Request Id ${request.id} set as ${status} successfuly`);
      reloadData();
    } else {
      toast.error("An error ocurred while trying to update the request");
    }
  }

  async function updateRequestAssignedTo(request) {
    handleCloseMoreOptions(null);
    const data = await Requests.updateSDSRequestAssignedTo(request.id, currentUser.username);
    if (data) {
      toast.success(`Request Id ${request.id} assigned successfuly`);
      reloadData();
    } else {
      toast.error("An error ocurred while trying to update the request");
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === "requestStatusName") {
      const orderMap = {
        InProgress: 1,
        Assigned: 2,
        Submitted: 3,
        OnHold: 4,
      };
      const aOrder = orderMap[a[orderBy]] || 5;
      const bOrder = orderMap[b[orderBy]] || 5;
      return aOrder - bOrder;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const handleCloseOptionsMenu = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    switch (option) {
      case SDSRequestRowMenuOption.AssignToMe:
        updateRequestAssignedTo(moreOptionsSelected);
        break;
      case SDSRequestRowMenuOption.SetAsCancelled:
        updateRequestStatus(moreOptionsSelected, requestStatusOptions.cancelled);
        break;
      default:
        break;
    }
  };

  return (
    <SDSRequestContextProvider>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={gridColumns}
        tableId="gridSdsRequests"
        rowLength={filteredEvents.length}
        enablePaging={true}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={"No data found"}
        isDataLoading={loading}>
        <StyledTableBody>
          {determineStartArray().map((row, index) => {
            return (
              <TableRow hover key={index}>
                <StyledTableCell component="th" scope="row" style={{ width: "10px" }}>
                  <MoreOptions onClick={(e) => handleOpenMoreOptions(e, row)} color="black" />
                </StyledTableCell>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.id}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.requestedByEmail}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.sdsRequestType?.description}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {convertDateFormat(row?.createdDate)}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {convertDateFormat(row?.requestedCompletionDate)}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.requestStatusName}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.project?.name}{" "}
                </StyledTableCellClickable>
                <StyledTableCellClickable
                  onClick={() => {
                    setSelectedRequest(row);
                  }}>
                  {" "}
                  {row?.description}{" "}
                </StyledTableCellClickable>
              </TableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <SDSRequestRowMenu
        optionsToDisplay={[
          SDSRequestRowMenuOption.AssignToMe,
          SDSRequestRowMenuOption.SetAsCancelled,
        ]}
        request={moreOptionsSelected}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseOptionsMenu}
        onItemClick={handleMenuItemClick}
      />

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label>{modalMessageText}</label>
      </ModalSimpleButton>

      {selectedRequest && (
        <Card variant="elevation" style={{ padding: "1rem", marginTop: "2rem" }}>
          <SubmitSDS
            origin={viewFormOrigin.process}
            reloadList={reloadData}
            request={selectedRequest}
          />
        </Card>
      )}

      <div>&nbsp;</div>
    </SDSRequestContextProvider>
  );
}
