import { useState } from "react";
import { Box } from "@mui/material";
import { GlobalTabCollection, StyledTab } from "../styles";
import SdsRequestIntendedUseAdmin from "./Inventory/SDS/RequestIntendedUse";
import SdsRequestTypeAdmin from "./Inventory/SDS/RequestType";
import SdsNotificationAdmin from "./Inventory/SDS/Notifications";
import SdsChangeLogView from "./Inventory/SDS/ChangeLog";
import SdsOtherOptions from "./Inventory/SDS/OtherOptions";


const InventoryMasterDataManagement = () => {
  const [mainTabValue, setMainTabValue] = useState(0);
  const [sdsTabValue, setSdsTabValue] = useState(0);


  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
  }

  const renderMainTab = () => {
    switch (mainTabValue) {
      case 0: {
        return (
          <div>
            <GlobalTabCollection
              sx={{ mt: 1 }}
              value={sdsTabValue}
              onChange={handleSdsTabChange}
              aria-label="sds tab navigation"
            >
              <StyledTab label="Request Intended Use" />
              <StyledTab label="Request Type" />
              <StyledTab label="Notifications" />
              <StyledTab label="Change Log" />
              <StyledTab label="Other Options" />
            </GlobalTabCollection>
            {renderSdsTab()}
          </div>
        );
      }
      default: {
        // ignore
      }
    }
  }

  const handleSdsTabChange = (event, newValue) => {
    setSdsTabValue(newValue);
  };

  const renderSdsTab = () => {
    switch (sdsTabValue) {
      case 0: {
        return <SdsRequestIntendedUseAdmin />
      }
      case 1: {
        return <SdsRequestTypeAdmin />;
      }
      case 2: {
        return <SdsNotificationAdmin />;
      }
      case 3: {
        return <SdsChangeLogView />;
      }
      case 4: {
        return <SdsOtherOptions />;
      }
      default: {
        // ignore
      }
    }
  };
  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pb: 1 }}>
        <GlobalTabCollection
          value={mainTabValue}
          onChange={handleMainTabChange}
          aria-label="inventory tab navigation"
        >
          <StyledTab label="SDS Requests" />
        </GlobalTabCollection>
      </Box>
      {renderMainTab()}
    </div>
  );
};

export default InventoryMasterDataManagement;
