import { useState, useEffect } from 'react';
import { TextField, Typography, Box, Paper, Grid } from '@mui/material';
import { GlobalButton } from '../../../styles';
import { styled } from '@mui/system';
import SDSRequest from '../../../../api/SDSRequest/SDSRequest';
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";

const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  elevation: 3,
}));

const SdsOtherOptions = () => {
  const [days, setDays] = useState(0);
  const [customMessage, setCustomMessage] = useState('');
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const daysData = await SDSRequest.getDaysAutoPriorityRule();
        setDays(daysData);

        const messageData = await SDSRequest.getSdsCustomMessage();
        setCustomMessage(messageData === '.' ? '' : messageData);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    };

    fetchData();
  }, []);

  const handleDaysChange = (e) => {
    setDays(e.target.value);
  };

  const handleCustomMessageChange = (e) => {
    setCustomMessage(e.target.value);
  };

  const handleSavePriorityRule = async () => {
    SDSRequest.updateDaysAutoPriorityRule(days).then((response) => {
      if (response.message === 'Success')
      {
        openModalMessages('Save Complete', `Changes saved successfully!`)
      } else {
        if (response.message)
        {
          openModalMessages('Settings Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
        } else {
          openModalMessages('Settings Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
        }
      }
    })
  };

  const handleSaveCustomMessage = async () => {
    SDSRequest.updateSdsCustomMessage(customMessage).then((response) => {
      if (response.message === 'Success')
      {
        openModalMessages('Save Complete', `Changes saved successfully!`)
      } else {
        if (response.message)
        {
          openModalMessages('Settings Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
        } else {
          openModalMessages('Settings Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
        }
      }
    })
  };

  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        marginTop={2}
      >
        <Grid item xs={12} sm={6}>
          <Item elevation={3}>
            <Typography variant="subtitle1">Maximum Working Days to change the request priority from Normal to Rush</Typography>
            <TextField
              label="Working Days"
              type="number"
              value={days}
              onChange={handleDaysChange}
              fullWidth
              margin="normal"
            />
            <small>
              <em>
                If the difference between the <strong>Requested Completion Date</strong> and <strong>Last Acceptable Date</strong>
                is less than this value, the request will be marked as <strong>Rush</strong>.
                Leaving this field as 0 (Zero) will disable this rule.
              </em>
            </small>
            <br />
            <GlobalButton
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
              onClick={handleSavePriorityRule}
            >
              Save
            </GlobalButton>
          </Item>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Item elevation={3}>
            <Typography variant="subtitle1">Custom Message for SDS</Typography>
            <TextField
              label="Custom Message"
              value={customMessage}
              onChange={handleCustomMessageChange}
              multiline
              rows={4}
              fullWidth
              margin="normal"
            />
            <small>
              <em>
                This message will be displayed at the top of the SDS request form.
              </em>
            </small>
            <br />
            <GlobalButton
              variant="contained"
              color="primary"
              sx={{ marginTop: 2 }}
              onClick={handleSaveCustomMessage}
            >
              Save
            </GlobalButton>
          </Item>
        </Grid>
      </Grid>

        {/* Informational Messages */}
        <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          {Array.isArray(modalMessagesText) ?
          (modalMessagesText && modalMessagesText.map((text, index) => {
              return (
                  <div style={{display:"flex"}} key={`myErrorChecks${index}`}>
                      <label>
                      {text}
                      </label>
                  </div>
              )
              })
          )
          :
          ( <label>
              {modalMessagesText}
            </label>
          )}      
      </ModalSimpleButton>
    </Box>
  );
};

export default SdsOtherOptions;