import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { GlobalTabCollection, StyledTab } from "../../styles";
import ProcessSDS from "./ProcessSDS";
import SDSBacklog from "./Backlog";

const SDS = ({ initialTabValue = 0, view, id }) => {
  const [tabValue, setTabValue] = React.useState(initialTabValue);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    switch (view) {
      case "backlog": {
        setTabValue(0);
        break;
      }
      case "process": {
        setTabValue(1);
        break;
      }
      default: {
        // ignore
      }
    }
  }, [view]);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <SDSBacklog id={id} />
      }
      case 1: {
        return <ProcessSDS id={id} />;
      }
      default: {
        // ignore
      }
    }
  };
  return (   
      <div>
        <Box sx={{ bgcolor: "#fff", pb: 1 }}>
          <GlobalTabCollection
            value={tabValue}
            onChange={handleChange}
            aria-label="ant example"
          >
            <StyledTab label="Submit SDS Request" />
            <StyledTab label="Process SDS Requests" />
          </GlobalTabCollection>
        </Box>
        {renderSearchTab()}
      </div>
  );
};

export default SDS;
