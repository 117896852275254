import React, { useContext } from "react";
import { Box } from "@mui/material";
import ProjectManagement from "./ProjectManagement/ProjectManagement";
import ProjectReview from "./ProjectReview/ProjectReview";
import ProjectUserReview from "./ProjectUser/ProjectUserReview";
import SiteLocationAdminMenu from "../SiteLocationAdminMenu";
import UOMManagement from "../UOMManagement";
import { GlobalTabCollection, StyledTab } from "../../styles.js";
import ReleaseManagement from "../ReleaseManagement";
import PageUnauthorized from "../../PageUnauthorized";
import UserContext from "../../../context/UserContext";
import { hasRole, Roles } from "../../../global";

enum MenuItem {
  SiteLocationAdminMenu = "Site and Location Management",
  ProjectManagement = "Project Management",
  ProjectReview = "Project Review",
  ProjectUserReview = "Project User Review",
  UOMManagement = "Units of Measure",
  ReleaseManagement = "Release Management",
}

type ClaimsWrapper = {
  roles: string[];
};

const GeneralAdminMenu = () => {
  const [tabValue, setTabValue] = React.useState(MenuItem.SiteLocationAdminMenu);

  const { idTokenClaims } = useContext(UserContext);
  const roles = (idTokenClaims as unknown as ClaimsWrapper).roles ?? [];
  const developer = hasRole(Roles.Developer, roles);

  const renderSearchTab = () => {
    switch (tabValue) {
      case MenuItem.SiteLocationAdminMenu: {
        return <SiteLocationAdminMenu />;
      }
      case MenuItem.ProjectManagement: {
        return <ProjectManagement />;
      }
      case MenuItem.ProjectReview: {
        return <ProjectReview />;
      }
      case MenuItem.ProjectUserReview: {
        return <ProjectUserReview />;
      }
      case MenuItem.UOMManagement: {
        return <UOMManagement />;
      }
      case MenuItem.ReleaseManagement: {
        return developer ? <ReleaseManagement /> : <PageUnauthorized />;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const handleTabChange = (
    _event: React.SyntheticEvent,
    newValue: React.SetStateAction<MenuItem>,
  ) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div>
        <Box sx={{ bgcolor: "#fff" }}>
          <GlobalTabCollection value={tabValue} onChange={handleTabChange} aria-label="ant example">
            {Object.values(MenuItem).map((item) => (
              <StyledTab value={item} label={item} />
            ))}
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </>
  );
};

export default GeneralAdminMenu;
