import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import { visuallyHidden } from '@mui/utils';
import "./index.css";

import {
    Box,
    TextField,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableHead,
    TableRow,
    TableSortLabel,
    Toolbar,
    Typography,
    Paper,
    IconButton,
    Tooltip,
    Select,
    MenuItem,
} from "@mui/material";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


function DataGrid(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells, enableSelect, enableDelete } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>

                {enableSelect === true ?
                    <TableCell padding="checkbox" key={`headCell.field-checkbox`}>
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell> : <></>}

                {enableDelete === true ?
                    <TableCell padding="checkbox" key={`headCell.field-delete`}>
                    </TableCell> : <></>}

                {headCells.map((headCell, index) => (
                    <TableCell
                        key={`headCell.field-${headCell.field}-${index}`}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.field ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.field}
                            direction={orderBy === headCell.field ? order : 'asc'}
                            onClick={createSortHandler(headCell.field)}
                        >
                            {headCell.headerName}
                            {orderBy === headCell.field ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

DataGrid.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const { numSelected } = props;

    return (

        <div>
            {
                numSelected === 0 ? <div></div> :
                    <Toolbar
                        sx={{
                            pl: { sm: 2 },
                            pr: { xs: 1, sm: 1 },
                            ...(numSelected > 0 && {
                                bgcolor: (theme) =>
                                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                            }),
                        }}
                    >
                        {numSelected > 0 ? (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                color="inherit"
                                variant="subtitle1"
                                component="div"
                            >
                                {numSelected} selected
                            </Typography>
                        ) : (
                            <Typography
                                sx={{ flex: '1 1 100%' }}
                                variant="h6"
                                id="tableTitle"
                                component="div"
                            >
                            </Typography>
                        )}

                        {numSelected > 0 ? (
                            <Tooltip title="Delete">
                                <IconButton onClick={props.onDeleteAllClick}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Filter list">
                                <IconButton>
                                </IconButton>
                            </Tooltip>
                        )}
                    </Toolbar>

            }
        </div>

    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};


const CustomDataGrid = ({ cols, rows, setRows, enableSelect, onRowClick, enableSelectSingleRow, handleDataChange, enableDelete, sx, minWidth, canEdit = true, customDelete, initialRowsPerPage = 5, enableRowDelete }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {

        if (enableSelect === true) {
            if (event.target.checked) {

                rows.forEach(element => {
                    element["rowSelected"] = true;
                });
            }
            else {
                rows.forEach(element => {
                    element["rowSelected"] = false;
                });
            }

            setRows([...rows]);
        }
    };

    const handleDeleteAllClick = () => {

        if (enableSelect === true) {
            const newSelecteds = rows.map((n) => n.rowId);

            rows = rows.filter(function (item) {
                return !newSelecteds.includes(item.rowId)
            });

            setRows([...rows]);
        }

    };

    const handleClick = (event, name) => {

        if (event.target.type === undefined) {

            if (enableSelect === false) {

                rows.forEach(element => {
                    element["rowSelected"] = false;
                });

            }

            if (enableSelectSingleRow) {
                const foundSelectedRow = rows.find(row => row?.rowSelected)

                if (foundSelectedRow) {
                    const updatedArray = rows.map(row => {
                        if (foundSelectedRow?.rowId === row?.rowId) {
                            return {
                                ...row,
                                rowSelected: false
                            }
                        } else if (row?.rowId === name) {
                            return {
                                ...row,
                                rowSelected: true
                            }
                        } else {
                            return row
                        }
                    })
                    setRows(updatedArray)
                } else {
                    const updatedArray = rows.map(row => {
                        if (row?.rowId === name) {
                            return {
                                ...row,
                                rowSelected: true
                            }
                        }
                        return row
                    })
                    setRows(updatedArray);
                }
            } else {
                let index = rows.findIndex(obj => { return obj.rowId === name });
                rows[index]['rowSelected'] = rows[index]['rowSelected'] === undefined ? true : !rows[index]['rowSelected'];
                setRows([...rows]);
            }
        }
    };

    const handleCheck = (event, name) => {
        if (enableSelect === true) {

            let index = rows.findIndex(obj => { return obj.rowId === name });
            rows[index]['rowSelected'] = rows[index]['rowSelected'] === undefined ? true : !rows[index]['rowSelected'];
            setRows([...rows]);

        }
    };

    const handleDelete = (event, row) => {
        if (customDelete) {
            customDelete(row);
        }

        if (enableDelete === true) {
            rows = rows.filter(item => item !== row);
            
            setRows([...rows]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => {
        const index = rows.findIndex(obj => { return obj.rowId === name });
        return rows[index]['rowSelected'] === true;
    };

    const countSelected = () => {

        const result = rows.filter(function (item) {
            return item['rowSelected'] === true
        });

        return result.length;
    }

    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const CustomTableCell = (props) => {

        if (props) {

            const size = props.size ? props.size : 'small';

            switch (props.cellType) {
                case 'label':
                    if (props.onRowClick) {
                        return (
                            <TableCell onClick={(event) => props.onRowClick(props.row)} key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>{props.cellValue}</TableCell>
                        )
                    }
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>{props.cellValue}</TableCell>
                    )
                case 'text':
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <TextField
                                key={`custom-cell-field-${props.fieldName}-${props.rowId}`} size={size} onChange={e => {
                                    props.row[props.fieldName] = e.target.value;
                                    props.setRows([...props.rows], props.row);
                                }}
                                value={props.row[props.fieldName] ? props.row[props.fieldName] : ''}
                                disabled={!canEdit}
                            ></TextField>
                        </TableCell>
                    )
                case 'text-manageable':
                    return props.canEditValue ? (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <TextField 
                                key={`custom-cell-field-${props.fieldName}-${props.rowId}`} 
                                type="text" 
                                size={size} 
                                onChange={e => {
                                    props.setRows((prevState) => {
                                        const copiedRow = JSON.parse(JSON.stringify(props.row))
                                        copiedRow[props.fieldName] = e.target.value ? e.target.value : null
                                        if (handleDataChange) {
                                            const { propertyName, returnedValue } = handleDataChange(copiedRow)
                                            copiedRow[propertyName] = returnedValue
                                        }
                                        const newArray = prevState?.map(row => {
                                            if (row?.rowId === copiedRow.rowId) {
                                                return copiedRow
                                            }
                                            return row
                                        })
                                       return newArray
                                    })
                                }}
                                value={props.row[props.fieldName] ? props.row[props.fieldName] : ''}
                                disabled={!canEdit}
                            />
                        </TableCell>
                    ) : (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>{props.cellValue}</TableCell>
                    )
                case 'checkbox':
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <Checkbox key={`custom-cell-field-${props.fieldName}-${props.rowId}`} size={size}
                                onChange={e => {
                                    props.row[props.fieldName] = e.target.checked;
                                    props.setRows([...props.rows])
                                }}
                                checked={props.row[props.fieldName]}
                                disabled={!canEdit}
                            />
                        </TableCell>
                    )
                case 'checkbox-manageable':
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <Checkbox 
                                key={`custom-cell-field-${props.fieldName}-${props.rowId}`} 
                                size={size}
                                onChange={e => {
                                    props.setRows((prevState) => {
                                        const copiedRow = JSON.parse(JSON.stringify(props.row))
                                        copiedRow[props.fieldName] = e.target.checked
                                        if (handleDataChange) {
                                            const { propertyName, returnedValue } = handleDataChange(copiedRow)
                                            copiedRow[propertyName] = returnedValue
                                        }
                                        const newArray = prevState?.map(row => {
                                            if (row?.rowId === copiedRow.rowId) {
                                                return copiedRow
                                            }
                                            return row
                                        })
                                       return newArray
                                    })
                                }}
                                checked={props.row[props.fieldName]}
                                disabled={!canEdit || !props.canEditValue}
                            />
                        </TableCell>
                    )

                case 'integer':
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}><TextField inputProps={{ step: 1 }} type="number" key={`custom-cell-field-${props.fieldName}-${props.rowId}`} size={size} onChange={e => {
                            props.row[props.fieldName] = e.target.value ? parseFloat(e.target.value).toFixed(0) : null;
                            props.setRows([...props.rows]);
                        }}
                            value={props.row[props.fieldName] || props.row[props.fieldName] === 0 ? props.row[props.fieldName] : ''}
                            disabled={!canEdit}
                        ></TextField></TableCell>
                    )
                case 'integer-manageable':
                    return props.canEditValue ? (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <TextField 
                                key={`custom-cell-field-${props.fieldName}-${props.rowId}`} 
                                inputProps={{ step: 1 }} 
                                type="number" 
                                size={size} 
                                onChange={e => {
                                    props.setRows((prevState) => {
                                        const copiedRow = JSON.parse(JSON.stringify(props.row))
                                        copiedRow[props.fieldName] = e.target.value ? parseFloat(e.target.value).toFixed(0) : null
                                        if (handleDataChange) {
                                            const { propertyName, returnedValue } = handleDataChange(copiedRow)
                                            copiedRow[propertyName] = returnedValue
                                        }
                                        const newArray = prevState?.map(row => {
                                            if (row?.rowId === copiedRow.rowId) {
                                                return copiedRow
                                            }
                                            return row
                                        })
                                       return newArray
                                    })
                                }}
                                value={props.row[props.fieldName] ? props.row[props.fieldName] : ''}
                                disabled={!canEdit}
                            />
                        </TableCell>
                    ) : (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>{props.cellValue}</TableCell>
                    )
                case 'decimal':
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}>
                            <TextField className='decimal-input' inputProps={{ step: 0.01 }} type="number" key={`custom-cell-field-${props.fieldName}-${props.rowId}`} size={size} onChange={e => {
                                props.row[props.fieldName] = e.target.value;
                                props.setRows([...props.rows]);
                            }}
                                value={props.row[props.fieldName] ? props.row[props.fieldName] : `${props.row[props.fieldName]}` === '0' ? 0 : ''}
                                disabled={!canEdit}
                            >
                            </TextField>
                        </TableCell>
                    )

                case 'select': return (
                    <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`} size={size}>

                        <Select size={size}
                            key={`custom-cell-field-${props.fieldName}-${props.rowId}`}
                            onChange={e => {

                                props.row[props.fieldName].forEach(element => {
                                    if (element['value'] === e.target.value)
                                        element['selected'] = true;
                                    else
                                        element['selected'] = false;
                                });

                                props.setRows([...props.rows]);
                            }}
                            value={
                                props.row[props.fieldName].filter(function (item) {
                                    return item['selected'] === true
                                }).length === 0 ? "" :
                                    props.row[props.fieldName].filter(function (item) {
                                        return item['selected'] === true
                                    })[0]['value']
                            
                            }
                            disabled={!canEdit}>
                            {
                                props.row[props.fieldName] ?
                                    props.row[props.fieldName].map((item) => (
                                        <MenuItem value={item.value} key={item.key}>{item.value}</MenuItem>
                                    )) : <MenuItem></MenuItem>
                            }
                        </Select>
                    </TableCell>)
                
                case 'select-manageable': 
                    return (
                        <TableCell key={`custom-tcell-field-${props.fieldName}-${props.rowId}`} size={size}>
                            <Select 
                                key={`custom-cell-field-${props.fieldName}-${props.rowId}`}
                                size={size}
                                style={{ width: '100%' }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, 
                                        }
                                    }
                                }}
                                onChange={e => {
                                    props.setRows((prevState) => {
                                        const copiedRow = JSON.parse(JSON.stringify(props.row))
                                        copiedRow[props.fieldName] = e.target.value
                                        if (handleDataChange) {
                                            const { propertyName, returnedValue } = handleDataChange(copiedRow)
                                            copiedRow[propertyName] = returnedValue
                                        }
                                        const newArray = prevState?.map(row => {
                                            if (row?.rowId === copiedRow.rowId) {
                                                return copiedRow
                                            }
                                            return row
                                        })
                                       return newArray
                                    })
                                }}
                                value={props.row[props.fieldName] ? props.row[props.fieldName] : ''}
                                disabled={!canEdit}
                            >
                                {props.rowSelectOptions && props.rowSelectOptions.length && props.rowSelectOptions.map(selectOption => (
                                    <MenuItem 
                                        value={selectOption} 
                                        key={selectOption}
                                    >
                                        {selectOption}
                                    </MenuItem>  
                                ))}
                            </Select>
                        </TableCell>
                    )

                default: <TableCell onClick={props.onRowClick ? (event) => props.onRowClick(props.row): {}} key={`custom-tcell-field-${props.fieldName}-${props.rowId}`}></TableCell>
            }
        }

        return <TableCell></TableCell>;
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>

                {enableSelect === true ? <EnhancedTableToolbar
                    numSelected={countSelected()}
                    onDeleteAllClick={handleDeleteAllClick}
                /> : <></>}

                <TableContainer sx={sx}>
                    <Table
                        sx={{ minWidth: minWidth ? minWidth : 750 }}
                        aria-labelledby="tableTitle"
                    >
                        <DataGrid
                            numSelected={countSelected()}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                            headCells={cols}
                            enableSelect={enableSelect}
                            enableDelete={enableDelete}
                        />

                        {
                            rows.length > 0 &&
                            <TableBody>
                                {stableSort(rows, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {

                                        const isItemSelected = isSelected(row.rowId);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={!enableDelete ? onRowClick ? (event) => onRowClick(row) : (event) => handleClick(event, row.rowId) : () => {}}
                                                role="checkbox"
                                                aria-checked={ isItemSelected }
                                                tabIndex={-1}
                                                key={`custom-row-${row.rowId}-${index}`}
                                                selected={isItemSelected}
                                                style={{ cursor: onRowClick ? "pointer" : null }}
                                            >

                                                {enableSelect === true &&
                                                    <TableCell padding="checkbox" key={`headCell.field-checkbox-${index}`}>
                                                        <Checkbox
                                                            color="primary"
                                                            onClick={(event) => handleCheck(event, row.rowId)}
                                                            checked={isItemSelected}
                                                            inputProps={{
                                                                'aria-labelledby': labelId,
                                                            }}
                                                        />
                                                    </TableCell>
                                                }

                                                {enableDelete === true &&
                                                    <TableCell padding="checkbox" key={`headCell.field-delete-${index}`}>
                                                        <IconButton type="button" aria-label="delete" onClick={(event) => handleDelete(event, row)} disabled={enableRowDelete && !enableRowDelete(row)}>
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </TableCell>
                                                }

                                                {
                                                    cols ?
                                                        cols.map((col, index) => (
                                                            CustomTableCell({ onRowClick: onRowClick, rowId: row.rowId, rowSelectOptions: row.rowSelectOptions, canEditValue: row.canEditValue, row: row, rows: rows, setRows: setRows, cellValue: row[col.field], fieldName: col.field, cellType: col.type, size: col.size })
                                                        )) : <TableCell key={`custom-cell-${row.rowId}-${index}`}></TableCell>
                                                }

                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        }


                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box >
    );
}

export default CustomDataGrid;
