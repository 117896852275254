import { Box } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import Project from "../../../../api/Admin/Project";
import { useState } from "react";
import { CustomNetworkError } from "../../../../api/helpers";
import { Alert } from "../ProjectManagement/components/Alert";
import { useEffect } from "react";
import { MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataTableWrapper } from "../ProjectManagement/components/DataTableWrapper";
import { StyledTableCell, StyledTableRow } from "../../../../components/UXDataTable";
import { CustomMenu } from "../ProjectManagement/components/CustomMenu";
import CustomModal from "../../../../components/Modal";
import ProjectUser from "../../../../api/Admin/ProjectUser";
import ModalTwoButtons from "../../../../components/Modal/ModalTwoButtons";
import {ProjectUserAddForm} from "../ProjectUser/ProjectUserAddForm";

export type ProjectUserFormProps = {
  project: Project | undefined;
  onClose?: () => void;
}

export type ProjectUserFormCriteria = {
  projectId: number;
  userEmail: string;
};

  type ProjectUserAlert = {
  title: string;
  message: string;
  details?: string | undefined;
  onOk?: () => void | undefined;
};

const Header = styled(Box)(() => ({
    fontSize: "20px",
    fontWeight: "bold",
    paddingRight: 10,
}));

const ConfirmRemoveUserAlert = styled(ModalTwoButtons)({});

const tableKey = "usersProjectsTable";
const fetchError = "There was an error fetching users project.";
const addError = "There was an error adding user to project.";
const removeError = "There was an error removing user from project.";

const columns = [
  {
    id: "userEmail",
    label: "User Email",
    notSorteable: false
  }
];

const successfullyAddUserToProjectAlert: ProjectUserAlert = {
  title: "User Added",
  message: "User was successfully added to project.",
};

const successfullyRemovedUserFromProjectAlert: ProjectUserAlert = {
  title: "User Removed",
  message: "User was successfully removed from project.",
};

const errorProjectUsersAlert = (
  message: string, 
  error: CustomNetworkError): ProjectUserAlert => ({
    title: "Error",
    message: message,
    details: `Reason: ${getNetworkErrorMessage(error)}`,
});

const getNetworkErrorMessage = (error: CustomNetworkError) => {
  const { status } = error;
  if (status === 403)
    return "You are not authorized to view or modify users.";
  
  return (error.message ??
    "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
  );
}; 

export const ProjectUserForm = ({
  project,
  onClose
}: ProjectUserFormProps) => {

  const [alert, setAlert] = useState<ProjectUserAlert | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<ProjectUser[]>([]);
  const [selectedUserEmail, setselectedUserEmail] = useState<string>("");
  const [userIsUnauthorized, setUserIsUnauthorized] = useState<boolean>(true);
  const [displayRemoveUserFromProjectAlert, setDisplayRemoveUserFromProjectAlert] = useState<boolean>(false);
  const [showProjectUserAddFormModal, setShowProjectUserAddFormModal] = useState(false);    

  useEffect(() =>{
    let cancelPromise = false;

    setLoading(true);
    ProjectUser.getUsersByProjectId(project?.id)
    .then((response: ProjectUser[]) =>
    {
      if (cancelPromise) return;

      setUserIsUnauthorized(false);
      setUsers(response);                
    })
    .catch((err) =>
    {
      setAlert(errorProjectUsersAlert(fetchError, err));
    })
    .finally(() =>
    {
      setLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [project?.id]);

  const reloadUsers = () => {
    ProjectUser.getUsersByProjectId(project?.id)
    .then((response: ProjectUser[]) =>
    {
      setUsers(response);                
    })
    .catch((err) =>
    {
      setAlert(errorProjectUsersAlert(fetchError, err));
    })
  };

  const handleRemoveUserAlertOkClick = () => {
    setDisplayRemoveUserFromProjectAlert(false);

    ProjectUser.DeleteUserByProjectId(project?.id, selectedUserEmail)
    .then((_response) =>
    {
      reloadUsers();
      setAlert(successfullyRemovedUserFromProjectAlert);               
    })
    .catch((err) =>
    {
      setAlert(errorProjectUsersAlert(removeError, err));
    })
  };

  const handleRemoveUserAlertCancelClick = () => setDisplayRemoveUserFromProjectAlert(false);

  const handleRemoveUserClick = (userEmail: string) => {
    setselectedUserEmail(userEmail ?? "");
    setDisplayRemoveUserFromProjectAlert(true);
  };

  const handleProjectUserAddFormOnSave = (userEmail : string) => {
    setShowProjectUserAddFormModal(false);

    ProjectUser.AddUserByProjectId(project?.id, userEmail)
    .then((_response) =>
    {
      reloadUsers();
      setAlert(successfullyAddUserToProjectAlert);               
    })
    .catch((err) =>
    {
      setAlert(errorProjectUsersAlert(addError, err));
    })
  };

  const handleAddUserToProjectClick = () => setShowProjectUserAddFormModal(true);     

  const handleProjectUserAddFormOnClose = () => setShowProjectUserAddFormModal(false);

  const handleAlertOkClick = () => {
    alert?.onOk?.();
    setAlert(undefined);
  };

  return(
    <>
      <Box
        display="flex"
        justifyContent={"space-between"}
        alignItems={"center"}
        paddingBottom={".8rem"}>
        <Box sx={{ display: "inline-flex", alignItems: "center" }}>
          <Header>All Users ({users.length})</Header>
          <GlobalButton
            variant="contained"
            onClick={() => handleAddUserToProjectClick()}
            disabled={userIsUnauthorized}>
            Add User
          </GlobalButton>
        </Box>
      </Box>
      <DataTableWrapper
        tableId="users"
        columns={columns}
        tableWidth="100%"
        blankFirstHeader={true}
        noDataFoundMessage={
          userIsUnauthorized
            ? "You are not authorized to view or modify users."
            : "No users assigned."
        }
        isDataLoading={loading}
        rowData={users}
        rowComponent={(user, rowIndex) => (
          <StyledTableRow key={`${tableKey}-row-${rowIndex}`}>
            <StyledTableCell width={5}>
              <CustomMenu key={`${tableKey}basic-menu${rowIndex}`}>
                <MenuItem onClick={() => {handleRemoveUserClick(user?.userEmail)}}>Remove</MenuItem>
              </CustomMenu>                
            </StyledTableCell>
            <StyledTableCell>{user?.userEmail}</StyledTableCell>                    
          </StyledTableRow>
        )}
      />
      <><br></br></>
      <Box display="flex" flexDirection={"row"} alignItems="center" justifyContent={"center"} textAlign={"center"}>                                               
        <GlobalSecondaryButton
          variant="contained"
          onClick={() => {
            onClose?.();
        }}>
        Close
        </GlobalSecondaryButton>
      </Box>
      <ConfirmRemoveUserAlert
        title="Remove user"
        button1Text="OK"
        button1Action={handleRemoveUserAlertOkClick}
        isButton1Disabled={false}
        button2Text="Cancel"
        button2Action={handleRemoveUserAlertCancelClick}
        isButton2Disabled={false}
        open={displayRemoveUserFromProjectAlert}
        setOpen={undefined}
        setClose={undefined}>
        <Box>Are you sure you want to remove {selectedUserEmail} from project: {project?.name}?</Box>
      </ConfirmRemoveUserAlert>
      <CustomModal
        title={`Add user to project: ${project?.name}`}
        open={showProjectUserAddFormModal}
        setOpen={setShowProjectUserAddFormModal}
        setClose={undefined}>
        <ProjectUserAddForm
          project={project}
          onSave={handleProjectUserAddFormOnSave} 
          onCancel={handleProjectUserAddFormOnClose}       
        />
      </CustomModal>          
      <Alert
        title={alert?.title}
        message={alert?.message}
        details={alert?.details}
        display={alert !== undefined}
        onOk={handleAlertOkClick}
      />
    </>
  );
};