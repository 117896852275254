import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import { convertToLocalTime } from "../../../global";

const commonStyles = StyleSheet.create({
  column: {
    paddingLeft: "9px",
    paddingTop: "3px",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
});

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    padding: "32px 64px",
    fontSize: "10px",
  },
  firstSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "16px",
  },
  boldText: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  regularSectionTitle: {
    fontWeight: "bold",
    textDecoration: "underline",
    fontSize: "12px",
  },
  emphasizedText: {
    color: "#58111A",
    fontWeight: "bold",
    fontSize: "12px",
  },
  section: {
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
  },
  table: {
    width: "100%",
    marginTop: "8px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    borderTop: "1px solid black",
  },
  oneThirdColumn: {
    ...commonStyles.column,
    width: "33.33%",
  },
  oneQuarterColumn: {
    width: "25%",
  },
  noBorderRight: {
    borderRight: "none",
  },
  marginBottom: {
    marginBottom: "16px",
  },
  marginTop: {
    marginTop: "16px",
  },
  labelText: {
    fontSize: "8px",
  },
  smallMarginTop: {
    marginTop: "8px",
  },
  smallMarginBottom: {
    marginTop: "8px",
  },
  noBorder: {
    border: "none",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  underlineText: {
    textDecoration: "underline",
  },
});

function SpecialAnalysisPage({
  specialAnalysis,
  businessPhones,
}: {
  specialAnalysis: any;
  businessPhones?: string;
}) {
  const dataClassificationName =
    specialAnalysis.specialAnalysisForm &&
      specialAnalysis.specialAnalysisForm.dataClassificationName
      ? specialAnalysis.specialAnalysisForm.dataClassificationName
      : "";

  const reportFormatName =
    specialAnalysis.specialAnalysisForm &&
      specialAnalysis.specialAnalysisForm.reportFormatName
      ? specialAnalysis.specialAnalysisForm.reportFormatName
      : "";

  const samplesAndDescriptions =
    specialAnalysis.specialAnalysisSamples && specialAnalysis.specialAnalysisSamples.length > 0
      ? specialAnalysis.specialAnalysisSamples.map(
        (wrs: any) => `${wrs.sampleName}: ${wrs.sample?.description}`
      )
      : [];

  return (
    <Page size="A4" wrap>
      <View style={styles.page}>
        <View style={styles.firstSection}>
          <Text style={styles.boldText}>SPECIAL ANALYSIS REQUEST</Text>
          <Text style={styles.boldText}>(Organic Analysis Group)</Text>
        </View>
        <View
          style={{
            marginTop: "24px",
            border: "1px solid black",
            padding: "8px",
          }}
        >
          <View style={[styles.row, styles.noBorder]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Requester Name:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.requestedByEmail}
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Tel. No:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {businessPhones}
            </Text>
          </View>
          <View style={[styles.smallMarginTop, styles.row, styles.noBorder]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Date:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.createdDate
                ? convertToLocalTime(specialAnalysis.createdDate)
                : ""}
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Cost Code:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.chargeCode}
            </Text>
          </View>
          <View style={[styles.smallMarginTop, styles.row, styles.noBorder]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Business Section:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.businessUnitName}
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Sample MIDAS No.:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
          </View>
        </View>
        <Text style={[styles.labelText, styles.smallMarginTop]}>
          PLEASE INCLUDE A COPY OF THIS FORM WITH SAMPLES.
        </Text>
        <View style={[styles.flexRow, styles.smallMarginTop]}>
          <Text style={[styles.labelText]}>SEND SAMPLES TO ROOM:</Text>
          <Text style={[styles.labelText]}>
            {specialAnalysis.deliveryLocationName}
          </Text>
        </View>
        <View style={[styles.flexRow, styles.smallMarginBottom]}>
          <Text style={[styles.labelText]}>MPI Classification Level:</Text>
          <View style={[styles.flexColumn, { marginLeft: "12px" }]}>
            <CheckBox
              checked={dataClassificationName === "Unclassified"}
              label="Unclassified (None)"
            />
            <CheckBox
              checked={
                dataClassificationName ===
                "Proprietary - Restricted Distribution"
              }
              label="Restricted"
            />
            <CheckBox
              checked={dataClassificationName === "Proprietary"}
              label="Proprietary"
            />
          </View>
        </View>
        <View style={[styles.smallMarginTop]}>
          <Text
            style={[
              styles.labelText,
              styles.underlineText,
              styles.smallMarginBottom,
            ]}
          >
            Description of material to be analyzed: Origin, history, previous
            analyses, correspondences, and all other related background
            information. Attachments are welcome and strongly encouraged to
            enhance our ability to most expeditiously and most appropriately
            meet your needs.
          </Text>
        </View>
        <View style={[styles.section]}>
          {samplesAndDescriptions.length > 0 &&
            samplesAndDescriptions.map((sd: string) => (
              <Text key={sd} style={[styles.labelText]}>
                {sd}
              </Text>
            ))}
        </View>
        <View style={[styles.flexRow, styles.smallMarginTop]}>
          <Text style={[styles.labelText]}>
            {specialAnalysis.specialAnalysisForm?.requestDescription}
          </Text>
        </View>
        <View style={[styles.flexRow, styles.smallMarginTop, styles.section]}>
          <Text style={[styles.regularSectionTitle]}>
            Fundamental reason for analysis:
          </Text>
          <Text style={[styles.labelText]}>
            {specialAnalysis.specialAnalysisForm?.requestReason}
          </Text>
        </View>
        <View style={[styles.flexRow, styles.smallMarginTop, styles.section]}>
          <Text style={[styles.regularSectionTitle]}>
            Analyses required, if known, or nature of results expected:
          </Text>
          <Text style={[styles.labelText]}>
            {specialAnalysis.specialAnalysisForm?.testingRequirements}
          </Text>
        </View>
        <View style={[styles.row, styles.noBorder]}>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            Requested Completion Date:
          </Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            {specialAnalysis.requestedCompletionDate
              ? convertToLocalTime(specialAnalysis.requestedCompletionDate)
              : ""}
          </Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            Requester's Signature:
          </Text>
          <Text
            style={[
              styles.labelText,
              styles.oneQuarterColumn,
              { borderBottom: "1px solid black" },
            ]}
          ></Text>
        </View>
        <View style={[styles.row, styles.noBorder, styles.smallMarginTop]}>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            Priority:
          </Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            {specialAnalysis.priorityName}
          </Text>
        </View>
        <View style={[styles.row, styles.noBorder, styles.smallMarginTop]}>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            Brief Justification if Rush:
          </Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}>
            {specialAnalysis.priorityReason}
          </Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
          <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
        </View>
        <View
          style={[styles.flexRow, styles.smallMarginTop, styles.section]}
          wrap={false}
        >
          <Text style={[styles.regularSectionTitle]}>
            Final report format: (Initial results will be reported by phone or
            EM.)
          </Text>
          <View style={[styles.flexRow, styles.smallMarginTop]}>
            <CheckBox
              checked={reportFormatName === "EM - Summary Only"}
              label="EM-Summary Only"
            />
            <CheckBox
              checked={reportFormatName === "EM - Summary Only"}
              label="IOC-Summary Only"
              marginLeft="8px"
            />
            <CheckBox
              checked={reportFormatName === "IOC - Formal Report"}
              label="IOC-Formal Report w/Attachments"
              marginLeft="8px"
            />
            <CheckBox
              checked={reportFormatName === "Data Only"}
              label="Data Only"
              marginLeft="8px"
            />
          </View>
        </View>
        <View
          style={[styles.flexRow, styles.smallMarginTop, styles.section]}
          wrap={false}
        >
          <Text style={[styles.regularSectionTitle, { alignSelf: "center" }]}>
            FOR ANALYTICAL USE:
          </Text>
          <View style={[styles.row, styles.noBorder, styles.smallMarginTop]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Estimated Man/Hours:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.specialAnalysisForm?.estimatedTotalHours}
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Estimated Completion Date:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {" "}
              {specialAnalysis.specialAnalysisForm &&
                specialAnalysis.specialAnalysisForm.estimatedCompletionDate
                ? convertToLocalTime(
                  specialAnalysis.specialAnalysisForm.estimatedCompletionDate
                )
                : ""}
            </Text>
          </View>
          <View style={[styles.row, styles.noBorder, styles.smallMarginTop]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Special Analysis Request #:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              {specialAnalysis.specialAnalysisForm?.id}
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Date Completed:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
          </View>
          <View style={[styles.row, styles.noBorder, styles.smallMarginTop]}>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Charge to PCA:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}>
              Reported via:
            </Text>
            <Text style={[styles.labelText, styles.oneQuarterColumn]}></Text>
          </View>
        </View>
      </View>
    </Page>
  );
}

function CheckBox({
  checked,
  label,
  marginLeft,
}: {
  label: string;
  checked: boolean;
  marginLeft?: string;
}) {
  return (
    <View
      style={[
        styles.flexRow,
        { alignItems: "center", marginLeft: marginLeft ?? undefined },
      ]}
    >
      {checked ? (
        <View
          style={{
            border: "1px solid black",
            width: "8px",
            height: "8px",
            position: "relative",
            marginRight: "8px",
          }}
        >
          <Text
            style={{
              position: "absolute",
              fontSize: "8px",
            }}
          >
            X
          </Text>
        </View>
      ) : (
        <View
          style={{
            border: "1px solid black",
            width: "8px",
            height: "8px",
            marginRight: "6px",
            position: "relative",
          }}
        />
      )}
      <Text style={[styles.labelText]}>{label}</Text>
    </View>
  );
}

type SummaryProps = {
  specialAnalysis: any | null;
  businessPhones?: string;
  billingList: any[];
};

export default function Summary({ specialAnalysis, businessPhones, billingList }: SummaryProps) {
  if (!specialAnalysis) {
    return (
      <Document>
        <Page size="A4"></Page>
      </Document>
    );
  }

  const jadeDescription = billingList.find(f => f.costCode === specialAnalysis.chargeCode)?.description ?? '';

  return (
    <Document>
      <Page size="A4" wrap>
        <View style={styles.page}>
          <View>
            <View style={styles.firstSection}>
              <Text style={styles.boldText}>
                Special Analysis Summary for{" "}
                {specialAnalysis.mwrName ? specialAnalysis.mwrName : specialAnalysis.id} as
                of {convertToLocalTime(new Date())}
              </Text>
            </View>
          </View>

          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>Date Created</Text>
            <Text>
              {specialAnalysis.createdDate
                ? convertToLocalTime(specialAnalysis.createdDate)
                : "N/A"}
            </Text>
          </View>
          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>Last Acceptable Date</Text>
            <Text>
              {specialAnalysis.lastAcceptableCompletionDate
                ? convertToLocalTime(specialAnalysis.lastAcceptableCompletionDate)
                : "N/A"}
            </Text>
          </View>
          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>Status</Text>
            <Text>{specialAnalysis.statusName}</Text>
          </View>

          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>
              Company Code/Cost Center
            </Text>
            <Text>{specialAnalysis.chargeCode ? `${specialAnalysis.chargeCode} ~ ${jadeDescription}` : ''}</Text>
          </View>

          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>
              Special Analysis Description
            </Text>
            <Text>{specialAnalysis.description}</Text>
          </View>
          <View style={{ ...styles.section }}>
            <Text style={styles.regularSectionTitle}>
              Special Analysis Comments/Detail
            </Text>
            <Text>{specialAnalysis.comments}</Text>
          </View>

          <View style={{ ...styles.section, marginTop: 16 }} wrap={false}>
            <Text style={styles.regularSectionTitle}>
              MIDAS # Sample, Chem ID and PSIMS ID
            </Text>
            {specialAnalysis.specialAnalysisSamples &&
              specialAnalysis.specialAnalysisSamples.length > 0 && (
                <View style={styles.table}>
                  <View style={[styles.row, styles.boldText]}>
                    <Text
                      style={[
                        styles.oneThirdColumn,
                        styles.noBorderRight,
                        styles.boldText,
                      ]}
                    >
                      MIDAS #
                    </Text>
                    <Text
                      style={[
                        { ...styles.oneThirdColumn, borderRight: "none" },
                      ]}
                    >
                      CHEM ID
                    </Text>
                    <Text style={[styles.oneThirdColumn, styles.boldText]}>
                      Current Container Location
                    </Text>
                    <Text style={[styles.oneThirdColumn, styles.boldText]}>
                      Delivery Location
                    </Text>
                    <Text style={[styles.oneThirdColumn, styles.boldText]}>
                      PSIMS ID
                    </Text>
                  </View>
                  {specialAnalysis.specialAnalysisSamples.map(
                    (sample: any, index: number) => (
                      <View
                        style={[
                          styles.row,
                          styles.boldText,
                          {
                            borderBottom:
                              index + 1 ===
                                specialAnalysis.specialAnalysisSamples.length
                                ? "1px solid black"
                                : "none",
                          },
                        ]}
                        key={sample.id + sample.name}
                      >
                        <Text
                          style={[
                            styles.oneThirdColumn,
                            styles.noBorderRight,
                            styles.emphasizedText,
                          ]}
                        >
                          {sample.sample?.sampleName}
                        </Text>
                        <Text
                          style={[styles.oneThirdColumn, styles.noBorderRight]}
                        >
                          {sample.sample.chemID}
                        </Text>
                        <Text style={{ ...styles.oneThirdColumn }}>
                          {sample.existingContainer
                            ? sample.existingContainer.locationName
                            : ""}
                        </Text>
                        <Text style={{ ...styles.oneThirdColumn }}>
                          {sample.existingContainer
                            ? sample.existingContainer.returnLocationName
                            : ""}
                        </Text>
                        <Text style={{ ...styles.oneThirdColumn }}>
                          {sample.sample.psimsid}
                        </Text>
                      </View>
                    )
                  )}
                </View>
              )}
          </View>
        </View>
      </Page>
      <SpecialAnalysisPage
        specialAnalysis={specialAnalysis}
        businessPhones={businessPhones}
      />
    </Document>
  );
}
