import React from "react";
import { styled } from "@mui/material/styles";
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from "@mui/material";
  
import { Table, TableCell, TableRow } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const adPersonas = {
    title: "Personas",
    description: "Personas are designed around a specific job functionality and come with a predefined set of Roles for the job.",
    isPersona: true,
    groups: [
        {
            title: "Analytical Technician",
            adGroup: "MIDAS.AnalyticalTechnician.UG",
            roles: "Analyst, Test Activator, Instrument Administrator, Container Mover, SQC Report, SQC Viewer"
        },
        {
            title: "Analytical Test Owner",
            adGroup: "MIDAS.TestOwner.UG",
            roles: "Analyst, Analytical Data Reviewer, Test Activator, Test Priority Maintainer, Method Administrator, Instrument Administrator, SQC Administrator, SQC Reviewer, SQC Viewer"
        },
        {
            title: "Analytical Supervisor",
            adGroup: "MIDAS.AnalyticalSupervisor.UG",
            roles: "Test Priority Maintainer, Test Activator, Analytical Data Reviewer, Method Administrator, Instrument Administrator, Result Component Administrator, SQC Administrator, SQC Reviewer, SQC Viewer"
        },
        {
            title: "Blending Technician",
            adGroup: "MIDAS.BlendTechnician.UG",
            roles: "Batch Component Substituter, Container Mover, Batch Preparer"
        },
        {
            title: "Blending Supervisor",
            adGroup: "MIDAS.BlendSupervisor.UG",
            roles: "Batch Assigner, Batch Test Status Maintainer, Blend Maintainer, Instrument Administrator, Batch Component Substituter"
        },
        {
            title: "Distillation Technician",
            adGroup: "MIDAS.DistillationTechnician.UG",
            roles: "Distillation Experiment Maintainer, Distillation Template Maintainer, Container Mover"
        },
        {
            title: "Distillation Supervisor",
            adGroup: "MIDAS.DistillationSupervisor.UG",
            roles: "Distillation Corrector, Distillation Experiment Maintainer, Distillation Template Maintainer"
        },
        {
            title: "SHE Personel",
            adGroup: "MIDAS.Safety.UG",
            roles: "New Chemical Authorization Approver, TSCA Approver, Substance Maintainer, Substance Safety Maintainer, Substance Structure Maintainer"
        },
        {
            title: "Stationary Testing Technician",
            adGroup: "MIDAS.StationaryTestingTechnician.UG",
            roles: "ST Request Engineer, Container Mover"
        },
        {
            title: "Stationary Testing Supervisor",
            adGroup: "MIDAS.StationaryTestingSupervisor.UG",
            roles: "ST Request Engineer, ST Procedure Administrator, ST Schedule Administrator, ST Stand Administrator"
        },
        {
            title: "Work Request Technician",
            adGroup: "MIDAS.WorkRequestTechnician.UG",
            roles: "Work Request Technician, Container Mover, Drum Field Maintainer, Shipping Coordinator"
        },
        {
            title: "Work Request Supervisor",
            adGroup: "MIDAS.WorkRequestSupervisor.UG",
            roles: "Work Request Technician, Work Request Assigner, Work Request Facility Assigner, Work Request Reporter, Container Mover, Drum Field Maintainer"
        },
        {
            title: "MIDAS Master Data Administrator",
            adGroup: "MIDAS.Administrator.UG",
            roles: "Analytical Master Data Administrator, Chemical Inventory/Safety Master Data Administrator, Distillation Master Data Administrator, Formulations Master Data Administrator, Location/Site Master Data Administrator, Stationary Testing Master Data Administrator, Stockroom Master Data Administrator, Work Request Master Data Administrator"
        }
    ]
};

const adRoles  = {
    title: "Roles",
    description: "Roles are related to specific functionality in MIDAS 3.  Generally, necessary Roles will come with membership in your Persona(s), but Roles can also be added individually.",
    isPersona: false,
    groups: [
        {
            title: "Analytical Data Reviewer",
            adGroup: "MIDAS.ROLE.TESTREVIEWER.UG",
            roleName: "TEST.REVIEWER",
            description: "Allows the User to review Analytical Test data, Correct Test submissions, and mark Test data as Validated or Corrected"
        },
        {
            title: "Analytical Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.ANALYTICAL.UG",
            roleName: "MASTERDATA.ANALYTICAL",
            description: "Allows the User to edit master data tables specific to the Analytical area - Method Category, Test Facility, Test Priority, Test Status, Method Facility Status, Test Condition Name"
        },
        {
            title: "Batch Assigner",
            adGroup: "MIDAS.ROLE.BLEND.BATCHASSIGNER.UG",
            roleName: "BATCH.ASSIGNER",
            description: "Allows the User to assign a technician, other than themselves, to a submitted Batch request"
        },
        {
            title: "Batch Component Substituter",
            adGroup: "MIDAS.ROLE.BLEND.COMPONENTSUBSTITUTER.UG",
            roleName: "BATCH.COMPONENTSUBSTITUTER",
            description: "Allows the User to substitute a requested Batch Component for another approved Substance"
        },
        {
            title: "Batch Preparer",
            adGroup: "MIDAS.ROLE.BLEND.BATCHPREPARER.UG",
            roleName: "BATCH.PREPARER",
            description: "Allows the User to process a formulations Batch request"
        },
        {
            title: "Batch Test Status Maintainer",
            adGroup: "MIDAS.ROLE.BLEND.BATCHSTATUSMAINTAINER.UG",
            roleName: "BATCH.STATUSMAINTAINER",
            description: "Allows the User to manually change the Status of a Blend Request or Batch, outside of the automatic process"
        },
        {
            title: "Blend Maintainer",
            adGroup: "MIDAS.ROLE.BLEND.MAINTAINER.UG",
            roleName: "BLEND.MAINTAINER",
            description: "Allows the User to update any Blend Request in the Formulations space.  (A User can update their own Blend Request after submission up until work on the Blend Request has started)"
        },
        {
            title: "Chemical Inventory/Safety Master Data",
            adGroup: "MIDAS.ROLE.MASTERDATA.CHEMICAL.UG",
            roleName: "MASTERDATA.CHEMICAL",
            description: "Allows the User to update Master Data elements pertaining to Chemical Safety and Inventory: ChemIDCategory, JapanFireSafetyCode, JapanSDSCode, SubstanceStatus, SubstanceSubstitue, AuthorizationRequestStatus, SpecialHandlingStatus, ExperimentalSubstanceType, ExperimentalSubstanceSubtype1, ExperimentalSubstanceSubtype2, ExperimentalSubstanceSubtype3"
        },
        {
            title: "Container Disposition Manager",
            adGroup: "MIDAS.ROLE.CONTAINER.DISPOSITIONMAINTAINER.UG",
            roleName: "CONTAINER.DISPOSITIONMAINTAINER",
            description: "Allows the User to manager the Container Disposition process, including adding a Site to the Container Disposition process (future use)"
        },
        {
            title: "Container Editor",
            adGroup: "MIDAS.ROLE.CONTAINER.EDITOR.UG",
            roleName: "CONTAINER.EDITOR",
            description: "Allows the User to edit properties of a container that they do not own."
        },
        {
            title: "Container Mover",
            adGroup: "MIDAS.ROLE.CONTAINER.RECEIVER.UG",
            roleName: "CONTAINER.RECEIVER",
            description: "Allows the User to receive a Container to a new Location that does not belong to them.  (A User can freely move their own Containers to a new Location)"
        },
        {
            title: "Distillation Corrector",
            adGroup: "MIDAS.ROLE.DISTILLATION.CORRECTOR.UG",
            roleName: "DISTILLATION.CORRECTOR",
            description: "Allows the User to Correct a Distillation Experiments data after the completion of the experiment"
        },
        /*{
            title: "Distillation Experiment Maintainer",
            adGroup: "MIDAS.ROLE.DISTILLATION.EXPERIMENTMAINTAINER.UG",
            roleName: "DISTILLATION.EXPERIMENTMAINTAINER",
            description: "Allow the User to edit any Distillation Experiment after submission.  (A User can always update their own submissions up until work has started)"
        },*/
        {
            title: "Distillation Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.DISTILLATION.UG",
            roleName: "MASTERDATA.DISTILLATION",
            description: "Allows the User to manage Master Data elements in the Distillation space: DistillationCutType, DistillationExperimentPriority, DistillationStatus, DistillationType"
        },
        /*{
            title: "Distillation Template Maintainer",
            adGroup: "MIDAS.ROLE.DISTILLATION.TEMPLATEMAINTAINER.UG",
            roleName: "DISTILLATION.TEMPLATEMAINTAINER",
            description: "Allows the User to create and maintainer Distillation Template for use in a Distillation Experiment"
        },*/
        {
            title: "Drum Field Maintainer",
            adGroup: "MIDAS.ROLE.CONTAINER.DRUMMAINTAINER.UG",
            roleName: "CONTAINER.DRUMMAINTAINER",
            description: "Allows the User to receive a Container to and from the drum storage areas (CDRUM)"
        },
        {
            title: "EMBSI User",
            adGroup: "**See your supervisor**",
            roleName: "EMBSIUSER",
            description: "Allows the User to create and maintain EMBSI Use Only Samples and Containers"
        },
        {
            title: "Formulations Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.FORMULATIONS.UG",
            roleName: "MASTERDATA.FORMULATIONS",
            description: "Allows the User to maintain Master Data specific to the Formulations area: BatchCertificationStatus, BatchStatus, BlendFacility, BlendPriority, BlendProductGroup, BlendStatus, BlendStudyStatus, BlendStudyType"
        },
        {
            title: "General SRA Access",
            adGroup: "*See your supervisor*",
            roleName: "GENERALSRA",
            description: "Allows the User to access data for Projects marked as General SRA Access, regardless of individual membership in those Projects"
        },
        {
            title: "Instrument Adminstrator",
            adGroup: "MIDAS.ROLE.INSTRUMENT.ADMINISTRATOR.UG",
            roleName: "INSTRUMENT.ADMINISTRATOR",
            description: "Allows the User to manage and maintain instruments within the system"
        },
        {
            title: "Inventory Coordinator",
            adGroup: "MIDAS.ROLE.INVENTORY.COORDINATOR.UG",
            roleName: "INVENTORY.COORDINATOR",
            description: "Allows the User to manage the MIDAS based inventory process (future use)"
        },
        {
            title: "Location/Site Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.SITE.UG",
            roleName: "MASTERDATA.SITE",
            description: "Allows the User to maintain Master Data related to the application-wide Location and Site objects"
        },
        {
            title: "Lot Certifier",
            adGroup: "MIDAS.ROLE.LOT.CERTIFIER.UG",
            roleName: "LOT.CERTIFIER",
            description: "Allows the User to certify and enter CoA data for a Lot received from an external vendor"
        },
        {
            title: "Method Administrator",
            adGroup: "MIDAS.ROLE.METHODADMINISTRATOR.UG",
            roleName: "METHOD.ADMINISTRATOR",
            description: "Allows the User to create and maintain Method and MethodFacility setup data for the Analytical features"
        },
        {
            title: "New Chemical Authorization Approver",
            adGroup: "MIDAS.ROLE.NCA.APPROVER.UG",
            roleName: "NCA.APPROVER",
            description: "Allows the User to edit and approve, or deny, a New Chemical Authorization request"
        },
        {
            title: "Precious Metals User",
            adGroup: "MIDAS.ROLE.PMUSER.UG",
            roleName: "PMUSER",
            description: "Allows the User to create and maintain Samples and Containers flagged for Controlled Precious Metal"
        },
        {
            title: "Project Administrator",
            adGroup: "MIDAS.ROLE.PROJECTADMIN.UG",
            roleName: "MASTERDATA.PROJECT",
            description: "Allows the User to manage project setup details (for projects they don't own), including granting users access to projects"
        },
        {
            title: "Result Component Administrator",
            adGroup: "MIDAS.ROLE.RESULTCOMPONENT.ADMINISTRATOR.UG",
            roleName: "RESULTCOMPONENT.ADMINISTRATOR",
            description: "Allows the User to create and maintain Result Component (formerly IDNUM) data for use in Analytical features"
        },
        {
            title: "Shipping Coordinator",
            adGroup: "MIDAS.ROLE.WORKREQUEST.SHIPPINGCOORDINATOR.UG",
            roleName: "WORKREQUEST.SHIPPINGCOORDINATOR",
            description: "Allows the User to process Shipping Work Requests"
        },
        {
            title: "SQC Administrator",
            adGroup: "MIDAS.ROLE.SQC.ADMINISTRATOR.UG",
            roleName: "SQC.ADMINISTRATOR",
            description: "Allows the User to add and maintain SQC/QC setup data to existing MethodFacilities"
        },
        {
            title: "SQC Reporter",
            adGroup: "MIDAS.ROLE.SQC.REPORTER.UG",
            roleName: "QC.REPORTER",
            description: "Allows the User to enter SQC/QC data in the MIDAS SQC system"
        },
        {
            title: "SQC Reviewer",
            adGroup: "MIDAS.ROLE.SQC.REVIEWER.UG",
            roleName: "QC.REVIEWER",
            description: "Allows the User to review and update SQC/QC data in the MIDAS SQC system"
        },
        {
            title: "SQC Viewer",
            adGroup: "MIDAS.ROLE.SQC.VIEWER.UG",
            roleName: "QC.VIEWER",
            description: "Allows the User to view SQC/QC data in the MIDAS SQC system"
        },
        {
            title: "ST Procedure Administrator",
            adGroup: "MIDAS.ROLE.ST.PROCEDUREADMIN.UG",
            roleName: "STATIONARYTESTING.PROCEDUREADMINISTRATOR",
            description: "Allows the User to create and maintain the Stationary Testing Procedure and Test templates"
        },
        {
            title: "ST Schedule Administrator",
            adGroup: "MIDAS.ROLE.ST.SCHEDULEADMIN.UG",
            roleName: "STATIONARYTESTING.SCHEDULEADMINISTRATOR",
            description: "Allows the User to adjust the test schedule for a submitted Stationary Test experiment"
        },
        {
            title: "ST Stand Administrator",
            adGroup: "MIDAS.ROLE.ST.STANDADMIN.UG",
            roleName: "STATIONARYTESTING.STANDADMINISTRATOR",
            description: "Allows the User to create and maintain Stationary Testing Stands"
        },
        {
            title: "Stationary Testing Master Data",
            adGroup: "MIDAS.ROLE.MASTERDATA.ST.UG",
            roleName: "MASTERDATA.STATIONARYTESTING",
            description: "Allows the User to maintain Master Data relevant to the Stationary Testing area: STEngineer, STFacility, STIntervalResultOption, STMethodClassification, STParameter, STQueue, STStandType, STStatus, STType"
        },
        {
            title: "STLC Maintainer",
            adGroup: "MIDAS.ROLE.STLCMAINTAINER.UG",
            roleName: "STLC.MAINTAINER",
            description: "Allows the User to maintain Supplier, Tradename, and Test Plan data ingested from ProMIS"
        },
        {
            title: "Stockroom Administrator",
            adGroup: "MIDAS.ROLE.STOCKROOM.ADMIN.UG",
            roleName: "STOCKROOM.ADMINISTRATOR",
            description: "Allows the User to perform basic functions included with the Stockroom Worker role and also allows for administrative management in stockroom. This includes adding new items, managing vendors and working with POs."
        },
        {
            title: "Stockroom Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.STOCKROOM.UG",
            roleName: "MASTERDATA.STOCKROOM",
            description: "Allows the User to maintain Master Data objects relevant to the Stockroom area: StockroomCategory, StockroomContractWorker, StockroomVendor, EquipmentStatus, EquipmentType"
        },
        {
            title: "Stockroom Worker",
            adGroup: "MIDAS.ROLE.STOCKROOM.WORKER.UG",
            roleName: "STOCKROOM.WORKER",
            description: "Allows the User to perform basic functions in the stockroom module such as fulfilling orders, receiving stock, and returning items."
        },
        {
            title: "Substance Maintainer",
            adGroup: "MIDAS.ROLE.SUBSTANCE.MAINTAINER.UG",
            roleName: "SUBSTANCE.MAINTAINER",
            description: "Allows the User to create and maintain base data for all Substances (excludes safety). A User can always update Substances that they own."
        },
        {
            title: "Substance Safety Maintainer",
            adGroup: "MIDAS.ROLE.SUBSTANCE.SAFETYMAINTAINER.UG",
            roleName: "SUBSTANCE.SAFETYMAINTAINER",
            description: "Allows the User to update and validate safety information for any Substance."
        },
        {
            title: "Substance Structure Maintainer",
            adGroup: "MIDAS.ROLE.SUBSTANCE.STRUCTUREMAINTAINER.UG",
            roleName: "SUBSTANCE.STRUCTUREMAINTAINER",
            description: "Allows the User to create and maintain Structures (SMILES) for use with Substances."
        },
        {
            title: "Test Activator",
            adGroup: "MIDAS.ROLE.TESTACTIVATOR.UG",
            roleName: "TEST.ACTIVATOR",
            description: "Allows the User to set the Status of a Test from Pending to Backlogged."
        },
        {
            title: "Test Analyst",
            adGroup: "MIDAS.ROLE.ANALYST.UG",
            roleName: "ANALYST",
            description: "Allows the User to access the Analytical Services-Simple Result Entry Page, access the Analytical Services-Worksheet Result Entry Page, post data to a Pending Test (through all sources), and set the status of a Test to In Progress."
        },
        {
            title: "Test Priority Maintainer",
            adGroup: "MIDAS.ROLE.TEST.PRIORITYMAINTAINER.UG",
            roleName: "TEST.PRIORITYMAINTAINER",
            description: "Allows the User to maintain the priority of tests in the system."
        },
        {
            title: "TSCA Approver",
            adGroup: "MIDAS.ROLE.TSCA.APPROVER.UG",
            roleName: "TSCA.APPROVER",
            description: "Allows the User to update, approve, or deny a TSCA Approval request for a Shipping Work Request"
        },
        {
            title: "Work Request Assigner",
            adGroup: "MIDAS.ROLE.WORKREQUEST.ASSIGNER.UG",
            roleName: "WORKREQUEST.ASSIGNER",
            description: "Allows the User to manually update the AssignedTo value of a Work Request"
        },
        {
            title: "Work Request Facility Assigner",
            adGroup: "MIDAS.ROLE.WORKREQUEST.FACILITYASSIGNER.UG",
            roleName: "WORKREQUEST.FACILITYASSIGNER",
            description: "Allows the User to assign a Work Request to a specific Facility"
        },
        {
            title: "Work Request Master Data Administrator",
            adGroup: "MIDAS.ROLE.MASTERDATA.WORKREQUEST.UG",
            roleName: "MASTERDATA.WORKREQUEST",
            description: "Allows the User to maintain Master Data objects relevant to the Work Request area: WorkRequestBusinessUnit, WorkRequestFacility, WorkRequestPriority, WorkRequestStatus, WorkRequestType, Shipping Reason"
        },
        {
            title: "Work Request Reporter",
            adGroup: "MIDAS.ROLE.WORKREQUEST.REPORTER.UG",
            roleName: "WORKREQUEST.REPORTER",
            description: "Allows the User to run reports and metrics (PISCES) on the Work Request processes"
        },
        {
            title: "Work Request Technician",
            adGroup: "MIDAS.ROLE.WORKREQUEST.TECHNICIAN.UG",
            roleName: "WORKREQUEST.TECHNICIAN",
            description: "Allows the User to process a Work Request request"
        }    
    ]
};

const adProjects  = {
    title: "Projects",
    description: "Projects in MIDAS 3 are groupings of samples, specific to MIDAS 3 to limit access to Sample data for members of a specific development project, e.g. F1M or Military projects",
    groups: []
};


const Roles = ({ ...props }) => {
    const StyledAccordion = styled(Accordion)(() => ({
        width: "50rem",
        margin:"0.25rem 0"
    }));
    
    const StyledAccordionDetails = styled(AccordionDetails)(() => ({
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap"
    }));

    const StyledAccordionSummary = styled(AccordionSummary)(() => ({
        color: "#1890ff"
    }));

    const StyledTableCellInfo = styled(TableCell)({
        border: "none",
        paddingBottom:"0px",
        paddingTop:"0px",
        color:"#00000099",
        fontFamily:"EMprint",
        fontWeight:"400",
        fontSize: "14px"
    });

    const LogicalGroup = ({ title, description, isPersona, groups }) => (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography><h3>{title}</h3></Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <p>{description}</p>
                {groups.map((group, index) => (
                    <AdGroup
                        key={index}
                        title={group.title}
                        adGroup={group.adGroup}
                        roleNames={isPersona ? group.roles : group.roleName}
                        description={group.description}
                        isPersona={isPersona}
                    />
                ))}
            </StyledAccordionDetails>
        </StyledAccordion>
    );

    const AdGroup = ({ title, adGroup, roleNames, description = "Details TBD", isPersona = false }) => (
        <StyledAccordion>
            <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography><h4>{title}</h4></Typography>
            </StyledAccordionSummary>
            <StyledAccordionDetails>
                <Table>
                    <TableRow>
                        <StyledTableCellInfo><b>Active Directory group:</b> {adGroup}</StyledTableCellInfo>
                    </TableRow>
                    <TableRow>
                        <StyledTableCellInfo><b> MIDAS 3 {isPersona? "Roles:" : "Role Name:"}</b> {roleNames}</StyledTableCellInfo>
                    </TableRow>
                    <TableRow>
                        <StyledTableCellInfo>{description}</StyledTableCellInfo>
                    </TableRow>
                </Table>
            </StyledAccordionDetails>
        </StyledAccordion>
    );

    return (
        <div>
            <p>
                By default, everyone with an ExxonMobil email address (connecting through a GME computer) has an account in MIDAS 3; MIDAS 3 supports Single Sign-On with the company Active Directory.  However, Role assignments are required for specific functionality throughout the MIDAS 3 system.  These Roles, or higher level Personas (base collection of Roles), are assigned through Active Directory group membership. 
                <br/><br/>
                Click <a
                    href='https://emprod.service-now.com/itsp?id=sc_cat_item&sys_id=aa73c5761b1170108b18326ecc4bcb97&referrer=popular_items'
                    target='_blank'
                    rel='noopener noreferrer'
                >here</a> to request to join a Persona or Role group.  Refer to the training material on how to use the form.
            </p>
            { [adPersonas, adRoles, adProjects].map((item, index) => (
                <LogicalGroup
                    key={index}
                    title={item.title}
                    description={item.description}
                    groups={item.groups}
                    isPersona={item.isPersona}
                />
            ))}
        </div>
    );
};

export default Roles;