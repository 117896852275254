import * as Yup from "yup";
import { calculateDateFromToday } from "./helpers";

export const ProjectFormValidationSchema = (invalidProjectNames: string[]) => {
  const nameMaxChars = 20;
  const descriptionMaxChas = 200;
  const minimumDaysFromToday = 30;
  const minimumDate = calculateDateFromToday(minimumDaysFromToday);

  return Yup.object().shape({
    name: Yup.string()
      .required("Project name is required")
      .max(nameMaxChars, `Project name cannot exceed ${nameMaxChars} characters`)
      .test("unique-name", "Project name must be unique", (value) => {
        if (!value) return true;
        const lowerCaseValue = value.toLowerCase().trim();
        return !invalidProjectNames.some((name) => name.toLowerCase().trim() === lowerCaseValue);
      }),
    description: Yup.string().max(
      descriptionMaxChas,
      `Project description cannot exceed ${descriptionMaxChas} characters`,
    ),
    ownerEmail: Yup.string().required("Project owner email is required"),
    secondaryOwnerEmail: Yup.string()
    .nullable()
    .test("email-different", "Secondary owner should be different from primary ", function (value) {
      if (!value) return true;
      return value !== this.parent.ownerEmail;
    }),
    expirationDate: Yup.date()
      .required("Project expiration date is required")
      .when("isActive", {
        is: true,
        then: (schema) => schema.min(
          minimumDate,
          `Project expiration date must be at least  ${minimumDaysFromToday} days from today’s date`,
        )
      }),
    restrictAtEOL: Yup.boolean(),
    isActive: Yup.boolean(),
    ndaAgreementFile: Yup.mixed<File>().when(
      "ndaAgreementFileName",
      (ndaAgreementFileName, schema) =>
        !ndaAgreementFileName ? schema.required("File is required") : schema,
    ),
  });
};
