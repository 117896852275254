import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import { defaultShippingAddress, FILE_ATTACHMENT_TABLE_COLUMNS, getFacilitiesExternal, labelProp, StyledAutocomplete, StyledTextField, SubTitle, IsInternationalShipping } from "../constants";
import { convertToLocalTime, countries, DatePickerKeyDownEvent, downloadFile } from "../../../../global";
import { WorkRequestFormAutocomplete, WorkRequestFormDate, WorkRequestFormInput } from "../WorkRequestFormInput";
import WorkRequestNonChemicalTable from "../../NewWorkRequests/components/WorkRequestNonChemicalTable";
import SampleSafetyInfo from "./SampleSafetyInfo";
import { useState } from "react";
import ModalMethodFacilityExternal from "../ModalMethodFacilityExternal";
import { Warning } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import TrashIcon from "@mui/icons-material/Delete";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import FileDragAndDrop from "../../../../components/FileDragAndDrop";

const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export default function ShippingInformation({
  setAddressBookOpen,
  setShippingAddress,
  shippingAddress,
  setShippingRequest,
  shippingRequest,
  shippingCarriersLoading,
  shippingCarriers,
  shippingReasonsLoading,
  shippingReasons,
  errors,
  setErrors,
  addCommentComponent,
  nonChemicalShipping,
  setNonChemicalShipping,
  myWorkRequest,
  unitOfMeasureWUnit,
  unitOfMeasureLoading,
  mySamplesInfo,
  dotHazardClass,
  dotHazardClassLoading,
  workRequestId,
  isProcess,
  disableForm,
  files,
  setFiles,
  openModalMessages,
}) {
  const [modalFacilityExternalOpen, setModalFacilityExternalOpen] = useState(false);
  const [shippedVia, setShippedVia] = useState(null);
  const [trackingCode, setTrackingCode] = useState("");

  // function handlePrint() {
  //   window.print();
  // }

  function clearAddress() {
    setShippingAddress(defaultShippingAddress)
  }

  // useEffect(() => {
  //   const shippingAddressChanged = somethingIsDifferent(shippingAddress, defaultShippingAddress);
  //   if (!shippingAddressChanged) {
  //     let method = null

  //     mySamplesInfo.find(f => f.containers.find(c => {
  //       if (c.testSubmissionJSON) {
  //         const parsedValue = JSON.parse(c.testSubmissionJSON)
  //         method = parsedValue?.methods && parsedValue?.methods.length > 0 ? parsedValue.methods.find(m => m.externalLaboratory?.shippingAddress) : null;
  //         return true;
  //       }
  //       return false;
  //     }))

  //     if (method) {
  //       handleAddressBook(method.externalLaboratory?.shippingAddress);
  //     }
  //   }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  function addTracking() {
    const carrier = shippingCarriers.find((s) => s.carrierName === shippedVia);

    if (carrier) {
      setShippingRequest(() => ({
        ...shippingRequest,
        shippingTrackings: [
          ...shippingRequest?.shippingTrackings,
          {
            shippingRequestID: shippingRequest?.id,
            shippingCarrierID: carrier.id,
            shipmentTrackingNumber: trackingCode,
          },
        ],
      }));

      setShippedVia(null);
      setTrackingCode("");
    }
  }

  function deleteTracking(tracking) {
    const trackings = shippingRequest?.shippingTrackings.filter(
      (t) => t !== tracking
    );
    setShippingRequest(() => ({
      ...shippingRequest,
      shippingTrackings: trackings,
    }));
  }

  function handleChangeFile(property, index) {
    const listFiles = files.map((file, idx) => {
      if (index === idx && file.file?.id) {
        return {
          ...file,
          file: {
            ...file.file,
            [property]: !file.file[property],
          },
        };
      } else if (index === idx) {
        return {
          ...file,
          [property]: !file[property],
        };
      }
      return file;
    });

    setFiles([...listFiles]);
  }

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
        sendWithShipment: false,
        isSDS: false,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f !== file);

    setFiles(newFiles);
  }

  function HandleFileDownload(file) {
    downloadFile('workrequestfiles', file.fileName, file.filePath, openModalMessages)
  }

  const commonProps = {
    setData: (data) => {
      setShippingRequest(data);
    },
    data: shippingRequest,
    setErrors,
    errors
  };

  const commonPropsAddress = {
    setData: (data) => {
      setShippingAddress(data);
    },
    data: shippingAddress,
    setErrors,
    errors
  };

  const facilitiesExternal = getFacilitiesExternal(myWorkRequest.hasContainer ? mySamplesInfo.flatMap((samples) => (samples.containers)) : mySamplesInfo) ?? [];

  return (
    <div className="work-request-shipping-information">
      {myWorkRequest.isNonChemical && (
        <WorkRequestNonChemicalTable
          myWorkRequest={myWorkRequest}
          unitOfMeasureLoading={unitOfMeasureLoading}
          unitOfMeasure={unitOfMeasureWUnit}
          nonChemicalShipping={nonChemicalShipping}
          setNonChemicalShipping={setNonChemicalShipping}
          disableForm={disableForm}
        />
      )}
      <div className="header-button">
        <div>
          <GlobalSecondaryButton
            className="button"
            onClick={() => setAddressBookOpen(true)}
            disabled={disableForm}
          >
            Add from Address Book
          </GlobalSecondaryButton>
          <GlobalSecondaryButton
            className="button"
            onClick={clearAddress}
            disabled={disableForm}
          >
            Clear Address
          </GlobalSecondaryButton>
        </div>
      </div>

      <div className="first-grid">
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['recipientName']}
          property="recipientName"
          required={true}
          disabled={disableForm}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Recipient's Email"
          property="emailAddress"
          maxLength={60}
          disabled={disableForm}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['phoneNumber1']}
          property="phoneNumber1"
          required={true}
          maxLength={30}
          disabled={disableForm}
        />
        <WorkRequestFormAutocomplete
          {...commonPropsAddress}
          options={countries.map(country => ({country: country}))}
          noOptionsText={"No Country Found"}
          property={'country'}
          propertyLabel={'country'}
          label={labelProp['country']}
          required
          disabled={disableForm}
          setData={(data) => {
            commonPropsAddress.setData(data);
            if (IsInternationalShipping(data)) {
              commonProps.setData({
                ...commonProps.data,
                isTSCAReview: true,
              })
            }
          }}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['postalCode']}
          property="postalCode"
          required={true}
          maxLength={20}
          disabled={disableForm}
        />

        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['state']}
          property="state"
          required={true}
          maxLength={20}
          disabled={disableForm}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['city']}
          property="city"
          required={true}
          maxLength={60}
          disabled={disableForm}
        />

      </div>

      <div className="second-grid">
        <WorkRequestFormInput
          {...commonPropsAddress}
          label={labelProp['address1']}
          property="address1"
          required={true}
          maxLength={255}
          disabled={disableForm}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Company"
          property="company"
          maxLength={255}
          disabled={disableForm}
        />
        <WorkRequestFormInput
          {...commonPropsAddress}
          label="Department/Floor No."
          property="department"
          maxLength={255}
          disabled={disableForm}
        />

        <WorkRequestFormDate
          {...commonProps}
          property={'requestedDeliveryDate'}
          label={labelProp['requestedDeliveryDate']}
          minDate={new Date()}
          required
          onKeyDown={DatePickerKeyDownEvent}
          disabled={disableForm}
        />
        <WorkRequestFormAutocomplete
          {...commonProps}
          options={shippingCarriers}
          noOptionsText={
            shippingCarriersLoading
              ? "Loading Carriers..."
              : "No Carriers Found"
          }
          property={'shippingCarrierID'}
          propertyLabel={'carrierName'}
          label={labelProp['shippingCarrierID']}
          required
          disabled={disableForm}
        />

        <WorkRequestFormAutocomplete
          {...commonProps}
          options={shippingReasons}
          noOptionsText={
            shippingReasonsLoading ? "Loading Reasons..." : "No Reasons Found"
          }
          property={'shippingReasonName'}
          label={labelProp['shippingReasonName']}
          required
          disabled={disableForm}
        />
      </div>

      <div style={{ display: "flex" }}>
        <FormControl
          error={errors.isPrepaid}
          disabled={disableForm}
        >
          <FormLabel id="shipment-payment-radio-buttons-group">
            * Shipment Payment:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="shipment-payment-radio-buttons-group"
            name="shipment-payment-radio-buttons-group"
            onChange={(e) => {
              const value = e.target.value;
              commonProps.setData({
                ...commonProps.data,
                isPrepaid: value === "true" ? true : false,
              })
              setErrors({
                ...errors,
                isPrepaid: false
              });
            }}
            value={commonProps.data.isPrepaid}
          >
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Prepaid"
            />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Collect"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div>
        <StyledDivider />
        <StyledDiv>
          <p
            className="shipping-label"
          >
            ETQ Info
          </p>
        </StyledDiv>

        <FormGroup style={{ width: 286 }}>
          <FormControlLabel
            control={<Switch />}
            onChange={(e) => {
              commonProps.setData({
                ...commonProps.data,
                is3PTesting: e.target.checked,
              })
            }}
            checked={
              commonProps.data?.is3PTesting === null
                ? false
                : commonProps.data?.is3PTesting
            }
            label="Request is for 3rd party testing?"
            disabled={disableForm}
          />
        </FormGroup>
      </div>
      <div>
        <StyledDivider />
        <StyledDiv style={{
          justifyContent: 'space-between',
          marginTop: "20px"
        }}>
          <p
            style={{
              fontFamily: "EMprint",
              fontWeight: "600",
              fontSize: "16px",
              color: "#545459",
              textTransform: "none",
              textAlign: "left",
            }}
          >
            Sample Safety Info
          </p>
          {facilitiesExternal?.length > 0 && !workRequestId && <div className="sample-safety-warning-external">
            <div>This work request will get splitted into another {facilitiesExternal?.length} requests</div>
            <IconButton
              color="warning"
              style={{ padding: 0 }}
            >
              <Warning
                fontSize="small"
                color="inherit"
                onClick={() => setModalFacilityExternalOpen(true)}
              />
            </IconButton>
          </div>}
        </StyledDiv>
        <SampleSafetyInfo
          mySamplesInfo={mySamplesInfo}
          myWorkRequest={myWorkRequest}
          setShippingRequest={setShippingRequest}
          shippingRequest={shippingRequest}
          dotHazardClass={dotHazardClass}
          dotHazardClassLoading={dotHazardClassLoading}
          workRequestId={workRequestId}
          isReadOnly={disableForm}
        />
        <ModalMethodFacilityExternal
          facilitiesExternal={facilitiesExternal}
          modalOpen={modalFacilityExternalOpen}
          setModalOpen={setModalFacilityExternalOpen}
          created={false}
        />
      </div>
      <StyledDivider />
      <FileDragAndDrop
        disabled={disableForm}
        handleFiles={handleFiles}
        files={files.map((item) => item.file)}
        showFiles={false}
      ></FileDragAndDrop>
      <div
        style={{
          width: "50%",
          minWidth: "800px",
        }}
      >
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={FILE_ATTACHMENT_TABLE_COLUMNS}
          blankFirstHeader={true}
          tableId="midasFileAttachments"
          rowLength={files.length}
          enablePaging={false}
          rowsPerPage={files.length}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No Files Added"}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
        >
          <StyledTableBody key={"myTablebody"}>
            {files.map((file, rowIndex) => {
              return (
                <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                  <StyledTableCell component="th" scope="row">
                    <IconButton
                      aria-label="upload"
                      component="span"
                      onClick={() => deleteFile(file)}
                      disabled={file.file.id ? true : false}
                    >
                      <TrashIcon />
                    </IconButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <GlobalButton style={{ textTransform: "none" }} onClick={e => HandleFileDownload(file.file)}>{file.file.name}</GlobalButton>
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            file.file?.id
                              ? file.file.sendWithShipment
                              : file.sendWithShipment
                          }
                          onChange={(e) =>
                            handleChangeFile("sendWithShipment", rowIndex)
                          }
                        ></Checkbox>
                      }
                      label={"Yes"}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disableForm}
                          checked={
                            file.file?.id ? file.file.isSDS : file.isSDS
                          }
                          onChange={(e) =>
                            handleChangeFile("isSDS", rowIndex)
                          }
                        ></Checkbox>
                      }
                      label={"Yes"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
      <StyledDivider />
      <div>
        <p className="shipping-label">
          TSCA Review
        </p>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            checked={commonProps.data.isTSCAReview}
            onChange={(e) => {
              commonProps.setData({
                ...commonProps.data,
                isTSCAReview: e.target.checked,
              })
            }}
            label={<>TSCA Review is Required (Required for all international shipments)</>}
            disabled={disableForm || IsInternationalShipping(commonPropsAddress?.data)}
          />
        </FormGroup>
      </div>

      <StyledDivider />
      <StyledDiv>
        <p className="shipping-label">
          Terms
        </p>
      </StyledDiv>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={commonProps.data.isAcknowledged}
              onChange={(e) => {
                commonProps.setData({
                  ...commonProps.data,
                  isAcknowledged: e.target.checked,
                })
              }}
            />
          }
          label="I acknowledge that the descriptions on this form will be printed on MIDAS labels and are deemed acceptable for external use"
          disabled={disableForm}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={commonProps.data.containsSamples}
              onChange={(e) => {
                commonProps.setData({
                  ...commonProps.data,
                  containsSamples: e.target.checked,
                })
              }}
            />
          }
          label="This shipment contains chemicals"
          disabled={disableForm}
        />
      </FormGroup>
      {isProcess && <>
        <SubTitle>Tracking Detail</SubTitle>
        <Grid container spacing={2}>
          <Grid item md={3}>
            <StyledTextField
              size="small"
              margin="normal"
              variant="outlined"
              label="Tracking Code"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 60 }}
              onChange={(e) => setTrackingCode(e.target.value)}
              value={trackingCode}
              disabled={disableForm}
            // disabled={!isShippingRole && !ownerCanEdit}
            ></StyledTextField>
          </Grid>
          <Grid item md={3}>
            <StyledAutocomplete
              style={{ width: "100%" }}
              disablePortal
              disabled={disableForm}
              // disabled={!isShippingRole && !ownerCanEdit}
              noOptionsText={
                shippingCarriersLoading
                  ? "Loading Carriers..."
                  : "No Carriers Found"
              }
              options={shippingCarriers}
              getOptionLabel={(option) =>
                option?.carrierName
                  ? option.carrierName
                  : shippingCarriers.length > 0
                    ? shippingCarriers.find((f) => f.carrierName === option)
                      ?.carrierName
                    : ``
              }
              autoHighlight
              autoSelect
              onChange={(e, value) => setShippedVia(value?.carrierName)}
              value={shippedVia}
              isOptionEqualToValue={(option, value) =>
                value?.carrierName === option?.carrierName
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  label="* Ship Via"
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                />
              )}
            />
          </Grid>
          <Grid
            item
            xs={4}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <GlobalButton
              name="addCode"
              variant="contained"
              onClick={addTracking}
              disabled={shippedVia === null || trackingCode === "" || disableForm}
            >
              Add Code
            </GlobalButton>
          </Grid>
        </Grid>
        <div>
          <UXDataTableWithoutBody
            tableWidth="50%"
            cols={[
              "Tracking Code",
              "Shipped Via",
              "Created Date",
              "Shipped Date",
              "Delivered Date",
            ]}
            blankFirstHeader={true}
            tableId={`midasShippingTracking`}
            rowLength={shippingRequest?.shippingTrackings?.length}
            enablePaging={false}
            rowsPerPage={shippingRequest?.shippingTrackings?.length}
            page={0}
            handleChangePage={null}
            handleChangeRowsPerPage={null}
            noDataFoundMessage={"No Tracking Added"}
            enableAddIcon={false}
            addFunction={() => null}
            addToolTipText=""
            isDataLoading={false}
          >
            <StyledTableBody key={"myTablebody"}>
              {shippingRequest?.shippingTrackings?.map((tracking, rowIndex) => {
                return (
                  <StyledTableRow
                    hover
                    key={`tracking-custom-row-${rowIndex}`}
                    style={{ width: "10px" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      <IconButton
                        disabled={tracking.id || disableForm ? true : false}
                        aria-label="deleteTracking"
                        component="span"
                        onClick={() => deleteTracking(tracking)}
                      >
                        <TrashIcon />
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>
                      {tracking.shipmentTrackingNumber}
                    </StyledTableCell>
                    <StyledTableCell>
                      {
                        shippingCarriers.find(
                          (f) => f.id === tracking.shippingCarrierID
                        )?.carrierName
                      }
                    </StyledTableCell>
                    <StyledTableCell>
                      {convertToLocalTime(tracking.createdDate)}
                    </StyledTableCell>
                    <StyledTableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          // disabled={!isShippingRole && !ownerCanEdit}
                          size="small"
                          inputFormat="MM/dd/yyyy"
                          onChange={(e) => {
                            let newTrackings = [
                              ...shippingRequest.shippingTrackings,
                            ];
                            newTrackings[rowIndex].shippedDate = e;

                            setShippingRequest({
                              ...shippingRequest,
                              shippingTrackings: newTrackings,
                            });
                          }}
                          // minDate={new Date()}
                          value={tracking.shippedDate}
                          renderInput={(params) => (
                            <StyledTextField
                              onKeyDown={DatePickerKeyDownEvent}
                              {...params}
                              variant="outlined"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ ...params.inputProps }}
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                          disabled={disableForm}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                    <StyledTableCell>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          // disabled={!isShippingRole && !ownerCanEdit}
                          size="small"
                          inputFormat="MM/dd/yyyy"
                          onChange={(e) => {
                            let newTrackings = [
                              ...shippingRequest.shippingTrackings,
                            ];
                            newTrackings[rowIndex].deliveredDate = e;

                            setShippingRequest({
                              ...shippingRequest,
                              shippingTrackings: newTrackings,
                            });
                          }}
                          // minDate={new Date()}
                          value={tracking.deliveredDate}
                          renderInput={(params) => (
                            <StyledTextField
                              onKeyDown={DatePickerKeyDownEvent}
                              {...params}
                              variant="outlined"
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              inputProps={{ ...params.inputProps }}
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                          disabled={disableForm}
                        />
                      </LocalizationProvider>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </StyledTableBody>
          </UXDataTableWithoutBody>
        </div>
      </>}
      {addCommentComponent()}
    </div>
  );
}
