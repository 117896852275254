import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SortableTable } from "../../components/SortableTable/SortableTable";
import {
  applyFiltersToArray,
  convertDateFormat,
  getGlobalHyperLinkColor,
} from "../../global";
import ProcessMenuItem from "./ProcessMenuItem";
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";

const LinkTo = styled(Link)({
  textAlign: "left",
  fontFamily: "EMprint",
  fontWeight: "600",
  textTransform: "none",
  color: getGlobalHyperLinkColor(),
  textDecoration: "underline",
  cursor: "pointer",
});

const TableRequestQueue = ({
  filters,
  myRequests,
  reloadSpecialAnalysis,
  isDataLoading,
  billingList,
  isProcessing,
  copySpecialAnalysis,
}) => {
  const [filteredRequests, setFilteredRequests] = useState([]);

  const MySortableSpecialAnalysisCols = [
    {
      id: "id",
      label: "ID",
      transform: (value) => (
        <LinkTo to={{ pathname: `/viewSpecialAnalysis/${value}`, state: { tab: 'process' } }}>{value}</LinkTo>
      )
    },
    {
      id: "comments",
      label: "Comments History",
      transform: (value) => (
        <IntegrationInstructionsIcon
          onClick={() =>
            openModalMessage(
              "View Comment History",
              value.split('|').filter((val) => !val.includes(":  Old value: '") && val.length > 0 && val.split('):')[1].length > 0).join('<br />'),
              "Close"
            )
          }>
        </IntegrationInstructionsIcon>
      )
    },
    {
      id: "priorityName",
      label: "Priority",
    },

    {
      id: "description",
      label: "Description",
    },
    {
      id: "requestedByEmail",
      label: "Requester",
    },
    {
      id: "assignedToEmail",
      label: "Assigned To",
    },
    {
      id: "requestedCompletionDate",
      label: "Requested Completion",
      transform: (value) => {
        return value ? convertDateFormat(value, false) : "";
      },
    },
    {
      id: "lastAcceptableCompletionDate",
      label: "Last Acceptable Date",
      transform: (value) => (value ? convertDateFormat(value, false) : ""),
    },
    {
      id: "businessUnitName",
      label: "Business Unit",
    },
    {
      id: "statusName",
      label: "Status",
    },
    {
      id: "chargeCode",
      label: "JADE #",
    }];

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, myRequests);
    setFilteredRequests(filteredArray);
  }, [filters, myRequests]);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }
  const openModalMessage = (title, message, btnText) => {
    setModalMessagesOpen(true);
    setModalMessagesText(message);
    setModalMessagesButtonText(btnText);
    setModalMessagesTitle(title);
  };

  return (
    <div style={{ width: "100%" }}>
      <SortableTable
        columns={MySortableSpecialAnalysisCols.filter(f => (!isProcessing && f.label !== 'Business Unit') || isProcessing)}
        count={filteredRequests.length ?? 0}
        loading={isDataLoading}
        rows={filteredRequests}
        rowsPerPage={10}
        initialOrderBy={"priorityName"}
        children={<ProcessMenuItem myRequests={myRequests} billingList={billingList} reloadSpecialAnalysis={reloadSpecialAnalysis} copySpecialAnalysis={copySpecialAnalysis} />}
        tableSize="small"
      ></SortableTable>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <div dangerouslySetInnerHTML={{ __html: modalMessagesText }} />
        <label>
        </label>
      </ModalSimpleButton >
    </div >
  );
};

export default TableRequestQueue;
