import { Box } from "@mui/system";
import { useState } from "react"
import { GlobalButton, GlobalSecondaryButton, GlobalTabCollection, StyledTab } from "../../../styles";
import MyRequests from "./myRequests";
import AllRequests from "./allRequests";
import CustomModal from "../../../../components/Modal";
import SubmitSDS from "../SubmitSDS";
import { viewFormOrigin } from "./constants";

const MYREQUESTSTAB = 0;
const ALLREQUESTSTAB = 1;

const SDSBacklog = ({ id }) => {
  const [selectedTab, setSelectedTab] = useState(MYREQUESTSTAB);
  const [newRequestOpen, setNewRequestOpen] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const handleTabChange = (event, newTab) => {
    setSelectedTab(newTab);
  }

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case MYREQUESTSTAB: {
        return (
          <MyRequests reloadData={reloadData} id={id} />
        );
      }
      case ALLREQUESTSTAB: {
        return (
          <AllRequests reloadData={reloadData} />
        );
      }
      default: {
        alert("Invalid tab index")
      }
    }
  }

  const handleNewRequestCompleted = () => {
    setNewRequestOpen(false);
    setReloadData(true);
  }

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="sub nav tab strip"
        >
          <StyledTab label="My Requests" />
          <StyledTab label="All Requests" />
        </GlobalTabCollection>

        <GlobalButton
          style={{ marginTop: "-.8rem" }}
          variant="contained"
          type="button"
          onClick={() => { setNewRequestOpen(true) }}
        >
          Submit New Request
        </GlobalButton>

      </Box>

      {renderSelectedTab()}

      <CustomModal
        title="Submit New SDS Request"
        open={newRequestOpen}
        setOpen={setNewRequestOpen}
        style={{ width: "80%" }}
      >

        <SubmitSDS
          origin={viewFormOrigin.newRequest}
          afterSubmit={handleNewRequestCompleted}
        />

        <div style={{
          display: "flex",
          justifyContent: "center",
        }}>
          <GlobalSecondaryButton
            style={{
              marginTop: "35px",
              marginLeft: "6px",
            }}
            variant='contained'
            type="button"
            onClick={() => { setNewRequestOpen(false) }}
          >Cancel</GlobalSecondaryButton>

        </div>

      </CustomModal>
    </div>
  )
}

export default SDSBacklog;