import { useContext, useMemo } from "react";
import { MenuItem, Menu } from "@mui/material";
import SDSRequest from "../../../api/SDSRequest/SDSRequest";
import UserContext from "../../../context/UserContext";
import { SDSRequestState, UserContextType } from "./types";
import { useSdsRequestStateTransition, useSdsRequestUserRoles } from "./hooks";

export enum SDSRequestRowMenuOption {
  SetAsDraft,
  SetAsOnHold,
  SetAsSubmitted,
  AssignToMe,
  SetAsCancelled,
}

interface SDSRequestRowMenuProps {
  optionsToDisplay?: SDSRequestRowMenuOption[];
  request: SDSRequest;
  anchorEl: HTMLElement;
  open: boolean;
  onClose: () => void;
  onItemClick: (option: SDSRequestRowMenuOption) => void;
}

export const SDSRequestRowMenu = ({
  optionsToDisplay = [
    SDSRequestRowMenuOption.SetAsDraft,
    SDSRequestRowMenuOption.SetAsOnHold,
    SDSRequestRowMenuOption.SetAsSubmitted,
    SDSRequestRowMenuOption.AssignToMe,
    SDSRequestRowMenuOption.SetAsCancelled,
  ],
  request,
  anchorEl,
  open,
  onClose,
  onItemClick,
}: SDSRequestRowMenuProps) => {
  const currentUser = useContext(UserContext);

  const { sdsRequestUserRoles } = useSdsRequestUserRoles(currentUser as UserContextType, request);
  const { canTransition } = useSdsRequestStateTransition(request, sdsRequestUserRoles);

  const requestCanBeSetAsDraft = useMemo(
    () => canTransition(SDSRequestState.Draft),
    [canTransition],
  );
  const requestCanBeSetAsOnHold = useMemo(
    () => canTransition(SDSRequestState.OnHold),
    [canTransition],
  );
  const requestCanBeSetAsSubmitted = useMemo(
    () => canTransition(SDSRequestState.Submitted),
    [canTransition],
  );
  const canAssignRequestToMe = useMemo(
    () =>
      canTransition(SDSRequestState.Assigned) &&
      [SDSRequestState.Assigned, SDSRequestState.Submitted].includes(request?.requestStatusName),
    [canTransition, request],
  );
  const requestCanBeSetAsCancelled = useMemo(
    () => canTransition(SDSRequestState.Cancelled),
    [canTransition],
  );

  const options = {
    [SDSRequestRowMenuOption.SetAsDraft]: {
      key: "mnuSetDraf",
      onClick: () => {
        onItemClick(SDSRequestRowMenuOption.SetAsDraft);
      },
      disabled: !requestCanBeSetAsDraft,
      label: "Set as Draft",
    },
    [SDSRequestRowMenuOption.SetAsSubmitted]: {
      key: "mnuSetSubmitted",
      onClick: () => {
        onItemClick(SDSRequestRowMenuOption.SetAsSubmitted);
      },
      disabled: !requestCanBeSetAsSubmitted,
      label: "Set as Submitted",
    },
    [SDSRequestRowMenuOption.AssignToMe]: {
      key: "mnuAssignToMe",
      onClick: () => {
        onItemClick(SDSRequestRowMenuOption.AssignToMe);
      },
      disabled: !canAssignRequestToMe,
      label: "Assign to Me",
    },
    [SDSRequestRowMenuOption.SetAsOnHold]: {
      key: "mnuSetHold",
      onClick: () => {
        onItemClick(SDSRequestRowMenuOption.SetAsOnHold);
      },
      disabled: !requestCanBeSetAsOnHold,
      label: "Set as On Hold",
    },
    [SDSRequestRowMenuOption.SetAsCancelled]: {
      key: "mnuCancel",
      onClick: () => {
        onItemClick(SDSRequestRowMenuOption.SetAsCancelled);
      },
      disabled: !requestCanBeSetAsCancelled,
      label: "Set as Cancelled",
    },
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{ "aria-labelledby": "basic-button" }}>
      {optionsToDisplay &&
        optionsToDisplay.map((option) => {
          const { key, onClick, disabled, label } = options[option];
          return (
            <MenuItem key={key} onClick={onClick} disabled={disabled}>
              {label}
            </MenuItem>
          );
        })}
    </Menu>
  );
};
