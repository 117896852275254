import { useEffect, useState } from "react";
import { Box, FormControlLabel, IconButton, Menu, MenuItem, Switch } from '@mui/material'
import { Delete, Edit } from "@mui/icons-material";
import { StyledTableBody, StyledTableRow, UXDataTableWithoutBody } from '../../../../components/UXDataTable'
import MethodSelection from '../../../../components/MethodSelection'
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import ModalMessages from '../../../../components/Modal/ModalSimpleButton'
import { StyledTableCellLarge, StyledTableCellMedium, StyledTableCellSmall } from '../constants'
import { formatMidasNumber, somethingIsDifferent } from '../../../../global'
import CreateUpdateContainers from "./CreateUpdateContainers";
import { getConvertedCurrentAmount, getOriginalAmount } from "../../shared";
import AddSamples from "./AddSamples";
import LocationField from "../../../../components/LocationField";
import { GlobalButton } from "../../../styles";
import WorkRequestContainerManagement from "./WorkRequestContainerManagement";
import CopyTestsModal from "./CopyTestsModal";
import SettingsIcon from '@mui/icons-material/Settings';

const mySampleInfoCols = [
    "MIDAS #",
    "Description",
    "Amount Available",
];

const myContainersCols = [
    "Container",
    "PSIMS ID",
    "UoM",
    "Original Amt.",
    "Amt. Requested",
    "Amt. Available",
    "Using in Shipping",
    "Final Location",
];

const ContainersAndTests = ({
    isReadOnly = false,
    mySamplesInfo,
    setMySamplesInfo,
    unitOfMeasureLoading,
    unitOfMeasure,
    addCommentComponent,
    shippingRequest,
    setShippingRequest,
    workRequestId,
    setIsRequiredComment,
    isProcess,
    availableLocations,
    prepareData,
    myWorkRequest,
    setMyWorkRequest,
}) => {
    const [isMySampleInfoModalOpen, setIsMySampleInfoModalOpen] = useState(false);
    const [mySampleInfoToEdit, setMySampleInfoToEdit] = useState(null);

    const [modalDeleteItemOpen, setModalDeleteItemOpen] = useState(false);
    const [modalDeleteItemButton1Text, setModalDeleteItemButton1Text] = useState('');
    const [modalDeleteItemButton2Text, setModalDeleteItemButton2Text] = useState('');
    const [modalDeleteItemTitle, setModalDeleteItemTitle] = useState('');
    const [modalDeleteItemText, setModalDeleteItemText] = useState('');

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const [selectedSampleInfoIndex, setSelectedSampleInfoIndex] = useState(null);
    const [selectedSampleInfoToDelete, setSelectedSampleInfoToDelete] = useState(null);

    const [isMyContainerModalOpen, setIsMyContainerModalOpen] = useState(false);
    const [selectedMyContainerIndex, setSelectedMyContainerIndex] = useState(null);
    const [selectedMyContainerToDelete, setSelectedMyContainerToDelete] = useState(null);
    const [myContainerToEdit, setMyContainerToEdit] = useState(null);
    const [methods, setMethods] = useState([]);
    const [containeringMethod, setContaineringMethod] = useState('midas');
    const [bulkLocation, setBulkLocation] = useState(null);
    const [copyTestsModalOpen, setCopyTestsModalOpen] = useState(false);
    const [anchorElContainers, setAnchorElContainers] = useState(null);

    const selectedSampleInfo = mySamplesInfo[selectedSampleInfoIndex];
    const selectedMyContainer = selectedSampleInfo?.containers[selectedMyContainerIndex];

    function closeModalMessages() {
        setModalMessagesOpen(false);
    }

    function openModalMessages(title, text) {
        setModalMessagesOpen(true);
        setModalMessagesTitle(title);
        setModalMessagesText(text);
    }

    const handleMySampleInfoDeleteIcon = (mySampleInfo) => {
        openModalDeleteItem("Confirm Deletion", "Are you sure you want to delete this sample info?  This action cannot be reversed.", "Yes", "No")
        setSelectedSampleInfoToDelete(mySampleInfo);
    }

    const handleMySampleInfoEditIcon = (mySample) => {
        setMySampleInfoToEdit(mySample)
        setIsMySampleInfoModalOpen(true)
    }

    const handleMyContainerDeleteIcon = (myContainer) => {
        openModalDeleteItem("Confirm Deletion", "Are you sure you want to delete this container?  This action cannot be reversed.", "Yes", "No")
        setSelectedMyContainerToDelete(myContainer)
    }

    const handleMyContainerEditIcon = (myContainer) => {
        setMyContainerToEdit(myContainer)
        setIsMyContainerModalOpen(true)
    }

    function closeModalDeleteItem() {
        if (selectedSampleInfoToDelete) {
            setSelectedSampleInfoToDelete(null);
            setSelectedSampleInfoIndex(null);
        } else if (selectedMyContainerToDelete) {
            setSelectedMyContainerToDelete(null);
            setSelectedMyContainerIndex(null);
        }
        setModalDeleteItemOpen(false);
    }

    function openModalDeleteItem(title, text, buttonText, button2Text) {
        setModalDeleteItemButton1Text(buttonText)
        setModalDeleteItemButton2Text(button2Text)
        setModalDeleteItemOpen(true);
        setModalDeleteItemTitle(title);
        setModalDeleteItemText(text);
    }

    function deleteItem() {
        if (selectedSampleInfoToDelete) {
            setMySamplesInfo([...mySamplesInfo.filter(f => f !== selectedSampleInfoToDelete)]);
            openModalMessages('Deleted', 'Sample info deleted with success!')
            setShippingRequest({
                ...shippingRequest,
                shippingItems: shippingRequest.shippingItems.filter(f => selectedSampleInfoToDelete?.sample?.sampleName !== f.sampleName)
            })
            if (selectedSampleInfoToDelete.containers.some(s => s.id) && isProcess) setIsRequiredComment(true);
        } else if (selectedMyContainerToDelete) {
            if (selectedMyContainerToDelete.id && isProcess) setIsRequiredComment(true);
            const key = `${selectedMyContainerToDelete.sampleName}${selectedMyContainerToDelete.existingContainerID}`;
            const newSampleInfo = {
                ...selectedSampleInfo,
                containers: selectedSampleInfo.containers.filter(f => `${f.sampleName}${f.existingContainerID}` !== key)
            }
            setMySamplesInfo([...mySamplesInfo.map((sample) => {
                if (selectedSampleInfo?.sample?.sampleName === sample?.sample?.sampleName) return newSampleInfo;
                else return sample;
            })]);
            setShippingRequest({
                ...shippingRequest,
                shippingItems: shippingRequest.shippingItems.filter(f => !(selectedSampleInfo?.sample?.sampleName === f.sampleName && f.containerID === selectedMyContainerToDelete.existingContainerID))
            })
            openModalMessages('Deleted', 'Container deleted with success!')
        }
        closeModalDeleteItem();
    }

    function onCloseMySampleInfoModal() {
        setIsMySampleInfoModalOpen(false);
        setMySampleInfoToEdit(null);
    }

    function onCloseMyContainerModal() {
        setIsMyContainerModalOpen(false);
        setMyContainerToEdit(null);
    }

    function onCloseCopyTestsModal(methodsString) {
        if (methodsString) {
            const parsedValue = JSON.parse(methodsString);

            const methodsToCopy = [];
            parsedValue.methods.forEach(method => {
                const key = `${method.method.name}-${method.methodFacility.testFacilityAbv}`;

                if (!methods.some(s => `${s.method.name}-${s.methodFacility.testFacilityAbv}` === key)) methodsToCopy.push(method);
            })
            onChangeMethods([...methods, ...methodsToCopy]);
        }

        setCopyTestsModalOpen(false);
    }

    useEffect(() => {
        if (selectedMyContainer && !!(selectedSampleInfo.containers.find(cut => cut.id === selectedMyContainer.id))) {
            if (selectedMyContainer?.testSubmissionJSON) {
                const parsedValue = JSON.parse(selectedMyContainer.testSubmissionJSON);
                onChangeMethods(parsedValue.methods ? parsedValue.methods : parsedValue);
                setContaineringMethod(!parsedValue.containeringMethod || parsedValue.containeringMethod === '' ? 'midas' : parsedValue.containeringMethod);
            } else {
                onChangeMethods([])
                setContaineringMethod('midas');
            }
        } else {
            onChangeMethods([]);
            setContaineringMethod('midas');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMyContainerIndex])

    useEffect(() => {
        if (methods?.length) {
            onChangeMethods(methods);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [containeringMethod])

    function onChangeMethods(methods) {
        setMethods(methods);
        setMySamplesInfo((prevList) => {
            return prevList.map(mySampleInfo => {
                const keyMySample = mySampleInfo.id ?? `${mySampleInfo?.sample?.sampleName}`;
                const keySelectedMySampleInfo = selectedSampleInfo?.id ?? `${selectedSampleInfo?.sample?.sampleName}`;
                if (keyMySample === keySelectedMySampleInfo) {
                    const updatedMySampleInfo = {
                        ...mySampleInfo,
                        containers: mySampleInfo.containers.map(container => {
                            const keyContainer = container.id ?? `${container.existingContainerID}`;
                            const keySelectedMyContainer = selectedMyContainer?.id ?? `${selectedMyContainer?.existingContainerID}`;
                            if (keyContainer === keySelectedMyContainer) {
                                const methodFiltered = methods.filter((x) => x.methodFacility);
                                let updatedMyContainer = {
                                    ...selectedMyContainer,
                                    testSubmissionJSON: JSON.stringify({ containeringMethod: containeringMethod, methods: methodFiltered })
                                }
                                const changeMethods = somethingIsDifferent(selectedMyContainer?.testSubmissionJSON, updatedMyContainer?.testSubmissionJSON);
                                if (container?.id && isProcess && changeMethods) setIsRequiredComment(true);
                                return updatedMyContainer;
                            }
                            return container;
                        })
                    }

                    return updatedMySampleInfo;
                }
                return mySampleInfo;
            })
        })
    }

    const handleClickMenuContainers = (event) => {
        setAnchorElContainers(event.currentTarget)
    }

    const methodFacilitiesExternal = methods.filter(f => f.externalLaboratory);
    const uniqueFacilitiesExternal = new Set(methodFacilitiesExternal.map(f => f.externalLaboratory?.laboratoryName));

    return (
        <>
            <Box display="flex" flexDirection="column">
                <FormControlLabel control={<Switch onChange={() => setMyWorkRequest({ ...myWorkRequest, hasContainer: !myWorkRequest.hasContainer })} checked={myWorkRequest.hasContainer} disabled={workRequestId} />} label="Do you want to select a specific container?" labelPlacement="start" />
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={mySampleInfoCols}
                    blankFirstHeader
                    tableId="mySamplesInfoTable"
                    rowLength={mySamplesInfo?.length}
                    noDataFoundMessage={'No Sample info found'}
                    enableAddIcon={!isReadOnly}
                    addToolTipText="Add new"
                    addFunction={() => {
                        setIsMySampleInfoModalOpen(true);
                    }}
                >
                    <StyledTableBody>
                        {mySamplesInfo && mySamplesInfo.length && mySamplesInfo.map((mySampleInfo, index) => {
                            const key = mySampleInfo?.sample?.sampleName;
                            const keySelectedMySampleInfo = selectedSampleInfo?.sample?.sampleName;

                            return <StyledTableRow
                                key={index}
                                hover
                                style={{ border: keySelectedMySampleInfo === key && '3px solid #1976D2', cursor: 'pointer' }}
                                onClick={() => {
                                    if (selectedSampleInfo && keySelectedMySampleInfo === key) {
                                        setSelectedSampleInfoIndex(null)
                                    } else {
                                        setSelectedSampleInfoIndex(index)
                                        setSelectedMyContainerIndex(null)
                                    }
                                }}
                            >
                                <StyledTableCellSmall>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="error"
                                            style={{ padding: 0 }}
                                        >
                                            <Delete
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleMySampleInfoDeleteIcon(mySampleInfo)}
                                            />
                                        </IconButton>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="info"
                                            style={{ padding: 0 }}
                                        >
                                            <Edit
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleMySampleInfoEditIcon(mySampleInfo)}
                                            />
                                        </IconButton>
                                    </Box>
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {formatMidasNumber(mySampleInfo?.sample?.sampleName)}
                                </StyledTableCellMedium>
                                <StyledTableCellLarge>
                                    {mySampleInfo?.sample?.description}
                                </StyledTableCellLarge>
                                <StyledTableCellMedium>
                                    {mySampleInfo?.amountAvailable}
                                </StyledTableCellMedium>
                            </StyledTableRow>
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>
            {selectedSampleInfo && myWorkRequest.hasContainer && <Box display="flex" flexDirection="column">
                <UXDataTableWithoutBody
                    tableWidth='100%'
                    cols={isProcess ? [...myContainersCols, "Final Sublocation"] : myContainersCols}
                    blankFirstHeader
                    tableId="myContainerTable"
                    rowLength={selectedSampleInfo.containers?.length}
                    noDataFoundMessage={'No container found'}
                    enableAddIcon={!isReadOnly}
                    addToolTipText="Add new"
                    addFunction={() => {
                        setIsMyContainerModalOpen(true)
                    }}
                >
                    <StyledTableBody>
                        {selectedSampleInfo?.containers && selectedSampleInfo?.containers.length && selectedSampleInfo?.containers.map((myContainer, index) => {
                            const key = myContainer.id ?? `${myContainer.existingContainerID}`;
                            const keySelectedMyContainer = selectedMyContainer?.id ?? `${selectedMyContainer?.existingContainerID}`;
                            const containersSameKey = selectedSampleInfo?.containers.filter(f => `${f.existingContainerID}` === key);
                            const totalAmt = getConvertedCurrentAmount(myContainer, false, containersSameKey);
                            const container = myContainer?.sample?.containers.find(f => f.id === myContainer.existingContainerID);
                            return <StyledTableRow
                                key={index}
                                hover
                                style={{ border: keySelectedMyContainer === key && '3px solid #1976D2', cursor: 'pointer' }}
                                onClick={() => {
                                    if (selectedMyContainer && keySelectedMyContainer === key) {
                                        setSelectedMyContainerIndex(null)
                                    } else {
                                        setSelectedMyContainerIndex(index)
                                    }
                                }}
                            >
                                <StyledTableCellSmall>
                                    <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="error"
                                            style={{ padding: 0 }}
                                        >
                                            <Delete
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleMyContainerDeleteIcon(myContainer)}
                                            />
                                        </IconButton>
                                        <IconButton
                                            disabled={isReadOnly}
                                            color="info"
                                            style={{ padding: 0 }}
                                        >
                                            <Edit
                                                fontSize="small"
                                                color="inherit"
                                                onClick={() => handleMyContainerEditIcon(myContainer)}
                                            />
                                        </IconButton>
                                    </Box>
                                </StyledTableCellSmall>
                                <StyledTableCellMedium>
                                    {container?.containerNumber}
                                </StyledTableCellMedium>
                                <StyledTableCellSmall>
                                    {myContainer?.sample?.psimsid}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {myContainer?.requestedAmountUoM}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {getOriginalAmount(totalAmt, myContainer)}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {myContainer?.requestedAmount}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {getConvertedCurrentAmount(myContainer, false, containersSameKey)}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {myContainer?.isUsedInShipping ? 'Yes' : 'No'}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {myContainer?.requestedLocationName}
                                </StyledTableCellSmall>
                                <StyledTableCellSmall>
                                    {myContainer?.deliveryLocationSublocation}
                                </StyledTableCellSmall>
                            </StyledTableRow>
                        })}
                    </StyledTableBody>
                </UXDataTableWithoutBody>
            </Box>}
            {
                selectedMyContainer && !isReadOnly && <div className="wk-container-and-tests-bulk-location">
                    <LocationField
                        margin="dense"
                        fieldWidth={"11rem"}
                        value={bulkLocation}
                        handleChange={(value) => {
                            setBulkLocation(value);
                        }}
                        label="Final Location"
                        inputProps={{ style: { fontSize: 12 } }}
                    />

                    <GlobalButton
                        style={{ width: 160 }}
                        name="bulkLocation"
                        variant="contained"
                        onClick={() => {
                            onChangeMethods(methods.map(method => ({
                                ...method,
                                requestedLocationName: bulkLocation
                            })));
                            setBulkLocation(null);
                        }}
                        disabled={!bulkLocation || methods.length < 1}
                    >
                        Bulk Final Location
                    </GlobalButton>
                </div>
            }
            {selectedMyContainer && <Box marginTop={4} marginBottom={2}>
                <div className="wk-container-and-tests-settings">
                    <SettingsIcon style={{
                        paddingBottom: "5px",
                        paddingLeft: "15px",
                        fontSize: 40,
                        marginTop: "10px"
                    }}
                        id="gearicon"
                        onClick={e => { handleClickMenuContainers(e) }}
                    >
                    </SettingsIcon>
                    <Menu
                        key={`ContainerMenu`}
                        anchorEl={anchorElContainers}
                        open={anchorElContainers !== null}
                        onClose={(e) => setAnchorElContainers(null)}
                    >

                        <MenuItem
                            key={`menuItem~CopyTests`}
                            onClick={() => {
                                setCopyTestsModalOpen(true)
                                setAnchorElContainers(null)
                            }}
                        >Copy Container Tests</MenuItem>
                    </Menu>
                </div>
                <MethodSelection
                    width="100%"
                    selectedMethods={methods ?? []}
                    setSelectedMethods={onChangeMethods}
                    showCompletionDate={true}
                    showContainerGrouping={false}
                    incomingTemplate={null}
                    showSearchTemplateName={true}
                    isReadOnly={isReadOnly || !selectedSampleInfo}
                    showEstimateAndPriority={true}
                    showFinalLocation={true}
                    maxMethodsAllowed={999}
                    unitOfMeasureRequested={selectedMyContainer.unitOfMeasure}
                    showMinSizeLabel={true}
                    availableUOMs={unitOfMeasure}
                />
                {!workRequestId && uniqueFacilitiesExternal?.size > 0 && <label>This work request will get splitted into another {uniqueFacilitiesExternal?.size} requests</label>}
            </Box>}
            {isProcess &&
                mySamplesInfo.length > 0 &&
                methods.filter((x) => x.methodFacility).length > 0 &&
                selectedMyContainer &&
                !isReadOnly && <WorkRequestContainerManagement
                    availableLocations={availableLocations}
                    mySamplesInfo={mySamplesInfo}
                    setMySamplesInfo={setMySamplesInfo}
                    methods={methods}
                    setMethods={onChangeMethods}
                    setContaineringMethod={setContaineringMethod}
                    containeringMethod={containeringMethod}
                    selectedMyContainer={selectedMyContainer}
                    selectedSampleInfo={selectedSampleInfo}
                    availableUOMs={unitOfMeasure}
                    key={`${selectedSampleInfoIndex} - ${selectedMyContainerIndex}`}
                    prepareData={prepareData}
                    myWorkRequest={myWorkRequest}
                />}
            {addCommentComponent()}
            {isMySampleInfoModalOpen && <AddSamples
                isModalOpen={isMySampleInfoModalOpen}
                setIsModalOpen={setIsMySampleInfoModalOpen}
                setIsModalClose={onCloseMySampleInfoModal}
                mySamplesInfo={mySamplesInfo}
                setMySamplesInfo={setMySamplesInfo}
                mySampleInfoToEdit={mySampleInfoToEdit}
                unitOfMeasureLoading={unitOfMeasureLoading}
                unitOfMeasure={unitOfMeasure}
            />}

            {isMyContainerModalOpen && <CreateUpdateContainers
                isModalOpen={isMyContainerModalOpen}
                setIsModalOpen={setIsMyContainerModalOpen}
                setIsModalClose={onCloseMyContainerModal}
                unitOfMeasureLoading={unitOfMeasureLoading}
                unitOfMeasure={unitOfMeasure}
                selectedSampleInfo={selectedSampleInfo}
                mySamplesInfo={mySamplesInfo}
                setMySamplesInfo={setMySamplesInfo}
                myContainerToEdit={myContainerToEdit}
                shippingRequest={shippingRequest}
                setShippingRequest={setShippingRequest}
                setIsRequiredComment={setIsRequiredComment}
                isProcess={isProcess}
            />}
            {copyTestsModalOpen && <CopyTestsModal
                isModalOpen={copyTestsModalOpen}
                setIsModalOpen={setCopyTestsModalOpen}
                setIsModalClose={onCloseCopyTestsModal}
                mySamplesInfo={mySamplesInfo}
                sampleNameDefault={selectedSampleInfo?.sample?.sampleName}
                containerIdToHide={selectedMyContainer?.existingContainerID}
            />}


            <ModalTwoButtons title={modalDeleteItemTitle} button1Text={modalDeleteItemButton1Text} button1Action={deleteItem} button2Text={modalDeleteItemButton2Text} button2Action={closeModalDeleteItem} open={modalDeleteItemOpen} setOpen={setModalDeleteItemOpen}>
                <label>
                    {modalDeleteItemText}
                </label>

                <div></div>
            </ModalTwoButtons>


            {/* Informational Messages */}
            <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
                <label>
                    {modalMessagesText}
                </label>
            </ModalMessages>
        </>
    )
}

export default ContainersAndTests