import { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import Project from "../../../../api/Admin/Project";
import { sortAscending } from "../ProjectManagement/helpers";
import { CustomNetworkError } from "../../../../api/helpers";
import { DataTableWrapper } from "../ProjectManagement/components/DataTableWrapper";
import { StyledTableCell, StyledTableRow } from "../../../../components/UXDataTable";
import { CustomMenu } from "../ProjectManagement/components/CustomMenu";
import CustomModal from "../../../../components/Modal";
import { Alert } from "../ProjectManagement/components/Alert";
import { ProjectForm, ProjectFormCriteria } from "../ProjectManagement/ProjectForm";
import UserContext from "../../../../../src/context/UserContext";
import { useContext } from "react";
import { hasRole, Roles } from "../../../../global";

type ProjectManagementAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void;
};

type ClaimsWrapper = {
  roles: string[];
};

const ProjectReview = () =>
{
    const [projects, setProjects] = useState<Project[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userIsUnauthorized, setUserIsUnauthorized] = useState<boolean>(true);
    const [alert, setAlert] = useState<ProjectManagementAlert | undefined>(undefined);    
    const [project, setProject] = useState<Project | undefined>();    
    const [showProjectFormModal, setShowProjectFormModal] = useState(false);
    const [processLoading, setProcessLoading] = useState<boolean>(false);
    const [showProjectFormModalReadOnly, setShowProjectFormModalReadOnly] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const { idTokenClaims } = useContext(UserContext);
    const roles = (idTokenClaims as unknown as ClaimsWrapper).roles ?? [];

    const tableKey = "projectsTable";

    const columns =
    [
        {
            id: "name",
            label: "Project Name",
            notSorteable: false
        },
        {
            id: "description",
            label: "Description",
            notSorteable: false
        },
        {
            id: "ownerEmail",
            label: "Project Owner",
            notSorteable: false
        },
        {
            id: "secondaryOwnerEmail",
            label: "Secondary Owner",
            notSorteable: false
        },
        {
            id: "reviewStatus",
            label: "Review Status",
            notSorteable: false
        },
    ];

    const hasMasterDataProjectOrDevRole : boolean = hasRole(Roles.Developer, roles) || hasRole(Roles.MasterDataProject, roles);

    useEffect(() =>
    {
        setLoading(true);
        
        if(hasMasterDataProjectOrDevRole){
          Project.getAllManagementProjects(["Pending"])
          .then((response: Project[]) =>
          {
              setUserIsUnauthorized(false);
              setProjects(response.sort((a, b) => sortAscending(a.name, b.name)));
          })
          .catch((err) =>
          {
              setAlert(errorFetchingProjectsAlert(getNetworkErrorMessage(err)));
          })
          .finally(() =>
          {
              setLoading(false);
          });
        }
        else{
          setAlert(errorViewProjectsAlert());
          setLoading(false);
        }

    }, [refresh, hasMasterDataProjectOrDevRole]);

    const handleReviewClick = (project: Project) => {
        if (project.ndaAgreementFileName && project.ndaAgreementFileName.length > 0) {
          project.ndaAgreementFile = createFilePlaceholder(project.ndaAgreementFileName);
        }
        setProject(project);
        setShowProjectFormModalReadOnly(true);
        getProject(project.id);
    };

    const handleEditProjectFormOnClose = () => {
        setProject(undefined);
        setShowProjectFormModal(false);
      };

    const handleAlertOkClick = () => {
        alert?.onOk?.();
        setAlert(undefined);
    };

    const createFilePlaceholder = (fileName: string) => {
        const filePlaceholder = new File([], fileName);
        return filePlaceholder;
      };

    const getProject = (id: number) => {
        setProcessLoading(true);
        Project.get(id)
          .then((response: Project) => {
            if (response.ndaAgreementFileName && response.ndaAgreementFileName.length > 0) {
              response.ndaAgreementFile = createFilePlaceholder(response.ndaAgreementFileName);
            }
            setProject(response);
            setProcessLoading(false);
          })
          .catch((err) => {
            setAlert(errorFetchingProjectsAlert(getNetworkErrorMessage(err)));
            setShowProjectFormModal(false);
          })
          .finally(() => {
            setProcessLoading(false);
          });
      };

      const updateProject = (request: Project) => {
        Project.update(request.id, request)
          .then((response: Project) => {
            const indexToUpdate = projects.findIndex((project) => project.id === request.id);
    
            setProjects((prevProjects) => {
              prevProjects[indexToUpdate] = response;
              return prevProjects;
            });
    
            setProcessLoading(false);
            handleEditProjectFormOnClose();
            setAlert(successfullyUpdatedProjectAlert);
          })
          .catch((err) => {
            setAlert(errorUpdatingProjectAlert(getNetworkErrorMessage(err)));
          })
          .finally(() => {
            setProcessLoading(false);
          });
      };

      const handleProjectFormOnSave = (formValues?: ProjectFormCriteria) => {
        setProcessLoading(true);
    
        const projectToUpdate = projects.find((project) => project.id === formValues?.id);
        
        if (projectToUpdate) {
            updateProject({
                ...projectToUpdate,
                projectEndDate: formValues?.expirationDate,
                ndaAgreementFileName: formValues?.ndaAgreementFile?.name,
                ...formValues,
            });
        }
      };

    const handleEditClick = (project: Project) => {
        if (project.ndaAgreementFileName && project.ndaAgreementFileName.length > 0) {
          project.ndaAgreementFile = createFilePlaceholder(project.ndaAgreementFileName);
        }
        setProject(project);
        setShowProjectFormModal(true);
        getProject(project.id);
      };
    
      const handleProjectFormReadOnlyOnClose = () => {
          setProject(undefined);
          setShowProjectFormModalReadOnly(false);        
      };

      const handleProjectFormReadOnlyOnSave = () => {
        setRefresh((prev) => prev + 1);
      };

      const handleProjectRejectFormOnSave = () => {
        setProject(undefined);
        setShowProjectFormModalReadOnly(false);
        setRefresh((prev) => prev + 1);        
      };

      const isMenuItemDisabled = () => {
          return !(hasRole(Roles.Developer, roles) || hasRole(Roles.MasterDataProject, roles));          
      };
      
    return (
    <>
        <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingBottom={".8rem"}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Header>All Projects ({projects.length})</Header>
            </Box>
        </Box>
        <DataTableWrapper
            tableId="projects"
            columns={columns}
            tableWidth="100%"
            blankFirstHeader={true}
            noDataFoundMessage={
                userIsUnauthorized
                    ? "You are not authorized to view or modify projects."
                    : "No projects available."
            }
            isDataLoading={loading}
            rowData={projects}
            rowComponent={(project, rowIndex) => (
            <StyledTableRow key={`${tableKey}-row-${rowIndex}`}>
                <StyledTableCell width={5}>
                    <CustomMenu key={`${tableKey}basic-menu${rowIndex}`}>
                        <MenuItem onClick={() => { handleEditClick(project);}} data-disabled={isMenuItemDisabled()}>Edit Project</MenuItem>
                        <MenuItem onClick={() => { handleReviewClick(project);}} data-disabled={isMenuItemDisabled()}>Review Project</MenuItem>
                    </CustomMenu>                
                </StyledTableCell>
                <StyledTableCell>{project.name}</StyledTableCell>
                <StyledTableCell>{project.description}</StyledTableCell>
                <StyledTableCell>{project.ownerEmail}</StyledTableCell>
                <StyledTableCell>{project.secondaryOwnerEmail}</StyledTableCell>
                <StyledTableCell>{project.reviewStatus}</StyledTableCell>
            </StyledTableRow>
        )}
    />    
    <CustomModal
        title="Edit Project"
        open={showProjectFormModal}
        setOpen={setShowProjectFormModal}
        setClose={undefined}>
        <ProjectForm
          project={project}
          invalidProjectNames={projects.compactMap((p) => (p.id === project?.id ? null : p.name))}
          disabled={processLoading}
          onSave={handleProjectFormOnSave}
          onCancel={handleEditProjectFormOnClose}
        />
      </CustomModal>
      <CustomModal
        title={"Review Project"}
        open={showProjectFormModalReadOnly}
        setOpen={setShowProjectFormModalReadOnly}
        setClose={undefined}>
        <ProjectForm
          project={project}
          invalidProjectNames={projects.compactMap((p) => (p.id === project?.id ? null : p.name))}
          disabled={processLoading}
          readOnly={true}
          onSave={handleProjectFormReadOnlyOnSave}
          onCancel={handleProjectFormReadOnlyOnClose}
          onRejectSave = {handleProjectRejectFormOnSave}
        />
      </CustomModal>      
    <Alert
        title={alert?.title}
        message={alert?.message}
        details={alert?.details}
        display={alert !== undefined}
        onOk={handleAlertOkClick}
    />
    </>);
};

const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403) {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};

const errorFetchingProjectsAlert = (details: string): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error fetching projects.",
  details: `Reason: ${details}`,
});

const errorViewProjectsAlert = (): ProjectManagementAlert => ({
  title: "Error",
  message: "There was an error fetching projects.",
  details: "Reason: You are not authorized to view pending projects",
});

const errorUpdatingProjectAlert = (details: string): ProjectManagementAlert => ({
    title: "Error",
    message: "There was an error updating the project.",
    details: `Reason: ${details}`,
});

const successfullyUpdatedProjectAlert: ProjectManagementAlert = {
    title: "Success",
    message: "Project was successfully updated.",
};

const Header = styled(Box)(() => ({
  fontSize: "20px",
  fontWeight: "bold",
  paddingRight: 10,
}));

export default ProjectReview;
