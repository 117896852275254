import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Menu, MenuItem, FormControlLabel, Checkbox, CircularProgress, Autocomplete, TextField} from "@mui/material";
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell, StyledTableRow } from "../../components/UXDataTable";
import { applyFiltersToArray, hasRole, Roles, isNumber, convertDateFormat, exportToExcel } from "../../global";
import UserContext from "../../context/UserContext";
import ModalMessages from "../../components/Modal/ModalSimpleButton";
import Batch from "../../api/Formulations/Batch";
import ModalTwoButtons from "../../components/Modal/ModalTwoButtons";
import EmailAddressTextField from "../../components/EmailAddressField";
import BlendPrepSheet from "./EditBatch/BlendPrepSheet";
import ModalNoButton from "../../components/Modal/ModalNoButton";
import MoreOptions from "@mui/icons-material/MoreVert";
import { GlobalButton } from "../../pages/styles";


const StyledAutocomplete = styled(Autocomplete)({
  '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
    fontSize: 16,
  },
  width:"50%",
});

const MyBatchCols_New = ['', 'Study Name', 'Blend Name', 'Batch #', 'Priority', 'Creator', 'Required Date', 'Requested Amt.', 'Assigned Blender',  'Status', 'Age', 'Facility']


const TableBatchQueue = ({filters, allBatches, isDataLoading, refreshData, setRefreshData, availableUoMs}) => {
  const currentUser = useContext(UserContext) 
  const roles = currentUser?.idTokenClaims.roles;

  const hasBatchAssigner = hasRole(Roles.BatchAssigner, roles) || hasRole(Roles.Developer, roles)
  const hasBatchPrepareer = hasRole(Roles.BatchPreparer, roles) || hasRole(Roles.Developer, roles)

  const [filteredBatches, setFilteredBatches] = useState([])
  const noDataMessage = `No active batches found in the queue`

  const [unassignBlender, setUnassignBlender] = useState(false)
  const [tempBatchID, setTempBatchID] = useState(0);
  const [tempBlenderEmail, setTempBlenderEmail] = useState('')
  const [blenderEmailError, setBlenderEmailError] = useState(false)

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');
  
  const [modalTwoBtnOpen, setModalTwoBtnOpen] = useState(false);
  const [modalTwoBtnButton1Text, setModalTwoBtnButton1Text] = useState('');
  const [modalTwoBtnButton2Text, setModalTwoBtnButton2Text] = useState('');
  const [modalTwoBtnTitle, setModalTwoBtnTitle] = useState('');
  const [modalTwoBtnText, setModalTwoBtnText] = useState('');

  const [modalUnassignOpen, setModalUnassignOpen] = useState(false);
  const [modalUnassignButton1Text, setModalUnassignButton1Text] = useState('');
  const [modalUnassignButton2Text, setModalUnassignButton2Text] = useState('');
  const [modalUnassignTitle, setModalUnassignTitle] = useState('');
  const [modalUnassignText, setModalUnassignText] = useState('');

  const [modalComponentReportOpen, setModalComponentReportOpen] = useState(false);
  const [modalComponentReportButton1Text, setModalComponentReportButton1Text] = useState('');
  const [modalComponentReportButton2Text, setModalComponentReportButton2Text] = useState('');
  const [modalComponentReportTitle, setModalComponentReportTitle] = useState('');
  const [modalComponentReportText, setModalComponentReportText] = useState('');

  const [myBatchInfo, setMyBatchInfo] = useState([])
  const [myMethods, setMyMethods] = useState([])
  const [toPrepareAmount, setToPrepareAmount] = useState(null)
  const [toPrepareUoM, setToPrepareUoM] = useState(null)

  const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
  const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
  const [modalNoButtonText, setModalNoButtonText] = useState('');

  const [anchorEl, setAnchorEl] = useState(null)
  const [batchQueueItem, setBatchQueueItem] = useState(null)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [checkedBatchIDs, setCheckedBatchIDs] = useState([])

  const [isComponentAvailabilityProcessing, setIsComponentAvailabilityProcessing] = useState(false)
  const [reportUoM, setReportUoM] = useState(availableUoMs.find(obj => obj.uoMName === 'g'))

    function closeModalNoButton() {
      setModalNoButtonOpen(false);
    }

    function openModalNoButton(title, text) {
      setModalNoButtonOpen(true);
      setModalNoButtonTitle(title);
      setModalNoButtonText(text);
    }

    function closeModalMessages() {
      setModalMessagesOpen(false);
    }
    
    function openModalMessages(title, text) {
      setModalMessagesOpen(true);
      setModalMessagesTitle(title);
      setModalMessagesText(text);
    }

    function closeModalTwoButtons() {
      setModalTwoBtnOpen(false)
    }
  
    function openModalTwoButtons(title, text, buttonText, button2Text) {
      setModalTwoBtnButton1Text(buttonText)
      setModalTwoBtnButton2Text(button2Text)
      setModalTwoBtnOpen(true)
      setModalTwoBtnTitle(title)
      setModalTwoBtnText(text)
    }

    function closeModalUnassign() {
      setModalUnassignOpen(false)
    }
  
    function openModalUnassign(title, text, buttonText, button2Text) {
      setModalUnassignButton1Text(buttonText)
      setModalUnassignButton2Text(button2Text)
      setModalUnassignOpen(true)
      setModalUnassignTitle(title)
      setModalUnassignText(text)
    }

    
    function closeModalComponentReport() {
      setModalComponentReportOpen(false)
    }
  
    function openModalComponentReport(title, text, buttonText, button2Text) {
      setModalComponentReportButton1Text(buttonText)
      setModalComponentReportButton2Text(button2Text)
      setModalComponentReportOpen(true)
      setModalComponentReportTitle(title)
      setModalComponentReportText(text)
    }

    const openMenu = () => {
      return anchorEl != null
    }
 
    const handleOpenMoreOptions = (event) => {
        setAnchorEl(event.currentTarget)
    }
            
    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    function AssignBlenderClick(batchQueue){
      if (batchQueue){
        if (batchQueue.preparedByEmail !== '' && batchQueue.preparedByEmail !== null)
        {
          openModalMessages("Batch Error", "You cannot set the blender because there is already an assigned blender!")
          return
        }

        setTempBatchID(batchQueue.id)
        openModalTwoButtons("Set Blender", "Enter the email address of the blender you want to assign.","Ok", "Cancel")
      }
    }

    function GenerateComponentAvailabilityReport ()
    {
      if (checkedBatchIDs.length > 0 && reportUoM !== null)
      {
        setIsComponentAvailabilityProcessing(true)
        closeModalComponentReport()

        Batch.generateComponentAvailabilityReport(checkedBatchIDs, reportUoM.uoMName).then((res) => {

          setIsComponentAvailabilityProcessing(false)

          if (res.message === 'Success')
          {
            for(var i = 0; i <  res.result.length; i++) 
            {
              if (res.result[i].midasNumbers.length > 0)
              {
                res.result[i].midasNumbers = res.result[i].midasNumbers.join(", ")
              }
            }

            exportToExcel(res.result, "Component Availability")

          } else {
            openModalMessages('Report Failed to Generate', `${res.message}. Try again or contact support if you feel this is an error.`);
          }
        })
      }
    }

    function UnAssignBlenderClick(batchQueue){
      setTempBatchID(batchQueue.id)
      openModalUnassign("Confirm Unassign", "Are you sure you want to unassign this batch?", "Yes", "No")
    }

    function ConfirmUnassign() {
      setTempBlenderEmail('')
      setUnassignBlender(true)
      setModalUnassignOpen(false)
    }

    function ValidateEnteredBlender(){
      if (!(blenderEmailError))
      {
        UpdateAssignedBlender()
      }
    }

    function UpdateAssignedBlender(allowNullAssignee){
      if (tempBatchID > 0){  
  
        if (allowNullAssignee === false && (tempBlenderEmail === null || tempBlenderEmail === ''))
        {
          openModalMessages("Batch Error", "The blender email must be valid!")
          return
        }
  
        Batch.updateAssignedBlender(tempBatchID, tempBlenderEmail).then((res) => {
          if (res.message === 'Success')
          {
            Batch.updateBatchStatus(tempBatchID, tempBlenderEmail === '' ? 'Submitted' : 'Assigned', '').then((res2) => {
              if (res.message === 'Success')
              {
                openModalMessages("Batch Updated", "Batch successfully updated!")
                setRefreshData(!refreshData)
              } else {
                openModalMessages('Batch Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
              }

              closeModalTwoButtons()
              setTempBlenderEmail('')
              setTempBatchID(0)
            })
          } else {
            openModalMessages('Batch Failed to Update', `${res.message}. Contact support if you feel this is an error.`);
            
            closeModalTwoButtons()
            setTempBlenderEmail('')
            setTempBatchID(0)
          }
        })
      }
    }

    useEffect(() => {
      var filteredArray = applyFiltersToArray(filters, allBatches)
      setFilteredBatches(filteredArray)
    }, [filters, allBatches])

    useEffect(() => {
      if (unassignBlender === true)
      {
        UpdateAssignedBlender(true)
        setUnassignBlender(false)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unassignBlender])

    function GetBlendInfoAndPrint(batchQueue)
    {
      let cancelPromise = false
      
      //open loading modal
      openModalNoButton("Generating Batch Prep Sheet", "Please wait while batch prep sheet is created...")

      Batch.getBatchByID(batchQueue.id).then((res) => {

        closeModalNoButton()

        if (cancelPromise) return

        if (res.message === "Success")
        {
          setToPrepareAmount(res.result.actualAmount ? res.result.actualAmount : res.result.requestedAmount)
          setToPrepareUoM(res.result.unitOfMeasureActual ? res.result.unitOfMeasureActual : res.result.unitOfMeasureRequested)
          
          //recalculate the requested percentages
          res.result.blendRequest.blendComponents.forEach((oBlendComponent) => {
            if (oBlendComponent.componentUoMName === 'wt%' || oBlendComponent.componentUoMName === 'vol%')
            {
              if (isNumber(oBlendComponent.targetAmountLower))
              {
                const componentIndex = res.result.blendRequest.blendComponents.findIndex(obj => obj.id === oBlendComponent.id)

                res.result.blendRequest.blendComponents[componentIndex].targetAmountLower = ((res.result.blendRequest.blendComponents[componentIndex].toPrepareValue / (res.result.actualAmount ? res.result.actualAmount : res.result.requestedAmount)) * 100)
              }
            }
          })

          setMyBatchInfo(res.result)
          setMyMethods(res.result.analyticalTesting ? JSON.parse(res.result.analyticalTesting) : [])      

        } else {
          openModalMessages("Error Loading", `There was an error loading the Batch ${batchQueue.id}, please try again.  ${res.message} `)
          setToPrepareAmount(null)
          setToPrepareUoM(null)
          setMyBatchInfo([])
          setMyMethods([])
        } 
      });
    }

    useEffect(() => {
      if (myBatchInfo && myBatchInfo !== null && myBatchInfo.id > 0)
      {
        window.print();
      }
    }, [myBatchInfo])

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const determineStartArray = () => {
      return (filteredBatches.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }

    function handleBatchCheck (batchID) {
      const copyCheckedBatchIDs = structuredClone(checkedBatchIDs)
      const isChecked = copyCheckedBatchIDs.includes(batchID)

      if (isChecked)
      {
        const index = copyCheckedBatchIDs.indexOf(batchID)
        copyCheckedBatchIDs.splice(index, 1)
      } else {
        copyCheckedBatchIDs.push(batchID)       
      }

      setCheckedBatchIDs(copyCheckedBatchIDs)
    }
 
  return (
       <div>
         {/* <UXDataTable tableWidth='100%' cols={MyBatchCols} rows={filteredBatches.sort((a, b) => new Date(a.age) - new Date(b.age))} moreOptionsCell={true} enablePaging={true} 
                      noDataMessage={noDataMessage} menuProps={menuItems} defaultRowsPerPage={10} isDataLoading={isDataLoading} tableName={'batchQueue'} enableSorting={true}></UXDataTable> */}


          <UXDataTableWithoutBody 
              tableWidth='100%' 
              cols={MyBatchCols_New} 
              blankFirstHeader={true}
              tableId="allBatches"
              rowLength={filteredBatches ? filteredBatches.length : 0}
              enablePaging={true}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              noDataFoundMessage={noDataMessage}
              enableAddIcon={false}
              addFunction={null}
              addToolTipText={''}  
              isDataLoading={isDataLoading}
            > 
          <StyledTableBody>
            {filteredBatches && filteredBatches.length > 0 && determineStartArray().map((oBatch, index) => {
              return (
                <>
                <StyledTableRow  key={`myBatchRow${index}`}>
                  <StyledTableCell>
                    <FormControlLabel style={{marginLeft:"5px", width:"20px"}} 
                        control={<Checkbox 
                          onChange={e => {handleBatchCheck(oBatch.id)}}
                    ></Checkbox>} label=""/>
                  </StyledTableCell>
                  <StyledTableCell style={{width:"30px"}}>
                    <MoreOptions color='black' aria-haspopup="true" onClick={(e) => {handleOpenMoreOptions(e); setBatchQueueItem(oBatch)}}/>
                  </StyledTableCell>
                  <StyledTableCell>{oBatch.studyName}</StyledTableCell>
                  <StyledTableCell>{oBatch.blendName}</StyledTableCell>
                  <StyledTableCell>{oBatch.batchSequenceNumber}</StyledTableCell>
                  <StyledTableCell style={{background: (oBatch.blendPriorityName === 'Normal' ? '' : 'pink') }}>{oBatch.blendPriorityName}</StyledTableCell>
                  <StyledTableCell>{oBatch.batchCreatedByEmail}</StyledTableCell>
                  <StyledTableCell>{convertDateFormat(oBatch.requestedCompletionDate)}</StyledTableCell>
                  <StyledTableCell>{`${oBatch.requestedAmount} ${oBatch.requestedAmountUoM}`}</StyledTableCell>
                  <StyledTableCell>{oBatch.preparedByEmail}</StyledTableCell>
                  <StyledTableCell>{oBatch.batchStatusName}</StyledTableCell>
                  <StyledTableCell>{oBatch.age}</StyledTableCell>
                  <StyledTableCell>{oBatch.blendFacilityName}</StyledTableCell>
                </StyledTableRow>
                </>
              )
            })}         
          </StyledTableBody>

          </UXDataTableWithoutBody>
        {isComponentAvailabilityProcessing ? 
            (<>              
            <CircularProgress style={{textTransform: "none"}}></CircularProgress>
            <GlobalButton 
              disabled={true}
              variant="contained" 
            >
              {"Compute Component Availability"}
            </GlobalButton>
            </>) : 
            ( <GlobalButton 
              disabled={checkedBatchIDs.length < 1}
              variant="contained" 
              onClick={() => openModalComponentReport("Generate Report", "Please select what UoM you would like to generate the report using.", "Ok", "Cancel")}
            >
              {"Compute Component Availability"}
            </GlobalButton>)
          }
         
          <Menu
              anchorEl={anchorEl}
              open={openMenu()}
              onClose={(e) => handleCloseMenu(e)}>
              <MenuItem 
                component={Link} 
                to= {{pathname: '/prepareBatch', state: {incomingBatchID: batchQueueItem?.id}}}
                disabled={!(hasBatchPrepareer)}
              >Work On Batch</MenuItem>
              <MenuItem 
                disabled={!(hasBatchAssigner)}
                onClick={() => {handleCloseMenu(); AssignBlenderClick(batchQueueItem)}}
              >Assign Blender
              </MenuItem>
              <MenuItem 
                disabled={!(hasBatchAssigner)}
                onClick={() => {handleCloseMenu(); UnAssignBlenderClick(batchQueueItem)}}
              >Unassign Blender
              </MenuItem>
              <MenuItem 
                onClick={() => {handleCloseMenu(); GetBlendInfoAndPrint(batchQueueItem)}}
              >Print Prep Sheet
              </MenuItem>
          </Menu>

        {/* Informational Messages */}
          <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
           <label>
               {modalMessagesText}
           </label>     
         </ModalMessages>

          {/* Assigned Blender */}
          <ModalTwoButtons title={modalTwoBtnTitle} button1Text={modalTwoBtnButton1Text} button1Action={ValidateEnteredBlender} isButton1Disabled={(tempBlenderEmail === null || tempBlenderEmail === '')} button2Text={modalTwoBtnButton2Text} button2Action={closeModalTwoButtons} open={modalTwoBtnOpen} setOpen={setModalTwoBtnOpen}>
                <label>
                    {modalTwoBtnText}
                </label>

                <div></div>

                <EmailAddressTextField
                  fontSize = {16}
                  fieldWidth = {"350px"} 
                  validatedUserEmail = {tempBlenderEmail}
                  setValidatedUserEmail = {(e) => setTempBlenderEmail(e)}
                  hasErrors={blenderEmailError}
                  setHasErrors = {(e) => setBlenderEmailError(e)}
                  isDisabled={false}
                  labelText="Blender Email"
                  placeholderText="Blender Email"
                  showPlusMeButton={true}
                  size="small"
                  margin="normal">
                </EmailAddressTextField>
          </ModalTwoButtons>
          
          <ModalTwoButtons title={modalUnassignTitle} button1Text={modalUnassignButton1Text} button1Action={ConfirmUnassign} button2Text={modalUnassignButton2Text} button2Action={closeModalUnassign} open={modalUnassignOpen} setOpen={setModalUnassignOpen}>
            <label>
              {modalUnassignText}
            </label>
          </ModalTwoButtons>

          <ModalTwoButtons title={modalComponentReportTitle} button1Text={modalComponentReportButton1Text} button1Action={GenerateComponentAvailabilityReport} button2Text={modalComponentReportButton2Text} button2Action={closeModalComponentReport} open={modalComponentReportOpen} setOpen={setModalComponentReportOpen}>
            <label>
                {modalComponentReportText}
            </label>

            <StyledAutocomplete
                disablePortal
                noOptionsText={"Loading UoMs..."}
                options={availableUoMs}
                getOptionLabel={(option) => option.uoMName}
                onChange={(e, value) => {setReportUoM(value)}}
                autoHighlight
                autoSelect
                value={reportUoM}
                isOptionEqualToValue={(option, value) => value.uoMName === option.uoMName}
                renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="normal" label = "* UoM" 
                InputLabelProps={{shrink: true}} InputProps={{ ...params.InputProps }}/>} 
            />
          </ModalTwoButtons>

          <BlendPrepSheet
            myBatchInfo={myBatchInfo} 
            myMethodInfo={myMethods}
            toPrepareUoM={toPrepareUoM}
            toPrepareValue={toPrepareAmount}
          ></BlendPrepSheet>

          <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
            <label>
              {modalNoButtonText}
            </label>
          </ModalNoButton>
       </div>

         
  );
};

export default TableBatchQueue;