import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  styled
} from "@mui/material";

import FormControlLabel from '@mui/material/FormControlLabel';
import MethodManagementDataGrid from "./MethodManagementDataGrid";
import MethodManagementFacility from "./MethodManagementFacility";
import MethodCategoryApi from "../../api/LIMS/MethodCategory";
import ResultComponentApi from "../../api/Admin/ResultComponent";
import TestFacilityApi from "../../api/Admin/TestFacility";
import LocationApi from "../../api/Admin/Location";
import MethodFacilityStatusApi from "../../api/LIMS/MethodFacilityStatus";
import TestConditionNameApi from "../../api/Admin/TestConditionName";
import ModalTwoButtons from '../../components/Modal/ModalTwoButtons';
import MethodApi from "../../api/LIMS/Method";
import { convertToLocalTime } from "../../global";

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  marginBottom: "20px",
  gap: "20px",
  justifyContent: "normal!important"
}));

const StyledTextField = styled(TextField)(() => ({
  width: "35rem"
}));


const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  width: "70rem"
}));

const StyledSelect = styled(Select)(() => ({
  width: "35rem"
}));


const StyledDiv = styled('div')({
  display: 'flex',
  justifyContent: "space-between"
});

const StyledDatagridDiv = styled('div')({
  display: 'flex',
  minHeight: '20vh',
  justifyContent: 'space-between',
  marginBottom: '1rem',
});

const StyledInputLabel = styled(InputLabel)(() => ({
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  whiteSpace: 'normal;'
}));

const columnsFacility = [

  { field: 'abreviation', headerName: 'Abreviation', type: 'label' },
];

const columnsResultComponent = [
  { field: 'resultComponentName', headerName: 'Result Component Name', type: 'label', size: 'medium' },
  { field: 'uoMName', headerName: 'Default UoM', type: 'select', size: 'medium' },
  { field: 'isOptionalResult', headerName: 'Is Optional', type: 'checkbox', size: 'small' }
];




const MethodManagementForm = ({
  infoData,
  setInfoData,
  methodFacilityList,
  setMethodFacilityList,
  invalidField,
  setSelectedFacility,
  rowsFacility,
  setRowsFacility,
  rowsResultComponent,
  setRowsResultComponent,
  methodFacilityResultComponents,
  setRowsMethodFacilityResultComponent,
  unitOfMeasureList,
  handleChangeMethodManagementFilter,
  canEdit,
  showFacility,
  setShowFacility }) => {

  function initialStateMethodFacility() {
    return {
      Id: 0,
      testFacilityAbv: '',
      containeringGroup: null,
      legacyTableID: 0,
      requiresEstimates: false,
      testOwnerEmail: '',
      testCostUSD: null,
      requiredSampleSize: null,
      requiredSampleSizeUoM: '',
      requiresConditions: false,
      testConditionSetupValue: '',
      requiresSeparateContainer: false,
      messageForSubmitter: '',
      allowPrioritization: false,
      targetTurnaroundInDays: null,
      dropboxLocation: '',
      isSQCMethod: false,
      allowUserDefinedConditions: false,
      testConditionOfferings: [],
      methodFacilityResultComponents: [],
      methodStatusName: "",
    }
  }

  const [categoryList, setCategoryList] = React.useState(null);
  const [locationList, setLocationList] = React.useState([]);
  const [methodFacilityStatusList, setMethodFacilityStatusList] = React.useState([]);

  const [facilityList, setFacilityList] = React.useState(null);
  const [resultComponentList, setResultComponentList] = React.useState(null);
  const [conditionsList, setConditionsList] = React.useState(null);
  
  const [selectedInfoResultComponentList, setSelectedInfoResultComponentList] = React.useState(null);

  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);

  const initialRowsMethodFacilityResultComponent = rowsResultComponent;

  //#region Facilities
  React.useEffect(() => {
    if (rowsFacility.length === 0) {
      TestFacilityApi.getAll().then((res) => {
        if (res.length > 0) {
          setFacilityList(res.map(function (item) { return { id: item.abreviation, value: item.testFacilityName, text: `${item.abreviation} - ${item.testFacilityName}` } }));
        }
      });

    }
  }, [rowsFacility]);

  function addNewFacility(facility) {
    if (facility !== null) {
      if (rowsFacility.find(obj => { return obj.id === facility.id }) === undefined) {
        let state = initialStateMethodFacility();
        state.testFacilityAbv = {
          rowId: facility.id,
          id: facility.id,
          abreviation: facility.id,
        };

        state.methodFacilityResultComponents = initialRowsMethodFacilityResultComponent.map(item => {
          return {
            ...item,
            resultComponentName: item.resultComponentName,
            methodName: item.methodName,
            methodFacilityID: item.methodFacilityID,
            upperLimit: null,
            lowerLimit: null,
            significantDigits: null,
          }
        })

        setMethodFacilityList([...methodFacilityList, {
          ...state
        }]);

        setRowsFacility([...rowsFacility, state.testFacilityAbv])
      }
    }
  }

  React.useMemo(() => {

    TestConditionNameApi.getAll().then((res) => {
      if (res.length > 0) {
        setConditionsList(res.map(function (item) { return { id: item.name, value: item.name, text: item.name } }));
      }
    });
  }, [setConditionsList]);


  //#region Result Component
  React.useEffect(() => {
    if (rowsResultComponent.length === 0) {
      ResultComponentApi.getAll().then((res) => {
        if (res.length > 0) {
          setResultComponentList(res.map(function (item) { return { id: item.legacyIDNUM, value: item.name, text: item.name } }));
        }
      });
    }
  }, [rowsResultComponent]);

  React.useEffect(() => {
    if (selectedInfoResultComponentList !== null) {

      setSelectedInfoResultComponentList(null);

      if (rowsResultComponent.find(obj => { return obj.id === selectedInfoResultComponentList.id }) === undefined) {
        const resultComponent = {
          rowId: selectedInfoResultComponentList.id,
          id: selectedInfoResultComponentList.id,
          resultComponentName: selectedInfoResultComponentList.name,
          upperLimit: null,
          lowerLimit: null,
          significantDigits: null,
          isOptionalResult: false,
          uoMName: unitOfMeasureList.map(function (item) { return { key: item.uoMName, value: item.uoMName, selected: false } })
        }
        const rowsResult = [...rowsResultComponent, resultComponent];
        setRowsResultComponent(rowsResult)

        setMethodFacilityList((prevValue) => prevValue.map(facility => {
          return {
            ...facility,
            methodFacilityResultComponents: rowsResult.map((mrc) => {
              const mfrc = facility.methodFacilityResultComponents.find(m => m.resultComponentName === mrc.resultComponentName);
              return {
                resultComponentName: mrc.resultComponentName,
                methodName: facility.methodName,
                methodFacilityID: facility.id,
                upperLimit: mfrc?.upperLimit || mfrc?.upperLimit === 0 ? mfrc?.upperLimit : mrc.upperLimit,
                lowerLimit: mfrc?.lowerLimit || mfrc?.lowerLimit === 0 ? mfrc?.lowerLimit : mrc.lowerLimit,
                significantDigits: mfrc?.significantDigits || mfrc?.significantDigits === 0 ? mfrc?.significantDigits : mrc.significantDigits,
              }
            })
          }
        }));
      }
    } else {
      setMethodFacilityList((prevValue) => prevValue.map(facility => {
        return {
          ...facility,
          methodFacilityResultComponents: rowsResultComponent.map((mrc) => {
            const mfrc = facility.methodFacilityResultComponents.find(m => m.resultComponentName === mrc.resultComponentName);
            return {
              resultComponentName: mrc.resultComponentName,
              methodName: facility.methodName,
              methodFacilityID: facility.id,
              upperLimit: mfrc?.upperLimit || mfrc?.upperLimit === 0 ? mfrc?.upperLimit : mrc.upperLimit,
              lowerLimit: mfrc?.lowerLimit || mfrc?.lowerLimit === 0 ? mfrc?.lowerLimit : mrc.lowerLimit,
              significantDigits: mfrc?.significantDigits || mfrc?.significantDigits === 0 ? mfrc?.significantDigits : mrc.significantDigits,
            }
          })
        }
      }));
    }
  }, [setRowsResultComponent, rowsResultComponent, selectedInfoResultComponentList, setMethodFacilityList, unitOfMeasureList]);
  //#endregion

  React.useEffect(() => {
    if (categoryList === null) {
      MethodCategoryApi.getAll().then((res) => {
        if (res.length > 0) {
          let array = [];
          array.push({ id: undefined, name: "" });
          array.push(...res);

          setCategoryList(array);
        }
      });

    }
  }, [categoryList]);

  //#region Location
  React.useEffect(() => {
    if (locationList.length === 0) {
      LocationApi.getAll().then((res) => {
        if (res.length > 0) {
          setLocationList(res.filter(l => l.locationName.startsWith("DB")));
        }
      });

    }
  }, [locationList]);

  React.useEffect(() => {
    if (locationList.length === 0) {
      MethodFacilityStatusApi.getAll().then((res) => {
        if (res.length > 0) {
          setMethodFacilityStatusList(res.filter(l => l.isActive === true));
        }
      });

    }
  }, [locationList]);

  //#endregion 

  React.useEffect(() => {

    const rowsSelected = rowsFacility.filter(function (item) {
      return item['rowSelected'] === true
    });

    if (rowsSelected.length > 0) {
      if (methodFacilityList.find(obj => { return obj.testFacilityAbv.id === rowsSelected[0].id }) === undefined || methodFacilityList.length === 0) {

        let state = initialStateMethodFacility();
        state.testFacilityAbv = rowsSelected[0];
        state.methodFacilityResultComponents = initialRowsMethodFacilityResultComponent.map(item => {
          return {
            ...item,
            resultComponentName: item.resultComponentName,
            methodName: item.methodName,
            methodFacilityID: item.methodFacilityID,
            upperLimit: null,
            lowerLimit: null,
            significantDigits: null,
          }
        })

        setSelectedFacility(state);
      }
      else {
        let methodfac = methodFacilityList.find(obj => { return obj.testFacilityAbv.id === rowsSelected[0].id });

        setSelectedFacility(methodfac);
      }
    }

  }, [methodFacilityList, rowsFacility, setSelectedFacility, initialRowsMethodFacilityResultComponent]);


  function findMethod() {
    handleChangeMethodManagementFilter(infoData.name);
    closeModalTwoButtonsOpen();
  }

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function checkExistingMethod(method) {

    if (method) {
      MethodApi.get(method).then((methodExists) => {
        if ((methodExists !== undefined && methodExists !== null))
          setModalTwoButtonsOpen(true)
      });
    }
  }

  function clickFacility(facility) {
    if (facility && facility.rowId)setShowFacility(facility.rowId)
  }

  return (

    <div>

      <StyledDiv>

        <div>

          <StyledBox sx={{ flexDirection: 'column' }}>
            <StyledFormControl sx={{ m: 1 }} variant='outlined'>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField
                  onChange={e => {
                    setInfoData(() => ({
                      ...infoData,
                      name: e.target.value
                    }));
                  }}
                  onBlur={e => {
                    checkExistingMethod(e.target.value)
                  }}
                  value={infoData.name ? infoData.name : ''}
                  id="method-management-name"
                  label="Method name"
                  variant="outlined" size="small" margin="none" 
                  error={invalidField.name.length === 0 ? false : true}
                  disabled={infoData.id ? true : false}
                  helperText={invalidField.name}
                  inputProps={{ maxLength: 50 }}
                />

                {<StyledFormControl sx={{ width: "18rem" }} fullWidth error={invalidField.methodCategoryName ? true : false} >
                  <InputLabel id="method-management-category">Method Category</InputLabel>
                  <StyledSelect sx={{ width: "18rem" }}
                    labelId="method-management-category"
                    id="method-management-category-select"
                    variant="outlined" size="small" margin="none" 
                    onChange={e => {
                      setInfoData(() => ({
                        ...infoData,
                        methodCategoryName: e.target.value
                      }))
                    }}
                    value={categoryList && infoData.methodCategoryName ? infoData.methodCategoryName : ''}
                    label="Method Category"
                    disabled={!canEdit}
                  >
                    {
                      categoryList &&
                      categoryList.map((methodCategory) => (
                        <MenuItem value={methodCategory.methodCategoryName ? methodCategory.methodCategoryName : ''} key={methodCategory.methodCategoryName}>{methodCategory.methodCategoryName}</MenuItem>
                      ))
                    }
                  </StyledSelect>

                </StyledFormControl>}

                <FormControlLabel
                  label="Active"
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    isActive: e.target.checked
                  }))}
                  checked={infoData.isActive}
                  control={<Checkbox />}
                  disabled={!canEdit}
                />

                <FormControlLabel
                  label="Destructive"
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    isMethodDestructive: e.target.checked
                  }))}
                  checked={infoData.isMethodDestructive}
                  control={<Checkbox />}
                  disabled={!canEdit}
                />

              </StyledBox>

              <StyledBox sx={{ justifyContent: "space-between" }}>
                <StyledTextField sx={{ width: "70rem" }}
                  onChange={e => setInfoData(() => ({
                    ...infoData,
                    description: e.target.value
                  }))}
                  id="method-management-description"
                  label="Method Description"
                  variant="outlined" size="small" margin="none" 
                  value={infoData.description ? infoData.description : ''}
                  inputProps={{ maxLength: 200 }}
                  disabled={!canEdit}
                />

              </StyledBox>

            </StyledFormControl>


            {infoData.id === 0 ? <div></div> :

              <StyledBox sx={{ m: 1, width: "100%", justifyContent: "space-between" }}>

                {!infoData.createdByEmail ?

                  <StyledInputLabel sx={{ position: "relative" }}>Create By <b> unknown </b> on <b> n.d. </b></StyledInputLabel>

                  : <StyledInputLabel sx={{ position: "relative" }}>Create By <b>{infoData.createdByEmail}</b> on
                    <b> {convertToLocalTime(infoData.createdDate)}</b>
                  </StyledInputLabel>}

                {!infoData.createdByEmail ? <div></div> :

                  !infoData.lastModifiedByEmail ?
                    <div></div>

                    : <StyledInputLabel sx={{ position: "relative" }}>Last Updated By <b>{infoData.lastModifiedByEmail}</b> on
                      <b> {convertToLocalTime(infoData.lastModifiedDate)}</b>

                    </StyledInputLabel>}

              </StyledBox>
            }

          </StyledBox>

          <StyledDatagridDiv>
            <MethodManagementDataGrid key="dt-grid-facility" sx={{ width: "23rem", height: '20rem' }} setSelectedInfo={addNewFacility} title={'Available Facilities'} buttonText={canEdit ? 'Add Facility' : null} rows={rowsFacility} setRows={setRowsFacility} cols={columnsFacility} modalList={facilityList} enableSelect={false} minWidth={340} canEdit={canEdit} selectionDataGrid={clickFacility}></MethodManagementDataGrid>
            <MethodManagementDataGrid key="dt-grid-resultComponent" 
              sx={{ width: "70rem", height: "20rem" }} 
              selectedInfo={selectedInfoResultComponentList} 
              setSelectedInfo={setSelectedInfoResultComponentList} 
              title={'Result Component'} 
              buttonText={canEdit ? 'Add Result Component' : null} 
              rows={rowsResultComponent && rowsResultComponent?.length > 0 ? rowsResultComponent.sort((a, b) => a.resultComponentName.localeCompare(b.resultComponentName)) : rowsResultComponent} 
              setRows={setRowsResultComponent} 
              cols={columnsResultComponent} 
              modalList={resultComponentList} 
              enableDelete={canEdit ? true : false} 
              canEdit={canEdit}>
            </MethodManagementDataGrid>
          </StyledDatagridDiv>

          {
            methodFacilityList && methodFacilityList.length > 0 && methodFacilityList.map(facility => {
              return (
                <MethodManagementFacility
                  invalidField={invalidField}
                  selectedFacility={facility}
                  methodFacilityResultComponents={methodFacilityResultComponents}
                  setRowsMethodFacilityResultComponent={setRowsMethodFacilityResultComponent}
                  unitOfMeasureList={unitOfMeasureList}
                  conditionsList={conditionsList}
                  canEdit={canEdit}
                  methodFacilityList={methodFacilityList}
                  initialStateMethodFacility={initialStateMethodFacility}
                  initialRowsMethodFacilityResultComponent={initialRowsMethodFacilityResultComponent}
                  setMethodFacilityList={setMethodFacilityList}
                  locationList={locationList}
                  methodFacilityStatusList={methodFacilityStatusList}
                  setSelectedFacility={setSelectedFacility}
                  showFacility={showFacility}
                />
              )
            })
          }

        </div>

      </StyledDiv>

      <ModalTwoButtons title={'Warning'} button1Text={'No'} button1Action={closeModalTwoButtonsOpen} button2Text={'Yes'} button2Action={findMethod} open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        <label>
          This method already exist, would you like to use it?
        </label>
      </ModalTwoButtons>

    </div >

  );
};

export default MethodManagementForm;
