import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import SpecialAnalysis from "../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisBusinessUnit from "../../api/SpecialAnalysis/SpecialAnalysisBusinessUnit";
import SpecialAnalysisStatus from "../../api/SpecialAnalysis/SpecialAnalysisStatus";
import WorkRequestPriority from "../../api/WorkRequest/WorkRequestPriority";
import FilterMenu from "../../components/FilterMenu";
import UserContext from "../../context/UserContext";
import TableAllRequests from "./TableAllRequests";
import TableRequestQueue from "./TableRequestQueue";
import TableMyOpenRequests from "./TableMyOpenRequests";
import BillingInfo from "../../api/Admin/BillingInfo";
import {
  applyFiltersToArray,
  convertDateFormat,
  exportToCsv,
} from "../../global";
import {
  GlobalButton,
  GlobalSecondaryButton,
  GlobalTabCollection,
  StyledTab,
} from "../styles";

const filterInitialValues = [
  {
    name: null,
    displayName: null,
    operator: null,
    enumValues: [],
    value: "",
  },
];

const ProcessSpecialAnalysis = ({ copySpecialAnalysis }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState(filterInitialValues);

  const [allRequests, setAllRequests] = useState([]);
  const [filteredAllRequests, setFilteredAllRequests] = useState([]);
  const [isAllRequestsLoading, setIsAllRequestsLoading] = useState(true);

  const [queueRequests, setQueueRequests] = useState([]);
  const [isQueueRequestsLoading, setIsQueueRequestsLoading] = useState(true);

  const [myRequests, setMyRequests] = useState([]);
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;

  const [requestStatuses, setRequestStatuses] = useState([]);
  const [requestPriorities, setRequestPriorities] = useState([]);
  const [requestBusinessUnits, setRequestBusinessUnits] = useState([]);

  const exportRef = useRef([]);
  const [billingList, setBillingList] = useState([]);

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "integer", enumValues: [] },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "priorityName",
      displayName: "Priority",
      type: "enum",
      enumValues: requestPriorities,
      multiple: true,
    },
    {
      name: "assignedToEmail",
      displayName: "Assigned To",
      type: "string",
      enumValues: [],
    },
    {
      name: "requestedByEmail",
      displayName: "Requested By",
      type: "string",
      enumValues: [],
    },
    {
      name: "createdDate",
      displayName: "Requested Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "requestedCompletionDate",
      displayName: "Requested Completion Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "businessUnitName",
      displayName: "Business Unit",
      type: "enum",
      enumValues: requestBusinessUnits,
      multiple: true
    },
    {
      name: "statusName",
      displayName: "Status",
      type: "enum",
      enumValues: requestStatuses,
      multiple: true
    },
    {
      name: "chargeCode",
      displayName: "JADE #",
      type: "string",
      enumValues: [],
    }
  ]; //.sort((a,b) => a.displayName.localeCompare(b.displayName));

  const isAllRequestsInitLoading = isAllRequestsLoading && (!allRequests || allRequests.length === 0);

  useEffect(() => {
    let cancelPromise = false;
    setIsAllRequestsLoading(true);

    function getAllRequestsDataRecr(currPage, allData = []) {
      if (cancelPromise) return allData;
      SpecialAnalysis.getAll(currPage, 200).then((res) => {
        if (res === null || res.length === 0) {
          setIsAllRequestsLoading(false);
          return allData;
        }
        else {
          allData = [...allData, ...res];
          currPage++;
          setAllRequests(allData.filter(item => item.statusName !== "Draft"))
          return getAllRequestsDataRecr(currPage, allData);
        }
      });
    }

    getAllRequestsDataRecr(0); //Recursively load all data

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  useEffect(() => {
    const filteredArray = applyFiltersToArray(filters, allRequests)
    setFilteredAllRequests(filteredArray);
  }, [filters, allRequests]);

  function reloadSpecialAnalysis(id) {
    SpecialAnalysis.getById(id).then((result) => {
      if (result) {
        setAllRequests(prevItems => prevItems.map(item =>
          item.id === id ? result : item
        ));
      }
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysis.getMyOpenAssignedSpecialAnalysis(userEmail).then((res) => {
      if (cancelPromise) return;
      setMyRequests(res);
      setIsMyRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [userEmail]);

  function reloadOpenRequests() {
    SpecialAnalysis.getMyOpenAssignedSpecialAnalysis(userEmail).then((res) => {
      setMyRequests(res.filter(item => item.statusName !== "Draft"));
      setIsMyRequestsLoading(false);
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysis.getQueueRequests().then((res) => {
      if (cancelPromise) return;
      setQueueRequests(
        res.filter(item => item.statusName !== "Draft").sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      );
      setIsQueueRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  function reloadQueueRequests() {
    SpecialAnalysis.getQueueRequests().then((res) => {
      setQueueRequests(
        res.filter(item => item.statusName !== "Draft").sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
      );
      setIsQueueRequestsLoading(false);
    });
  }

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysisStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatuses(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.statusName)
          .sort()
      );
    });

    WorkRequestPriority.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestPriorities(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.priorityName)
          .sort(),
      );
    });

    SpecialAnalysisBusinessUnit.getAllActive().then((res) => {
      if (cancelPromise) return;
      setRequestBusinessUnits(
        res.sort((a, b) =>
          a.businessUnitName.localeCompare(b.businessUnitName)
        ).map((item) => item.businessUnitName)
      );
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  const handleChange = (event, newValue) => {
    setFilters(filterInitialValues);
    setTabValue(newValue);
  };

  useEffect(() => {
    let cancelPromise = false;
    BillingInfo.getAll().then((res) => {
      if (cancelPromise) return;

      if (res.message === 'Success') {
        setBillingList(res.result.filter(result => result.isActive === true))
      }
    });
    return () => {
      cancelPromise = true;
    };
  }, []);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        exportRef.current = applyFiltersToArray(filters, myRequests);
        return (
          <TableMyOpenRequests
            filters={filters}
            myRequests={myRequests}
            isDataLoading={isMyRequestsLoading}
            userEmail={userEmail}
            isProcessing={true}
            billingList={billingList}
            reloadSpecialAnalysis={reloadOpenRequests}
            copySpecialAnalysis={copySpecialAnalysis}
          />
        );
      }
      case 1: {
        exportRef.current = applyFiltersToArray(filters, queueRequests);;
        return (
          <TableRequestQueue
            filters={filters}
            myRequests={queueRequests}
            isDataLoading={isQueueRequestsLoading}
            billingList={billingList}
            isProcessing={true}
            reloadSpecialAnalysis={reloadQueueRequests}
            copySpecialAnalysis={copySpecialAnalysis}
          />
        );
      }
      case 2: {
        exportRef.current = filteredAllRequests;

        return (
          <TableAllRequests
            filters={filters}
            filteredAllRequests={filteredAllRequests}
            isDataLoading={isAllRequestsInitLoading}
            isProcessing={true}
            userEmail={userEmail}
            billingList={billingList}
            reloadSpecialAnalysis={reloadSpecialAnalysis}
            copySpecialAnalysis={copySpecialAnalysis}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="ant example"
        >
          <StyledTab label="My Assigned Requests" />
          <StyledTab label="Request Queue" />
          <StyledTab label="All Requests" />
        </GlobalTabCollection>

        <Box
          display="flex"
          alignItems="center"
          marginLeft="auto"
          marginTop="-.8rem"
        >
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            disabled={tabValue === 2 && isAllRequestsLoading}
            onClick={(e) =>
              exportToCsv(
                applyFiltersToArray(filters, exportRef.current).map((item) => {
                  return {
                    ID: item.id,
                    Description: item.description,
                    Priority: item.priorityName,
                    Requester: item.requestedByEmail,
                    "Assigned To": item.assignedToEmail,
                    "Requested Date": convertDateFormat(item.createdDate, false),
                    "Requested Completion": convertDateFormat(item.requestedCompletionDate, false),
                    "Last Acceptable Date": convertDateFormat(item.lastAcceptableCompletionDate, false),
                    Status: item.statusName,
                    "JADE Number": item.chargeCode
                  };
                }),
                "Special Analysis"
              )
            }
          >
            Export CSV
          </GlobalButton>
          <GlobalButton
            // disabled={tabValue > 1}
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => setFilteringOpen(true)}
          >
            Filters
          </GlobalButton>

          {!(filters[0].name === null) && (
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => setFilters(filterInitialValues)}
            >
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      {renderSearchTab()}

      <FilterMenu
        open={filteringOpen}
        setOpen={setFilteringOpen}
        applyBtnAction={() => setFilteringOpen(false)}
        cancelButtonAction={() => setFilteringOpen(false)}
        filteringInfo={filterOptions}
        appliedFilters={filters}
        setAppliedFilters={setFilters}
      />

      {/* Informational Messages */}
    </div>
  );
};

export default ProcessSpecialAnalysis;
