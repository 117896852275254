import TrashIcon from "@mui/icons-material/Delete";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Location from "../../../api/Admin/Location";
import SpecialAnalysis from "../../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisBusinessUnit from "../../../api/SpecialAnalysis/SpecialAnalysisBusinessUnit";
import SpecialAnalysisFileAttachment from "../../../api/SpecialAnalysis/SpecialAnalysisFileAttachment";
import SpecialAnalysisPriority from "../../../api/SpecialAnalysis/SpecialAnalysisPriority";
import EmailAddressTextField from "../../../components/EmailAddressField";
import FileDragAndDrop from "../../../components/FileDragAndDrop";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../components/UXDataTable";
import UserContext from "../../../context/UserContext";
import {
  getMessage,
  isEmailValid,
  convertToBase64,
  DatePickerKeyDownEvent,
} from "../../../global";
import { GlobalButton, GlobalSecondaryButton } from "../../styles";
import NewSpecialAnalysisForm from "../NewSpecialAnalysisForm";

import BillingInfoField from "../../../components/BillingInfoField";
import PrintInformation from "../../../components/PrintInformation";

import SpecialAnalysisSampleInfoTable from "./components/SpecialAnalysisSampleInfoTable";
import {
  FILE_ATTACHMENT_TABLE_COLUMNS,
  StyledTextField,
  StyledAutocomplete,
  StyledDiv,
  StyledDivider,
  StyledShortTextField,
  SubTitle,
  WORK_REQUEST_PRIORITIES_ENUM,
  defaultErrorChecksSpecialAnalysis,
  defaultNewSpecialAnalysisObject,
  defaultSpecialAnalysis,
  specialAnalysisErrors,
  subsectionTitle,
  copySpecialAnalysis,
} from "./constants.ts";

const NewSpecialAnalysis = ({ ...props }) => {
  const history = useHistory();
  const location = useLocation();

  let mySpecialAnalysisToCopy = location.state && location.state.mySpecialAnalysis ? location.state.mySpecialAnalysis : null;
  if (mySpecialAnalysisToCopy) {
    mySpecialAnalysisToCopy = copySpecialAnalysis(mySpecialAnalysisToCopy);
  }

  const currentUser = useContext(UserContext);

  const [mySpecialAnalysis, setMySpecialAnalysis] = useState(mySpecialAnalysisToCopy ?? defaultSpecialAnalysis);
  const [errorChecks, setErrorChecks] = useState(defaultErrorChecksSpecialAnalysis);
  const [specialAnalysisErrorCheck, setSpecialAnalysisErrorCheck] = useState(
    specialAnalysisErrors
  );

  const [requestBusinessUnits, setRequestBusinessUnits] = useState([]);
  const [requestBusinessUnitsLoading, setRequestBusinessUnitsLoading] =
    useState(true);

  const [locations, setLocations] = useState([]);

  const [files, setFiles] = useState([]);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [redirect, setRedirect] = useState(false);

  const [specialAnalysisPriority, setspecialAnalysisPriority] = useState("");
  const [newComment, setNewComment] = useState("");

  const [printInfoOpen, setPrintInfoOpen] = React.useState(false);

  const [specialAnalysisPriorityLoading, setspecialAnalysisPriorityLoading] =
    useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const formik = useFormik({
    initialValues: mySpecialAnalysis.specialRequest ?? defaultNewSpecialAnalysisObject,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      setMySpecialAnalysis((prevState) => ({
        ...prevState,
        specialRequest: values,
      }));
      formik.resetForm();
    },
  });

  const [submitOnce, setSubmit] = useState(0);

  const validateSpecialAnalysis = () => {
    let values = formik.values;

    let errorCheck = { ...specialAnalysisErrorCheck };
    if (!values.requestDescription) {
      errorCheck.requestDescription = true;
    } else {
      errorCheck.requestDescription = false;
    }

    if (!values.requestReason) {
      errorCheck.requestReason = true;
    } else {
      errorCheck.requestReason = false;
    }
    if (!values.testingRequirements) {
      errorCheck.testingRequirements = true;
    } else {
      errorCheck.testingRequirements = false;
    }

    if (!values.groupLeaderEmail) {
      errorCheck.groupLeaderEmail = true;
    } else {
      errorCheck.groupLeaderEmail = false;
    }

    if (!values.dataClassificationName) {
      errorCheck.dataClassificationName = true;
    } else {
      errorCheck.dataClassificationName = false;
    }
    if (!values.reportFormatName) {
      errorCheck.reportFormatName = true;
    } else {
      errorCheck.reportFormatName = false;
    }

    setSpecialAnalysisErrorCheck(errorCheck);

    let hasError = false;
    for (const key in specialAnalysisErrorCheck) {
      if (specialAnalysisErrorCheck[key]) hasError = true;
    }

    return hasError;
  };

  function AutoCompleteChangeAndValidate(property, value) {
    const copyMyspecialAnalysis = structuredClone(mySpecialAnalysis);
    copyMyspecialAnalysis[property] = value;
    setMySpecialAnalysis(copyMyspecialAnalysis);
  }
  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };

  useEffect(() => {
    let cancelPromise = false;
    Promise.all([
      SpecialAnalysisPriority.getAll()
        .then((res) => {
          setspecialAnalysisPriority(res);
          setspecialAnalysisPriorityLoading(false);
        })
        .catch(() => {
          setspecialAnalysisPriorityLoading(false);
        }),
      SpecialAnalysisBusinessUnit.getAllActive().then((res) => {
        if (cancelPromise) return;
        setRequestBusinessUnitsLoading(false);
        setRequestBusinessUnits(
          res.sort((a, b) =>
            a.businessUnitName.localeCompare(b.businessUnitName)
          )
        );
      }),

      Location.getAllActive().then((res) => {
        if (cancelPromise) return;
        setLocations(
          res.sort((a, b) => a.locationName.localeCompare(b.locationName))
        );
      }),
    ]).then(() => {
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
        sendWithShipment: false,
        isSDS: false,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f !== file);

    setFiles(newFiles);
  }

  async function submit() {
    const areFieldsInvalid = validateFields();
    if (areFieldsInvalid.hasError && areFieldsInvalid.fields.length > 0) {
      const fields = areFieldsInvalid.fields.join("\n");
      openModalMessages(
        "Missing information!",
        "There are required fields missing:\n\n" + fields
      );
      return;
    }

    if (files.length === 0 && !submitOnce) {
      openModalMessages(
        "Save Attachment",
        "Do not forget to add a SDS or any other supporting documentation."
      );

      setSubmit(1);
      return;
    }

    setLoadingSubmit(true);

    const data = await prepareData(mySpecialAnalysis);
    SpecialAnalysis.createSpecialAnalysis(data)
      .then(async (res) => {
        if (res?.message !== 'Success') {
          openModalMessages('Fail', `Something went wrong creating the Special Analysis, please try again later. ${res?.message ? res?.message : ""}`, 'Ok');
          setLoadingSubmit(false);
        } else {
          const specialAnalysis = res.result;
          let filesSent = await sendFiles(specialAnalysis.id);

          if (filesSent) finallyCreate(true);

          setLoadingSubmit(false);
          // if (myDraftObj && myDraftObj.id) Draft.delete(myDraftObj.id).then((res) => { });
        }
      })
      .catch((err) => {
        setLoadingSubmit(false);
        openModalMessages('Fail', `Something went wrong creating the Special Analysis, please try again later. ${err?.message ? err?.message : ""}`, 'Ok');
      });
  }

  // function isSampleValidToPrint(sample) {
  //   return (
  //     sample.sampleName &&
  //     sample.sampleName !== "" &&
  //     sample.containers &&
  //     sample.containers.length > 0
  //   );
  // }

  // function verifyHasLabelToPrint(message) {
  //   setModalMessagesText(message);
  //   const allSampleValidForPrint = mySpecialAnalysis.specialAnalysisSamples.every(
  //     (specialAnalysisSample) => isSampleValidToPrint(specialAnalysisSample.sample)
  //   );

  //   if (allSampleValidForPrint) {
  //     openPrintInfo(mySpecialAnalysis?.specialAnalysisSamples);
  //   }
  // }

  async function sendFiles(id) {
    if (files.length > 0) {
      let result = await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData();
          formData.append("file", file.file);
          formData.append("specialAnalysisID", id);
          formData.append("sendWithShipment", file.sendWithShipment);
          formData.append("isSDS", file.isSDS);

          let resp = await SpecialAnalysisFileAttachment.postAttachs(formData);

          return resp;
        })
      );
      if (!!result.length) return true;
      else return false;
    }
    return true;
  }

  function finallyCreate(redirect) {
    openModalMessages(
      "Special Analysis Create",
      "Special Analysis Created Successfully"
    );
    setModalMessagesButtonText("Ok");
    setRedirect(redirect);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  const checkSampleErros = () => {
    let hasError = false;
    const selectedSamples = mySpecialAnalysis.specialAnalysisSamples.filter((x) => x.sample);

    selectedSamples.forEach((x) => {
      if (
        !x.existingContainerID ||
        !x.requestedAmountUoM ||
        !x.requestedLocationName ||
        !x.requestedAmount
      ) {
        hasError = true;
      }

      let currentContainer = x.sample.containers.find(
        (y) => y.id === x.existingContainerID
      );
      hasError = x.requestedAmount > currentContainer.currentAmount;
    });

    return hasError;
  };

  const jadeRequired = true;

  const validateFields = () => {
    let fieldsInvalidList = [];
    let businessUnitName = null;
    let chargeCode = null
    let requestedByEmail = null;
    let lastAcceptableCompletionDate = null;
    let description = null;
    let requestedCompletionDate = null;

    let fieldsSpecialAnalysis = validateSpecialAnalysis()
    let priorityError = false;
    let sampleHasError = checkSampleErros();

    if (!mySpecialAnalysis.requestedCompletionDate) {
      fieldsInvalidList.push("Requested Completion Date");
      requestedCompletionDate = getMessage("REQUIRED_FIELD");
    }
    if (!mySpecialAnalysis.lastAcceptableCompletionDate) {
      fieldsInvalidList.push("Last Acceptable Date");
      lastAcceptableCompletionDate = getMessage("REQUIRED_FIELD");
    }

    if (!mySpecialAnalysis.description) {
      fieldsInvalidList.push("Description");
      description = getMessage("REQUIRED_FIELD");
    }

    if (!mySpecialAnalysis.businessUnitName) {
      fieldsInvalidList.push("Business Unit");
      businessUnitName = getMessage("REQUIRED_FIELD");
    }
    if (!mySpecialAnalysis.chargeCode && jadeRequired) {
      fieldsInvalidList.push("JADE Number");
      chargeCode = getMessage("REQUIRED_FIELD");
    }
    if (!mySpecialAnalysis.requestedByEmail) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = getMessage("REQUIRED_FIELD");
    } else if (!isEmailValid(mySpecialAnalysis.requestedByEmail)) {
      fieldsInvalidList.push("Requester Name");
      requestedByEmail = "Invalid E-mail";
    }

    if (
      mySpecialAnalysis.priorityName === WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
      !mySpecialAnalysis.priorityReason
    ) {
      fieldsInvalidList.push("Priority Reason");
      priorityError = true;
    }

    setErrorChecks({
      ...errorChecks,
      businessUnitName,
      requestedByEmail,
      requestedCompletionDate,
      lastAcceptableCompletionDate,
      description,
      chargeCode,
    });
    return {
      hasError:
        businessUnitName !== null ||
        requestedByEmail !== null ||
        fieldsSpecialAnalysis ||
        priorityError ||
        sampleHasError ||
        chargeCode !== null ||
        description !== null,
      fields: fieldsInvalidList,
    };
  };

  const prepareData = async (specialAnalysis, draft = false) => {
    const specialAnalysisSamples = specialAnalysis.specialAnalysisSamples.filter(
      (wrs) => wrs.sample
    );

    const filesBase64 = await Promise.all(
      files.map(async (file) => {
        return {
          ...file,
          file: await convertToBase64(file.file),
        };
      })
    );

    return {
      ...specialAnalysis,
      businessUnit: null,
      specialAnalysisSamples:
        (specialAnalysisSamples
        ).map((sample) => {
          let currentContainer = sample.sample.containers.find(
            (x) => x.id === sample.existingContainerID
          );
          delete sample.newContainer;
          return {
            ...sample,
            requestedAmount:
              sample.requestedAmount ?? currentContainer.currentAmount,
            requestedAmountUoM:
              sample.requestedAmountUoM ?? currentContainer.containerSizeUoM,
            sample: draft ? sample.sample : null,
            sampleName: sample.sample?.sampleName,
            unitOfMeasure: null,
            location: null,
            container: draft ? sample.container : null,
          };
        }),
      files: draft ? JSON.stringify(filesBase64) : null,
      specialRequest: specialAnalysis.specialRequest,
    };
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");

    if (redirect) {
      history.push("/workRequests");
    }
  }

  const SaveNewDraft = async (e) => {
    const data = await prepareData(mySpecialAnalysis, true);
    data.statusName = "Draft";

    SpecialAnalysis.createSpecialAnalysis(data)
      .then(async (res) => {
        if (res?.message !== 'Success') {
          openModalMessages('Fail', `Something went wrong creating the draft, please try again later. ${res?.message ? res?.message : ""}`, 'Ok');
          setLoadingSubmit(false);
        } else {
          const specialAnalysis = res.result;
          let filesSent = await sendFiles(specialAnalysis.id);

          if (filesSent) finallyCreate(true);

          setLoadingSubmit(false);
          closeModalMessages();
          setRedirect(true);
          setModalMessagesOpen(true);
          setModalMessagesText("Draft Successfully Saved!");
          setModalMessagesButtonText("Ok");
          setModalMessagesTitle("Save Successful");
        }
      })
      .catch((err) => {
        setLoadingSubmit(false);
        openModalMessages('Fail', `Something went wrong creating draft, please try again later. ${err?.message ? err?.message : ""}`, 'Ok');
      });
  };

  // function openPrintInfo(sampleList) {
  //   let arrayPrintInfo = [];
  //   sampleList?.forEach((specialAnalysisSample) => {
  //     if (
  //       specialAnalysisSample.sample &&
  //       specialAnalysisSample.sample.containers &&
  //       specialAnalysisSample.sample.containers.length > 0
  //     ) {
  //       let currentContainer = specialAnalysisSample.sample.containers.find(
  //         (x) => x.id === specialAnalysisSample.existingContainerID
  //       ).containerNumber;

  //       let newPrintInfo = new PrintLabel({
  //         sampleName: specialAnalysisSample.sample.sampleName,
  //         containerNumbers: [currentContainer],
  //         includeAdditionalSampleInformation: false,
  //         chemIDOnly: false,
  //         printLabelSizeType: null,
  //         isBlindCoded: false,
  //         blindDescription: null,
  //         blindSampleName: null,
  //         isShelfLabel: false,
  //         shelfLabelText: null,
  //         includeShelfBarcode: false,
  //       });

  //       arrayPrintInfo.push(newPrintInfo);
  //     }
  //   });

  //   setPrintLabelInfo(arrayPrintInfo);
  //   setPrintInfoOpen(true);
  // }

  function closePrintInformation() {
    setPrintInfoOpen(false);
    finallyCreate(true);
  }

  useEffect(() => {
    let chemicals = mySpecialAnalysis.specialAnalysisSamples.filter(
      (i) => i.sample !== null
    );

    if (!chemicals.length) return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mySpecialAnalysis.specialAnalysisSamples]);

  return (
    <div>
      <span className="pageheader">Create Special Analysis</span>

      <Divider className="dividerbar" />

      <Box style={{ width: "100%", marginTop: "1rem" }}>
        <StyledDiv>
          {(<Tooltip title='If container is not already in the Special Analysis Lab, please submit an On-Site Transport Request or Container Registration Request with the "Final Location" as DBLE253' placement="right">
            <p style={subsectionTitle}>Special Analysis Info</p>
          </Tooltip>)}
        </StyledDiv>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
            gap: "1rem",
          }}
        >
          <StyledAutocomplete
            disablePortal
            noOptionsText={
              requestBusinessUnitsLoading
                ? "Loading Business Units..."
                : "No Business Units Found"
            }
            options={requestBusinessUnits}
            onChange={(e, value) =>
              AutoCompleteChangeAndValidate(
                "businessUnitName",
                value?.businessUnitName
              )
            }
            getOptionLabel={(option) =>
              option?.businessUnitName
                ? option.businessUnitName
                : requestBusinessUnits.length > 0
                  ? requestBusinessUnits.find(
                    (f) => f.businessUnitName === option
                  )?.businessUnitName
                  : ""
            }
            autoHighlight
            autoSelect
            value={mySpecialAnalysis.businessUnitName}
            isOptionEqualToValue={(option, value) =>
              value?.businessUnitName === option?.businessUnitName
            }
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="* Business Unit"
                error={
                  errorChecks.businessUnitName === null
                    ? false
                    : errorChecks.businessUnitName &&
                    !mySpecialAnalysis.businessUnitName
                }
                helperText={
                  errorChecks.businessUnitName &&
                    !mySpecialAnalysis.businessUnitName
                    ? errorChecks.businessUnitName
                    : ""
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr 1fr 1fr",
            gap: "1rem",
          }}
        >
          <BillingInfoField
            billingValue={
              mySpecialAnalysis.chargeCode === null ? "" : mySpecialAnalysis.chargeCode
            }
            handleChange={(e) => {
              setMySpecialAnalysis(() => ({
                ...mySpecialAnalysis,
                chargeCode: e,
              }));
            }}
            hasErrors={
              errorChecks.chargeCode === null ? false : errorChecks.chargeCode
            }
            margin="normal"
            fieldWidth="100%"
            helperText={errorChecks.chargeCode ? errorChecks.chargeCode : ""}
            marginLeft={null}
            marginRight={null}
            required={jadeRequired}

          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="* Requested Completion Date"
              onChange={(e) =>
                setMySpecialAnalysis(() => ({
                  ...mySpecialAnalysis,
                  requestedCompletionDate: e,
                }))
              }
              minDate={new Date()}
              maxDate={
                mySpecialAnalysis.lastAcceptableCompletionDate
                  ? new Date(mySpecialAnalysis.lastAcceptableCompletionDate)
                  : null
              }
              value={mySpecialAnalysis.requestedCompletionDate}
              renderInput={(params) => (
                <StyledShortTextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={
                    !!errorChecks.requestedCompletionDate &&
                    !mySpecialAnalysis.requestedCompletionDate
                  }
                  helperText={
                    errorChecks.requestedCompletionDate &&
                      !mySpecialAnalysis.requestedCompletionDate
                      ? errorChecks.requestedCompletionDate
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ ...params.inputProps }}
                  InputProps={{ ...params.InputProps }}
                  style={{ width: "100%" }}
                />
              )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="* Last Acceptable Date"
              onChange={(e) =>
                setMySpecialAnalysis(() => ({
                  ...mySpecialAnalysis,
                  lastAcceptableCompletionDate: e,
                }))
              }
              minDate={
                new Date(mySpecialAnalysis.requestedCompletionDate || new Date())
              }
              value={mySpecialAnalysis.lastAcceptableCompletionDate}
              renderInput={(params) => (
                <StyledShortTextField
                  onKeyDown={DatePickerKeyDownEvent}
                  {...params}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  error={
                    !!errorChecks.lastAcceptableCompletionDate &&
                    !mySpecialAnalysis.lastAcceptableCompletionDate
                  }
                  helperText={
                    errorChecks.lastAcceptableCompletionDate &&
                      !mySpecialAnalysis.lastAcceptableCompletionDate
                      ? errorChecks.lastAcceptableCompletionDate
                      : ""
                  }
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ ...params.inputProps }}
                  InputProps={{ ...params.InputProps }}
                  style={{ width: "100%" }}
                />
              )}
            />
          </LocalizationProvider>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
          }}
        >
          <StyledAutocomplete
            disablePortal
            style={{ width: "100%" }}
            noOptionsText={
              specialAnalysisPriorityLoading
                ? "Loading Priorities..."
                : "No Priorities Found"
            }
            options={specialAnalysisPriority}
            onChange={(e, value) =>
              AutoCompleteChangeAndValidate("priorityName", value?.priorityName)
            }
            getOptionLabel={(option) =>
              option?.priorityName
                ? option.priorityName
                : specialAnalysisPriority.length > 0
                  ? specialAnalysisPriority.find((f) => f.priorityName === option)
                    ?.priorityName
                  : ""
            }
            autoHighlight
            autoSelect
            value={mySpecialAnalysis.priorityName}
            isOptionEqualToValue={(option, value) =>
              value?.priorityName === option?.priorityName
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                variant="outlined"
                size="small"
                margin="normal"
                label="* Priority"
                error={
                  errorChecks.priorityName === null
                    ? false
                    : errorChecks.priorityName
                }
                helperText={
                  errorChecks.priorityName ? errorChecks.priorityName : ""
                }
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
              />
            )}
          />

          <TextField
            sx={{ width: "100%" }}
            size="small"
            margin="normal"
            fullWidth
            variant="outlined"
            label="Priority Reason"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              setMySpecialAnalysis(() => ({
                ...mySpecialAnalysis,
                priorityReason: e.target.value,
              }))
            }
            value={
              mySpecialAnalysis.priorityReason === null
                ? ""
                : mySpecialAnalysis.priorityReason
            }
            error={
              mySpecialAnalysis.priorityName ===
              WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
              !mySpecialAnalysis.priorityReason
            }
            helperText={
              mySpecialAnalysis.priorityName ===
                WORK_REQUEST_PRIORITIES_ENUM.RUSH &&
                !mySpecialAnalysis.priorityReason
                ? getMessage("REQUIRED_FIELD")
                : ""
            }
          />
        </div>

        <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <TextField
            size="small"
            sx={{ flex: 1 }}
            margin="none"
            variant="outlined"
            label="* Description"
            InputLabelProps={{ shrink: true }}
            multiline
            error={!!errorChecks.description && !mySpecialAnalysis.description}
            helperText={
              errorChecks.description && !mySpecialAnalysis.description
                ? errorChecks.description
                : ""
            }
            onChange={(e) =>
              setMySpecialAnalysis(() => ({
                ...mySpecialAnalysis,
                description: e.target.value,
              }))
            }
            value={
              mySpecialAnalysis.description === null
                ? ""
                : mySpecialAnalysis.description
            }
          />

          <EmailAddressTextField
            fontSize={16}
            margin={"none"}
            fieldWidth={"20%"}
            validatedUserEmail={
              mySpecialAnalysis.requestedByEmail === null
                ? ""
                : mySpecialAnalysis.requestedByEmail
            }
            setValidatedUserEmail={(value) => {
              setMySpecialAnalysis(() => ({
                ...mySpecialAnalysis,
                requestedByEmail: value,
              }));
            }}
            setHasErrors={() => {
              setErrorChecks((prev) => ({
                ...prev,
                requestedByEmail: "Invalid E-mail",
              }));
            }}
            hasErrors={
              errorChecks.requestedByEmail === null
                ? false
                : errorChecks.requestedByEmail
            }
            isDisabled={false}
            labelText="* Requester Email"
            placeholderText="* Requester Email"
            showPlusMeButton={true}
          />
        </div>
      </Box>
      <SubTitle>Add Comment</SubTitle>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8}>
          <StyledTextField
            multiline
            size="small"
            margin="normal"
            variant="outlined"
            label="Comment"
            InputLabelProps={{ shrink: true }}
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          md={4}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "6px",
          }}
        >
          <GlobalButton
            name="saveComment"
            variant="contained"
            onClick={() => {
              let timeStamp = new Date().toLocaleString();

              let commentWithDate = `${currentUser.username} (${timeStamp}):  ${newComment}`;
              let comment = mySpecialAnalysis.comments
                ? commentWithDate + "|" + mySpecialAnalysis.comments
                : commentWithDate;
              setMySpecialAnalysis(() => ({
                ...mySpecialAnalysis,
                comments: comment,
              }));
              setNewComment("");
            }}
            disabled={newComment === "" || !newComment}
          >
            Save Comment
          </GlobalButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={10} md={8}>
          <StyledTextField
            size="small"
            margin="normal"
            variant="outlined"
            label="Comments History"
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 500 }}
            multiline
            rows={4}
            value={
              mySpecialAnalysis.comments
                ? mountComments(mySpecialAnalysis.comments.split("|"))
                : ""
            }
            disabled={true}
          />
        </Grid>
      </Grid>

      <StyledDivider />

      <StyledDiv>
        <p style={subsectionTitle}>MIDAS Sample Info</p>
      </StyledDiv>

      <StyledDiv>
        <SpecialAnalysisSampleInfoTable
          mySpecialAnalysis={mySpecialAnalysis}
          setMySpecialAnalysis={setMySpecialAnalysis}
          locations={locations}
          ownerCanEdit={true}
          isProcessing={false}
        />
      </StyledDiv>
      <StyledDivider />

      <StyledDiv>
        <p style={subsectionTitle}>File Attachments</p>
      </StyledDiv>

      <FileDragAndDrop
        handleFiles={handleFiles}
        files={files.map((item) => item.file)}
      />
      <div
        style={{
          width: "50%",
          minWidth: "800px",
        }}
      >
        <UXDataTableWithoutBody
          tableWidth="100%"
          cols={FILE_ATTACHMENT_TABLE_COLUMNS}
          blankFirstHeader={true}
          tableId={`midasFileAttachments`}
          rowLength={files.length}
          enablePaging={false}
          rowsPerPage={files.length}
          page={0}
          handleChangePage={null}
          handleChangeRowsPerPage={null}
          noDataFoundMessage={"No Files Added"}
          enableAddIcon={false}
          addFunction={() => null}
          addToolTipText=""
          isDataLoading={false}
        >
          <StyledTableBody key={"myTablebody"}>
            {files.map((file, rowIndex) => {
              return (
                <StyledTableRow hover key={`custom-row-${rowIndex}`}>
                  <StyledTableCell component="th" scope="row">
                    <Tooltip title="Delete File" placement="right">
                      <TrashIcon onClick={() => deleteFile(file)}></TrashIcon>
                    </Tooltip>
                    {/* <Tooltip title="Download File" placement="right">
                      <DownloadIcon onClick={() => downloadFileBlob(file.file)}>
                      </DownloadIcon>
                    </Tooltip> */}
                  </StyledTableCell>
                  <StyledTableCell>{file.file.name}</StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>

      {(
        <NewSpecialAnalysisForm
          mySpecialAnalysis={mySpecialAnalysis}
          specialAnalysisErrorCheck={specialAnalysisErrorCheck}
          formik={formik}
          ownerCanEdit={true}
        />
      )}

      <FormControlLabel
        style={{ marginTop: "20px" }}
        label="Create associated On-site Transport work request"
        onChange={(e) =>
          setMySpecialAnalysis(() => ({
            ...mySpecialAnalysis,
            isCreateOnsiteTransportRequest: e.target.checked,
          }))
        }
        checked={mySpecialAnalysis.isCreateOnsiteTransportRequest}
        control={<Checkbox />}
      />

      <Box display="flex" paddingTop={1} paddingBottom={10} gap={2}>
        <GlobalButton name="btnSave" variant="contained" onClick={submit} disabled={loadingSubmit ? true : false}>
          Submit Special Analysis
        </GlobalButton>
        {loadingSubmit && <CircularProgress style={{ textTransform: "none", marginLeft: "15px" }}></CircularProgress>}

        <GlobalSecondaryButton
          name="btnSaveDraft"
          variant="contained"
          onClick={(e) => {
            SaveNewDraft();
          }}
        >
          Save As Draft
        </GlobalSecondaryButton>
      </Box>
      {/* </Box> */}

      {/* Informational Messages */}
      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label style={{ whiteSpace: "break-spaces" }}>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>

      <PrintInformation
        open={printInfoOpen}
        setOpen={setPrintInfoOpen}
        button2Action={closePrintInformation}
        button2Text="Close"
        printLabelInfo={'printLabelInfo'}
        isShelfLabel={false}
      />
    </div>
  );
};

export default NewSpecialAnalysis;
