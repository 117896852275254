import SDSRequest from "../../../../api/SDSRequest/SDSRequest";
import { SDSRequestState, SDSRequestUserRole } from "../types";

interface Transition {
  to: SDSRequestState;
  allowedRoles: SDSRequestUserRole[];
}

const stateTransitions: { [key in SDSRequestState]?: Transition[] } = {
  [SDSRequestState.Draft]: [
    {
      to: SDSRequestState.Submitted,
      allowedRoles: [SDSRequestUserRole.Creator, SDSRequestUserRole.Requester],
    },
    {
      to: SDSRequestState.Cancelled,
      allowedRoles: [SDSRequestUserRole.Creator, SDSRequestUserRole.Requester],
    },
  ],
  [SDSRequestState.Submitted]: [
    {
      to: SDSRequestState.Draft,
      allowedRoles: [SDSRequestUserRole.Creator, SDSRequestUserRole.Requester],
    },
    { to: SDSRequestState.Assigned, allowedRoles: [SDSRequestUserRole.Assigner] },
    {
      to: SDSRequestState.Cancelled,
      allowedRoles: [
        SDSRequestUserRole.Creator,
        SDSRequestUserRole.Requester,
        SDSRequestUserRole.Processor,
      ],
    },
  ],
  [SDSRequestState.Assigned]: [
    {
      to: SDSRequestState.Assigned,
      allowedRoles: [SDSRequestUserRole.Assigner],
    },
    {
      to: SDSRequestState.Submitted,
      allowedRoles: [SDSRequestUserRole.Assigner, SDSRequestUserRole.Processor],
    },
    { to: SDSRequestState.InProgress, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.OnHold, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.Completed, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.Cancelled, allowedRoles: [SDSRequestUserRole.Processor] },
  ],
  [SDSRequestState.InProgress]: [
    { to: SDSRequestState.Assigned, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.OnHold, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.Completed, allowedRoles: [SDSRequestUserRole.Processor] },
    { to: SDSRequestState.Cancelled, allowedRoles: [SDSRequestUserRole.Processor] },
  ],
  [SDSRequestState.OnHold]: [
    {
      to: SDSRequestState.Draft,
      allowedRoles: [SDSRequestUserRole.Creator, SDSRequestUserRole.Requester],
    },
    {
      to: SDSRequestState.Submitted,
      allowedRoles: [SDSRequestUserRole.Creator, SDSRequestUserRole.Requester],
    },
    { to: SDSRequestState.InProgress, allowedRoles: [SDSRequestUserRole.Processor] },
    {
      to: SDSRequestState.Cancelled,
      allowedRoles: [
        SDSRequestUserRole.Creator,
        SDSRequestUserRole.Requester,
        SDSRequestUserRole.Processor,
      ],
    },
  ],
};

export const useSdsRequestStateTransition = (
  request: SDSRequest,
  userRoles: SDSRequestUserRole[],
) => {
  const toRequestStatus = (state: string): SDSRequestState | undefined => {
    if (Object.values(SDSRequestState).includes(state as SDSRequestState)) {
      return state as SDSRequestState;
    }
    return undefined;
  };

  const canTransition = (nextState: SDSRequestState): boolean => {
    const currentStatus = toRequestStatus(request?.requestStatusName);
    if (!currentStatus) return false;

    const validTransitions = stateTransitions[currentStatus];
    if (!validTransitions) return false;

    return validTransitions.some(
      (transition) =>
        transition.to === nextState &&
        transition.allowedRoles.some((role) => userRoles.includes(role)),
    );
  };

  const getAvailableStatuses = (): SDSRequestState[] => {
    const currentStatus = toRequestStatus(request?.requestStatusName);
    if (!currentStatus) return [];

    const validTransitions = stateTransitions[currentStatus];
    if (!validTransitions) return [];

    return validTransitions
      .filter((transition) => transition.allowedRoles.some((role) => userRoles.includes(role)))
      .map((transition) => transition.to);
  };

  return { canTransition, getAvailableStatuses };
};
