import { Autocomplete, Divider, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getGlobalHyperLinkColor } from "../../../global";

export const autoCompleteFontSize = 16;
export const sampleGridFontSize = 12;

export const Title = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "22px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
});

export const StyledTextField = styled(TextField)({
  width: "100%",
});

export const SubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});

export const subsectionTitle = {
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
};

export const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

export const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export const Option = styled("li")({
  fontSize: sampleGridFontSize,
});

export const DescriptionLink = styled("span")({
  fontFamily: "EMprint",
  fontWeight: "600",
  textAlign: "left",
  fontSize: "14px",
  paddingRight: "20px",
  textTransform: "none",
  color: getGlobalHyperLinkColor(),
  textDecoration: "underline",
  cursor: "pointer",
});

export const defaultSpecialAnalysisSample = {
  specialAnalysis: null,
  sampleName: "",
  sample: null,
  requestedAmount: null,
  requestedAmountUoM: null,
  unitOfMeasure: null,
  newContainerID: null,
  existingContainerID: null,
  container: null,
  requestedLocationName: null,
  returnLocationName: null,
  location: null,
  deliveryLocationSublocation: "",
};

export const defaultSpecialAnalysis = {
  statusName: "Submitted",
  specialAnalysisStatusName: null,
  description: "",
  requestedByEmail: "",
  createdByEmail: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  assignedDate: null,
  inProgressDate: null,
  completedDate: null,
  priorityName: "Normal",
  specialAnalysisPriority: null,
  priorityReason: "",
  chargeCode: "",
  deliveryLocationName: null,
  specialAnalysisLocation: null,
  assignedToEmail: "",
  projectID: null,
  project: null,
  businessUnitName: null,
  businessUnit: null,
  specialAnalysisSamples: [defaultSpecialAnalysisSample],
  specialRequest: null,
  comments: null,
  completedByEmail: null,
};

export const defaultErrorChecksSpecialAnalysis = {
  businessUnitName: null,
  chargeCode: null,
  requestedByEmail: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
};

export const specialAnalysisErrors = {
  groupLeaderEmail: false,
  requestDescription: false,
  requestReason: false,
  testingRequirements: false,
};

export const defaultNewSpecialAnalysisObject = {
  id: 0,
  specialAnalysisID: 0,
  requestDescription: "",
  requestReason: "",
  testingRequirements: "",
  priority: 5,
  dataClassificationName: "",
};

export const defaultContainer = {
  sampleName: null,
  tests: null,
  containerNumber: 0,
  containerTypeName: "",
  containerType: null,
  containerStatusName: "Confirmed",
  containerStatus: null,
  cylinderSerialNumber: null,
  size: "",
  containerSizeUoM: "",
  uom: null,
  tareWeight: null,
  tareWeightUoM: null,
  currentAmount: "",
  ownerEmail: null,
  createdDate: null,
  createdByEmail: null,
  lastModifiedDate: null,
  lastModifiedByEmail: null,
  subLocation: null,
  expirationDate: null,
  nextDispositionDate: null,
  discardOnDisposition: false,
  locationName: "",
  location: null,
  parentID: null,
  parentContainer: null,
  returnLocationName: "",
  returnLocation: null,
};

export enum WORK_REQUEST_PRIORITIES_ENUM {
  RUSH = "Rush",
}

export const FILE_ATTACHMENT_TABLE_COLUMNS = [
  "File Name",
];

export const SPECIAL_ANALYSIS_TABLE_COLUMNS = [
  "MIDAS #",
  "Container",
  "Description",
  "Container Return Location",
];

export const copySpecialAnalysis = (specialAnalysisToCopy: any) => {
  delete specialAnalysisToCopy.id;
  delete specialAnalysisToCopy.specialRequest?.id;
  delete specialAnalysisToCopy.specialRequest?.specialAnalysisID;

  const specialAnalysis = {
    ...specialAnalysisToCopy,
    statusName: defaultSpecialAnalysis.statusName,
    createdByEmail: defaultSpecialAnalysis.createdByEmail,
    createdDate: null,
    comments: null,
    assignedToEmail: null,
    assignedDate: null,
    requestedCompletionDate: defaultSpecialAnalysis.requestedCompletionDate,
    lastAcceptableCompletionDate: defaultSpecialAnalysis.lastAcceptableCompletionDate,
    lastModifiedDate: defaultSpecialAnalysis.lastModifiedDate,
    lastModifiedByEmail: defaultSpecialAnalysis.lastModifiedByEmail,
    completedDate: defaultSpecialAnalysis.completedDate,
    completedByEmail: defaultSpecialAnalysis.completedByEmail,
    specialAnalysisSamples: specialAnalysisToCopy.specialAnalysisSamples.map((wrSample: any) => {
      delete wrSample.id;
      delete wrSample.specialAnalysisID;
      return {
        ...wrSample,
        newContainerID: null,
        newContainer: null,
        existingContainer: null,
      }
    })

  }
  return specialAnalysis;
}
