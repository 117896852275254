import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import { StyledScrollableBox } from "../../../styles";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../components/UXDataTable";
import { experimentProcessDetailsCols } from "../../../tableColumns";
import type { STRequestProcessResult } from "../../../types";
import { useDetailedStRequest } from "../../../context/DetailedStRequestContext";

type STRequestProcessResultTableProps = {
  stRequestProcessResults: STRequestProcessResult[];
  handleEdit(value: STRequestProcessResult): void;
  handleDelete(value: STRequestProcessResult): void;
};

export function STRequestProcessResultTable({
  stRequestProcessResults,
  handleEdit,
  handleDelete,
}: STRequestProcessResultTableProps) {
  const { canManageProcessResultDetails } = useDetailedStRequest();

  return (
    <Box display="grid" gridTemplateColumns="1fr" gap={2} mt={1}>
      <StyledScrollableBox>
        <Table>
          <StyledTableBody>
            <StyledTableRow>
              {experimentProcessDetailsCols.map((col) => (
                <StyledTableCell
                  key={`custom-header-${col}`}
                  style={{
                    verticalAlign: "middle",
                    border: "1px solid #F4F4F4",
                    textAlign: "left",
                    borderBottomColor: "#D2D2D2",
                    fontWeight: 600,
                    color: "rgba(0, 0, 0, 0.8)",
                  }}>
                  {col}
                </StyledTableCell>
              ))}
            </StyledTableRow>
            {stRequestProcessResults.map((ev, i) => {
              const result = ev?.resultText ? ev.resultText : (ev?.resultValue ?? "").toString();
              const resultComponentName =
                ev.processComponent?.processComponentName ?? ev.processComponentName;
              const key = i + resultComponentName + result + ev.resultUoMName;

              return (
                <StyledTableRow key={key}>
                  <StyledTableCell style={{ width: "40%" }}>{resultComponentName}</StyledTableCell>
                  <StyledTableCell style={{ width: "25%" }}>{result}</StyledTableCell>
                  <StyledTableCell style={{ width: "25%" }}>{ev.resultUoMName}</StyledTableCell>
                  <StyledTableCell style={{ width: "10%" }}>
                    <IconButton
                      type="button"
                      aria-label="Edit"
                      onClick={() => handleEdit(ev)}
                      style={{ cursor: canManageProcessResultDetails ? "pointer" : "not-allowed" }}
                      disabled={!canManageProcessResultDetails}>
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      type="button"
                      aria-label="Delete"
                      onClick={() => handleDelete(ev)}
                      style={{ cursor: canManageProcessResultDetails ? "pointer" : "not-allowed" }}
                      disabled={!canManageProcessResultDetails}>
                      <DeleteIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </StyledTableBody>
        </Table>
      </StyledScrollableBox>
    </Box>
  );
}
