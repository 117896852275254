import { Box } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import SpecialAnalysis from "../../api/SpecialAnalysis/SpecialAnalysis";
import SpecialAnalysisStatus from "../../api/SpecialAnalysis/SpecialAnalysisStatus";
import WorkRequestPriority from "../../api/WorkRequest/WorkRequestPriority";
import FilterMenu from "../../components/FilterMenu";
import UserContext from "../../context/UserContext";
import {
  applyFiltersToArray,
  convertDateFormat,
  exportToCsv,
} from "../../global";
import {
  GlobalButton,
  GlobalSecondaryButton,
  GlobalTabCollection,
  StyledTab,
} from "../styles";
import TableAllRequests from "./TableAllRequests";
import TableMyOpenRequests from "./TableMyOpenRequests";

const SpecialAnalyses = ({ copySpecialAnalysis }) => {
  const [tabValue, setTabValue] = useState(0);
  const [filteringOpen, setFilteringOpen] = useState(false);
  const [filters, setFilters] = useState([
    {
      name: null,
      displayName: null,
      operator: null,
      enumValues: [],
      value: "",
    },
  ]);

  const [allRequests, setAllRequests] = useState([]);
  const [filteredAllRequests, setFilteredAllRequests] = useState([]);
  const [isAllRequestsLoading, setIsAllRequestsLoading] = useState(true);

  const [myRequests, setMyRequests] = useState([]);
  const [isMyRequestsLoading, setIsMyRequestsLoading] = useState(true);
  const currentUser = useContext(UserContext);
  const userEmail = currentUser.username;

  const [requestStatuses, setRequestStatuses] = useState([]);
  const [requestPriorities, setRequestPriorities] = useState([]);

  const exportRef = useRef([]);

  const filterOptions = [
    { name: "id", displayName: "Request ID", type: "integer", enumValues: [] },
    {
      name: "description",
      displayName: "Description",
      type: "string",
      enumValues: [],
    },
    {
      name: "priorityName",
      displayName: "Priority",
      type: "enum",
      enumValues: requestPriorities,
      multiple: true,
    },
    {
      name: "assignedToEmail",
      displayName: "Assigned To",
      type: "string",
      enumValues: [],
    },
    {
      name: "requestedByEmail",
      displayName: "Requested By",
      type: "string",
      enumValues: [],
    },
    {
      name: "createdDate",
      displayName: "Requested Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "requestedCompletionDate",
      displayName: "Requested Completion Date",
      type: "date",
      enumValues: [],
    },
    {
      name: "statusName",
      displayName: "Status",
      type: "enum",
      enumValues: requestStatuses,
      multiple: true
    },
    {
      name: "chargeCode",
      displayName: "JADE #",
      type: "string",
      enumValues: [],
    }
  ]; //.sort((a,b) => a.displayName.localeCompare(b.displayName));

  const isAllRequestsInitLoading = isAllRequestsLoading && (!allRequests || allRequests.length === 0);

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysis.getMyOpenSpecialAnalysis(userEmail).then((res) => {
      if (cancelPromise) return;
      setMyRequests(res)
      setIsMyRequestsLoading(false);
    });

    return () => {
      cancelPromise = true;
    };
  }, [userEmail]);

  useEffect(() => {
    let cancelPromise = false;
    setIsAllRequestsLoading(true);

    function getAllRequestsDataRecr(currPage, allData = []) {
      if (cancelPromise) return allData;
      SpecialAnalysis.getAll(currPage, 200).then((res) => {
        if (res === null || res.length === 0) {
          setIsAllRequestsLoading(false);
          return allData;
        }
        else {
          allData = [...allData, ...res];
          currPage++;
          setAllRequests(allData.filter(item => item.statusName !== "Draft"))
          return getAllRequestsDataRecr(currPage, allData);
        }
      });
    }

    getAllRequestsDataRecr(0); //Recursively load all data

    return () => {
      cancelPromise = true;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps  
  }, []);

  useEffect(() => {
    const filteredArray = applyFiltersToArray(filters, allRequests)
    setFilteredAllRequests(filteredArray.filter(item => item.statusName !== "Draft"));
  }, [filters, allRequests]);

  useEffect(() => {
    let cancelPromise = false;

    SpecialAnalysisStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestStatuses(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.statusName)
          .sort()
      );
    });

    WorkRequestPriority.getAll().then((res) => {
      if (cancelPromise) return;
      setRequestPriorities(
        res
          .filter((result) => result.isActive === true)
          .map((item) => item.priorityName)
          .sort(),
      );
    });

    return () => {
      cancelPromise = true;
    };
  }, []);

  const handleChange = (event, newValue) => {
    clearFiltersClick();
    setTabValue(newValue);
  };

  function closeFiltering() {
    setFilteringOpen(false);
  }

  function applyFilters() {
    setFilteringOpen(false);
  }

  const filterClick = (event) => {
    setFilteringOpen(true);
  };

  const clearFiltersClick = (event) => {
    setFilters([
      {
        name: null,
        displayName: null,
        operator: null,
        enumValues: [],
        value: null,
      },
    ]);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        exportRef.current = applyFiltersToArray(filters, myRequests);;
        return (
          <TableMyOpenRequests
            filters={filters}
            myRequests={myRequests}
            isDataLoading={isMyRequestsLoading}
            userEmail={userEmail}
            isProcessing={false}
            copySpecialAnalysis={copySpecialAnalysis}
            isSpecialAnalysis={true}
          />
        );
      }
      case 1: {
        exportRef.current = filteredAllRequests;
        return (
          <TableAllRequests
            filters={filters}
            filteredAllRequests={filteredAllRequests}
            isDataLoading={isAllRequestsInitLoading}
            isProcessing={false}
            userEmail={userEmail}
            copySpecialAnalysis={copySpecialAnalysis}
            isSpecialAnalysis={true}
          />
        );
      }
      default: {
        alert(tabValue);
      }
    }
  };

  const renderFilterMenu = () => {
    switch (tabValue) {
      case 0: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }
      case 1: {
        return (
          <FilterMenu
            open={filteringOpen}
            setOpen={setFilteringOpen}
            applyBtnAction={applyFilters}
            cancelButtonAction={closeFiltering}
            filteringInfo={filterOptions}
            appliedFilters={filters}
            setAppliedFilters={setFilters}
          />
        );
      }

      case 2: {
        return <></>;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <div>
      <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }} display="flex">
        <GlobalTabCollection
          style={{ marginRight: "1rem" }}
          scrollButtons="auto"
          variant="scrollable"
          value={tabValue}
          onChange={handleChange}
          aria-label="ant example"
        >
          <StyledTab label="My Open Requests" />
          <StyledTab label="All Requests" />
        </GlobalTabCollection>

        <GlobalButton
          style={{ marginTop: "-.8rem" }}
          variant="contained"
          component={Link}
          to="/newSpecialAnalysis"
        >
          Create New Request
        </GlobalButton>

        <Box
          display="flex"
          alignItems={"center"}
          marginLeft="auto"
          marginTop="-.8rem"
        >
          <GlobalButton
            style={{ marginRight: "1rem" }}
            variant="contained"
            disabled={tabValue === 1 && isAllRequestsLoading}
            onClick={(e) =>
              exportToCsv(
                exportRef.current.map((item) => {
                  return {
                    ID: item.id,
                    Description: item.description,
                    Priority: item.priorityName,
                    Requester: item.requestedByEmail,
                    "Assigned To": item.assignedToEmail,
                    "Requested Date": convertDateFormat(item.createdDate, false),
                    "Requested Completion": convertDateFormat(item.requestedCompletionDate, false),
                    "Last Acceptable Date": convertDateFormat(item.lastAcceptableCompletionDate, false),
                    Status: item.statusName,
                    "JADE number": item.chargeCode
                  };
                }),
                "Special Analysis"
              )
            }
          >
            Export CSV
          </GlobalButton>
          <GlobalButton
            // disabled={tabValue > 1}
            style={{ marginRight: "1rem" }}
            variant="contained"
            onClick={() => filterClick()}
          >
            Filters
          </GlobalButton>

          {!(filters[0].name === null) && (
            <GlobalSecondaryButton
              variant="contained"
              onClick={() => clearFiltersClick()}
            >
              Clear Filters
            </GlobalSecondaryButton>
          )}
        </Box>
        <Box sx={{ p: 1 }} />
      </Box>
      {renderSearchTab()}
      {renderFilterMenu()}
      {/* Informational Messages */}
    </div>
  );


};

export default SpecialAnalyses;
