import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, FormControlLabel, InputLabel, TextField, Typography, Tooltip } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SpecialHandlingStatus from "../../../api/LIMS/SpecialHandlingStatus";
import ModalTwoButtons from '../../../components/Modal/ModalTwoButtons';
import { convertToLocalTime, listDefaultRetentionMonths } from "../../../global";

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'start',
  width: '100%',
  flexDirection: 'row',
}));

const StyledDivInputs = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '20%',
  gap: '20px'
}));

const StyledDivCheckbox = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
}));

const StyledBoxInputs = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  width: '90%'
}));


const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const StyledButton = styled(Button)(() => ({
  textTransform: 'none',
  height: '2.2rem',
  marginRight: '20px'
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  justifyContent: 'space-between',
}));

const StyledInputLabel = styled(InputLabel)(() => ({
  wordBreak: 'break-all',
  wordWrap: 'break-word',
  whiteSpace: 'normal;',
  textAlign: 'end',
  width: '39%'
}));

const SafetyInformation = ({
  currentSubstance,
  setCurrentSubstance,
  canEdit,
  fieldsCheck,
  validateFields,
  expandedPanel,
  setExpandedPanel
}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [listSpecialHandling, setListSpecialHandling] = React.useState([]);
  const [modalTwoButtonsOpen, setModalTwoButtonsOpen] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(expanded !== panel ? panel : false);
  };

  useEffect(() => {
    SpecialHandlingStatus.getAll().then((resp) => {
      setListSpecialHandling(resp)
    })
  }, [])

  function closeModalTwoButtonsOpen() {
    setModalTwoButtonsOpen(false);
  }

  function setIsValidated() {
    setCurrentSubstance(() => ({
      ...currentSubstance,
      isValidated: true
    }))
    closeModalTwoButtonsOpen()
  }

  function handleChangeFields(field, value) {

    setCurrentSubstance(prevState => ({
      ...prevState,
      isValidated: false,
      [field]: value
    }));

    validateFields(prevState => ({
      ...prevState,
      [field]: value === null || value === ""
    }));
  };

  function populateRetentionMonthValue(currentRetention) {
    if (currentRetention === null) {
      return ''
    }

    if (listDefaultRetentionMonths.includes(currentRetention)) {
      return currentRetention
    } else {
      return 'Custom'
    }
  }

  return (
    <Accordion expanded={expandedPanel === 'panel2'}>
      <StyledAccordionSummary
        expandIcon={<ExpandMoreIcon
         onClick = {() => setExpandedPanel(expandedPanel === 'panel2' ? null : 'panel2')}
        />}
        aria-controls="panel2bh-content"
        id="panel2bh-header"
      >
        <Typography variant="h5" sx={{ width: 'calc(100% - 15rem)', flexShrink: 0 }} onClick={handleChange('panel2')} >
          Safety Information
        </Typography>
        <StyledButton sx={{ width: "15rem" }}
          variant='contained'
          type="submit"
          onClick={() => setModalTwoButtonsOpen(true)}
          disabled={!canEdit}
        >Validate Safety Information</StyledButton>
      </StyledAccordionSummary>
      <StyledAccordionDetails>
        <StyledDiv>
          <StyledDivInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '120px' },
              }}
            >
              <TextField size="small" id="nfpa-h" label="NFPA H" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value;
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;
                  handleChangeFields('nfpah', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.nfpah){
                    handleChangeFields('nfpah', null);
                  }
                }}
                error={fieldsCheck.nfpah}
                helperText={fieldsCheck.nfpah ? 'Required' : ''}
                value={currentSubstance.nfpah === null ? '' : currentSubstance.nfpah}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                disabled={!canEdit}
              />
              <TextField size="small" id="hmis-h" label="HMIS H" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;
                  handleChangeFields('hmish', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.hmish){
                    handleChangeFields('hmish', null);
                  }
                }}
                error={fieldsCheck.hmish}
                helperText={fieldsCheck.hmish ? 'Required' : ''}
                value={currentSubstance.hmish === null ? '' : currentSubstance.hmish}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                disabled={!canEdit}
              />

            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '120px' },
              }}
            >
              <TextField size="small" id="nfpa-f" label="NFPA F" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;

                  handleChangeFields('nfpaf', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.nfpaf){
                    handleChangeFields('nfpaf', null);
                  }
                }}
                error={fieldsCheck.nfpaf}
                helperText={fieldsCheck.nfpaf ? 'Required' : ''}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                value={currentSubstance.nfpaf === null ? '' : currentSubstance.nfpaf}
                disabled={!canEdit}
              />
              <TextField size="small" id="hmis-f" label="HMIS F" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;

                  handleChangeFields('hmisf', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.hmisf){
                    handleChangeFields('hmisf', null);
                  }
                }}
                error={fieldsCheck.hmisf}
                helperText={fieldsCheck.hmisf ? 'Required' : ''}
                value={currentSubstance.hmisf === null ? '' : currentSubstance.hmisf}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                disabled={!canEdit}
              />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '120px' },
              }}
            >
              <TextField size="small" id="nfpa-r" label="NFPA R" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value 
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;
                  handleChangeFields('nfpar', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.nfpar){
                    handleChangeFields('nfpar', null);
                  }
                }}
                error={fieldsCheck.nfpar}
                helperText={fieldsCheck.nfpar ? 'Required' : ''}
                value={currentSubstance.nfpar === null ? '' : currentSubstance.nfpar}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                disabled={!canEdit}
              />
              <TextField size="small" id="hmis-r" label="HMIS R" variant="outlined"
                onChange={e => {
                  let value = e.target.value.replace(/[^0-9]/g, '').charAt(0);
                  value = value === "" ? null : value
                  let validValue = value > 4 ? 4 : value < 0 ? 0 : value;
                  handleChangeFields('hmisr', validValue);
                }}
                onBlur={() => {
                  if (!currentSubstance.hmisr){
                    handleChangeFields('hmisr', null);
                  }
                }}
                error={fieldsCheck.hmisr}
                helperText={fieldsCheck.hmisr ? 'Required' : ''}
                value={currentSubstance.hmisr === null ? '' : currentSubstance.hmisr}
                type="text"
                InputProps={{ inputProps: { min: 0, max: 4 } }}
                disabled={!canEdit}
              />

            </StyledBoxInputs>
          </StyledDivInputs>
          <StyledDivCheckbox>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '180px' },
              }}
            >
              <Tooltip title="Select Carcinogens">
                <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  onClick={e => handleChangeFields('isPHSCat1', !currentSubstance.isPHSCat1)}
                  checked={currentSubstance.isPHSCat1}
                  disabled={!canEdit}
                />} label="PHS Category 1" />
              </Tooltip>
              <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                onClick={e => handleChangeFields('isChronic', !currentSubstance.isChronic)}
                checked={currentSubstance.isChronic}
                disabled={!canEdit}
              />} label="Chronic" />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '180px' },
              }}
            >
              <Tooltip title="Reproductive toxins">
                <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  onClick={e => handleChangeFields('isPHSCat2', !currentSubstance.isPHSCat2)}
                  checked={currentSubstance.isPHSCat2}
                  disabled={!canEdit}
                />} label="PHS Category 2" />
              </Tooltip>
              <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                onClick={e => handleChangeFields('isPeroxidizable', !currentSubstance.isPeroxidizable)}
                checked={currentSubstance.isPeroxidizable}
                disabled={!canEdit}
              />} label="Peroxidizable" />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '180px' },
              }}
            >
              <Tooltip title="High acute toxicity">
                <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  onClick={e => handleChangeFields('isPHSCat3', !currentSubstance.isPHSCat3)}
                  checked={currentSubstance.isPHSCat3}
                  disabled={!canEdit}
                />} label="PHS Category 3" />
              </Tooltip>
              <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                onClick={e => handleChangeFields('isEngineeredNanoMaterial', !currentSubstance.isEngineeredNanoMaterial)}
                checked={currentSubstance.isEngineeredNanoMaterial}
                disabled={!canEdit}
              />} label="Engineered Nano Material" />
            </StyledBoxInputs>
            <StyledBoxInputs
              sx={{
                '& > :not(style)': { width: '180px' },
              }}
            >
              <Tooltip title="Sensitizer">
                <FormControlLabel control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  onClick={e => handleChangeFields('isPHSCat4', !currentSubstance.isPHSCat4)}
                  checked={currentSubstance.isPHSCat4}
                  disabled={!canEdit}
                />} label="PHS Category 4" />
              </Tooltip>
            </StyledBoxInputs>
          </StyledDivCheckbox>
          {currentSubstance.lastSafetyModifiedByEmail && <StyledInputLabel sx={{ position: "relative" }}>Last Verified By <b> {currentSubstance.lastSafetyModifiedByEmail}</b> on <b>{convertToLocalTime(new Date(currentSubstance.lastSafetyModifiedDate))}</b></StyledInputLabel>}

        </StyledDiv>
        <Autocomplete
          size="small"
          id="special-handing-select"
          disablePortal
          noOptionsText={"Loading Special Handing..."}
          options={listSpecialHandling.map((specialHandling) => specialHandling.specialHandlingName)}
          getOptionLabel={(option) => option}
          onChange={(e, value) => handleChangeFields('specialHandlingName', value)}
          autoHighlight
          autoSelect
          disabled={!canEdit}
          value={currentSubstance.specialHandlingName === '' ? null : currentSubstance.specialHandlingName}
          isOptionEqualToValue={(option, value) => value.specialHandlingName === option.specialHandlingName}
          renderInput={(params) => <TextField {...params} variant="outlined" size="small" label="Special Handing" error={false} InputProps={{ ...params.InputProps }} />}
          style={{ width: '260px' }}
        />
        <StyledBoxInputs
          sx={{
            '& > :not(style)': { width: '220px' },
          }}
        >
          <Autocomplete
            //disablePortal
            options={listDefaultRetentionMonths}
            getOptionLabel={(option) => option}
            onChange={(e, value) => handleChangeFields('smgDefaultRetentionMonths', value)}
            // onChange={(e, value) => setCurrentSample(() => ({
            //     ...currentSample,
            //     uom: value
            //     }))}
            // autoHighlight
            // autoSelect
            value={populateRetentionMonthValue(currentSubstance.smgDefaultRetentionMonths)}
            //value={currentSample.uom}
            isOptionEqualToValue={(option, value) => value === option}
            style={{ width: "260px" }}
            renderInput={(params) => <TextField {...params} variant="outlined" size="small" margin="none" label="Default Retention Months" InputLabelProps={{ shrink: true }} InputProps={{ ...params.InputProps }} />}
          />

          {/* <FormControl style={{ width: '260px' }} size="small">
            <InputLabel id="default-retention-months">Default Retention Months</InputLabel>
            <Select
              labelId="default-retention-months"
              id="default-retention-months-select"
              value={populateRetentionMonthValue(currentSubstance.smgDefaultRetentionMonths)}
              //value={currentSubstance.smgDefaultRetentionMonths === null ? '' : currentSubstance.smgDefaultRetentionMonths}
              label="Default Retention Months"
              onChange={e => handleChangeFields('smgDefaultRetentionMonths', e.target.value)}
              disabled={!canEdit}
            >
              <MenuItem value={null}></MenuItem>
              {
                listDefaultRetentionMonths.map((item, index) => {
                  return (
                    <MenuItem value={item} key={index}>{item}</MenuItem>
                  )
                })
              }
              <MenuItem value={'Custom'}>Custom</MenuItem>
            </Select>
          </FormControl> */}
          {populateRetentionMonthValue(currentSubstance.smgDefaultRetentionMonths) === 'Custom' && <TextField size="small" id="retention-months-custom" label="Retention Months Custom" variant="outlined"
            onChange={e => {
              const value = e.target.value;
              handleChangeFields('retentionMonthsCustom', value < 0 ? 0 : value)
            }
            }
            value={currentSubstance.retentionMonthsCustom === null ? '' : currentSubstance.retentionMonthsCustom}
            type="number"
            InputProps={{ inputProps: { min: 0 } }}
            disabled={!canEdit}
          />}
        </StyledBoxInputs>
      </StyledAccordionDetails>
      <ModalTwoButtons title={'Safety Validation'} button1Text={'Yes'} button1Action={setIsValidated} button2Text={'No'} button2Action={() => setCurrentSubstance({
        ...currentSubstance,
        isValidated: false
      },
        closeModalTwoButtonsOpen())}
        open={modalTwoButtonsOpen} setOpen={setModalTwoButtonsOpen}>
        Is this substance safety information valid?
      </ModalTwoButtons>
    </Accordion>
  );
};

export default SafetyInformation;