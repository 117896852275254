import { Box, Divider } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GlobalTabCollection, StyledTab } from "../../pages/styles";
import ComponentProcurement from "./ComponentProcurement/";
import { NewChemicalAuthorizationRequestContextProvider } from "./Context/NewChemicalAuthorizationRequestContext";
import ManageContainers from "./ManageContainers/";
import ManageSubstances from "./ManageSubstances";
import NewChemicalAuthorizationSubmission from "./NewChemicalAuthorization/SubmissionForm";
import PromoteSubstance from "./PromoteSubstance";
import SDS from "./SDS";
import SiteSafety from "./SiteSafety/SiteSafety";
import SMGCart from "./SMGCart";
import STLCManagement from "./STLCManagement";

const Inventory = ({ initialTabValue = 0 }) => {
  const { tab, view, id } = useParams();
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    switch (tab) {
      case "sds": {
        setTabValue(8);
        break;
      }
      case "substances": {
        setTabValue(1);
        break;
      }
      default: {
      }
    }
  }, [tab]);

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <ManageContainers></ManageContainers>;
      }
      case 1: {
        return <ManageSubstances></ManageSubstances>;
      }
      case 2: {
        return (
          <NewChemicalAuthorizationSubmission
            setTabValue={setTabValue}></NewChemicalAuthorizationSubmission>
        );
      }
      case 3: {
        return <ComponentProcurement></ComponentProcurement>;
      }
      case 4: {
        return <SiteSafety setTabValue={setTabValue}></SiteSafety>;
      }
      case 5: {
        return <SMGCart></SMGCart>;
      }
      case 6: {
        return <PromoteSubstance></PromoteSubstance>;
      }
      case 7: {
        return <STLCManagement></STLCManagement>;
      }
      case 8: {
        return <SDS view={view} id={id}></SDS>;
      }
      default: {
        console.log(tabValue);
      }
    }
  };
  return (
    <NewChemicalAuthorizationRequestContextProvider>
      <div>
        <span className="pageheader">Chemical Inventory and Safety</span>
        <Divider className="dividerbar" />
        <div>
          <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }}>
            <GlobalTabCollection
              value={tabValue}
              onChange={handleChange}
              aria-label="ant example"
              variant="scrollable"
              scrollButtons="auto">
              <StyledTab label="Manage Containers" value={0} />
              <StyledTab label="Manage Substances" value={1} />
              <StyledTab label="New Chemical Authorization" value={2} />
              {/* <StyledTab label="SDS Request" value={8} /> */}
              <StyledTab label="Component Procurement" value={3} />
              <StyledTab label="Site Safety" value={4} />
              <StyledTab label="SMG Postman" value={5} />
              <StyledTab label="Promote EC-Code" value={6} />
              <StyledTab label="STLC" value={7} />
            </GlobalTabCollection>
            <Box sx={{ p: 2 }} />
          </Box>
          {renderSearchTab()}
        </div>
      </div>
    </NewChemicalAuthorizationRequestContextProvider>
  );
};

export default Inventory;
