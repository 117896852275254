import {
  Checkbox,
  FormControlLabel,
  Menu,
  MenuItem,
  TableRow,
  TextField
} from '@mui/material';
import MoreOptions from "@mui/icons-material/MoreVert";
import { useEffect, useState } from 'react';
import SDSRequestIntendedUse from '../../../../api/SDSRequest/SDSRequestIntendedUse';
import { UXDataTableWithoutBody, StyledTableBody, StyledTableCell } from '../../../../components/UXDataTable';
import { GlobalButton } from '../../../styles';
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import ModalTwoButtons from "../../../../components/Modal/ModalTwoButtons";

const SdsRequestIntendedUseAdmin = () => {
  const [intendedUses, setIntendedUses] = useState([]);
  //const [showModal, setShowModal] = useState(false);
  const [currentUse, setCurrentUse] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('intendedUseName');
  const [showLoading, setShowLoading] = useState(false);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const [modalTwoButtonInfoOpen, setModalTwoButtonInfoOpen] = useState(false);
  const [modalTwoButtonInfoButton1Text, setModalTwoButtonInfoButton1Text] = useState('');
  const [modalTwoButtonInfoButton2Text, setModalTwoButtonInfoButton2Text] = useState('');
  const [modalTwoButtonInfoTitle, setModalTwoButtonInfoTitle] = useState('');
  const [modalTwoButtonInfoText, setModalTwoButtonInfoText] = useState('');

  const [useName, setUseName] = useState(currentUse ? currentUse.intendedUseName : '');
  const [isActive, setIsActive] = useState(currentUse ? currentUse.isActive : true);

  // const handleSubmit = () => {
  //   const use = { intendedUseName: useName, isActive };
  //   onSave(use);
  // };

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  function closeModalTwoButtonInfo() {
    setModalTwoButtonInfoOpen(false);
  }

  function openModalTwoButtonInfo(title, text, buttonText, button2Text) {
    setModalTwoButtonInfoButton1Text(buttonText)
    setModalTwoButtonInfoButton2Text(button2Text)
    setModalTwoButtonInfoOpen(true);
    setModalTwoButtonInfoTitle(title);
    setModalTwoButtonInfoText(text);
  }
  useEffect(() => {
    async function getData() {
      await fetchData();
    }
    getData();
  }, []);

  async function fetchData() {
    setShowLoading(true);
    const data = await SDSRequestIntendedUse.getAll();
    setIntendedUses(data);
    setShowLoading(false);
  }

  const handleEdit = (use) => {
    setCurrentUse(use);
    setUseName(use ? use.intendedUseName : '');
    setIsActive(use ? use.isActive : true);

    openModalTwoButtonInfo(currentUse ? 'Edit Intended Use' : 'Add Intended Use', "", "Update", "Close")
    //setShowModal(true);
    handleCloseMoreOptions();
  };

  const handleAddNew = () => {
    setCurrentUse(null);
    setUseName('');
    setIsActive(true);

    openModalTwoButtonInfo(currentUse ? 'Edit Intended Use' : 'Add Intended Use', "", "Save", "Close")
    //setShowModal(true);
  };

  const handleSave = () => {
    setShowLoading(true);
    const use = { intendedUseName: useName, isActive };

    if (currentUse) {
      SDSRequestIntendedUse.update(use).then((response) => {
        if (response.message === 'Success')
        {
          openModalMessages('Save Complete', `Changes saved successfully!`)
        } else {
          if (response.message)
          {
            openModalMessages('Settings Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('Settings Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
          }
        }
        closeModalTwoButtonInfo()
        //setShowModal(false);
        fetchData();
        setShowLoading(false);
      })    
    } else {
      SDSRequestIntendedUse.create(use).then((response) => {
        if (response.message === 'Success')
        {
          openModalMessages('Save Complete', `Changes saved successfully!`)
        } else {
          if (response.message)
          {
            openModalMessages('Settings Failed to Save', `${response ? response.message : ''}. Contact support if you feel this is an error.`);
          } else {
            openModalMessages('Settings Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
          }
        }
        closeModalTwoButtonInfo()
        // setShowModal(false);
        fetchData();
        setShowLoading(false);
      })     
    }

    // try {
    //   if (currentUse) {
    //     await SDSRequestIntendedUse.update(use);
    //   } else {
    //     await SDSRequestIntendedUse.create(use);
    //   }
    //   setShowModal(false);
    //   await fetchData();
    // } catch (error) {
    //   toast.error('Error saving intended use');
    // } finally {
    //   setShowLoading(false);
    // }
  };

  const columns = [
    { id: "", label: "", notSortable: true },
    { id: "intendedUseName", label: "Intended Use Name" },
    { id: "isActive", label: "Is Active" }
  ];

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest);
    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);
    setAnchorEl(null);
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArray = () => {
    return stableSort(intendedUses, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <>
      <GlobalButton variant="contained" onClick={handleAddNew} style={{ marginTop: "1rem", marginBottom: "1rem" }} >Add New</GlobalButton>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={columns}
        tableId="intendedUseTable"
        rowLength={intendedUses.length}
        enablePaging={true}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={showLoading ? 'Loading, please wait...' : 'No data found'}
        isDataLoading={showLoading}
      >
        <StyledTableBody>
          {determineStartArray().map((rowItem, index) => (
            <TableRow hover key={index}>
              <StyledTableCell component="th" scope="row" style={{ width: "10px" }}>
                <MoreOptions onClick={(e) => handleOpenMoreOptions(e, rowItem)} color="black" />
              </StyledTableCell>
              <StyledTableCell>{rowItem.intendedUseName}</StyledTableCell>
              <StyledTableCell>{rowItem.isActive ? 'Yes' : 'No'}</StyledTableCell>
            </TableRow>
          ))}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem
          key="mnuEdit"
          onClick={() => { handleEdit(moreOptionsSelected) }}
        >
          Edit
        </MenuItem>
      </Menu>

      {/* {showModal && (
        <IntendedUseModal
          show={showModal}
          onHide={() => setShowModal(false)}
          onSave={handleSave}
          intendedUse={currentUse}
          loading={showLoading}
        />
      )} */}

       {/* Informational Messages */}
      <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        {Array.isArray(modalMessagesText) ?
        (modalMessagesText && modalMessagesText.map((text, index) => {
            return (
                <div style={{display:"flex"}} key={`myErrorChecks${index}`}>
                    <label>
                    {text}
                    </label>
                </div>
            )
            })
        )
        :
        ( <label>
            {modalMessagesText}
          </label>
        )}      
      </ModalSimpleButton>

      <ModalTwoButtons title={modalTwoButtonInfoTitle} button1Text={modalTwoButtonInfoButton1Text} button1Action={handleSave} button2Text={modalTwoButtonInfoButton2Text} button2Action={closeModalTwoButtonInfo} open={modalTwoButtonInfoOpen} setOpen={setModalTwoButtonInfoOpen}>
        <label>
            {modalTwoButtonInfoText}
        </label>

        <div>                    
        <TextField
          margin="dense"
          label="Intended Use Name"
          type="text"
          fullWidth
          value={useName}
          onChange={(e) => setUseName(e.target.value)}
          disabled={!!currentUse}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isActive}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          }
          label="Is Active"
        />
        </div>
      </ModalTwoButtons>
    </>
  );
};

// const IntendedUseModal = ({ show, onHide, onSave, intendedUse, loading }) => {
//   const [useName, setUseName] = useState(intendedUse ? intendedUse.intendedUseName : '');
//   const [isActive, setIsActive] = useState(intendedUse ? intendedUse.isActive : true);

//   const handleSubmit = () => {
//     const use = { intendedUseName: useName, isActive };
//     onSave(use);
//   };

//   return (
//     <Dialog open={show} onClose={onHide}>
//       <DialogTitle>{intendedUse ? 'Edit Intended Use' : 'Add Intended Use'}</DialogTitle>
//       <DialogContent>
//         <TextField
//           margin="dense"
//           label="Intended Use Name"
//           type="text"
//           fullWidth
//           value={useName}
//           onChange={(e) => setUseName(e.target.value)}
//           disabled={!!intendedUse}
//         />
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={isActive}
//               onChange={(e) => setIsActive(e.target.checked)}
//             />
//           }
//           label="Is Active"
//         />
//       </DialogContent>
//       <DialogActions>
//         <GlobalButton
//           onClick={handleSubmit}
//           color="primary"
//           disabled={loading}
//         >
//           { loading ? 'Saving...' : 'Save' }
//         </GlobalButton>
//         <GlobalSecondaryButton
//           onClick={onHide}
//           color="primary"
//           disabled={loading}
//         >
//           Close
//         </GlobalSecondaryButton>
//       </DialogActions>
//     </Dialog>
//   );
// };

export default SdsRequestIntendedUseAdmin;