import React from "react";
import { styled } from "@mui/material/styles";
import API from "../src/api";
import * as XLSX from "xlsx";
import xmlbuilder from "xmlbuilder";
import TestConditionInstanceValue from "./api/LIMS/TestConditionInstanceValue";
import MethodFacility from "./api/LIMS/MethodFacility";
import Method from "./api/LIMS/Method";
import { PackageTestCriteriaApi } from "./api/Admin/PackageTestCriteria";
import Test from "./api/LIMS/Test";

//system general messages
export const globalMessages = {
  REQUIRED_FIELD: "This field is required",
  INVALID_VALUE: "This is not a valid value",
  NonSplitMsg:
    "A new container is created but all other test grouping parameters are ignored, except Site.",
  MidasMsg:
    "Let MIDAS do the work for you!  Test grouping will be determined by EXT facility, Requires Seperate Container, Drop Box Location, Container Grouping and Site.",
  existingMsg:
    "You do the work!  You can choose to add tests to an existing container (Existing Container), have the tests follow the regular MIDAS recommendation (Create from Parent) or a combination of both.",
};

const StyledBoldText = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  marginTop: "35px",
  textTransform: "none",
  textAlign: "left",
});

const StyledNormalText = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "400",
  fontSize: "16px",
  color: "#545459",
  marginTop: "35px",
  textTransform: "none",
  textAlign: "left",
});

//global hyperlink color
export function getGlobalHyperLinkColor() {
  return "blue";
}

export function isDate(value) {
  return value instanceof Date && !isNaN(value);
}

//converts the UTC time to the local users time for display purposes
export function convertToLocalTime(utcTime) {
  if (
    utcTime &&
    !(utcTime === "0001-01-01T00:00:00") &&
    new Date(utcTime) instanceof Date &&
    !isNaN(new Date(utcTime).valueOf())
  ) {
    var myDate = utcTime.toString().endsWith("Z")
      ? new Date(utcTime).toLocaleDateString()
      : new Date(utcTime + "Z").toLocaleDateString();
    var myTime = utcTime.toString().endsWith("Z")
      ? new Date(utcTime).toLocaleTimeString()
      : new Date(utcTime + "Z").toLocaleTimeString();

    return myDate + " " + myTime;
  } else {
    return "";
  }
}

export function convertDateFormat(utcTime, convertDateTimeToLocale = true) {
  if (utcTime && !(utcTime === "0001-01-01T00:00:00")) {
    var myDate;

    if (convertDateTimeToLocale === true) {
      myDate = utcTime.toString().endsWith("Z")
        ? new Date(utcTime).toLocaleDateString()
        : new Date(utcTime + "Z").toLocaleDateString();
    } else {
      myDate = new Date(utcTime).toLocaleDateString();
    }

    return myDate;
  } else {
    return "";
  }
}

export function convertCharacterSpecial(string) {
  string.replaceAll(" ", "%20");
  string.replaceAll("!", "%21");
  string.replaceAll("“", "%22");
  string.replaceAll("#", "%23");
  string.replaceAll("$", "%24");
  string.replaceAll("%", "%25");
  string.replaceAll("&", "%26");
  string.replaceAll("‘", "%27");
  string.replaceAll("*", "%2A");
  string.replaceAll("+", "%2B");
  string.replaceAll(",", "%2C");
  string.replaceAll("/", "%2F");
  string.replaceAll("=", "%3D");
  string.replaceAll("?", "%3F");
  return string;
}

//given the container array will calculate the total number of tests
export function numberOfTestsOnSample(myContainersArray) {
  var iTests = 0;

  if (myContainersArray) {
    for (let i = 0; i <= myContainersArray.length - 1; i++) {
      iTests += myContainersArray[i].tests.length;
    }
  }

  return iTests;
}

//capitalize the first letter of a word
export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

//format the midas number in the ##-###### format
export function formatMidasNumber(midasNumber) {
  let prettyMidasNumber = midasNumber;

  if (
    midasNumber &&
    midasNumber.length &&
    midasNumber.length === 10 &&
    !midasNumber.includes("-")
  ) {
    prettyMidasNumber = midasNumber.slice(0, 2) + "-" + midasNumber.slice(2, midasNumber.length);
  }
  return prettyMidasNumber;
}

//format the midas number in the ##-###### format
export function convertMIDASNumberToDatabaseFormat(midasNumber) {
  if (midasNumber === null || midasNumber === undefined || midasNumber.trim() === "") {
    return midasNumber;
  }

  let prettyMidasNumber;

  midasNumber = midasNumber.trim();
  midasNumber = midasNumber.replace(" ", "");

  let tokens = midasNumber.split("-");

  if (tokens.length === 1) {
    if (tokens[0].length === 13) {
      //new vs old barcode check
      //10001345000IW
      //1000001345000
      if (tokens[0].isInteger) {
        prettyMidasNumber = tokens[0].substring(0, 10);
      } else {
        prettyMidasNumber = `${tokens[0].substring(0, 2)}"00"${tokens[0].substring(2, 6)}`;
      }
    } else {
      if (tokens[0].isInteger && tokens[0].length !== 10) {
        const yearString = new Date().getFullYear().toString().substring(3, 4);
        prettyMidasNumber = `${yearString}${padLeadingZeros(tokens[0], 8)}`;
      } else {
        prettyMidasNumber = midasNumber;
      }
    }
  } else if (tokens.length === 2) {
    const yearString = tokens[0];
    prettyMidasNumber = `${yearString}${padLeadingZeros(tokens[1], 8)}`;
  } else {
    prettyMidasNumber = midasNumber;
  }

  return prettyMidasNumber;
}

function padLeadingZeros(num, size) {
  num = num.toString();
  while (num.length < size) num = "0" + num;
  return num;
}

export function isEmailValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function isNumber(number) {
  return !isNaN(parseFloat(number)) && isFinite(number);
}

export function RoundNumber(number, decimalPlaces, returnString = false) {
  if (returnString) {
    return (
      Math.round((Number(number) + Number.EPSILON) * 10 ** decimalPlaces) /
      10 ** decimalPlaces
    ).toFixed(decimalPlaces);
  } else {
    return +(
      Math.round((Number(number) + Number.EPSILON) * 10 ** decimalPlaces) /
      10 ** decimalPlaces
    ).toFixed(decimalPlaces);
  }
}

export function EvaluateOptionalNumberField(value) {
  if (value === null || value === "") {
    return false;
  } else {
    return !isNumber(value);
  }
}

export function EvaluateOptionalIntegerField(value) {
  if (value === null || value === "") {
    return false;
  } else {
    if (isNumber(value)) {
      return !Number.isInteger(Number(value));
    } else {
      return true;
    }
  }
}

export function EvaluateRequiredNumberField(value) {
  if (value === null || value === "") {
    return true;
  } else {
    return !isNumber(value);
  }
}

export function EvaluateRequiredIntegerField(value) {
  if (value === null || value === "") {
    return true;
  } else {
    if (isNumber(value)) {
      return !Number.isInteger(Number(value));
    } else {
      return true;
    }
  }
}

export function getMessage(key) {
  return globalMessages[key];
}

export function convertCelsiusToFahrenheit(celsius) {
  if (isNaN(+celsius)) {
    if (celsius?.includes("<")) {
      const strArr = celsius.split(" ");
      if (strArr[0] === "<") {
        strArr.reverse();
      }
      const value = strArr[0];
      return `< ${(+value * 1.8 + 32).toFixed()}`;
    } else if (celsius?.includes(">")) {
      const strArr = celsius.split(" ");
      if (strArr[0] === ">") {
        strArr.reverse();
      }
      const value = strArr[0];
      return `> ${(+value * 1.8 + 32).toFixed()}`;
    } else {
      return celsius;
    }
  }

  if (celsius === "") {
    return "";
  } else {
    return (+celsius * 1.8 + 32).toFixed();
  }
}

export function convertFahrenheitToCelsius(fahrenheit) {
  if (isNaN(+fahrenheit)) {
    if (fahrenheit?.includes("<")) {
      const strArr = fahrenheit.split(" ");
      if (strArr[0] === "<") {
        strArr.reverse();
      }
      const value = strArr[0];
      return `< ${(((+value - 32) * 5) / 9).toFixed()}`;
    } else if (fahrenheit?.includes(">")) {
      const strArr = fahrenheit.split(" ");
      if (strArr[0] === ">") {
        strArr.reverse();
      }
      const value = strArr[0];
      return `> ${(((+value - 32) * 5) / 9).toFixed()}`;
    } else {
      return fahrenheit;
    }
  }

  if (fahrenheit === "") {
    return "";
  } else {
    return (((+fahrenheit - 32) * 5) / 9).toFixed();
  }
}

export function generateUniqueID(a) {
  // eslint-disable-next-line no-mixed-operators
  return a
    ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16)
    : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, generateUniqueID);
}

export function exportToCsv(objList, csvName) {
  const rows = [];

  rows.push(Object.keys(objList[0]));

  objList.forEach(function (obj) {
    rows.push(
      Object.values(obj).map((item) => {
        if (item)
          return item
            .toString()
            .replaceAll("#", "")
            .replaceAll(",", "")
            .replaceAll(";", "")
            .replaceAll("\n", "")
            .replaceAll("\r", "");
        else return "";
      }),
    );
  });

  let csvContent = "data:text/csv;charset=utf-8,";

  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", csvName);
  document.body.appendChild(link);

  link.click();
}

export function exportToExcel(objList, excelName) {
  const rows = [];

  rows.push(Object.keys(objList[0]));

  objList.forEach(function (obj) {
    rows.push(
      Object.values(obj).map((item) => {
        if (item !== undefined && item !== null && item !== "") return item.toString();
        else return "";
      }),
    );
  });

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(rows);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, `${excelName}.xlsx`);
}

export function exportToXML(objList, xmlName) {
  const root = xmlbuilder.create("root");

  objList.forEach((item, index) => {
    const xmlItem = root.ele("item", { id: index });

    Object.keys(item).forEach((key) => {
      if (Array.isArray(item[key])) {
        const arrayElement = xmlItem.ele(key);
        item[key].forEach((subItem, subIndex) => {
          const xmlSubItem = arrayElement.ele(key.slice(0, -1), { id: subIndex });
          Object.keys(subItem).forEach((subKey) => {
            xmlSubItem.ele(subKey, subItem[subKey]);
          });
        });
      } else {
        xmlItem.ele(key, item[key]);
      }
    });
  });

  const blobXML = new Blob([root.end({ pretty: true })], { type: "application/xml" });

  const url = URL.createObjectURL(blobXML);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${xmlName}.xml`);
  document.body.appendChild(link);

  link.click();
}

export function applyFiltersToArray(filterOptions, arrayToFilter) {
  //used with the FilterMenu component
  var newArray = arrayToFilter;

  filterOptions.forEach((filterElement) => {
    if (filterElement?.name?.includes("Date") || filterElement?.name?.includes("date")) {
      const optionDateValue = new Date(filterElement?.value);
      if (filterElement.operator === "=") {
        const filteredArray = newArray.filter((item) => {
          const itemDateValue = new Date(item?.[filterElement?.name]);
          itemDateValue.setHours(0, 0, 0, 0);
          return optionDateValue.getTime() === itemDateValue.getTime();
        });
        newArray = filteredArray;
      } else if (filterElement.operator === ">") {
        const filteredArray = newArray.filter((item) => {
          const itemDateValue = new Date(item?.[filterElement?.name]);
          itemDateValue.setHours(0, 0, 0, 0);
          return optionDateValue.getTime() < itemDateValue.getTime();
        });
        newArray = filteredArray;
      } else if (filterElement.operator === "<") {
        const filteredArray = newArray.filter((item) => {
          const itemDateValue = new Date(item?.[filterElement?.name]);
          itemDateValue.setHours(0, 0, 0, 0);
          return optionDateValue.getTime() > itemDateValue.getTime();
        });
        newArray = filteredArray;
      }
    } else if (filterElement?.name?.includes(".")) {
      let propertySplit = filterElement?.name.split(".");

      if (propertySplit.length !== 2) {
        return newArray;
      }

      const propParent = propertySplit[0];
      const propChild = propertySplit[1];

      newArray = newArray.filter(
        (oArray) =>
          EvalNestObjectsForFiltering(
            oArray,
            propParent,
            propChild,
            filterElement.value,
            filterElement.operator,
          ) === true,
      );
    } else {
      if (filterElement.operator === "=") {
        if (filterElement.multiple)
          newArray = newArray.filter((oArray) =>
            filterElement.value.includes(oArray[filterElement.name]),
          );
        else
          newArray = newArray.filter(
            (oArray) =>
              (oArray[filterElement.name] !== null
                ? `${oArray[filterElement.name]}`?.toLowerCase()
                : oArray[filterElement.name]) === `${filterElement.value}`?.toLowerCase(),
          );
      } else if (filterElement.operator === "like") {
        newArray = newArray.filter((oArray) =>
          (oArray[filterElement.name] !== null
            ? `${oArray[filterElement.name]}`?.toLowerCase()
            : oArray[filterElement.name]
          )?.includes(`${filterElement.value}`?.toLowerCase()),
        );
      } else if (filterElement.operator === ">") {
        newArray = newArray.filter((oArray) => oArray[filterElement.name] > filterElement.value);
      } else if (filterElement.operator === "<") {
        newArray = newArray.filter((oArray) => oArray[filterElement.name] < filterElement.value);
      }
    }
  });

  return newArray;
}

function EvalNestObjectsForFiltering(object, propParent, propChild, valueToCheck, operator) {
  if (operator === "=") {
    if (object === null || object[propParent] === null || object[propParent][propChild] === null) {
      return false;
    } else if (`${object[propParent][propChild]}`.toLowerCase() === valueToCheck?.toLowerCase()) {
      return true;
    } else {
      return false;
    }
  } else if (operator === "like") {
    if (object === null || object[propParent] === null || object[propParent][propChild] === null) {
      return false;
    } else if (object[propParent][propChild]?.includes(valueToCheck.toLowerCase())) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function formatCreatedAndModifiedDateInfo(
  createdDate,
  createdBy,
  modifiedDate,
  modifiedBy,
  createdByLabel = "Created By",
) {
  return (
    <div style={{ display: "flex" }}>
      <StyledBoldText>{createdByLabel}:&nbsp;</StyledBoldText>
      <StyledNormalText>{createdBy}</StyledNormalText>
      <StyledBoldText>&nbsp;On&nbsp;</StyledBoldText>
      <StyledNormalText>{convertToLocalTime(createdDate)}</StyledNormalText>
      <StyledBoldText>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Modified By:&nbsp;</StyledBoldText>
      <StyledNormalText>{modifiedBy}</StyledNormalText>
      <StyledBoldText>&nbsp;On&nbsp;</StyledBoldText>
      <StyledNormalText>{convertToLocalTime(modifiedDate)}</StyledNormalText>
    </div>
  );
}

export const listDefaultRetentionMonths = [6, 12, 24, 36, 60, "Custom"];

export function hasRole(role, userRoles) {
  const providerAdfs = window.localStorage.getItem("AUTH_PROVIDER") === "Adfs";

  if (userRoles?.length > 0 || providerAdfs) {
    return (
      userRoles?.some((r) => r === role) || (RolesAdfs.some((ra) => ra === role) && providerAdfs)
    );
  } else return false;
}

export const RolesAdfs = [
  "ANALYST",
  "BATCH.PREPARER",
  "BLEND.MAINTAINER",
  "CONATINER.RECEIVER",
  "CONTAINER.EDITOR",
  "INSTRUMENT.ADMINISTRATOR",
  "QC.REPORTER",
  "QC.VIEWER",
  "TEST.ACTIVATOR",
  "STATIONARYTESTING.VALIDATOR",
  "STATIONARYTESTING.SUPPLEMENTALSAMPLE",
  "STATIONARYTESTING.PROCESSRESULT",
  "STATIONARYTESTING.INTERVALRESULT",
  "STATIONARYTESTING.EXPERIMENTPROCESSOR",
  "STATIONARYTESTING.SCHEDULEADMINISTRATOR",
  "STATIONARYTESTING.STANDADMINISTRATOR",
  "STATIONARYTESTING.PROCEDUREADMINISTRATOR",
];

//keep in alphabetical order
export const Roles = {
  Analyst: "ANALYST",
  BatchAssigner: "BATCH.ASSIGNER",
  BatchComponentSubstituter: "BATCH.COMPONENTSUBSTITUTER",
  BatchPreparer: "BATCH.PREPARER",
  BatchStatusMaintainer: "BATCH.STATUSMAINTAINER",
  BlendMaintainer: "BLEND.MAINTAINER",
  ComponentRequestAdministrator: "COMPONENTREQUEST.ADMINISTRATOR",
  ContainerDrummaintainer: "CONTAINER.DRUMMAINTAINER",
  ContainerReceiver: "CONTAINER.RECEIVER",
  ContainerEditor: "CONTAINER.EDITOR",
  Developer: "MIDAS.DEVELOPER",
  DistillationCorrector: "DISTILLATION.CORRECTOR",
  // DistillationExperimentMaintainer: "DISTILLATION.EXPERIMENTMAINTAINER",
  // DistillationTemplateMaintainer: "DISTILLATION.TEMPLATEMAINTAINER",
  EMBISUser: "EMBSIUSER",
  InstrumentAdministrator: "INSTRUMENT.ADMINISTRATOR",
  LotCertifier: "LOT.CERTIFIER",
  MasterDataAdmin: "MASTERDATA.ADMINISTRATOR",
  MasterDataAnalytical: "MASTERDATA.ANALYTICAL",
  MasterDataChemical: "MASTERDATA.CHEMICAL",
  MasterDataDistillation: "MASTERDATA.DISTILLATION",
  MasterDataFormulations: "MASTERDATA.FORMULATIONS",
  MasterDataSdsRequest: "MASTERDATA.SDSREQUEST",
  MasterDataStationaryTesting: "MASTERDATA.STATIONARYTESTING",
  MasterDataStockroom: "MASTERDATA.STOCKROOM",
  MasterDataWorkRequest: "MASTERDATA.WORKREQUEST",
  MasterDataProject: "MASTERDATA.PROJECT",
  MethodAdministrator: "METHOD.ADMINISTRATOR",
  NcaApprover: "NCA.APPROVER",
  PMUser: "PMUSER",
  ProcedureAdministrator: "STATIONARYTESTING.PROCEDUREADMINISTRATOR",
  ResultComponentAdministrator: "RESULTCOMPONENT.ADMINISTRATOR",
  SdsAssigner: "SDSREQUEST.ASSIGNER",
  SpecialAnalysisProcessor: "SPECIALANALYSIS.PROCESSOR",
  SpecialAnalysisAssigner: "SPECIALANALYSIS.ASSIGNER",
  SdsProcessor: "SDSREQUEST.PROCESSOR",
  StockroomAdmin: "STOCKROOM.ADMINISTRATOR",
  StockroomWorker: "STOCKROOM.WORKER",
  STLCMaintainer: "STLC.MAINTAINER",
  STScheduleAdministrator: "STATIONARYTESTING.SCHEDULEADMINISTRATOR",
  STStandAdministrator: "STATIONARYTESTING.STANDADMINISTRATOR",
  STValidator: "STATIONARYTESTING.VALIDATOR",
  STProcessResult: "STATIONARYTESTING.PROCESSRESULT",
  STIntervalResult: "STATIONARYTESTING.INTERVALRESULT",
  STSupplementalSample: "STATIONARYTESTING.SUPPLEMENTALSAMPLE",
  STExperimentProcessor: "STATIONARYTESTING.EXPERIMENTPROCESSOR",
  SQCAdministrator: "QC.ADMINISTRATOR",
  SQCReporter: "QC.REPORTER",
  SQCReviewer: "QC.REVIEWER",
  SQCViewer: "QC.VIEWER",
  SubstanceMaintainer: "SUBSTANCE.MAINTAINER",
  SubstanceSafetyMaintainer: "SUBSTANCE.SAFETYMAINTAINER",
  SubstanceStructureMaintainer: "SUBSTANCE.STRUCTUREMAINTAINER",
  TestActivator: "TEST.ACTIVATOR",
  TestReviewer: "TEST.REVIEWER",
  TSCAApprover: "TSCA.APPROVER",
  WorkRequestAssigner: "WORKREQUEST.ASSIGNER",
  WorkRequestFacilityAssigner: "WORKREQUEST.FACILITYASSIGNER",
  WorkRequestReporter: "WORKREQUEST.REPORTER",
  WorkRequestShippingCoordinator: "WORKREQUEST.SHIPPINGCOORDINATOR",
  WorkRequestTechnician: "WORKREQUEST.TECHNICIAN",
};

export async function getFileDownload(containerName, filePath) {
  const api = await API();
  const { data } = await api.get(
    `/fileDownload/?containerName=${containerName}&filePath=${filePath}`,
  );
  return data;
}

export function typeOfFile(fileName) {
  fileName = fileName.toLowerCase();

  if (fileName.includes(".png")) return "data:image/png;base64";
  else if (fileName.includes(".jpeg") || fileName.includes(".jpg")) return "data:image/jpeg;base64";
  else if (fileName.includes(".pdf")) return "data:application/pdf;base64";
  else if (fileName.includes(".txt")) return "data:application/txt;base64";
  else if (fileName.includes(".docx")) return "data:application/docx;base64";
  else if (fileName.includes(".xls")) return "data:application/txt;base64";
  else if (fileName.includes(".xlsx")) return "data:application/txt;base64";
  else if (fileName.includes(".xlsm")) return "data:application/txt;base64";
  else if (fileName.includes(".tif")) return "data:application/txt;base64";
  else if (fileName.includes(".rtf")) return "data:application/txt;base64";
  else return "data:application/txt;base64";
}

export async function downloadFile(containerName, fileName, fullPath, setModalOpen) {
  let filePath = "";
  let trimedFileName = fileName.trim();
  trimedFileName = trimedFileName.replace(/\?/g, "�"); //blob names have ? being replaced by unicode �
  //trimedFileName = convertCharacterSpecial(trimedFileName)

  if (fullPath.startsWith(`${"file:\\\\MFS:\\"}`)) {
    containerName = "midas-mfs";
    filePath = fullPath.split(`${"file:\\\\MFS:\\"}`);
    filePath[1] = `${filePath[1]}\\${trimedFileName}`;
  } else if (fullPath.startsWith(`${"File:\\\\MFS:\\"}`)) {
    containerName = "midas-mfs";
    filePath = fullPath.split(`${"File:\\\\MFS:\\"}`);
    filePath[1] = `${filePath[1]}\\${trimedFileName}`;
  } else {
    if (!fullPath.startsWith("https")) {
      if (setModalOpen) {
        setModalOpen(
          "File Cannot be Downloaded",
          `The legacy file located at "${fullPath}" cannot be downloaded because it was saved in an incorrect file share in MIDAS 2.  You will need to naviagte to the path manually to download.`,
        );
      }
      return;
    }

    filePath = fullPath.split(containerName);
  }
  //const splitFullPath = fullPath.split(containerName);

  //const response = await getFileDownload(containerName, filePath[1]);
  const response = await getFileDownload(containerName, encodeURIComponent(filePath[1]));

  if (response.message === "Success") {
    const base64String = response.result;

    const a = document.createElement("a"); //Create <a>

    let type = typeOfFile(trimedFileName);

    if (type === "unknown file type") {
      if (setModalOpen)
        setModalOpen(
          "File Failed to Download",
          `We did no recognize this file type, contact support if this continues.`,
        );
      return;
    }

    a.href = `${type},${base64String}`; //Image Base64 Goes here
    a.download = trimedFileName; //File name Here
    a.click(); //Downloaded file
  } else {
    if (setModalOpen) {
      setModalOpen(
        "File Failed to Download",
        `${response.message}. Contact support if you feel this is an error.`,
      );
    }
    return;
  }
}

export function ConvertUOMs(
  sampleObject,
  substanceObject,
  originalValue,
  originalUOMObject,
  convertToUOMObject,
) {
  if (!originalValue || !originalUOMObject || !convertToUOMObject) {
    return null;
  }

  if (convertToUOMObject === null) {
    convertToUOMObject = {
      uoMName: "g",
      type: "weight",
      metricStandardUoMName: "g",
      metricStandardConversion: 1,
    };
  }
  //make sure incoming value is numeric
  if (!isNumber(originalValue)) {
    return "error - original value is not a number";
  }

  let newValue = Number(originalValue);

  //same UOM, no need to convert
  if (originalUOMObject.uoMName === convertToUOMObject.uoMName) {
    return newValue;
  }

  //error check the UOMs
  if (
    originalUOMObject.metricStandardConversion === null ||
    originalUOMObject.metricStandardConversion === null
  ) {
    return "error - missing conversion info on original UOM";
  }

  //error check the UOMs
  if (
    convertToUOMObject.metricStandardConversion === null ||
    convertToUOMObject.metricStandardConversion === null
  ) {
    return "error - missing conversion info on convert to UOM";
  }

  const sampleDensity = sampleObject?.densityAtSTP;
  const substanceDensity = substanceObject?.densityAtSTPgml;

  let densityToUse = sampleDensity !== null && sampleDensity > 0 ? sampleDensity : substanceDensity;

  //make sure the density is set correctly
  if (!isNumber(densityToUse) || densityToUse <= 0) {
    densityToUse = 0.875;
  }

  //convert value to the metric standard (grams or mililiters)
  let originalMetricStandardValue = newValue * originalUOMObject.metricStandardConversion;

  //can only convert weight to volume and volume to weight
  if (originalUOMObject.type === "weight" && convertToUOMObject.type === "volume") {
    //convert the grams to ml
    let mililiterValue = originalMetricStandardValue / densityToUse;

    //convert the ml to the new volume uom
    newValue = mililiterValue / convertToUOMObject.metricStandardConversion;
  } else if (originalUOMObject.type === "volume" && convertToUOMObject.type === "weight") {
    //convert the ml to grams
    let gramValue = originalMetricStandardValue * densityToUse;

    //convert the ml to the new volume uom
    newValue = gramValue / convertToUOMObject.metricStandardConversion;
  } else if (originalUOMObject.type === convertToUOMObject.type) {
    //convert from metric standard to the requested UOM
    newValue = originalMetricStandardValue / convertToUOMObject.metricStandardConversion;
  } else {
    return "error - unknown UOM types";
  }

  return newValue;
}

// export const _PrintLabelSize = [
//   { name: "1x2", value: 0 },
//   { name: "3x4", value: 1 },
//   { name: "Drum", value: 2 },
// ];

// export const _PrintLabelType = [
//   { name: "Standard", value: 0 },
//   { name: "GHS", value: 1 },
//   { name: "Receipt", value: 2 },
//   { name: "Zinsser", value: 3 },
//   { name: "ChemicalOnly", value: 4 },
// ];

// export const _PrintLabelSizeType = [
//   { name: "1x2 Registration Label", value: 0 },
//   { name: "1x2 Inventory Label", value: 1 },
//   { name: "3x4 Registration Label", value: 2 },
//   { name: "3x4 Inventory Label", value: 3 },
//   { name: "3x4 GHS Label", value: 4 },
//   { name: "5x8 Drum Label", value: 5 }
// ];

export const printZpl = (zpl_String) => {
  var printWindow = window.open();
  printWindow.document.open("text/plain");
  printWindow.document.write(`<div>${zpl_String}</div>`);
  printWindow.document.body.style.wordBreak = "break-all";
  printWindow.document.close();

  //let browserString = navigator.userAgent;

  const { detect } = require("detect-browser");
  const browser = detect();

  //console.log(browser.name)

  switch (browser && browser.name) {
    case "chrome":
      printWindow.onload = () => {
        printWindow.focus();
        printWindow.print();

        printWindow.onafterprint = () => {
          printWindow.close();
        };
      };
      break;

    default:
      printWindow.focus();
      printWindow.print();
      printWindow.close();
  }

  // if (browserString.indexOf("Chrome") > -1)
  // {
  //   printWindow.onload = () => {
  //   printWindow.focus();
  //   printWindow.print();

  //   printWindow.onafterprint = () => {
  //     printWindow.close();
  //   }
  // };
  // } else {
  //   printWindow.focus();
  //   printWindow.print();
  //   printWindow.close();
  // }
};

export const isColor = (strColor) => {
  return /^#(([0-9A-Fa-f]{2}){3,4}|[0-9A-Fa-f]{3,4})$/.test(strColor);
};

export const toCamelCase = (object) => {
  var newO, origKey, newKey, value;
  if (object instanceof Array) {
    return object.map(function (value) {
      if (typeof value === "object") {
        value = toCamelCase(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in object) {
      if (object.hasOwnProperty(origKey)) {
        if (origKey === "ID") {
          newKey = "id";
        } else {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
        }

        value = object[origKey];
        if (value instanceof Array || (value !== null && value.constructor === Object)) {
          value = toCamelCase(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

export const displayHFRValue = (ChemId) => {
  const isChronic = (chemID) => {
    if (
      chemID.isChronic ||
      chemID.isPeroxidizable ||
      chemID.isPHSCat1 ||
      chemID.isPHSCat2 ||
      chemID.isPHSCat3 ||
      chemID.isPHSCat4
    )
      return "|*";
    return "";
  };

  if (ChemId.nfpah >= 0 && ChemId.nfpaf >= 0 && ChemId.nfpar >= 0)
    return `${ChemId?.nfpah ?? "-"}|${ChemId?.nfpaf ?? "-"}|${ChemId?.nfpar ?? "-"}${isChronic(
      ChemId,
    )}`;
  return "Null";
};

export const displayNfpaHmisHFRValue = (ChemId) => {
  const isChronic = (chemID) => {
    if (
      chemID.isChronic ||
      chemID.isPeroxidizable ||
      chemID.isPHSCat1 ||
      chemID.isPHSCat2 ||
      chemID.isPHSCat3 ||
      chemID.isPHSCat4
    )
      return "|*";
    return "";
  };

  let HFR = "";
  if (ChemId.nfpah >= 0 && ChemId.nfpaf >= 0 && ChemId.nfpar >= 0) {
    HFR = `${ChemId?.nfpah ?? "-"}|${ChemId?.nfpaf ?? "-"}|${ChemId?.nfpar ?? "-"}`;
  }
  if (ChemId.hmish >= 0 && ChemId.hmisf >= 0 && ChemId.hmisr >= 0) {
    HFR = `${HFR !== "" ? HFR + "|" : ""}${ChemId?.hmish ?? "-"}|${ChemId?.hmisf ?? "-"}|${
      ChemId?.hmisr ?? "-"
    }`;
  }
  return HFR !== "" ? `${HFR}${isChronic(ChemId)}` : "Null";
};

export const displayPHSValue = (chemId) => {
  if (chemId === null) {
    return "None";
  }
  let result = [];
  if (chemId.isPHSCat1) result.push("Carcinogen");
  if (chemId.isPHSCat2) result.push("Reproductive");
  if (chemId.isPHSCat3) result.push("Acute");
  if (chemId.isPHSCat4) result.push("Sensitizer");
  if (result.length === 0) return "None";

  let bungas = result.join(", ");
  return bungas;
};

export function RecalculateCurrentAmountOnUoMChange(
  sampleObj,
  substanceObj,
  currentAmount,
  startingUoMObj,
  toUoMObj,
) {
  let newCurrentAmount = currentAmount;

  if (currentAmount && currentAmount !== "" && startingUoMObj && toUoMObj) {
    newCurrentAmount = ConvertUOMs(
      sampleObj,
      substanceObj,
      currentAmount,
      startingUoMObj,
      toUoMObj,
    );
    //      newCurrentAmount = RoundNumber(ConvertUOMs(sampleObj, substanceObj, currentAmount, startingUoMObj, toUoMObj), 8)
  }

  return newCurrentAmount;
}

export function RecalculateInitialSizeOnUoMChange(
  sampleObj,
  substanceObj,
  initialSize,
  startingUoMObj,
  toUoMObj,
) {
  let newSize = initialSize;

  if (initialSize && initialSize !== "" && startingUoMObj && toUoMObj) {
    newSize = ConvertUOMs(sampleObj, substanceObj, initialSize, startingUoMObj, toUoMObj);
    //  newSize = RoundNumber(ConvertUOMs(sampleObj, substanceObj, initialSize, startingUoMObj, toUoMObj), 8)
  }

  return newSize;
}

export function CheckContainerCurrentAmount(oContainer) {
  if (isNumber(oContainer.currentAmount)) {
    if (isNumber(oContainer.size)) {
      if (Number(oContainer.size) < Number(oContainer.currentAmount)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function CheckContainerSize(oContainer) {
  if (!isNumber(oContainer.size)) {
    return true;
  }

  if (isNumber(oContainer.currentAmount)) {
    if (Number(oContainer.currentAmount) > Number(oContainer.size)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function InterpretChangeLogValue(value, decimalsToRound) {
  let testDate = new Date(value);

  if (isNumber(value)) {
    return RoundNumber(value, decimalsToRound, true);

    //need the starts with clause because there are sublocations that are in the format of "M-06" and this converts to a date correctly
  } else if (
    Object.prototype.toString.call(testDate) === "[object Date]" &&
    !isNaN(testDate) &&
    value !== null &&
    !value.startsWith("M-")
  ) {
    return convertToLocalTime(testDate);
  } else {
    return value;
  }
}

// export const convertToBase64_Blends = (file) => new Promise((resolve, reject) => {
//   const reader = new FileReader();

//   reader.readAsDataURL(file);
//   reader.onload = () => {
//     resolve(
//     reader.result.split(',')[1],
//     );
//   }
//   reader.onerror = reject;
// });

// export const convertBase64ToFile_Blends = (base64, fileName) => {
//   const byteCharacters = atob(base64);
//   //const byteCharacters = atob(file.base64);
//   const byteNumbers = new Array(byteCharacters.length);

//   for (let i = 0; i < byteCharacters.length; i++){
//     byteNumbers[i] = byteCharacters.charCodeAt(i);
//   }

//   const byteArray = new Uint8Array(byteNumbers);
//   const blob = new Blob([byteArray]);
//   //const blob = new Blob([byteArray], { type: file.type});

//   return new File([blob], fileName)
//   //return new File([blob], file.name, { type: file.type})
// };

export const convertToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve({
        base64: reader.result.split(",")[1],
        name: file.name,
        type: file.type,
      });
    };
    reader.onerror = reject;
  });

export const convertBase64ToFile = (file) => {
  const byteCharacters = atob(file.base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: file.type });

  return new File([blob], file.name, { type: file.type });
};

export function DatePickerKeyDownEvent(e) {
  if (e.key !== "Tab" && e.key !== "Delete" && e.key !== "Backspace") {
    e.preventDefault();
  }
}

export function SortCutNames(cutTypeName) {
  if (cutTypeName.includes("Charge")) {
    return 0;
  } else if (cutTypeName.includes("Trap")) {
    return 1;
  } else if (cutTypeName.includes("Cut")) {
    return 2;
  } else if (cutTypeName.includes("Resid")) {
    return 3;
  } else if (cutTypeName.includes("Blend")) {
    return 4;
  } else if (cutTypeName.includes("100N")) {
    return 5;
  } else if (cutTypeName.includes("150N")) {
    return 6;
  } else if (cutTypeName.includes("600N")) {
    return 7;
  } else {
    return 8;
  }
}

export function OrderTemplateCutList(cutList, cutTypesList) {
  if (cutTypesList.length === 0) {
    return [];
  }
  const list = [];
  const sortAlphaNum = (a, b) =>
    (a?.startingTemperatureC ? a?.startingTemperatureC : "0").localeCompare(
      b.startingTemperatureC ? b.startingTemperatureC : "0",
      "en",
      { numeric: true },
    );

  cutTypesList
    .sort(
      (a, b) => SortCutNames(a.distillationCutTypeName) - SortCutNames(b.distillationCutTypeName),
    )
    .forEach((oType) => {
      list[oType.distillationCutTypeName] = [];
    });

  cutList
    .sort(
      (a, b) => SortCutNames(a.distillationCutTypeName) - SortCutNames(b.distillationCutTypeName),
    )
    .forEach((cut) => {
      list[cut.distillationCutTypeName].push(cut);
    });

  const listArray = Object.values(list).map((x) => x);

  for (var a = 0; a < listArray.length; a++) {
    listArray[a].sort(sortAlphaNum);
    for (var b = 0; b < listArray[a].length; b++) {
      if (listArray[a][b]?.startingTemperatureC === "IBP") {
        const copy = listArray[a][b];
        listArray[a].splice(b, 1);
        listArray[a].unshift(copy);
      }
    }
  }

  return Object.values(listArray).flatMap((item) => item);
  //return listArray;
}

export function GetRequiredSampleSizeUoM(methodInfo) {
  return methodInfo.method?.isPackageTest
    ? methodInfo.method?.packageTestRequiredAmountUoMName
    : methodInfo.methodFacility?.requiredSampleSizeUoM;
}

export function GetRequiredSampleSize(methodInfo) {
  return methodInfo.method?.isPackageTest
    ? methodInfo.method?.packageTestRequiredAmount
    : methodInfo.methodFacility?.requiredSampleSize;
}

export async function BuildTestingPayload(
  oMethodInfo,
  containerToUse,
  containerIDToUse,
  chargeCode,
  testRequesterEmail,
) {
  let myFacility = null;
  let tests = [];
  let myTestConditionInstanceValues = [];

  if (oMethodInfo.method.isPackageTest === false) {
    myFacility = new MethodFacility({
      id: oMethodInfo.methodFacility.id,
      methodName: oMethodInfo.methodFacility.methodName,
      testFacilityAbv: oMethodInfo.methodFacility.testFacilityAbv,
      containeringGroup: oMethodInfo.methodFacility.containeringGroup,
      legacyTableID: oMethodInfo.methodFacility.legacyTableID,
      requiresEstimates: oMethodInfo.methodFacility.requiresEstimates,
      testOwnerEmail: oMethodInfo.methodFacility.testOwnerEmail,
      testCostUSD: oMethodInfo.methodFacility.testCostUSD,
      requiredSampleSize: oMethodInfo.methodFacility.requiredSampleSize,
      requiredSampleSizeUoM: oMethodInfo.methodFacility.requiredSampleSizeUoM,
      requiresConditions: oMethodInfo.methodFacility.requiresConditions,
      requiresSeparateContainer: oMethodInfo.methodFacility.requiresSeparateContainer,
      messageForSubmitter: oMethodInfo.methodFacility.messageForSubmitter,
      allowPrioritization: oMethodInfo.methodFacility.allowPrioritization,
      targetTurnaroundInDays: oMethodInfo.methodFacility.targetTurnaroundInDays,
      dropboxLocation: oMethodInfo.methodFacility.dropboxLocation,
      isSQCMethod: oMethodInfo.methodFacility.isSQCMethod,
    });

    if (oMethodInfo.conditionOffering !== null) {
      oMethodInfo.conditionOffering.testConditionSetupValues.forEach((oTCSV) => {
        var tempCondition = new TestConditionInstanceValue({
          testID: 0,
          testConditionName: oTCSV.testConditionName,
          testConditionValue: oTCSV.discreteValue,
          uomName: oTCSV.unitOfMeasure,
        });

        myTestConditionInstanceValues.push(tempCondition);
      });
    }

    const test = new Test({
      id: 0,
      containerID: containerIDToUse,
      container: containerToUse,
      containerSelectionType: oMethodInfo.containerSelectionType
        ? oMethodInfo.containerSelectionType
        : null,
      methodFacilityID: myFacility.id,
      methodFacility: myFacility,
      testSequenceNumber: -1,
      chargeCode: chargeCode,
      costCenter: null,
      testInstanceCostUSD: myFacility?.testCostUSD,
      lastModifiedByEmail: null,
      lastModifiedDate: null,
      testStatusName: null,
      submitterEmail: null,
      submittedDate: null,
      receivedDate: null,
      inProgressByEmail: null,
      inProgressDate: null,
      testedDate: null,
      reportedDate: null,
      analystEmail: null,
      inRetest: false,
      requestedCompletionDate: oMethodInfo.requestedCompletionDate,
      testPackageID: null,
      testComment: null,
      isFitForModeling: true,
      testEstimate: oMethodInfo.estimate,
      testPriorityName: oMethodInfo.testPriority.testPriorityName,
      testPriority: oMethodInfo.testPriority,
      requesterEmail: testRequesterEmail,
      testConditionInstanceValue: myTestConditionInstanceValues,
      externalLaboratory: oMethodInfo.externalLaboratory,
      externalLaboratoryName: null,
      testConditionOfferingID: oMethodInfo.conditionOffering
        ? oMethodInfo.conditionOffering.id
        : null,
      userDefinedTestGrouping: null,
    });

    tests.push(test);
  } else {
    var response = await Method.getPackageTestValidationStatus(oMethodInfo.method.name);

    if (response.message === "Success") {
      if (response.result.length > 0) {
        return response.result;
      }
    } else {
      return `Package test validation failed, please try again. ${
        response.message ? response.message : ""
      }`;
    }

    let myCriteria = await PackageTestCriteriaApi.getByPackageTestName(oMethodInfo.method.name);

    if (myCriteria) {
      const startingCriteria = myCriteria.filter(
        (criteria) => criteria.parentTestCriteriaID === null,
      );

      if (startingCriteria === null) {
        return `Error could not locate the starting method in the package test criteria`;
      }

      for (const oCriteria of startingCriteria) {
        if (oCriteria.methodFacilityID === null) {
          return `Criteria step is missing a method facility`;
        }

        if (oCriteria.testConditionOffering !== null) {
          oCriteria.testConditionOffering.testConditionSetupValues.forEach((oTCSV) => {
            if (oTCSV.discreteValue === null) {
              return `Test condition setup value ${oTCSV.testConditionOfferingID} for condition ${oTCSV.testConditionName} is missing a discrete value`;
            }

            var tempCondition = new TestConditionInstanceValue({
              testID: 0,
              testConditionName: oTCSV.testConditionName,
              testConditionValue: oTCSV.discreteValue,
              uomName: oTCSV.unitOfMeasure,
            });

            myTestConditionInstanceValues.push(tempCondition);
          });
        }

        const test = new Test({
          id: 0,
          containerID: containerIDToUse,
          container: containerToUse,
          containerSelectionType: oMethodInfo.containerSelectionType
            ? oMethodInfo.containerSelectionType
            : null,
          methodFacilityID: oCriteria.criteriaMethodFacility.id,
          methodFacility: oCriteria.criteriaMethodFacility,
          testSequenceNumber: -1,
          chargeCode: chargeCode,
          costCenter: null,
          testInstanceCostUSD: oCriteria.criteriaMethodFacility.testCostUSD,
          lastModifiedByEmail: null,
          lastModifiedDate: null,
          testStatusName: null,
          submitterEmail: null,
          submittedDate: null,
          receivedDate: null,
          inProgressByEmail: null,
          inProgressDate: null,
          testedDate: null,
          reportedDate: null,
          analystEmail: null,
          inRetest: false,
          requestedCompletionDate: oMethodInfo.requestedCompletionDate,
          testPackageID: oCriteria.id,
          testComment: null,
          isFitForModeling: true,
          testEstimate: oMethodInfo.estimate,
          testPriorityName: oMethodInfo.testPriority.testPriorityName,
          testPriority: oMethodInfo.testPriority,
          requesterEmail: testRequesterEmail,
          testConditionInstanceValue: myTestConditionInstanceValues,
          externalLaboratory: oMethodInfo.externalLaboratory,
          externalLaboratoryName: null,
          testConditionOfferingID: oCriteria.testConditionOfferingID,
          userDefinedTestGrouping: null,
        });

        tests.push(test);
      }
    } else {
      return `Error could not locate package test criteria for ${oMethodInfo.method.name}`;
    }
  }

  return tests;
}

export function isFlashPointValid(value) {
  return /^\d*([<>-]?\d*)?$/.test(value) || /^[0-9<>-]*$/.test(value) ? true : false;
}

export function convertFlashPoint(functionToConvert, value) {
  if (
    !functionToConvert ||
    value === undefined ||
    value === null ||
    value === "" ||
    !/\d/.test(value)
  )
    return value;

  const operator = value.match(/[<>]/);
  if (operator) {
    const numbers = value.split(operator);

    if (!numbers) return value;

    const first =
      parseInt(numbers[0]) || parseInt(numbers[0]) === 0
        ? functionToConvert(parseInt(numbers[0]))
        : numbers[0];
    const second =
      parseInt(numbers[1]) || parseInt(numbers[1]) === 0
        ? functionToConvert(parseInt(numbers[1]))
        : numbers[1];

    return `${first ?? ""}${operator ?? ""}${second ?? ""}`;
  } else {
    const regex = /(-?\d+)-(-?\d+)/;

    const numbers = value.match(regex);

    if (!numbers) return functionToConvert(parseInt(value));

    const first =
      parseInt(numbers[1]) || parseInt(numbers[1]) === 0
        ? functionToConvert(parseInt(numbers[1]))
        : numbers[1];
    const second =
      parseInt(numbers[2]) || parseInt(numbers[2]) === 0
        ? functionToConvert(parseInt(numbers[2]))
        : numbers[2];

    return `${first ?? ""}-${second ?? ""}`;
  }
}

export function formatConditionValue(values) {
  const keysToInclude = [
    { key: "maximumValue", text: "Max" },
    { key: "minimumValue", text: "Min" },
    { key: "discreteValue", text: "Discrete" },
    { key: "unitOfMeasure", text: "UoM" },
  ];

  if (values && values.length > 0) {
    const formattedValues = values
      .map((value) => {
        if (value.discreteValue != null && value.discreteValue !== "") {
          const uom = value.unitOfMeasure ? ` ${value.unitOfMeasure}` : "";
          return `Discrete : ${value.discreteValue}${uom}`;
        }

        const entries = Object.entries(value)
          .filter(
            ([key, val]) =>
              val !== null &&
              val !== undefined &&
              val !== "" &&
              keysToInclude.some((k) => k.key === key),
          )
          .map(([key, val]) => {
            return `${keysToInclude.find((k) => k.key === key)?.text}: ${val}`;
          });

        return entries.join(" ");
      })
      .filter((formatted) => formatted);

    return formattedValues.join(" | ");
  } else {
    return "";
  }
}

export function formatTestConditionInstanceValue(values) {
  if (values && values.length > 0) {
    let formattedValues = values
      .map((value) => {
        if (value.testConditionValue != null && value.testConditionValue !== "") {
          const uom = value.uoMName ? ` ${value.uoMName}` : "";
          return `${value.testConditionValue} ${uom}`;
        }

        return null;
      })
      .filter((val) => val);
    return formattedValues.join(" | ");
  } else {
    return "";
  }
}
export const removeMatchingProperties = (objectToReturn, propsToRemove) => {
  return Object.keys(objectToReturn).reduce((result, key) => {
    if (!(key in propsToRemove)) {
      result[key] = objectToReturn[key];
    }
    return result;
  }, {});
};

export const somethingIsDifferent = (newObject, oldObject) => {
  if (!oldObject || !newObject) return true;
  return Object.entries(newObject).some(([key, value]) => {
    const normalize = (value) =>
      Array.isArray(value) ? value.length : value === null || value === "" ? null : value;
    return normalize(value) !== normalize(oldObject[key]);
  });
};

export const formatParentTestLabel = (parentTest) => {
  if (!parentTest) return "";
  return `${formatMidasNumber(parentTest.sampleName)}/${parentTest.container?.containerNumber},${
    parentTest.testSequenceNumber
  }`;
};

export const featureToggle = {
  WorkRequestRedesign: process.env.REACT_APP_ENV === "Dev",
  SpecialAnalysis: true,
};

export const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "The Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo, Democratic Republic of the",
  "Congo, Republic of the",
  "Costa Rica",
  "Côte d’Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor (Timor-Leste)",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Eswatini",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "The Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, North",
  "Korea, South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Sudan, South",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
