import { useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import "./styles.css";
import { Box } from "@mui/system";
import { WorkRequestFormAutocomplete } from "../WorkRequestFormInput";
import { formatMidasNumber } from "../../../../global";

const CopyTestsSampleModal = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    mySamplesInfo,
    midasIdToHide
}) => {
    const [sampleToCopy, setSampleToCopy] = useState({
        sampleName: null,
        tests: null,
    });
    const [errors, setErrors] = useState({
        sampleName: null,
    });

    const commonProps = {
        setData: setSampleToCopy,
        data: sampleToCopy,
        setErrors,
        errors,
        margin: 'none',
    }

    const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true);

    function submit() {
        if (hasError()) {
            return;
        } else {
            setIsModalClose(mySamplesInfo.find(f => f.sample?.sampleName === sampleToCopy.sampleName)?.testSubmissionJSON);
        }
    }

    return <ModalTwoButtons
        title="Copy Tests From"
        button1Text={"Copy Tests"}
        button1Action={submit}
        isButton1Disabled={hasError()}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <form className="form-work-request-copy-tests">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="350px">
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    autoSelect={false}
                    options={mySamplesInfo.filter(f => f.sample?.sampleName !== midasIdToHide).map(sample => ({
                        midasNumber: formatMidasNumber(sample?.sample?.sampleName),
                        sampleName: sample?.sample?.sampleName
                    }))}
                    propertyLabel={'midasNumber'}
                    property={'sampleName'}
                    noOptionsText={"No Samples Found"}
                    label={`MIDAS #`}
                    required
                />
                
            </Box>
        </form>
    </ModalTwoButtons>
}
export default CopyTestsSampleModal
