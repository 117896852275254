export enum SDSRequestUserRole {
  Creator = "Creator",
  Requester = "Requester",
  Assigner = "Assigner",
  Processor = "Processor",
}

export enum SDSRequestState {
  Draft = "Draft",
  Submitted = "Submitted",
  Assigned = "Assigned",
  InProgress = "InProgress",
  OnHold = "OnHold",
  Completed = "Completed",
  Cancelled = "Cancelled",
}
