import SearchIcon from '@mui/icons-material/Search';
import { Box, CircularProgress, IconButton, InputBase, Paper, TableRow } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import SDSRequest from '../../../../api/SDSRequest/SDSRequest';
import { StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from '../../../../components/UXDataTable';
import { convertToLocalTime } from '../../../../global';

const SdsChangeLogView = () => {
  const [searchKey, setSearchKey] = useState('');
  const [changeLogs, setChangeLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const getChangeLogs = useCallback(async () => {
    setLoading(true);
    const result = await SDSRequest.getChangeLogs({ searchTerm: searchKey, skip: page * pageSize, take: pageSize });
    setChangeLogs(result.data);
    setTotalRecords(result.total);
    setLoading(false);
  }, [searchKey, page, pageSize]);

  useEffect(() => {
    getChangeLogs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  const handleSearch = () => {
    setPage(0);
    getChangeLogs();
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (e, newPageSize) => {
    setPageSize(newPageSize.props.value);
  };

  const convertPascalToSpaceSeparated = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const columns = [
    { id: 'primaryKeyValue', label: 'Request Id', notSortable: true },
    { id: 'dateChanged', label: 'Date Changed', notSortable: true },
    { id: 'propertyName', label: 'Property Name', notSortable: true },
    { id: 'oldValue', label: 'Old Value', notSortable: true },
    { id: 'newValue', label: 'New Value', notSortable: true },
    { id: 'changeMadeBy', label: 'Change Made By', notSortable: true },
    { id: 'status', label: 'Status', notSortable: true },
  ];

  return (
    <>
      <Box display="flex" justifyContent="center" mt={3} mb={3}>
        <Box display="flex" justifyContent="center" width="100%">
          <Paper
            component="form"
            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 500 }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ 'aria-label': 'search' }}
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
              onKeyDown={handleKeyDown}
              disabled={loading}
            />
            <IconButton
              type="button"
              sx={{ p: '10px' }}
              aria-label="search"
              onClick={handleSearch}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : <SearchIcon />}
            </IconButton>
          </Paper>
        </Box>
      </Box>

      <div style={{ height: 400, width: '100%' }}>        
        <UXDataTableWithoutBody
          tableId={"changeLogTable"}
          cols={columns}
          rowLength={totalRecords}
          enablePaging={true}
          enableSorteable={true}
          rowsPerPage={pageSize}
          page={page}
          handleChangePage={handlePageChange}
          handleChangeRowsPerPage={handlePageSizeChange}
          noDataFoundMessage={ loading ? 'Loading, please wait...' : 'No data found' }
          loading={loading}
        >
          <StyledTableBody>
            {changeLogs.map((rowItem, index) => (
              <TableRow key={index}>
                <StyledTableCell>{rowItem.primaryKeyValue}</StyledTableCell>
                <StyledTableCell>{convertToLocalTime(rowItem.dateChanged)}</StyledTableCell>
                <StyledTableCell>{convertPascalToSpaceSeparated(rowItem.propertyName)}</StyledTableCell>
                <StyledTableCell>{rowItem.oldValue}</StyledTableCell>
                <StyledTableCell>{rowItem.newValue}</StyledTableCell>
                <StyledTableCell>{rowItem.changeMadeBy}</StyledTableCell>
                <StyledTableCell>{rowItem.status}</StyledTableCell>
              </TableRow>
            ))}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
    </>
  );
};

export default SdsChangeLogView;