import API from "..";
import { checkAPIResponse } from "../helpers";

export default class ProjectUserApproval {
    constructor({
      id,
      projectID,
      projectName,
      userEmail,
      createdDate,
      createdByEmail,
      requestDescription,
      approvalStatus,
      completedByEmail
    }) {
      this.id = id;
      this.projectId = projectID;
      this.projectName = projectName;
      this.userEmail = userEmail;
      this.createdDate = createdDate;
      this.createdByEmail = createdByEmail;
      this.requestDescription = requestDescription;
      this.approvalStatus = approvalStatus;
      this.completedByEmail = completedByEmail;
    }

    static async getRequestsByOwner(ownerEmail) {
      const api = await API();
      const response = await api.get(`/projectapproval/byowner/${ownerEmail}`);
      checkAPIResponse(response);
      return response.data.result.map((d) => new ProjectUserApproval(d));
    }

    static async createProjectUserRequest(projectId, requestDescription){    
      const api = await API();         
      const response = await api.post('/projectapproval', {projectId, requestDescription});        
      checkAPIResponse(response);
      return response.data.result;
    }

    static async updateProjectUserRequest(id, isApproved){  
      console.log(isApproved);  
      const api = await API();         
      const response = await api.put(`/projectapproval/${id}`, {isApproved});        
      checkAPIResponse(response);
      return response.data.result;
    }
    
}