import { Box, CircularProgress, Alert } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import SDSRequestApi from "../../../../api/SDSRequest/SDSRequest";
import SDSRequestBusinessUnit from "../../../../api/SDSRequest/SDSRequestBusinessUnit";
import SDSRequestPriority from "../../../../api/SDSRequest/SDSRequestPriority";
import SDSRequestType from "../../../../api/SDSRequest/SDSRequestType";
import SDSRequestFileAttachment from "../../../../api/SDSRequest/SDSRequestFileAttachment";
import SDSRequestIntendedUse from "../../../../api/SDSRequest/SDSRequestIntendedUse";
import SDSRequestSyntheticIndicator from "../../../../api/SDSRequest/SDSRequestSyntheticIndicator";
import Project from "../../../../api/Admin/Project";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import FileDragAndDrop from "../../../../components/FileDragAndDrop";
import UserContext from "../../../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import {
  StyledTextField,
  StyledDiv,
  StyledDivider,
  SubTitle,
  defaultSDSRequest,
  SubSectionTitle,
  defaultErrors,
  labelProp,
} from "./constants";
import { SDSFormAutocomplete, SDSFormDate, SDSFormInput } from "./SDSFormInput";
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import ProductDevelopmentRelease from "./ProductDevelopmentRelease";
import ProductDevelopmentRevision from "./ProductDevelopmentRevision";
import Shipment from "./Shipment";
import BlindCoding from "./BlindCoding";
import SDSRequestStatus from "../../../../api/SDSRequest/SDSRequestStatus";
import toast from "react-hot-toast";
import { viewFormOrigin } from "../Backlog/constants";
import { requestStatusOptions } from "../constants";
import { convertToLocalTime, hasRole, Roles } from "../../../../global";
import { useSdsRequestStateTransition, useSdsRequestUserRoles } from "../hooks";

const SubmitSDS = ({ ...props }) => {
  const { request, origin, afterSubmit } = props;
  const [mySDSRequest, setMySDSRequest] = useState({ ...defaultSDSRequest });
  const [businessUnits, setBusinessUnits] = useState([]);
  const [businessUnitsLoading, setBusinessUnitsLoading] = useState(true);
  const [intendedUses, setIntendedUses] = useState([]);
  const [intendedUsesLoading, setIntendedUsesLoading] = useState(true);
  const [syntheticIndicators, setSyntheticIndicators] = useState([]);
  const [syntheticIndicatorsLoading, setSyntheticIndicatorsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [sdsRequestPriority, setSDSRequestPriority] = useState("");
  const [newComment, setNewComment] = useState("");
  const [sdsRequestPriorityLoading, setSDSRequestPriorityLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [sdsRequestType, setSDSRequestType] = useState([]);
  const [sdsRequestTypeLoading, setSDSRequestTypeLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [sdsRequestStatus, setSDSRequestStatus] = useState([]);
  const [sdsRequestStatusLoading, setSDSRequestStatusLoading] = useState(true);
  const [originalRequestStatus, setOriginalRequestStatus] = useState(requestStatusOptions.draft);
  const [requiredCommentAdded, setRequiredCommentAdded] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [customMessage, setCustomMessage] = useState("");
  const [autoPriorityRuleDays, setAutoPriorityRuleDays] = useState(0);
  const isNewRequest = origin === viewFormOrigin.newRequest;
  const isDraftRequest = request?.requestStatusName === requestStatusOptions.draft;
  const isDraftOrSubmittedRequest =
    request?.requestStatusName === requestStatusOptions.draft ||
    request?.requestStatusName === requestStatusOptions.submitted;
  const currentUser = useContext(UserContext);
  const roles = currentUser?.idTokenClaims.roles;
  const {
    sdsRequestUserRoles,
    canEdit: userCanEditRequest,
    canAssign: userCanAssignRequest,
    canProcess: userCanProcessRequest,
  } = useSdsRequestUserRoles(currentUser, request);

  const { getAvailableStatuses } = useSdsRequestStateTransition(request, sdsRequestUserRoles);

  const hasError = () => {
    const errs = bypassAssignedToEmailErrorIfNeeded();
    return Object.entries(errs).some(([key, value]) => value === null || value === true);
  };

  function updateErrors() {
    setErrors((prevErrors) => {
      const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
        acc[key] = prevErrors[key] === null ? true : prevErrors[key];
        return acc;
      }, {});

      return updatedErrors;
    });
  }

  const errorsToString = Object.entries(errors)
    .filter(([key, value]) => value === null || value === true)
    .map(([key]) => labelProp[key])
    .join("\n");

  const commonProps = {
    setData: setMySDSRequest,
    data: mySDSRequest,
    setErrors,
    errors,
  };

  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };

  useEffect(() => {
    let cancelPromise = false;

    Promise.all([
      SDSRequestType.getAllActive()
        .then((res) => {
          setSDSRequestType(
            res.map((type) => ({
              ...type,
              requestTypeName: type.typeName,
            })),
          );
          setSDSRequestTypeLoading(false);
        })
        .catch(() => {
          setSDSRequestTypeLoading(false);
        }),
      SDSRequestPriority.getAll()
        .then((res) => {
          setSDSRequestPriority(
            res.map((priority) => ({
              ...priority,
              priority: priority.priorityName,
            })),
          );
          setSDSRequestPriorityLoading(false);
        })
        .catch(() => {
          setSDSRequestPriorityLoading(false);
        }),
      SDSRequestBusinessUnit.getAllActive().then((res) => {
        if (cancelPromise) return;
        setBusinessUnitsLoading(false);
        setBusinessUnits(res.sort((a, b) => a.businessUnitName.localeCompare(b.businessUnitName)));
      }),
      SDSRequestIntendedUse.getAllActive().then((res) => {
        if (cancelPromise) return;
        setIntendedUsesLoading(false);
        setIntendedUses(res.sort((a, b) => a.intendedUseName.localeCompare(b.intendedUseName)));
      }),
      SDSRequestSyntheticIndicator.getAllActive().then((res) => {
        if (cancelPromise) return;
        setSyntheticIndicatorsLoading(false);
        setSyntheticIndicators(
          res.sort((a, b) => a.syntheticIndicatorName.localeCompare(b.syntheticIndicatorName)),
        );
      }),
      origin === viewFormOrigin.process
        ? Project.getAll().then((res) => {
            if (cancelPromise) return;
            setProjectsLoading(false);
            setProjects(
              res
                .map((project) => ({
                  ...project,
                  projectID: project.id,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            );
          })
        : Project.getByUser(currentUser.username).then((res) => {
            if (cancelPromise) return;
            setProjectsLoading(false);
            setProjects(
              res
                .map((project) => ({
                  ...project,
                  projectID: project.id,
                }))
                .sort((a, b) => a.name.localeCompare(b.name)),
            );
          }),
      SDSRequestStatus.getAll()
        .then((res) => {
          if (cancelPromise) return;
          setSDSRequestStatus(
            res.map((status) => ({
              ...status,
              requestStatusName: status.statusName,
            })),
          );
          setSDSRequestStatusLoading(false);
        })
        .catch(() => {
          setSDSRequestStatusLoading(false);
        }),
    ]).then(() => {});

    return () => {
      cancelPromise = true;
    };
  }, [origin, currentUser.username]);

  useEffect(() => {
    if (request) {
      setMySDSRequest(request);
      setOriginalRequestStatus(request.requestStatusName);
      SDSRequestFileAttachment.getAll(request.id)
        .then((res) => {
          setFiles(
            res.map((f) => ({
              file: {
                ...f,
                fileName: f.fileName,
                storagePath: f.storagePath,
                canDelete: false,
              },
            })),
          );
        })
        .catch(() => {
          toast.error("Could not load the request attachments");
        });

      // update errors based on data loaded from request
      setErrors((prevErrors) => {
        const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
          acc[key] = !(key in request && !!request[key]);
          return acc;
        }, {});
        return updatedErrors;
      });
    }
  }, [request]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const messageData = await SDSRequestApi.getSdsCustomMessage();
        setCustomMessage(messageData === "." ? "" : messageData);

        const daysData = await SDSRequestApi.getDaysAutoPriorityRule();
        setAutoPriorityRuleDays(daysData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  const countWorkdays = (startDate, endDate) => {
    let count = 0;
    let currentDate = new Date(startDate);

    currentDate.setDate(currentDate.getDate() + 1);

    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        count++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return count;
  };

  useEffect(() => {
    if (mySDSRequest.requestedCompletionDate && mySDSRequest.lastAcceptableCompletionDate) {
      const requestedDate = new Date(mySDSRequest.requestedCompletionDate);
      const lastAcceptableDate = new Date(mySDSRequest.lastAcceptableCompletionDate);
      const diffWorkdays = countWorkdays(requestedDate, lastAcceptableDate);

      if (diffWorkdays < autoPriorityRuleDays) {
        setMySDSRequest((prev) => ({ ...prev, priority: "Rush" }));
      }
    }
  }, [
    mySDSRequest.requestedCompletionDate,
    mySDSRequest.lastAcceptableCompletionDate,
    autoPriorityRuleDays,
  ]);

  const handleCanEdit = useCallback(() => {
    const finishedStatuses = ["Cancelled", "Completed"];
    const isRequestFinished = !!request && finishedStatuses.includes(originalRequestStatus);

    if (!origin || origin === viewFormOrigin.newRequest) {
      setCanEdit(true);
    }

    if (origin && origin === viewFormOrigin.process) {
      setCanEdit(
        !!request &&
          !isRequestFinished &&
          (hasRole(Roles.SdsProcessor, roles) || hasRole(Roles.Developer, roles)),
      );
    }

    if (origin && origin === viewFormOrigin.backlog) {
      setCanEdit(
        !!request &&
          !isRequestFinished &&
          (mySDSRequest.requestedByEmail === currentUser.username ||
            (mySDSRequest.requestStatusName === requestStatusOptions.draft &&
              mySDSRequest.createdByEmail === currentUser.username)),
      );
    }
  }, [
    origin,
    mySDSRequest.requestStatusName,
    request,
    roles,
    mySDSRequest.requestedByEmail,
    mySDSRequest.createdByEmail,
    currentUser.username,
    originalRequestStatus,
  ]);

  useEffect(() => {
    handleCanEdit();
  }, [canEdit, handleCanEdit]);

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f.file !== file);
    setFiles(newFiles);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }

  const prepareData = async (sdsRequest) => {
    const requestIsNotDraftOrSubmitted = ![
      requestStatusOptions.draft,
      requestStatusOptions.submitted,
    ].includes(sdsRequest.requestStatusName);
    const assignedToEmailIsEmpty = !sdsRequest.assignedToEmail;

    if (request) {
      if (
        request?.requestStatusName === requestStatusOptions.onHold &&
        [requestStatusOptions.draft, requestStatusOptions.submitted].includes(
          sdsRequest?.requestStatusName,
        )
      )
        sdsRequest.assignedToEmail = null;
      if (
        sdsRequest?.requestStatusName === requestStatusOptions.submitted &&
        sdsRequest?.assignedToEmail
      )
        sdsRequest.requestStatusName = requestStatusOptions.assigned;
      if (assignedToEmailIsEmpty && requestIsNotDraftOrSubmitted)
        sdsRequest.requestStatusName = requestStatusOptions.submitted;
    }

    return {
      ...sdsRequest,
      project: null,
      businessUnit: null,
      sdsRequestType: null,
      sdsRequestStatus: null,
      sdsRequestPriority: null,
    };
  };

  async function sendFiles(id) {
    if (files.length > 0) {
      let result = await Promise.all(
        files.map(async (fileObj) => {
          if (fileObj.file instanceof File) {
            const formData = new FormData();
            formData.append("file", fileObj.file);
            formData.append("sdsRequestID", id);
            let resp = await SDSRequestFileAttachment.postAttachs(formData);
            return resp;
          }
        }),
      );
      if (!!result.length) return true;
      else return false;
    }
    return true;
  }

  function finishedSubmit() {
    cancel();
    toast.success(`SDS Request ${!request ? "Created" : "Updated"} Successfully`);
    if (typeof afterSubmit === "function") afterSubmit();
  }

  function validateRequiredComment() {
    return (
      (originalRequestStatus === "Cancelled" || originalRequestStatus === "Completed") &&
      mySDSRequest.requestStatusName !== originalRequestStatus &&
      (mySDSRequest.requestStatusName !== "Cancelled" ||
        mySDSRequest.requestStatusName !== "Completed") &&
      !requiredCommentAdded
    );
  }

  function cancel() {
    setModalMessagesButtonText("Ok");
    setMySDSRequest(defaultSDSRequest);
    setOriginalRequestStatus(null);
    setRequiredCommentAdded(false);
    setErrors(defaultErrors);
    setFiles([]);
    setNewComment("");
    if (props.reloadList) {
      setMySDSRequest(defaultSDSRequest);
      props.reloadList();
    }
  }

  const renderInputsByType = () => {
    switch (mySDSRequest.requestTypeName) {
      case "ProductDevelopmentRelease":
        return (
          <ProductDevelopmentRelease
            commonProps={commonProps}
            disabled={!userCanEditRequest}
            syntheticIndicators={syntheticIndicators}
            syntheticIndicatorsLoading={syntheticIndicatorsLoading}
          />
        );
      case "ProductDevelopmentRevision":
        return (
          <ProductDevelopmentRevision commonProps={commonProps} disabled={!userCanEditRequest} />
        );
      case "BlindCoding":
        return <BlindCoding commonProps={commonProps} disabled={!userCanEditRequest} />;
      case "Shipment":
        return <Shipment commonProps={commonProps} disabled={!userCanEditRequest} />;
      default:
        return <></>;
    }
  };

  const bypassAssignedToEmailErrorIfNeeded = () => {
    const requestIsSubmitedOrAssigned = [
      requestStatusOptions.submitted,
      requestStatusOptions.assigned,
    ].includes(request?.requestStatusName);
    const assignedToEmailIsEmpty = !mySDSRequest.assignedToEmail;

    if (assignedToEmailIsEmpty && requestIsSubmitedOrAssigned) {
      const { assignedToEmail, ...otherErrors } = errors;
      return otherErrors;
    } else {
      return errors;
    }
  };

  const isValidRequest = () => {
    if (mySDSRequest)
      if (hasError()) {
        updateErrors();
        openModalMessages(
          "Missing information!",
          "There are required fields missing:\n\n" + errorsToString,
        );
        return false;
      }

    if (validateRequiredComment()) {
      openModalMessages(
        "Missing information!",
        "You have to add a new comment when re-opening a request.",
      );
      return false;
    }
    return true;
  };

  const saveRequest = async () => {
    if (mySDSRequest?.requestStatusName !== "Draft" && !isValidRequest()) {
      mySDSRequest.requestStatusName = originalRequestStatus;
      return;
    }

    setLoadingSubmit(true);
    const data = await prepareData(mySDSRequest);
    if (!request)
      SDSRequestApi.createSDSRequest(data)
        .then(async (res) => {
          if (res?.message !== "Success") {
            openModalMessages(
              "Fail",
              `Something went wrong creating the SDS Requests, please try again later. ${
                res?.message ? res?.message : ""
              }`,
              "Ok",
            );
            setLoadingSubmit(false);
          } else {
            const sdsRequest = res.result;
            let filesSent = await sendFiles(sdsRequest.id);

            if (filesSent) finishedSubmit(true);

            setLoadingSubmit(false);
          }
        })
        .catch((err) => {
          setLoadingSubmit(false);
          openModalMessages(
            "Fail",
            `Something went wrong creating the SDS Requests, please try again later. ${
              err?.message ? err?.message : ""
            }`,
            "Ok",
          );
        });
    else
      SDSRequestApi.updateSDSRequest(data)
        .then(async (res) => {
          if (res?.message !== "Success") {
            openModalMessages(
              "Fail",
              `Something went wrong while updating the SDS Request, please try again later. ${
                res?.message ? res?.message : ""
              }`,
              "Ok",
            );
            setLoadingSubmit(false);
          } else {
            const sdsRequest = res.result;
            let filesSent = await sendFiles(sdsRequest.id);
            if (filesSent) finishedSubmit(true);
            setLoadingSubmit(false);
          }
        })
        .catch((err) => {
          setLoadingSubmit(false);
          openModalMessages(
            "Fail",
            `Something went wrong creating the SDS Requests, please try again later. ${
              err?.message ? err?.message : ""
            }`,
            "Ok",
          );
        });
  };

  const showSaveButton =
    isNewRequest || userCanEditRequest || userCanAssignRequest || userCanProcessRequest;

  const handleSaveClick = () => {
    saveRequest();
  };

  const showSubmitButton =
    isNewRequest ||
    ((userCanEditRequest || userCanAssignRequest || userCanProcessRequest) &&
      [requestStatusOptions.draft, requestStatusOptions.onHold].includes(
        request?.requestStatusName,
      ));

  const handleSubmitClick = () => {
    mySDSRequest.requestStatusName = requestStatusOptions.submitted;
    saveRequest();
  };

  const showConvertToDraftButton =
    userCanEditRequest &&
    [requestStatusOptions.submitted, requestStatusOptions.onHold].includes(
      request?.requestStatusName,
    );

  const handleConvertToDraftClick = () => {
    mySDSRequest.requestStatusName = requestStatusOptions.draft;
    saveRequest();
  };

  return (
    <div>
      {customMessage && customMessage.trim() && (
        <Alert severity="warning" style={{ marginBottom: "1rem", whiteSpace: "pre-line" }}>
          {customMessage}
        </Alert>
      )}
      {request && (origin === viewFormOrigin.process || origin === viewFormOrigin.backlog) && (
        <Box style={{ width: "100%" }}>
          <div
            style={{
              display: "grid",
              gap: "1rem",
              height: 60,
              gridTemplateColumns: "1fr 3fr 4fr",
            }}>
            <SDSFormInput {...commonProps} disabled label={labelProp["id"]} property="id" />

            <SDSFormAutocomplete
              {...commonProps}
              setData={(data) => {
                commonProps.setData(data);
              }}
              options={sdsRequestStatus
                .filter(
                  (s) =>
                    s.statusName === request?.requestStatusName ||
                    (s.isActive && getAvailableStatuses().includes(s.statusName)),
                )
                .sort((a, b) => a.displayOrder - b.displayOrder)}
              property={"requestStatusName"}
              noOptionsText={sdsRequestStatusLoading ? "Loading Statuses..." : "No Status Found"}
              label={labelProp["requestStatusName"]}
              disabled={isDraftOrSubmittedRequest || !userCanProcessRequest}
              autoSelect={false}
            />

            <div style={{ marginBottom: "20px" }}>
              <EmailAddressTextField
                fontSize={16}
                validatedUserEmail={
                  !mySDSRequest.assignedToEmail ? "" : mySDSRequest.assignedToEmail
                }
                setValidatedUserEmail={(value) => {
                  setMySDSRequest(() => ({ ...mySDSRequest, assignedToEmail: value }));
                }}
                setHasErrors={(error) => {
                  setErrors((prev) => ({ ...prev, assignedToEmail: error }));
                }}
                bypassValidation={true}
                hasErrors={
                  [
                    requestStatusOptions.draft,
                    requestStatusOptions.submitted,
                    requestStatusOptions.assigned,
                  ].includes(request?.requestStatusName) && !mySDSRequest.assignedToEmail
                    ? false
                    : errors.assignedToEmail === null
                    ? false
                    : errors.assignedToEmail
                }
                labelText={labelProp["assignedToEmail"]}
                showPlusMeButton={true}
                isDisabled={isDraftRequest || !userCanAssignRequest}
                disabled={isDraftRequest || !userCanAssignRequest}
              />
            </div>
          </div>
        </Box>
      )}

      <Box style={{ width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr",
            gap: "1rem",
            height: 60,
          }}>
          <SDSFormAutocomplete
            {...commonProps}
            disabled={!userCanEditRequest}
            options={projects}
            property={"projectID"}
            propertyLabel={"name"}
            noOptionsText={projectsLoading ? "Loading Projects..." : "No Projects Found"}
            label={labelProp["projectID"]}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={!userCanEditRequest}
            options={sdsRequestType}
            property={"requestTypeName"}
            propertyLabel={"requestTypeName"}
            noOptionsText={sdsRequestTypeLoading ? "Loading Types..." : "No Types Found"}
            label={labelProp["requestTypeName"]}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={!userCanEditRequest}
            options={businessUnits}
            property={"businessUnitName"}
            noOptionsText={
              businessUnitsLoading ? "Loading Business Units..." : "No Business Units Found"
            }
            label={labelProp["businessUnitName"]}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={!userCanEditRequest}
            options={intendedUses}
            styled={{ marginBottom: 0 }}
            noOptionsText={
              intendedUsesLoading ? "Loading Intended Use..." : "No Intended Use Found"
            }
            property={"intendedUseName"}
            label={labelProp["intendedUseName"]}
            required
          />

          <SDSFormDate
            {...commonProps}
            disabled={!userCanEditRequest}
            property={"requestedCompletionDate"}
            label={labelProp["requestedCompletionDate"]}
            minDate={new Date()}
            maxDate={
              mySDSRequest.lastAcceptableCompletionDate
                ? new Date(mySDSRequest.lastAcceptableCompletionDate)
                : null
            }
            required
          />

          <SDSFormDate
            {...commonProps}
            disabled={!userCanEditRequest}
            property={"lastAcceptableCompletionDate"}
            label={labelProp["lastAcceptableCompletionDate"]}
            minDate={new Date(mySDSRequest.requestedCompletionDate || new Date())}
            required
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
            height: 60,
          }}>
          <SDSFormAutocomplete
            {...commonProps}
            disabled={!userCanEditRequest}
            setData={(data) => {
              if (data?.priority === "Rush") {
                setErrors({
                  ...errors,
                  priorityReason: null,
                });
              } else {
                delete errors.priorityReason;
              }
              commonProps.setData(data);
            }}
            options={sdsRequestPriority}
            property={"priority"}
            noOptionsText={
              sdsRequestPriorityLoading ? "Loading Priorities..." : "No Priorities Found"
            }
            label="Priority"
          />

          <SDSFormInput
            {...commonProps}
            disabled={!userCanEditRequest}
            label={labelProp["priorityReason"]}
            property="priorityReason"
            required={"priorityReason" in errors}
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 2fr",
            gap: "1rem",
            height: 60,
          }}>
          <SDSFormInput
            {...commonProps}
            disabled={!userCanEditRequest}
            label={labelProp["description"]}
            property="description"
            required
          />

          <EmailAddressTextField
            fontSize={16}
            margin={"none"}
            style={{ marginTop: "9px" }}
            validatedUserEmail={
              mySDSRequest.requestedByEmail === null ? "" : mySDSRequest.requestedByEmail
            }
            setValidatedUserEmail={(value) => {
              setMySDSRequest(() => ({
                ...mySDSRequest,
                requestedByEmail: value,
              }));
            }}
            setHasErrors={(error) => {
              setErrors((prev) => ({
                ...prev,
                requestedByEmail: error,
              }));
            }}
            hasErrors={errors.requestedByEmail === null ? false : errors.requestedByEmail}
            isDisabled={!userCanEditRequest}
            labelText={`* ${labelProp["requestedByEmail"]}`}
            placeholderText="* Requester Email"
            showPlusMeButton={true}
          />
        </div>
      </Box>
      <SubTitle>Add Comment</SubTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <StyledTextField
          multiline
          size="small"
          margin="normal"
          variant="outlined"
          label="Comment"
          InputLabelProps={{ shrink: true }}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          disabled={!canEdit}
        />

        <GlobalButton
          style={{ width: 160 }}
          name="saveComment"
          variant="contained"
          onClick={() => {
            let timeStamp = new Date().toLocaleString();

            let commentWithDate = `${currentUser.username} (${timeStamp}):  ${newComment}`;
            let comment = mySDSRequest.comments
              ? commentWithDate + "|" + mySDSRequest.comments
              : commentWithDate;
            setMySDSRequest(() => ({
              ...mySDSRequest,
              comments: comment,
            }));
            setNewComment("");
            setRequiredCommentAdded(true);
          }}
          disabled={newComment === "" || !newComment || !canEdit}>
          Save Comment
        </GlobalButton>
      </div>
      <div style={{ display: "flex" }}>
        <StyledTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Comments History"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 500 }}
          multiline
          rows={4}
          value={mySDSRequest.comments ? mountComments(mySDSRequest.comments.split("|")) : ""}
          disabled={true}
        />
      </div>

      <StyledDivider />

      <StyledDiv>
        <SubSectionTitle>File Attachments</SubSectionTitle>
      </StyledDiv>

      <FileDragAndDrop
        container={`sdsrequestfiles`}
        handleFiles={handleFiles}
        files={files.map((item) => item.file)}
        showFiles={true}
        handleDeleteFile={(file) => deleteFile(file)}
        message={"Drag and drop your SDS file here"}
        disabled={!canEdit}
      />
      <a href="https://ishareteam7.na.xom.com/sites/Midas3Training/Training%20Documents/SDS%20Request%20Template.xlsx">
        SDS Request Template Product/Formulation Information
      </a>
      {renderInputsByType()}

      <Box
        display="flex"
        paddingTop={2}
        paddingBottom={1}
        justifyContent="space-between"
        flexWrap="wrap">
        <Box display="flex" gap={2}>
          {showConvertToDraftButton && (
            <GlobalButton
              name="btnConvertToDraft"
              variant="contained"
              onClick={handleConvertToDraftClick}
              disabled={loadingSubmit ? true : false}>
              {"Convert to Draft"}
            </GlobalButton>
          )}

          {showSubmitButton && (
            <GlobalButton
              name="btnSave"
              variant="contained"
              onClick={handleSubmitClick}
              disabled={loadingSubmit ? true : false}>
              {"Submit"}
            </GlobalButton>
          )}

          {showSaveButton && (
            <GlobalButton
              name="btnSave"
              variant="contained"
              onClick={handleSaveClick}
              disabled={loadingSubmit ? true : false}>
              {origin === viewFormOrigin.process ? "Save Progress" : "Save"}
            </GlobalButton>
          )}

          {request && (
            <GlobalSecondaryButton
              name="btnCancel"
              variant="contained"
              onClick={cancel}
              disabled={loadingSubmit ? true : false}>
              Cancel
            </GlobalSecondaryButton>
          )}

          {loadingSubmit && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <CircularProgress style={{ textTransform: "none", marginLeft: "15px" }} />
              <span style={{ marginLeft: "1rem" }}> Working, Please Wait... </span>
            </div>
          )}
        </Box>

        <Box display="flex" alignItems="right" flexDirection="column">
          {mySDSRequest.createdDate && (
            <div style={{ textAlign: "right" }}>
              <strong>Created By:</strong> {mySDSRequest.createdByEmail} on{" "}
              {convertToLocalTime(mySDSRequest.createdDate)}
            </div>
          )}
          {mySDSRequest?.lastModifiedDate && (
            <div style={{ textAlign: "right" }}>
              <strong>Last Updated By:</strong> {mySDSRequest.lastModifiedByEmail} on{" "}
              {convertToLocalTime(mySDSRequest.lastModifiedDate)}
            </div>
          )}
        </Box>
      </Box>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label style={{ whiteSpace: "break-spaces" }}>{modalMessagesText}</label>
      </ModalSimpleButton>
    </div>
  );
};

export default SubmitSDS;
