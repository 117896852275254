import React, { cloneElement, ReactElement, useState, ReactNode, isValidElement } from "react";
import { Menu, MenuItem } from "@mui/material";
import MoreOptions from "@mui/icons-material/MoreVert";

type CustomMenuProps = {
  children: ReactNode;
  disabled?: boolean;
};

export const CustomMenu: React.FC<CustomMenuProps> = ({ children, disabled = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleMenuItemClick = (child: ReactElement<{ onClick?: (event: React.MouseEvent<HTMLLIElement>) => void }>) =>
    (event: React.MouseEvent<HTMLLIElement>) => {
      if(child.props.onClick){
        child.props.onClick(event);
      }
      handleClose();
    }; 

  return (
    <>
      <MoreOptions
        color={disabled ? "disabled" : undefined}
        aria-haspopup="true"
        onClick={handleOpenMenu}
      />
      <Menu
        id="custom-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {React.Children.map(children, (child) => {
          if(isValidElement(child) && child.type === MenuItem){
            return cloneElement(child as React.ReactElement<any>, { 
              onClick: handleMenuItemClick(child as ReactElement),
              disabled: child.props["data-disabled"] ?? false,              
            });
          }
          return child;
        })}
      </Menu>
    </>
  );
};
