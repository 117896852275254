import API from "..";

export default class SDSRequest {
  constructor({
    id,
    requestTypeName,
    sdsRequestType,
    requestStatusName,
    sdsRequestStatus,
    description,
    requestedByEmail,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    requestedCompletionDate,
    lastAcceptableCompletionDate,
    assignedToEmail,
    assignedDate,
    completedDate,
    completedByEmail,
    priority,
    sdsRequestPriority,
    priorityReason,
    businessUnitName,
    businessUnit,
    comments,
    intendedUseName,
    sdsRequestIntendedUse,
    isNorthAmerica,
    isSouthAmerica,
    isEurope,
    isAfrica,
    isAsiaPacific,
    countrySpecificInfo,
    sdsBlindCodeName,
    blindCodeForCommericalProduct,
    commericalProductDetails,
    blindCodeForExperimentalProduct,
    experimentalDetails,
    blindCodeForNonEM,
    parentProductName,
    isModeledAfterExistingProduct,
    existingProductDetails,
    isNewSDS,
    isCommercialFlagshipProduct,
    ecrNumber,
    syntheticIndicatorName,
    sdsRequestSyntheticIndicator,
    pH6Code,
    sdsShipmentName,
    componentsExist,
    containsNewOrExperimentalComponents,
    existingTechnicalSpec,
    projectID,
    project
  }) {
    this.id = id;
    this.requestTypeName = requestTypeName;
    this.sdsRequestType = sdsRequestType;
    this.requestStatusName = requestStatusName;
    this.sdsRequestStatus = sdsRequestStatus;
    this.description = description;
    this.requestedByEmail = requestedByEmail;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.requestedCompletionDate = requestedCompletionDate;
    this.lastAcceptableCompletionDate = lastAcceptableCompletionDate;
    this.assignedToEmail = assignedToEmail;
    this.assignedDate = assignedDate;
    this.completedDate = completedDate;
    this.completedByEmail = completedByEmail;
    this.priority = priority;
    this.sdsRequestPriority = sdsRequestPriority;
    this.priorityReason = priorityReason;
    this.businessUnitName = businessUnitName;
    this.businessUnit = businessUnit;
    this.comments = comments;
    this.intendedUseName = intendedUseName;
    this.sdsRequestIntendedUse = sdsRequestIntendedUse;
    this.isNorthAmerica = isNorthAmerica;
    this.isSouthAmerica = isSouthAmerica;
    this.isEurope = isEurope;
    this.isAfrica = isAfrica;
    this.isAsiaPacific = isAsiaPacific;
    this.countrySpecificInfo = countrySpecificInfo;
    this.sdsBlindCodeName = sdsBlindCodeName;
    this.blindCodeForCommericalProduct = blindCodeForCommericalProduct;
    this.commericalProductDetails = commericalProductDetails;
    this.blindCodeForExperimentalProduct = blindCodeForExperimentalProduct;
    this.experimentalDetails = experimentalDetails;
    this.blindCodeForNonEM = blindCodeForNonEM;
    this.parentProductName = parentProductName;
    this.isModeledAfterExistingProduct = isModeledAfterExistingProduct;
    this.existingProductDetails = existingProductDetails;
    this.isNewSDS = isNewSDS;
    this.isCommercialFlagshipProduct = isCommercialFlagshipProduct;
    this.ecrNumber = ecrNumber;
    this.syntheticIndicatorName = syntheticIndicatorName;
    this.sdsRequestSyntheticIndicator = sdsRequestSyntheticIndicator;
    this.pH6Code = pH6Code;
    this.sdsShipmentName = sdsShipmentName;
    this.componentsExist = componentsExist;
    this.containsNewOrExperimentalComponents = containsNewOrExperimentalComponents;
    this.existingTechnicalSpec = existingTechnicalSpec;
    this.projectID = projectID;
    this.project = project;
  }

  static async createSDSRequest(info) {
    const api = await API();
    const { data } = await api.post("/sdsRequest", info);
    return data;
  }

  static async updateSDSRequest(info) {
    const api = await API();
    const { data } = await api.put("/sdsRequest", info);
    return data;
  }

  static async updateSDSRequestStatus(id, status) {
    const api = await API();
    const { data } = await api.patch(`/sdsRequest/status/${id}`, status);
    return data;
  }

  static async updateSDSRequestAssignedTo(id, email) {
    const api = await API();
    const { data } = await api.patch(`/sdsRequest/assignedTo/${id}`, email);
    return data;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/sdsRequest`);
    if (data.result)
      return data.result.map((item) => new SDSRequest(item));
    return[];
  }

  static async getChangeLogs({ searchTerm, skip, take }) {
    try {
      const api = await API();
      const { data } = await api.get(`/sdsRequest/changeLogs`, {
        params: { searchTerm, skip, take },
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (data.result)
        return data.result;
    } catch (error) {
      console.error(error);
      return {
        data: [],
        total: 0,
      }
    }
  }

  static async getSdsNotificationRecipients() {
    const api = await API();
    const { data } = await api.get(`/sdsRequest/notificationRecipients`);
    return data;
  }

  static async updateSdsNotificationRecipients(info) {
    const api = await API();
    const response = await api.put(`/sdsRequest/notificationRecipients`, info);
    return response.data;
  }

  static async getDaysAutoPriorityRule() {
    const api = await API();
    const { data } = await api.get(`/sdsRequest/autoPriorityRule`);
    return data?.result ?? 0;
  }

  static async updateDaysAutoPriorityRule(days) {
    const api = await API();
    const response = await api.put(`/sdsRequest/autoPriorityRule`, days);
    return response.data;
  }

  static async getSdsCustomMessage() {
    const api = await API();
    const { data } = await api.get(`/sdsRequest/customMessage`);
    return data?.result ?? "";
  }

  static async updateSdsCustomMessage(message) {
    const api = await API();
    const response = await api.put(`/sdsRequest/customMessage`, message);
    return response.data;
  }
}
