import { convertToLocalTime, numberOfTestsOnSample, formatMidasNumber, displayHFRValue, formatParentTestLabel } from "../../global";
//import MoreOptions from "@mui/icons-material/MoreVert";
import {useState} from "react";
import { UXDataTableWithoutBody, StyledTableCell, UXDataTableNested, StyledTableBody, StyledTableRow, StyledTableCellLink } from "../../components/UXDataTable";
import {IconButton,TableRow} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ResultDisplayBox from "../../components/ResultDisplay";
import ParentTests from "./ParentTests";

const myTestsCols = ['MIDAS Number', 'Tests', 'Sample Description', 'Additional Info','Chem ID', 'HFR', 'Data Owner', 'Access Group']
const myMethodCols = ['TSN', 'Container', 'Method', 'Method Description', 'Results','Facility','Parent Test','Status', 'Date Completed']

const SearchTests = ({inputSearchCriteria, sampleSearchArray, dataIsLoading}) => {
  const [testRowOpen, setTestRowOpen] = useState([]);
  const [resultRowOpen, setResultRowOpen] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [parentTest, setParentTest] = useState(null);
  const [parentTestLabel, setParentTestLabel] = useState('');

  const noDataMessage = `No tests found with midas number, chem id or sample owner = ${inputSearchCriteria}`

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleTestRowClick = (sampleName) => {
    const testRowOpenIndex = testRowOpen.indexOf(sampleName);
    let newTestRowOpen = [];

    if (testRowOpenIndex === -1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen, sampleName);
    } else if (testRowOpenIndex === 0) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(1));
    } else if (testRowOpenIndex === testRowOpen.length - 1) {
      newTestRowOpen = newTestRowOpen.concat(testRowOpen.slice(0, -1));
    } else if (testRowOpenIndex > 0) {
      newTestRowOpen = newTestRowOpen.concat(
        testRowOpen.slice(0, testRowOpenIndex),
        testRowOpen.slice(testRowOpenIndex + 1)
      );
    }
    setTestRowOpen(newTestRowOpen);
  };

  const handleResultRowClick = (sampleName, sequenceNumber) => {
    const resultRowOpenIndex = resultRowOpen.indexOf(sampleName + "~" + sequenceNumber);
    let newResultRowOpen = [];

    if (resultRowOpenIndex === -1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen, sampleName + "~" + sequenceNumber);
    } else if (resultRowOpenIndex === 0) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(1));
    } else if (resultRowOpenIndex === resultRowOpen.length - 1) {
      newResultRowOpen = newResultRowOpen.concat(resultRowOpen.slice(0, -1));
    } else if (resultRowOpenIndex > 0) {
      newResultRowOpen = newResultRowOpen.concat(
        resultRowOpen.slice(0, resultRowOpenIndex),
        resultRowOpen.slice(resultRowOpenIndex + 1)
      );
    }
    setResultRowOpen(newResultRowOpen);
  };

  const determineStartArray = () => {
    return (sampleSearchArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
  }

  const getArrayOfTests = (containers) => {
    let arrayTests = []

    containers.forEach(oContainer => {
      oContainer.tests.forEach(oTest => {
        arrayTests.push(oTest)        
      });
    });

    return (arrayTests.sort((a, b) => a.testSequenceNumber  - b.testSequenceNumber))
  }

  const isTestRowOpen = (sampleName) => testRowOpen.indexOf(sampleName) !== -1;
  const isResultRowOpen = (sampleName, sequenceNumber) => resultRowOpen.indexOf(sampleName + "~" + sequenceNumber) !== -1;

  return (
    <>
      <UXDataTableWithoutBody 
        tableWidth='100%' 
        cols={myTestsCols} 
        blankFirstHeader={false}
        tableId="myTestsTable"
        rowLength={sampleSearchArray ? sampleSearchArray.length : 0}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={noDataMessage}
        enableAddIcon={false}
        addFunction={null}
        addToolTipText={null}  
        isDataLoading={dataIsLoading}
        >
  
          {sampleSearchArray && sampleSearchArray.length > 0 ? (
            determineStartArray().map((sampleRow, index) => {
              return (
                <StyledTableBody key={`myTestsTablebody${index}`}> 
                  <StyledTableRow>
                    <StyledTableCell component='th' scope='row' sx={{minWidth:"8rem"}}>
                      <b>
                        {formatMidasNumber(sampleRow.sampleName)}
                      </b>
                    </StyledTableCell>

                      <StyledTableCell align='right' style={{width:'5rem'}}>

                      {numberOfTestsOnSample(sampleRow.containers)}

                        <IconButton
                          aria-label='expand row'
                          size='small'
                          onClick={() => handleTestRowClick(sampleRow.sampleName)}>
                          {isTestRowOpen(sampleRow.sampleName) ? (
                            <KeyboardArrowUpIcon/>
                          ) : (
                            <KeyboardArrowDownIcon/>
                          )}
                        </IconButton>
                      </StyledTableCell>
                            
                      <StyledTableCell align='right'>{sampleRow.description}</StyledTableCell>
                      <StyledTableCell align='right'>{sampleRow.additionalSampleInformation}</StyledTableCell>
                      <StyledTableCell align='right'>{sampleRow.chemID}</StyledTableCell>
                      {/* <StyledTableCell align='right'>{sampleRow.parentSampleContainer ? formatMidasNumber(sampleRow.parentSampleContainer.sampleName) : ''}</StyledTableCell> */}

                      {sampleRow.substance ? (
                        <StyledTableCell align='right'>
                          {displayHFRValue(sampleRow.substance)}
                        </StyledTableCell>
                      ) : (<StyledTableCell align='right'>- -</StyledTableCell>)
                      }
                
                        <StyledTableCell align='right'>{sampleRow.sampleOwnerEmail}</StyledTableCell>
                        <StyledTableCell align='right'>{sampleRow.project?.name}</StyledTableCell>
                    </StyledTableRow>

                  {isTestRowOpen(sampleRow.sampleName) && (
                    <UXDataTableNested 
                    tableWidth={'100%'} 
                    cols={myMethodCols} 
                    blankFirstHeader={false} 
                    colSpan={myTestsCols.length + 1} 
                    isOpenComparator={sampleRow.sampleName} 
                    isOpenArray={testRowOpen}>

                    {/* {sampleRow.containers.map((containerRow) => (
                        containerRow.tests.map((testRow) => (   */}
                    {getArrayOfTests(sampleRow.containers).map((testRow) => (
                    <StyledTableBody key={`${sampleRow.sampleName}${testRow.testSequenceNumber}_"testtablebody"`}>
                      <StyledTableRow>
                        <StyledTableCell style={{width:'2rem'}}>{testRow.testSequenceNumber}</StyledTableCell>
                        <StyledTableCell style={{width:'2rem'}}>{sampleRow.containers.find(c => c.id === testRow.containerID).containerNumber}</StyledTableCell>
                        <StyledTableCell>{testRow.methodFacility?.methodName}</StyledTableCell>
                        <StyledTableCell>{testRow.methodFacility?.method?.description}</StyledTableCell>
                        <StyledTableCell style={{width:'4rem'}}>
                          <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => handleResultRowClick(sampleRow.sampleName, testRow.testSequenceNumber)}>
                            {isResultRowOpen(sampleRow.sampleName, testRow.testSequenceNumber) ? (
                              <KeyboardArrowUpIcon/>
                            ) : (
                              <KeyboardArrowDownIcon/>
                            )}
                          </IconButton>
                        </StyledTableCell>
                        <StyledTableCell>{testRow.methodFacility?.testFacilityAbv}</StyledTableCell>
                        <StyledTableCellLink onClick={() => {
                          setParentTest(testRow.parentTestID);
                          setParentTestLabel(formatParentTestLabel(testRow.parentTest));
                        }}>{formatParentTestLabel(testRow.parentTest)}</StyledTableCellLink>
                        <StyledTableCell>{testRow.testStatusName}</StyledTableCell>
                        <StyledTableCell>{convertToLocalTime(testRow.reportedDate)}</StyledTableCell>
                      </StyledTableRow>
                      <TableRow style={{paddingBottom:"0px",paddingTop:"0px", marginTop:"0px", marginBottom:"0px"}}>
                        <StyledTableCell colSpan={9} style={{paddingBottom:"0px",paddingTop:"0px", marginTop:"0px", marginBottom:"0px"}}>{isResultRowOpen(sampleRow.sampleName, testRow.testSequenceNumber) ? <ResultDisplayBox testID={testRow.id}></ResultDisplayBox> : null}</StyledTableCell>
                      </TableRow>
                      
                      </StyledTableBody>
                          ))
                      // ))
                    }
                    </UXDataTableNested>
                  )}
                  
                  </StyledTableBody>
              );
            })
          ) : (
            <StyledTableBody key={"no tests found"}>
              <TableRow key={"nothing found"}>
              <StyledTableCell colSpan='14' key={"nothing found cells"}>
                <p>{noDataMessage}</p>
              </StyledTableCell>
            </TableRow>
            </StyledTableBody>
          )}
    </UXDataTableWithoutBody>
    {parentTest && parentTest !== '' && <ParentTests open={parentTest && parentTest !== ''} setClose={() => setParentTest(null)} setParentTest={setParentTest} parentTest={parentTest} parentTestLabel={parentTestLabel}/>}
    </>
    );
};

export default SearchTests;
