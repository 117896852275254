import { useCallback, useEffect, useMemo, useState } from "react";
import SDSRequest from "../../../../api/SDSRequest/SDSRequest";
import { Roles } from "../../../../global";
import { SDSRequestUserRole, UserContextType } from "../types";
import { requestStatusOptions } from "../constants";

export const useSdsRequestUserRoles = (user: UserContextType, request: SDSRequest) => {
  const { idTokenClaims: { roles: userAdRoles = [] } = {}, username } = user || {};
  const [sdsRequestUserRoles, setSdsRequestUserRoles] = useState<SDSRequestUserRole[]>([]);

  useEffect(() => {
    const roles: SDSRequestUserRole[] = [];

    if (username === request?.createdByEmail) {
      roles.push(SDSRequestUserRole.Creator);
    }
    if (username === request?.requestedByEmail) {
      roles.push(SDSRequestUserRole.Requester);
    }
    if (userAdRoles?.includes(Roles.SdsAssigner) || userAdRoles?.includes(Roles.Developer)) {
      roles.push(SDSRequestUserRole.Assigner);
    }
    if (userAdRoles?.includes(Roles.SdsProcessor) || userAdRoles?.includes(Roles.Developer)) {
      roles.push(SDSRequestUserRole.Processor);
    }

    setSdsRequestUserRoles(roles);
  }, [request, username, userAdRoles]);

  const hasRole = useCallback(
    (role: SDSRequestUserRole): boolean => {
      return sdsRequestUserRoles.includes(role);
    },
    [sdsRequestUserRoles],
  );

  const canEdit = useMemo(() => {
    const requestIsNew = request === undefined;
    const requestIsEditable = [requestStatusOptions.draft, requestStatusOptions.submitted].includes(
      request?.requestStatusName,
    );
    const userIsCreatorOrRequester =
      hasRole(SDSRequestUserRole.Creator) || hasRole(SDSRequestUserRole.Requester);

    return requestIsNew || (userIsCreatorOrRequester && requestIsEditable);
  }, [request, hasRole]);

  const canAssign = useMemo(() => {
    const requestIsAssignable = [
      requestStatusOptions.submitted,
      requestStatusOptions.assigned,
    ].includes(request?.requestStatusName);

    return requestIsAssignable && hasRole(SDSRequestUserRole.Assigner);
  }, [request, hasRole]);

  const canProcess = useMemo(() => {
    const requestIsProcessable = [
      requestStatusOptions.submitted,
      requestStatusOptions.assigned,
      requestStatusOptions.inProgress,
      requestStatusOptions.onHold,
    ].includes(request?.requestStatusName);

    return requestIsProcessable && hasRole(SDSRequestUserRole.Processor);
  }, [request, hasRole]);

  return { sdsRequestUserRoles, canEdit, canAssign, canProcess };
};
