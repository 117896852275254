import { backlogViewType } from "./constants";
import GridRequests from "./gridRequests";

const MyRequests = ({ ...props }) => {
  const { reloadData } = props;
  return (
    <>
      <GridRequests
        type={backlogViewType.myRequests}
        reloadData={reloadData}
        id = {props.id}
      />
    </>
  );
}

export default MyRequests;