import { useEffect, useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import ValidatedMidasNumberTextField from "../../../../components/ValidatedMidasNumberTextField";
import "./styles.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { WorkRequestFormAutocomplete } from "../WorkRequestFormInput";
import { ConvertUOMs, RoundNumber } from "../../../../global";
import Sample from "../../../../api/LIMS/Sample";

const defaultSampleInfo = {
    sample: null,
    containers: [],
};

const gramUoMObject = { uoMName: 'g', type: 'weight', metricStandardUoMName: 'g', metricStandardConversion: 1 }
const mililiterUoMObject = { uoMName: 'ml', type: 'volume', metricStandardUoMName: 'ml', metricStandardConversion: 1 }

const AddSamples = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    mySamplesInfo,
    setMySamplesInfo,
    mySampleInfoToEdit,
    unitOfMeasureLoading,
    unitOfMeasure,
}) => {
    const [sampleInfo, setSampleInfo] = useState(mySampleInfoToEdit ?? {...defaultSampleInfo,
        uom: 'ml',
        unitOfMeasure: mililiterUoMObject
    });
    const [errors, setErrors] = useState({
        sample: mySampleInfoToEdit ? false : null,
    });
    const [loading, setLoading] = useState(false);

    const commonProps = {
        setData: setSampleInfo,
        data: sampleInfo,
        setErrors,
        errors,
        margin: 'none',
        styled: {
            marginRight: "unset",
            marginBottom: "unset",
        }
    }

    const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true) || !sampleInfo.sample;

    function updateErrors() {
        setErrors((prevErrors) => {
            const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
                acc[key] = prevErrors[key] === null ? true : prevErrors[key];
                return acc;
            }, {});

            return updatedErrors;
        });
    }

    function submit() {
        if (hasError()) {
            updateErrors();
            return;
        } else {
            setIsModalClose();

            if (mySampleInfoToEdit) {
                setMySamplesInfo([...mySamplesInfo.map(sample => {
                    if (sample?.sample?.sampleName === sampleInfo?.sample?.sampleName) return sampleInfo;
                    return sample;
                })]);
            } else {
                if (mySamplesInfo.some(s => s?.sample?.sampleName === sampleInfo?.sample?.sampleName)) return;
                else setMySamplesInfo([...mySamplesInfo, { ...sampleInfo }]);
            }
        }
    }

    useEffect(() => {
        async function searchSample() {
            if (mySampleInfoToEdit && !mySampleInfoToEdit.sample?.totalCurrentContainerVolInGrams && !mySampleInfoToEdit.sample?.totalCurrentContainerVolInMililiters) {
                setLoading(true);
                const sample = await Sample.getSamplesWithContainers(sampleInfo.sample?.sampleName);

                setSampleInfo({
                    ...sampleInfo,
                    sample: sample.result ?? sampleInfo.sample
                });
                setLoading(false);
            }
        }
        
        searchSample();

    }, [mySampleInfoToEdit, sampleInfo])

    function calculateAmount(oSample, selectedUoM){
        return selectedUoM.type === 'weight' ? `${RoundNumber(ConvertUOMs(oSample, oSample.substance, oSample.totalCurrentContainerVolInGrams, gramUoMObject, selectedUoM), 3).toLocaleString()} ${selectedUoM ? selectedUoM.uoMName : ''}` : 
            `${RoundNumber(ConvertUOMs(oSample, oSample.substance, oSample.totalCurrentContainerVolInGrams, mililiterUoMObject, selectedUoM), 3).toLocaleString()} ${selectedUoM ? selectedUoM.uoMName : ''}`
    };

    return <ModalTwoButtons
        title="Sample"
        button1Text={mySampleInfoToEdit ? "Update" : "Add"}
        button1Action={submit}
        isButton1Disabled={false}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <div className="form-work-request-add-samples">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="100%">
                <ValidatedMidasNumberTextField
                    margin="none"
                    showLabel={true}
                    fieldWidth="100%"
                    midasNumberObject={sampleInfo?.sample}
                    setMidasNumberObject={(e) => {
                        setSampleInfo({
                            ...sampleInfo,
                            sample: e,
                            amountAvailable: calculateAmount(e, sampleInfo.unitOfMeasure)
                        });
                    }}
                    hasErrors={errors.sample}
                    setHasErrors={(value) => setErrors({ ...errors, sample: value })}
                    chemIDToMatch={null}
                    isDisabled={mySampleInfoToEdit}
                />
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width={"100%"}>
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    options={unitOfMeasure.map(uom => ({ ...uom, uom: uom.uoMName }))}
                    unitOfMeasureLoading
                    noOptionsText={
                        unitOfMeasureLoading ? "Loading UoMs..." : "No UoMs Found"
                    }
                    property={'uom'}
                    propertyLabel={'uoMName'}
                    label={'UoM'}
                    disabled={sampleInfo.sample === null || loading}
                    setData={(data) => {
                        const selectedUoM = unitOfMeasure.find(
                            (x) => x.uoMName === data.uom
                        );

                        setSampleInfo({
                            ...data,
                            unitOfMeasure: selectedUoM,
                            amountAvailable: calculateAmount(sampleInfo.sample, selectedUoM)
                        }); 
                    }}
                />
                <Box display="flex" flexDirection="column" width={300} alignItems='center'>
                    <Typography>Amount Available:</Typography>
                    <Typography>{sampleInfo.amountAvailable}</Typography>
                </Box>
            </Box>
        </div>
    </ModalTwoButtons>
}
export default AddSamples
