import { useEffect, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import ProjectUserApproval from "../../../../api/Admin/ProjectUserApproval";
import { CustomNetworkError } from "../../../../api/helpers";
import { DataTableWrapper } from "../ProjectManagement/components/DataTableWrapper";
import { StyledTableCell, StyledTableRow } from "../../../../components/UXDataTable";
import { CustomMenu } from "../ProjectManagement/components/CustomMenu";
import { Alert } from "../ProjectManagement/components/Alert";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";

export type ProjectUserReviewProps = {
    projectUserApproval: ProjectUserApproval;
    onSave?: () => void;
    onCancel?: () => void;
};

type ProjectUserReviewAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void;
};

const ProjectUserReview = () =>
{
    const [projectUserRequests, setProjectUserRequests] = useState<ProjectUserApproval[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [userIsUnauthorized, setUserIsUnauthorized] = useState<boolean>(true);
    const [alert, setAlert] = useState<ProjectUserReviewAlert | undefined>(undefined);
    const [refresh, setRefresh] = useState(0);
    const [processLoading, setProcessLoading] = useState<boolean>(false);   

    const tableKey = "projectsUserReviewTable";

    const columns =
    [
        {
            id: "ProjectName",
            label: "Project Name",
            notSorteable: false
        },
        {
            id: "userEmail",
            label: "User Email",
            notSorteable: false
        },
        {
            id: "RequestDescription",
            label: "Request Description",
            notSorteable: false
        },
        {
            id: "createdDate",
            label: "Created Date",
            notSorteable: false
        },        
        {
            id: "approvalStatus",
            label: "Approval Status",
            notSorteable: false
        }
    ];

    useEffect(() =>
    {
        setLoading(true);
        ProjectUserApproval.getRequestsByOwner()
        .then((response: ProjectUserApproval[]) =>
        {         
            setProjectUserRequests(response);
            setUserIsUnauthorized(false);                     
        })
        .catch((err) =>
        {
            setAlert(errorFetchingProjectUserReviewAlert(getNetworkErrorMessage(err)));
        })
        .finally(() =>
        {
            setLoading(false);
        });
    }, [refresh]);

    const handleAlertOkClick = () => {
        alert?.onOk?.();
        setAlert(undefined);
        setRefresh((prev) => prev + 1);      
    };

    const handleProjectUserRequestClick = (id: number, isApproval: boolean) => {
        setProcessLoading(true);
            ProjectUserApproval.updateProjectUserRequest(id, isApproval)
                .then((response: ProjectUserApproval) => { 
                    switch(response.approvalStatus){
                        case "Approved":
                            return setAlert(successfullyUserRequestAcceptedProjectAlert);
                        case "Rejected":
                            return setAlert(successfullyUserRequestRejectedProjectAlert);
                    }
                })
                .catch((err) => {            
                    setAlert(errorFetchingProjectUserReviewAlert(getNetworkErrorMessage(err)));
                })
                .finally(() => {
                    setProcessLoading(false);
                });
    };
        
    return (
    <>
        <Box
            display="flex"
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingBottom={".8rem"}>
            <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Header>All Projects User Requests ({projectUserRequests.length})</Header>
            </Box>
        </Box>
        <DataTableWrapper
            tableId="projectRequests"
            columns={columns}
            tableWidth="100%"
            blankFirstHeader={true}
            noDataFoundMessage={
                userIsUnauthorized
                    ? "You are not authorized to view or modify project user requests."
                    : "No user requests for projects available."
            }
            isDataLoading={loading}
            rowData={projectUserRequests}
            rowComponent={(projectUserRequest, rowIndex) => (
            <StyledTableRow key={`${tableKey}-row-${rowIndex}`}>
                <StyledTableCell width={5}>
                    <CustomMenu key={`${tableKey}basic-menu${rowIndex}`}>
                        <MenuItem onClick={() => { handleProjectUserRequestClick(projectUserRequest.id, true); }}>Approve</MenuItem>
                        <MenuItem onClick={() => { handleProjectUserRequestClick(projectUserRequest.id, false); }}>Reject</MenuItem>                   
                    </CustomMenu>                
                </StyledTableCell>
                <StyledTableCell>{projectUserRequest.projectName}</StyledTableCell>
                <StyledTableCell>{projectUserRequest.userEmail}</StyledTableCell>
                <StyledTableCell>{projectUserRequest.requestDescription}</StyledTableCell>
                <StyledTableCell>{projectUserRequest.createdDate.split("T")[0]}</StyledTableCell>
                <StyledTableCell>{projectUserRequest.approvalStatus}</StyledTableCell>
                
            </StyledTableRow>
        )}
    />
    <ProgressSpinner display={processLoading} />    
    <Alert
        title={alert?.title}
        message={alert?.message}
        details={alert?.details}
        display={alert !== undefined}
        onOk={handleAlertOkClick}
    />
    </>);
};

const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403) {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};

const errorFetchingProjectUserReviewAlert = (details: string): ProjectUserReviewAlert => ({
    title: "Error",
    message: "There was an error fetching project user requests",
    details: `Reason: ${details}`,
});

const successfullyUserRequestAcceptedProjectAlert: ProjectUserReviewAlert = {
    title: "User Request Accepted",
    message: "User request was successfully accepted to the project.",
};

const successfullyUserRequestRejectedProjectAlert: ProjectUserReviewAlert = {
    title: "User Request Rejected",
    message: "User request was successfully rejected to the project.",
};

const Header = styled(Box)(() => ({
    fontSize: "20px",
    fontWeight: "bold",
    paddingRight: 10,
}));

export default ProjectUserReview;
