import { BrowserCacheLocation } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;

// Config object to be passed to Msal on creation
export const msalConfigAzureAd = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_APP_ID,  //"b6bee1fc-b959-4e1e-a347-c43267c2c499", // PLACE YOUR CLIENT ID HERE
    authority: "https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/",
    redirectUri: window.location.origin,
  },
  cache: {
    storeAuthStateInCookie: isIE || isEdge,
    cacheLocation: BrowserCacheLocation.LocalStorage
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
      },
    }
  },
};

// Example request configuration for accessing MS Graph.
export const graphLoginRequest = {
  scopes: ["https://graph.microsoft.com/User.Read.All"],
  domainHint: "exxonmobil.com",
};

// Place any scopes required by your app here
export const appLoginRequestAzure = {
  scopes: [],
  domainHint: "exxonmobil.com",
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

/**
 * Note: Configuration for scopes, api endpoints and client ids can be
 * configured in whichever way feels the most natural and accessible way
 *  for you and your applications use-case. Please feel free to abstract them
 *  away from this file in whichever way you see fit.
 */
