import API from "..";

export default class SDSRequestType {

  constructor({
    typeName,
    description,
    isActive
  }) 
    
  {
    this.typeName = typeName;
    this.description = description;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestType`);
    if (data.result) {
        return data.result.map((d) => new SDSRequestType(d));
    }
    else {
        return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestType`);
    if (data && data.result && data.result.length > 0) {
        return data.result.filter(r => r.isActive);
    }
    else {
        return [];
    }
  }

  static async create(type) {
    const api = await API();
    const response = await api.post(`/sdsRequestType`, type);
    return response.data;
  }
  
  static async update(type) {
    const api = await API();
    const response = await api.put(`/sdsRequestType`, type);
    return response.data;
  }
}
