import { useEffect, useState } from "react";
import ModalTwoButtons from '../../../../components/Modal/ModalTwoButtons'
import "./styles.css";
import { Box } from "@mui/system";
import { WorkRequestFormAutocomplete } from "../WorkRequestFormInput";
import { formatMidasNumber } from "../../../../global";

const CopyTestsModal = ({
    isModalOpen,
    setIsModalOpen,
    setIsModalClose,
    mySamplesInfo,
    sampleNameDefault,
    containerIdToHide
}) => {
    const [containerToCopy, setContainerToCopy] = useState({
        sampleName: sampleNameDefault,
        containerNumber: null,
        tests: null,
    });
    const [errors, setErrors] = useState({
        sampleName: false,
        containerNumber: null,
    });
    const [containerOptions, setContainerOptions] = useState([]);

    const commonProps = {
        setData: setContainerToCopy,
        data: containerToCopy,
        setErrors,
        errors,
        margin: 'none',
    }

    const hasError = () => Object.entries(errors).some(([key, value]) => value === null || value === true);

    useEffect(() => {
        setContainerOptions(mySamplesInfo.find(f => f.sample?.sampleName === containerToCopy.sampleName)?.containers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mySamplesInfo])

    function submit() {
        if (hasError()) {
            return;
        } else {
            setIsModalClose(containerOptions.find(f => `${f.existingContainer?.containerNumber}` === `${containerToCopy.containerNumber}`)?.testSubmissionJSON);
        }
    }

    return <ModalTwoButtons
        title="Copy Tests From"
        button1Text={"Copy Tests"}
        button1Action={submit}
        isButton1Disabled={hasError()}
        button2Text="Cancel"
        button2Action={() => {
            setIsModalClose();
        }}
        setOpen={setIsModalOpen}
        open={isModalOpen}
    >
        <form className="form-work-request-copy-tests">
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="100%">
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    autoSelect={false}
                    options={mySamplesInfo.map(sample => ({
                        midasNumber: formatMidasNumber(sample?.sample?.sampleName),
                        sampleName: sample?.sample?.sampleName
                    }))}
                    propertyLabel={'midasNumber'}
                    property={'sampleName'}
                    noOptionsText={"No Samples Found"}
                    label={`MIDAS #`}
                    setData={(data) => {
                        setContainerToCopy(data)
                        setContainerOptions(mySamplesInfo.find(f => f.sample?.sampleName === data.sampleName)?.containers)
                    }}
                    required
                />
                
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center" gap={1} width="350px">
                <WorkRequestFormAutocomplete
                    {...commonProps}
                    autoSelect={false}
                    options={containerOptions.filter(f => f.existingContainerID !== containerIdToHide && f.testSubmissionJSON && f.testSubmissionJSON !== '').map(container => {
                        const existingContainer = container.existingContainer ?? container.sample.containers.find(c => c.id === container.existingContainerID)
                        return {
                            ...existingContainer,
                            containerNumber: existingContainer?.containerNumber ? String(existingContainer?.containerNumber) : container.existingContainerID
                        }
                    })}
                    propertyLabel={'containerNumber'}
                    property={'containerNumber'}
                    noOptionsText={"No Containers Found"}
                    label={`Container Number`}
                    disabled={!containerToCopy.sampleName}
                    required
                />
                
            </Box>
        </form>
    </ModalTwoButtons>
}
export default CopyTestsModal
