import { Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import UserContext from "./context/UserContext";
import { PrefContextProvider } from "./context/PrefContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import { LocationContextProvider } from "./context/LocationContext";
import { SerialProvider } from "./context/SerialProvider";
import { useMsal } from "@azure/msal-react";

//Home
import Home from "./pages/Home";
import Help from "./pages/Help";

//Inventory
import Inventory from "./pages/Inventory";

//Tests
import Tests from "./pages/Tests";
import NewTest from "./pages/Tests/NewTests";

//Samples
import Samples from "./pages/Samples";
import NewSample from "./pages/Samples/NewSamples";

//Distillations
import Distillations from "./pages/Distillations";
import ProcessDistillations from "./pages/Distillations/ProcessDistillations";
import NewDistillationExperiment from "./pages/Distillations/CreateDistillationTabs/NewDistillationExperiment";

//Stationary Testing
import StationaryTesting from "./pages/StationaryTesting";
import NewStationaryRequest from "./pages/StationaryTesting/components/NewRequest.jsx";
import ProcessRequest from "./pages/StationaryTesting/components/ProcessRequest";

import Analytical from "./pages/Analytical";
import Utilities from "./pages/Utilities";
import Tutorials from "./pages/Tutorials";
import Bookmarks from "./pages/Bookmarks";
import Stockroom from "./pages/Stockroom";
import { Sqc } from "./pages/SQC";
import Sandbox from "./pages/Sandbox";
import Notifications from "./pages/Notifications";
import UPrefs from "./pages/UserPreferences";

//Blends
import Blends from "./pages/Blends";
import NewBlendStudy from "./pages/Blends/NewBlends";
import PrepareBatch from "./pages/Blends/EditBatch";

//Work Requests
import MIDASWorkRequests from "./pages/WorkRequests";
import NewWorkRequest from "./pages/WorkRequests/NewWorkRequests";
import NewSpecialAnalysis from "./pages/SpecialAnalysis/NewSpecialAnalysis";
import NewWorkRequestRedesign from "./pages/WorkRequests/NewWorkRequestRedesign";
import ViewWorkRequest from "./pages/WorkRequests/ViewWorkRequests/ViewWorkRequest";
import ViewSpecialAnalysis from "./pages/SpecialAnalysis/ViewSpecialAnalysis/ViewSpecialAnalysis";

//Admin
import Admin from "./pages/Admin";
import GlobalSearch from "./pages/GlobalSearch";
import PageNotFound from "./pages/PageNotFound";
import Logout from "./pages/External";
import UnauthorizedPage from "./pages/Unauthorized";
import { Toaster } from "react-hot-toast";

const App = () => {
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  return (
    <UserContext.Provider value={account}>
      <PrefContextProvider>
        <NotificationContextProvider>
          <LocationContextProvider>
            <SerialProvider>
              <Layout>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route path="/inventory/:tab/:view?/:id?" component={Inventory} />
                  <Route path="/inventory" component={Inventory} />
                  <Route path="/tests" component={Tests} />
                  <Route path="/analytical" component={Analytical} />
                  <Route path="/workRequests" component={MIDASWorkRequests} />
                  <Route path="/processWorkRequests" component={() => <MIDASWorkRequests initialTabValue={1} />} />
                  <Route path="/newWorkRequests" component={NewWorkRequest} />
                  <Route path="/newSpecialAnalysis" component={NewSpecialAnalysis} />
                  <Route path="/newWorkRequestRedesign/:id" component={NewWorkRequestRedesign} />
                  <Route path="/newWorkRequestRedesign" component={NewWorkRequestRedesign} />
                  <Route path="/viewWorkRequests/:id" component={ViewWorkRequest} />
                  <Route path="/viewSpecialAnalysis/:id" component={ViewSpecialAnalysis} />
                  <Route path="/processSpecialAnalysis" component={() => <MIDASWorkRequests initialTabValue={2} />} />
                  <Route path="/blends" component={Blends} />
                  <Route path="/blendstudy" component={NewBlendStudy} />
                  <Route path="/prepareBatch" component={PrepareBatch} />
                  <Route path="/distillations" component={Distillations} />
                  <Route path="/processDistillation" component={ProcessDistillations} />
                  <Route path="/newExperiment" component={NewDistillationExperiment} />
                  <Route path="/utilities" component={Utilities} />
                  <Route path="/tutorials" component={Tutorials} />
                  <Route path="/bookmarks" component={Bookmarks} />
                  <Route path="/search" component={GlobalSearch} />
                  <Route path="/admin" component={Admin} />
                  <Route path="/newTest" component={NewTest} />
                  <Route path="/notifications" component={Notifications} />
                  <Route path="/profile" component={UPrefs} />
                  <Route path="/logout" component={Logout} />
                  <Route path="/unauthorized" component={UnauthorizedPage} />
                  <Route path="/samples" component={Samples} />
                  <Route path="/newSample" component={NewSample} />
                  <Route path="/stockroom" component={Stockroom} />
                  <Route path="/stationarytesting" component={StationaryTesting} />
                  <Route path="/newStationaryRequest" component={NewStationaryRequest} />
                  <Route path="/processRequest" component={ProcessRequest} />
                  <Route path="/sqc" component={Sqc} />
                  <Route path="/sandbox" component={Sandbox} />
                  <Route path="/help" component={Help} />
                  <Route path="/*" component={PageNotFound} />
                </Switch>
                <Toaster position="top-right" />
              </Layout>
            </SerialProvider>
          </LocationContextProvider>
        </NotificationContextProvider>
      </PrefContextProvider>
    </UserContext.Provider>
  );
};

export default App;
