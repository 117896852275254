import React, { useState, useEffect, useContext } from "react";
import {
  UXDataTableWithoutBody,
  StyledTableBody,
  StyledTableCell,
  StyledTableCellLink,
  StyledTableRow,
} from "../../components/UXDataTable";
import { applyFiltersToArray, convertDateFormat, Roles, hasRole } from "../../global";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreOptions from "@mui/icons-material/MoreVert";
import { Link } from "react-router-dom";
import AssignTechnicianModal from "./AssignTechnicianModal";
import SpecialAnalysis from "../../api/SpecialAnalysis/SpecialAnalysis";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import Summary from "./PrintableComponents/Summary";
import { pdf } from "@react-pdf/renderer";
import User from "../../api/Admin/User";
import UserContext from "../../context/UserContext";
import PrintLabel from "../../api/LIMS/PrintLabel";
import PrintInformation from "../../components/PrintInformation";

const MySpecialAnalysisCols = [
  "ID",
  "Description",
  "Priority",
  "Requester",
  "Assigned To",
  "Requested Date",
  "Requested Completion",
  "Last Acceptable Date",
  "Business Unit",
  "Status",
  "JADE #",
  "Work Request ID",
];

const TableMyOpenRequests = ({
  filters,
  myRequests,
  reloadSpecialAnalysis,
  isDataLoading,
  userEmail,
  isProcessing,
  billingList,
  copySpecialAnalysis,
}) => {
  const [assignTechnicianModal, setAssignTechnicianModal] = useState(false);

  const [printLabelInfo, setPrintLabelInfo] = React.useState(null);
  const [printInfoOpen, setPrintInfoOpen] = React.useState(false);
  const [clickedMenuId, setClickedMenuId] = useState(null);
  const [filteredRequests, setFilteredRequests] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const currentUser = useContext(UserContext);
  const userRoles = currentUser?.idTokenClaims.roles;
  const isDeveloperRole = hasRole(Roles.Developer, userRoles);
  const isAssignerRole = hasRole(Roles.SpecialAnalysisAssigner, userRoles) || isDeveloperRole;

  const noDataMessage = `No open special analysis found for ${userEmail}`;

  useEffect(() => {
    var filteredArray = applyFiltersToArray(filters, myRequests);
    setFilteredRequests(filteredArray);
  }, [filters, myRequests]);

  function handleOpenMoreOptions(e, specialAnalysis) {
    setMoreOptionsSelected(specialAnalysis);

    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);

    setAnchorEl(null);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const determineStartArray = () => {
    return filteredRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }
  const openModalMessage = (title, message, btnText) => {
    setModalMessagesOpen(true);
    setModalMessagesText(message);
    setModalMessagesButtonText(btnText);
    setModalMessagesTitle(title);
  };
  function closePrintInformation() {
    setPrintInfoOpen(false);
  }

  const assignTechnician = (assignTechnicianEmail) => {
    SpecialAnalysis.updateAssignTo(clickedMenuId, assignTechnicianEmail)
      .then((res) => {
        if (res?.message !== "Success") {
          openModalMessage(
            "Fail",
            `There was an error updating technician. ${res?.message ? res?.message : ""}`,
            "Ok",
          );
        } else {
          reloadSpecialAnalysis();
          openModalMessage("Success", "Technician successfully updated", "Close");
        }
      })
      .catch((err) => {
        openModalMessage("Error", "There was an error updating technician", "Close");
      });
  };
  const currentRequest = myRequests?.find((x) => x.id === clickedMenuId) ?? null;

  async function getSummary() {
    if (moreOptionsSelected && moreOptionsSelected.id) {
      let businessPhones = "";

      const userPreferences = await User.validateEmailAddress(currentUser.username);

      if (
        userPreferences &&
        userPreferences.businessPhones &&
        userPreferences.businessPhones.length > 0
      ) {
        businessPhones = userPreferences.businessPhones.join(", ");
      }

      SpecialAnalysis.getById(moreOptionsSelected.id).then((response) => {
        if (response) {
          const asPdf = pdf([]);
          asPdf.updateContainer(
            <Summary
              specialAnalysis={response}
              businessPhones={businessPhones}
              billingList={billingList}></Summary>,
          );

          asPdf.toBlob().then((blob) => {
            var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    }
  }

  function openPrintInfo(sample, message = "") {
    let arrayPrintInfo = [];
    sample.forEach((sample) => {
      let newPrintInfo = new PrintLabel({
        sampleName: sample.sampleName,
        containerNumbers: [sample.existingContainer.containerNumber],
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false,
      });

      arrayPrintInfo.push(newPrintInfo);
    });

    setPrintLabelInfo(arrayPrintInfo);
    setPrintInfoOpen(true);
  }

  const reprintLabel = (id) => {
    SpecialAnalysis.getById(id).then((result) => {
      if (!result.specialAnalysisSamples.length) {
        openModalMessage(
          "No samples to print",
          "The selected request has no samples to be printed",
          "Close",
        );
        return;
      }
      openPrintInfo(result.specialAnalysisSamples);
    });
  };

  return (
    <div>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={MySpecialAnalysisCols.filter(
          (f) => (!isProcessing && f !== "Business Unit") || isProcessing)
        }
        blankFirstHeader={true}
        tableId={`allStudies`}
        rowLength={filteredRequests.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={noDataMessage}
        isDataLoading={isDataLoading}>
        <StyledTableBody key={"allRequestTablebody"}>
          {determineStartArray()
            .sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate))
            .map((specialAnalysis, index) => {
              return (
                <StyledTableRow key={`table-row-${index}`}>
                  <StyledTableCell style={{ width: "10px" }}>
                    <MoreOptions
                      onClick={(e) => {
                        setClickedMenuId(specialAnalysis.id);

                        handleOpenMoreOptions(e, specialAnalysis);
                      }}
                      color="black"
                    />
                  </StyledTableCell>
                  <StyledTableCellLink
                    scope="row"
                    component={Link}
                    to={{
                      pathname: `/viewSpecialAnalysis/${specialAnalysis.id}`,
                      state: { tab: isProcessing ? "process" : "submit" },
                    }}>
                    {specialAnalysis.id}
                  </StyledTableCellLink>
                  <StyledTableCell align="right">{specialAnalysis.description}</StyledTableCell>
                  <StyledTableCell align="right">{specialAnalysis.priorityName}</StyledTableCell>                 
                  <StyledTableCell align="right">{specialAnalysis.requestedByEmail}</StyledTableCell>
                  <StyledTableCell align="right">{specialAnalysis.assignedToEmail}</StyledTableCell>
                  <StyledTableCell align="right">
                    {convertDateFormat(specialAnalysis.createdDate, false)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {convertDateFormat(specialAnalysis.requestedCompletionDate, false)}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {convertDateFormat(specialAnalysis.lastAcceptableCompletionDate, false)}
                  </StyledTableCell>
                  {isProcessing && (
                    <StyledTableCell align="right">{specialAnalysis.businessUnitName}</StyledTableCell>
                  )}
                  <StyledTableCell align="right">{specialAnalysis.statusName}</StyledTableCell>
                  <StyledTableCell align="right">{specialAnalysis.chargeCode}</StyledTableCell>
                  <StyledTableCellLink
                    scope="row"
                    component={Link}
                    to={{
                      pathname: (specialAnalysis.parentSpecialAnalysisID !== undefined ? `/viewSpecialAnalysis/${specialAnalysis.parentSpecialAnalysisID}` : `/workrequests`),
                      state: { tab: isProcessing ? "process" : "submit" },
                    }}>
                    {specialAnalysis.parentSpecialAnalysisID}
                  </StyledTableCellLink>
                </StyledTableRow>
              );
            })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {isProcessing && (
          <>
            <MenuItem
              onClick={() => {
                getSummary();
                handleCloseMoreOptions();
              }}>
              Print
            </MenuItem>
            <MenuItem
              onClick={() => {
                reprintLabel(currentRequest.id);
                handleCloseMoreOptions();
              }}
              disabled={false}>
              Reprint Special Analysis Label
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAssignTechnicianModal(true);
                handleCloseMoreOptions();
              }}
              disabled={!isAssignerRole}>
              Assign
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            copySpecialAnalysis(currentRequest.id);
            handleCloseMoreOptions();
          }}>
          Copy Special Analysis
        </MenuItem>
      </Menu>
      {clickedMenuId > 0 && (
        <AssignTechnicianModal
          specialAnalysisId={clickedMenuId}
          assignTechnician={assignTechnician}
          assignTechnicianModal={assignTechnicianModal}
          setAssignTechnicianModal={setAssignTechnicianModal}
        />
      )}
      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label>{modalMessagesText}</label>
      </ModalSimpleButton>
      <PrintInformation
        open={printInfoOpen}
        setOpen={setPrintInfoOpen}
        button2Action={closePrintInformation}
        button2Text={"Close"}
        printLabelInfo={printLabelInfo}
        isShelfLabel={false}></PrintInformation>
    </div>
  );
};

export default TableMyOpenRequests;
