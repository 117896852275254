import { Box, TextField } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import Project from "../../../../api/Admin/Project";
import { useState } from "react";
import { CustomNetworkError } from "../../../../api/helpers";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { Alert } from "../ProjectManagement/components/Alert";

export type ProjectRejectFormProps = {
    project: Project | undefined;
    onSave?: () => void;
    onCancel?: () => void;
};

type ProjectRejectAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void | undefined;
};

export const ProjectRejectForm = ({
    project,
    onSave,
    onCancel
}: ProjectRejectFormProps) => {

    const [processLoading, setProcessLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<ProjectRejectAlert | undefined>(undefined);
    const [rejectText, setRejectText] = useState("");

    const handleAlertOkClick = () => {
            onSave?.();
            alert?.onOk?.();
            setAlert(undefined);
            onCancel?.();
    };

    const handleSaveRejectionClick = (rejectMessage: string) =>
    {
        setProcessLoading(true);
        Project.reviewPendingProjects(project?.id, false, rejectMessage)
        .then((response: Project) => {            
            if(response.reviewStatus === "Rejected"){
                setAlert(successfullyRejectedProjectAlert);
            }            
        })
        .catch((err) => {            
            setAlert(errorReviewingProjectAlert(getNetworkErrorMessage(err)));
        })
        .finally(() => {
            setProcessLoading(false);            
        });
    };    

    return(
    <>
        <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} textAlign={"center"} gap={2}>                                 
            <Box></Box>
            <Box>
                <TextField
                    fullWidth
                    label="Reason for rejection"
                    multiline
                    rows={5}                              
                    onChange = {(e) => setRejectText(e.target.value)}
                />
            </Box>                      
            <Box display="flex" flexDirection={"row"} alignItems="center" justifyContent={"center"} textAlign={"center"} gap={2}>                
                <GlobalButton
                    disabled={rejectText.trim() ===""}
                    variant='contained'
                    onClick={() => {handleSaveRejectionClick(rejectText);}}>
                    Save
                </GlobalButton>                               
                <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => { onCancel?.();}}>
                    Cancel
                </GlobalSecondaryButton>
            </Box>           
        </Box>
        <ProgressSpinner display={processLoading} />
        <Alert
            title={alert?.title}
            message={alert?.message}
            details={alert?.details}
            display={alert !== undefined}
            onOk={handleAlertOkClick}
        />
    </>
    );
};

const successfullyRejectedProjectAlert: ProjectRejectAlert = {
    title: "Rejected",
    message: "Project was successfully rejected.",
};

const errorReviewingProjectAlert = (details: string): ProjectRejectAlert => ({
    title: "Error",
    message: "There was an error reviewing project.",
    details: `Reason: ${details}`,
});

const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403)
    {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};
