import API from "..";

export default class Substance {
  /**
   * The Sample dimension table for M3
   * @param {String} SubstanceName The MIDAS of the Sample
   */
  constructor({
    chemID,
    substanceName,
    promotedChemID,
    parentChemID,
    chemIDCategoryName,
    description,
    isActive,
    isValidated,
    isExperimentalCode,
    isResearchCode,
    ownerEmail,
    createdByEmail,
    createdDate,
    lastModifiedByEmail,
    lastModifiedDate,
    lastSafetyModifiedByEmail,
    lastSafetyModifiedDate,
    psimsid,
    densityAtSTPgml,
    flashPointC,
    flashPointMethod,
    boilingPointC,
    nfpaf,
    nfpar,
    nfpah,
    hmisf,
    hmisr,
    hmish,
    isChronic,
    isPeroxidizable,
    isPHSCat1,
    isPHSCat2,
    isPHSCat3,
    isPHSCat4,
    isEngineeredNanoMaterial,
    smgDefaultRetentionMonths,
    specialHandlingName,
    jBlendDescription,
    jDescription,
    jFireSafetyCode,
    japanFireSafetyCode,
    jShelfLife,
    jToxicMaterialCode,
    jghsCode,
    japanGHSCode,
    jsdsCode,
    japanSDSCode,
    substanceFiles,
    isCommerciallyAvailable,
    wasPurchased,
    commerciallyAvailableNotes,
    substanceTypeName,
    substanceSubtype1ID,
    subtype1,
    substanceSubtype2ID,
    subtype2,
    substanceSubtype3ID,
    subtype3,
    lastUsedDate,
    promotedSubstance,
  }) {
    this.chemID = chemID;
    this.substanceName = substanceName;
    this.promotedChemID = promotedChemID;
    this.parentChemID = parentChemID;
    this.chemIDCategoryName = chemIDCategoryName;
    this.description = description;
    this.isActive = isActive;
    this.isValidated = isValidated;
    this.isExperimentalCode = isExperimentalCode;
    this.isResearchCode = isResearchCode;
    this.ownerEmail = ownerEmail;
    this.createdByEmail = createdByEmail;
    this.createdDate = createdDate;
    this.lastModifiedByEmail = lastModifiedByEmail;
    this.lastModifiedDate = lastModifiedDate;
    this.lastSafetyModifiedByEmail = lastSafetyModifiedByEmail;
    this.lastSafetyModifiedDate = lastSafetyModifiedDate;
    this.psimsid = psimsid;
    this.densityAtSTPgml = densityAtSTPgml;
    this.flashPointC = flashPointC;
    this.flashPointMethod = flashPointMethod;
    this.boilingPointC = boilingPointC;
    this.nfpaf = nfpaf;
    this.nfpar = nfpar;
    this.nfpah = nfpah;
    this.hmisf = hmisf;
    this.hmisr = hmisr;
    this.hmish = hmish;
    this.isChronic = isChronic;
    this.isPeroxidizable = isPeroxidizable;
    this.isPHSCat1 = isPHSCat1;
    this.isPHSCat2 = isPHSCat2;
    this.isPHSCat3 = isPHSCat3;
    this.isPHSCat4 = isPHSCat4;
    this.isEngineeredNanoMaterial = isEngineeredNanoMaterial;
    this.smgDefaultRetentionMonths = smgDefaultRetentionMonths;
    this.specialHandlingName = specialHandlingName;
    this.jBlendDescription = jBlendDescription;
    this.jDescription = jDescription;
    this.jFireSafetyCode = jFireSafetyCode;
    this.japanFireSafetyCode = japanFireSafetyCode;
    this.jShelfLife = jShelfLife;
    this.jToxicMaterialCode = jToxicMaterialCode;
    this.jghsCode = jghsCode;
    this.japanGHSCode = japanGHSCode;
    this.jsdsCode = jsdsCode;
    this.japanSDSCode = japanSDSCode;
    this.substanceFiles = substanceFiles;
    this.isCommerciallyAvailable = isCommerciallyAvailable;
    this.wasPurchased = wasPurchased;
    this.commerciallyAvailableNotes = commerciallyAvailableNotes;
    this.substanceTypeName = substanceTypeName;
    this.substanceSubtype1ID = substanceSubtype1ID;
    this.subtype1 = subtype1;
    this.substanceSubtype2ID = substanceSubtype2ID;
    this.subtype2 = subtype2;
    this.substanceSubtype3ID = substanceSubtype3ID;
    this.subtype3 = subtype3;
    this.lastUsedDate = lastUsedDate;
    this.promotedSubstance = promotedSubstance;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/substance`);
    return data.result.map((d) => new Substance(d));
  }

  static async getByChemID(chemID, includeInactives, includeStructureInfo) {
    const api = await API();
    const { data } = await api.get(`/substance/${chemID}?includeInactives=${includeInactives}&includeStructureInfo=${includeStructureInfo}`);
    return data
  }

  static async usageCheck(chemID) {
    const api = await API();
    const { data } = await api.get(`/substance/usagecheck?chemID=${chemID}`);
    if (data.result) {
      return data.result;
    } else {
      return null;
    }
  }

  static async hrfCalculate(chemIDs) {
    const api = await API();
    const { data } = await api.get(`/substance/hfrcalculate`, {
      params: { chemIDs: chemIDs }
    });
    if (data.result) {
      return data.result;
    } else {
      return null;
    }
  }

  static async search(searchCriteria, includeInactives) {
    const api = await API();
    const { data } = await api.get(`/substance/search?searchCriteria=${searchCriteria}&includeInactives=${includeInactives}`);
    return data
    // if (data.result) {
    //   return data.result.map((d) => new Substance(d));
    // } else {
    //   return null;
    // }
  }

  static async update(substance) {
    const api = await API();
    const { data } = await api.put(`/substance`, substance);
    return data;
  }

  static async create(substance) {
    const api = await API();
    const { data } = await api.post(`/substance`, substance);
    return data;
  }

  static async updatePartial(id, fields) {
    const api = await API();
    const { data } = await api.patch(`/substance/${id}`, fields);
    return data;
  }
}
