import TrashIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { Box, TableCell, TextField, Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import ValidatedMidasNumberTextField from "../../../../components/ValidatedMidasNumberTextField";
import LocateContainer from "../../../Tests/NewTests/LocateContainer";
import {
  SPECIAL_ANALYSIS_TABLE_COLUMNS,
  Option,
  StyledAutocomplete,
  defaultSpecialAnalysisSample,
  sampleGridFontSize,
} from "../constants";

export default function SpecialAnalysisSampleInfoTable({
  mySpecialAnalysis,
  setMySpecialAnalysis,
  locations,
  ownerCanEdit,
  isProcessorRole,
}) {
  const [tempHasErrors, setTempHasErrors] = useState(true);
  const [locateContainerIsOpen, setLocateContainerIsOpen] = useState(false);
  const [locateContainerMidas, setLocateContainerMidas] = useState("");
  const [locateContainerOnlySearch, setLocateContainerOnlySearch] = useState(false);

  const canEdit = ownerCanEdit || isProcessorRole;

  function AddNewSampleRow() {
    const copySpecialAnalysis = structuredClone(defaultSpecialAnalysisSample);

    const copyMySpecialAnalysis = structuredClone(mySpecialAnalysis);

    if (copyMySpecialAnalysis.id)
      copySpecialAnalysis.specialAnalysisID = copyMySpecialAnalysis.id;

    copyMySpecialAnalysis.specialAnalysisSamples.push(copySpecialAnalysis);

    setMySpecialAnalysis(copyMySpecialAnalysis);
  }

  function DeleteSampleRow(index) {
    const copyMySpecialAnalysis = structuredClone(mySpecialAnalysis);

    copyMySpecialAnalysis.specialAnalysisSamples.splice(index, 1);

    setMySpecialAnalysis(copyMySpecialAnalysis);
  }

  function handleLocateContainerOpen(open, midas, onlySearch = false) {
    setLocateContainerIsOpen(open)
    setLocateContainerMidas(midas)
    setLocateContainerOnlySearch(onlySearch)
  }

  function updateData_SpecialAnalysisSample(property, value, index, otherProps = {}) {
    if (property === "sample" && value?.containers?.length > 0) {
      value.containers = value.containers.filter(
        (myContainer) => myContainer.containerStatusName === "Confirmed"
      );
    }
    if (property === "sample" && !value) {
      mySpecialAnalysis.specialAnalysisSamples[index] = defaultSpecialAnalysisSample;
    }
    if (property === "existingContainerID") {
      const specialAnalysisSamples = mySpecialAnalysis.specialAnalysisSamples.map(
        (sample, idx) => {
          if (index === idx) {
            let currentContainer = sample.sample.containers.find(
              (x) => x.id === value
            );
            return {
              ...sample,
              existingContainerID: value,
              requestedAmount: value && sample.requestedAmount,
              requestedAmountUoM: value && (sample.requestedAmountUoM ?? currentContainer?.containerSizeUoM),
              unitOfMeasure: value && (sample.unitOfMeasure ?? currentContainer?.uom),
            };
          }
          return sample;
        }
      );
      setMySpecialAnalysis({
        ...mySpecialAnalysis,
        specialAnalysisSamples: specialAnalysisSamples,
      });
    } else if (property === "requestedAmountUoM") {
      const specialAnalysisSamples = mySpecialAnalysis.specialAnalysisSamples.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              requestedAmount: null,
              requestedAmountUoM: value?.uoMName,
              unitOfMeasure: value,
            };
          }
          return sample;
        }
      );

      setMySpecialAnalysis({
        ...mySpecialAnalysis,
        specialAnalysisSamples: specialAnalysisSamples,
      });
    } else {
      const specialAnalysisSamples = mySpecialAnalysis.specialAnalysisSamples.map(
        (sample, idx) => {
          if (index === idx) {
            return {
              ...sample,
              ...otherProps,
              [property]: value,
            };
          }
          return sample;
        }
      );

      setMySpecialAnalysis({
        ...mySpecialAnalysis,
        specialAnalysisSamples: specialAnalysisSamples,
      });
    }
  }

  return (
    <UXDataTableWithoutBody
      tableWidth="100%"
      cols={SPECIAL_ANALYSIS_TABLE_COLUMNS}
      blankFirstHeader={true}
      tableId="midasNumbersTable"
      rowLength={mySpecialAnalysis.specialAnalysisSamples.length}
      enablePaging={false}
      rowsPerPage={0}
      page={0}
      handleChangePage={null}
      handleChangeRowsPerPage={null}
      noDataFoundMessage="No MIDAS Numbers Found"
      enableAddIcon={canEdit}
      addFunction={() => AddNewSampleRow()}
      addToolTipText="Add new sample"
      isDataLoading={false}
    >
      <StyledTableBody key={"WRSamplesTable"}>
        {mySpecialAnalysis.specialAnalysisSamples.map((oSpecialAnalysisSample, index) => {
          return (
            <StyledTableRow key={`WRSamplesTable~Row${index}`}>
              <StyledTableCell component="th" scope="row">
                {index > 0 && !oSpecialAnalysisSample.id && canEdit && (
                  <Tooltip title="Delete Sample" placement="right">
                    <TrashIcon
                      onClick={() => DeleteSampleRow(index)}
                    ></TrashIcon>
                  </Tooltip>
                )}
              </StyledTableCell>

              <StyledTableCell align="left">
                <ValidatedMidasNumberTextField
                  isDisabled={!canEdit || oSpecialAnalysisSample.id}
                  margin="none"
                  showLabel={true}
                  fontSize={12}
                  fieldWidth="150px"
                  midasNumberObject={oSpecialAnalysisSample.sample}
                  setMidasNumberObject={(e) => {
                    updateData_SpecialAnalysisSample("sample", e, index);
                  }}
                  hasErrors={tempHasErrors}
                  setHasErrors={setTempHasErrors}
                  chemIDToMatch={null}
                />
              </StyledTableCell>

              <StyledTableCell align="right">
                <Box display={"flex"} alignItems={"center"}>
                  <Box>
                    <StyledAutocomplete
                      renderOption={(props2, option) => (
                        <Option {...props2}>{option.containerNumber}</Option>
                      )}
                      disabled={
                        oSpecialAnalysisSample.sample === null || !canEdit
                      }
                      disablePortal
                      noOptionsText={"No Containers Found"}
                      options={oSpecialAnalysisSample?.sample?.containers.filter(x => x.containerStatusName === 'Confirmed' || x.id === oSpecialAnalysisSample?.existingContainerID) ?? []}
                      onChange={(e, value) => {
                        updateData_SpecialAnalysisSample(
                          "existingContainerID",
                          value?.id,
                          index
                        );
                      }}
                      value={oSpecialAnalysisSample?.existingContainerID}
                      getOptionLabel={(option) => {
                        return option?.containerNumber
                          ? option.containerNumber.toString()
                          : oSpecialAnalysisSample?.sample?.containers.length > 0
                            ? oSpecialAnalysisSample?.sample?.containers
                              .find((f) => f.id === option)
                              ?.containerNumber.toString()
                            : ``;
                      }}
                      isOptionEqualToValue={(option, value) =>
                        value?.id === option?.id
                      }
                      autoHighlight
                      style={{ marginBottom: "5px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "100px" }}
                          variant="outlined"
                          margin="dense"
                          size="small"
                          // error={
                          //   oSpecialAnalysisSample.sample &&
                          //   !oSpecialAnalysisSample.existingContainerID &&
                          //   isFieldRequired(
                          //     mySpecialAnalysis.typeName,
                          //     "existingContainerID"
                          //   )
                          // }
                          inputProps={{
                            ...params.inputProps,
                            style: { fontSize: sampleGridFontSize },
                          }}
                        />
                      )}
                    />
                  </Box>

                  {oSpecialAnalysisSample.sample !== null &&
                    oSpecialAnalysisSample.sample?.containers.length > 0 && (
                      <Box>
                        <IconButton
                          type="button"
                          aria-label="search"
                          onClick={() => {
                            oSpecialAnalysisSample.sample !== null &&
                              oSpecialAnalysisSample.sample?.containers.length > 0
                              ? handleLocateContainerOpen(true, oSpecialAnalysisSample?.sample?.sampleName, (oSpecialAnalysisSample.sample === null || !canEdit))
                              : handleLocateContainerOpen(false, '');
                          }}
                        >
                          <SearchIcon style={{ fill: "blue" }} />
                        </IconButton>
                      </Box>
                    )}
                </Box>
              </StyledTableCell>
              {/* Description */}
              <TableCell
                style={{ width: "200px", fontSize: sampleGridFontSize }}
                align="left"
              >
                {oSpecialAnalysisSample.sample
                  ? oSpecialAnalysisSample.sample.description
                  : ""}
              </TableCell>
              <LocateContainer
                open={locateContainerIsOpen && locateContainerMidas === oSpecialAnalysisSample?.sample?.sampleName}
                setOpen={handleLocateContainerOpen}
                incomingUoMObject={null}
                midasNumber={oSpecialAnalysisSample?.sample?.sampleName}
                setSelectedContainer={(value) => {
                  updateData_SpecialAnalysisSample(
                    "existingContainerID",
                    value.id,
                    index
                  );
                }}
                updateAllMethods={null}
                methodInfo={null}
                allowAllSites={true}
                onlySearch={locateContainerOnlySearch}
              />
              <StyledTableCell
                style={{ fontSize: sampleGridFontSize }}
                align="left"
              >
                <StyledAutocomplete
                  disabled={oSpecialAnalysisSample.id && oSpecialAnalysisSample.id > 0}
                  disablePortal
                  options={locations}
                  noOptionsText={"Loading Locations..."}
                  onChange={(event, value) => {
                    updateData_SpecialAnalysisSample(
                      "returnLocationName",
                      value?.locationName,
                      index
                    );
                  }}
                  getOptionLabel={(option) =>
                    option?.locationName
                      ? option.locationName
                      : locations.length > 0
                        ? locations.find((f) => f.locationName === option)
                          ?.locationName
                        : ``
                  }
                  value={oSpecialAnalysisSample.returnLocationName}
                  isOptionEqualToValue={(option, value) => value?.locationName === option?.locationName}
                  autoHighlight
                  autoSelect
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ width: "150px" }}
                      margin="normal"
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                      error={oSpecialAnalysisSample.sample && !oSpecialAnalysisSample.returnLocationName}
                    />
                  )}
                />
              </StyledTableCell>
            </StyledTableRow>
          );
        })}
      </StyledTableBody>
    </UXDataTableWithoutBody>
  );
}