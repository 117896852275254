import { ConvertUOMs } from "../../global";

const defaultUom = {
  uoMName: "g",
  type: "weight",
  metricStandardUoMName: "g",
  metricStandardConversion: 1,
};

export function getConvertedCurrentAmount(oSpecialAnalysisSample, isUpdate, samplesSameKey = []) {
  if (isUpdate) {
    const selectedContainerWithValidCurrentAmountToDisplay =
      oSpecialAnalysisSample &&
      oSpecialAnalysisSample.existingContainer &&
      oSpecialAnalysisSample.existingContainer.currentAmount &&
      oSpecialAnalysisSample.existingContainer.containerSizeUoM;

    if (selectedContainerWithValidCurrentAmountToDisplay) {
      const validDataToDoConversion =
        oSpecialAnalysisSample.sample &&
        oSpecialAnalysisSample.sample.substance &&
        oSpecialAnalysisSample.unitOfMeasure;

      if (oSpecialAnalysisSample.requestedAmountUoM && validDataToDoConversion) {
        const sample = oSpecialAnalysisSample.sample;
        const substance = oSpecialAnalysisSample.sample.substance;
        const currentAmount =
          oSpecialAnalysisSample.existingContainer.currentAmount;
        const originalUoM = oSpecialAnalysisSample.existingContainer.uom;
        const convertToUoM = oSpecialAnalysisSample.unitOfMeasure;

        const newConvertedCurrentAmount = ConvertUOMs(
          sample,
          substance,
          currentAmount,
          originalUoM,
          convertToUoM
        );

        if (typeof newConvertedCurrentAmount === "number") {
          return `${
            newConvertedCurrentAmount % 1
              ? newConvertedCurrentAmount.toFixed(4)
              : newConvertedCurrentAmount
          } ${oSpecialAnalysisSample.requestedAmountUoM}`;
        }
      }
      return `${oSpecialAnalysisSample.existingContainer.currentAmount} ${oSpecialAnalysisSample.existingContainer.containerSizeUoM}`;
    }
  } else {
    const validForConversion =
      oSpecialAnalysisSample &&
      oSpecialAnalysisSample.sample &&
      oSpecialAnalysisSample.sample.containers &&
      oSpecialAnalysisSample.sample.containers.length > 0;

    if (validForConversion) {
      const container = oSpecialAnalysisSample.sample.containers.find(
        (c) => c.id === oSpecialAnalysisSample.existingContainerID
      );

      if (container) {
        const validDataToDoConversion =
          oSpecialAnalysisSample.sample &&
          oSpecialAnalysisSample.sample.substance &&
          oSpecialAnalysisSample.unitOfMeasure;

        if (oSpecialAnalysisSample.requestedAmountUoM && validDataToDoConversion) {
          const sample = oSpecialAnalysisSample.sample;
          const substance = oSpecialAnalysisSample.sample.substance;
          const currentAmount = container.currentAmount;
          const originalUoM = container.uom;
          const convertToUoM = oSpecialAnalysisSample.unitOfMeasure;

          let newConvertedCurrentAmount = ConvertUOMs(
            sample,
            substance,
            currentAmount,
            originalUoM,
            convertToUoM
          );

          if (typeof newConvertedCurrentAmount === "number") {
            samplesSameKey.forEach(item => {
              const requestedAmount = item.requestedAmount ? parseFloat(item.requestedAmount) : 0;
              newConvertedCurrentAmount -= requestedAmount;
            })
            return `${
              newConvertedCurrentAmount % 1
                ? newConvertedCurrentAmount.toFixed(4)
                : newConvertedCurrentAmount
            } ${oSpecialAnalysisSample.requestedAmountUoM}`;
          }
        }
        return `${container.currentAmount} ${container.containerSizeUoM}`;
      } else {
        const sample = oSpecialAnalysisSample.sample;
        const substance = oSpecialAnalysisSample.sample.substance;
        const convertToUoM = oSpecialAnalysisSample.unitOfMeasure ?? null;

        let fullValue = oSpecialAnalysisSample.sample.containers.reduce(
          (accumulator, currentValue) => {
            const newConvertedCurrentAmount = ConvertUOMs(
              sample,
              substance,
              currentValue.currentAmount,
              currentValue.uom,
              convertToUoM
            );

            return accumulator + newConvertedCurrentAmount;
          },
          0
        );

        if (typeof fullValue === "number") {
          samplesSameKey.forEach(item => {
            const requestedAmount = item.requestedAmount ? parseFloat(item.requestedAmount) : 0;
            fullValue -= requestedAmount;
          })
          return `${fullValue % 1 ? fullValue.toFixed(4) : fullValue} ${
            oSpecialAnalysisSample.requestedAmountUoM ?? defaultUom.uoMName
          }`;
        }
      }
    }
  }
  return "";
}
