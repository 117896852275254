import { Box, TextField } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles"; 
import ProjectUserApproval from "../../../../api/Admin/ProjectUserApproval";
import Project from "../../../../api/Admin/Project";
import { useState } from "react";
import { CustomNetworkError } from "../../../../api/helpers";
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { Alert } from "../ProjectManagement/components/Alert";

export type ProjectUserRequestFormProps = {
    project: Project | undefined;
    onSave?: () => void;
    onCancel?: () => void;
};

type ProjectUserRequestAlert = {
    title: string;
    message: string;
    details?: string | undefined;
    onOk?: () => void | undefined;
};

export const ProjectUserRequestForm = ({
    project,
    onSave,
    onCancel
}: ProjectUserRequestFormProps) => {

    const [processLoading, setProcessLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<ProjectUserRequestAlert | undefined>(undefined);
    const [requestDescription, setRequestDescription] = useState("");

    const handleAlertOkClick = () => {
            onSave?.();
            alert?.onOk?.();
            setAlert(undefined);
            onCancel?.();
    };

    const handleSaveProjectUserRequestClick = (requestDescription: string) =>
    {
        setProcessLoading(true);
        ProjectUserApproval.createProjectUserRequest(project?.id, requestDescription)
        .then((response) => {                     
            setAlert(successfullyRequestedProjectUserAlert);                       
        })
        .catch((err) => {            
            setAlert(errorRequestingProjectUserAlert(getNetworkErrorMessage(err)));
        })
        .finally(() => {
            setProcessLoading(false);            
        });
    };    

    return(
    <>
        <Box display="flex" flexDirection={"column"} gap={2}>                                 
            <Box></Box>
            <Box>
                <TextField
                    fullWidth
                    label="Request Description"
                    multiline
                    rows={5}                              
                    onChange = {(e) => setRequestDescription(e.target.value)}
                />
            </Box>                      
            <Box display="flex" flexDirection={"row"} alignItems="center" justifyContent={"center"} textAlign={"center"} gap={2}>                
                <GlobalButton
                    disabled={requestDescription.trim() ===""}
                    variant='contained'
                    onClick={() => {handleSaveProjectUserRequestClick(requestDescription);}}>
                    Save
                </GlobalButton>                               
                <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => { onCancel?.();}}>
                    Cancel
                </GlobalSecondaryButton>
            </Box>           
        </Box>
        <ProgressSpinner display={processLoading} />
        <Alert
            title={alert?.title}
            message={alert?.message}
            details={alert?.details}
            display={alert !== undefined}
            onOk={handleAlertOkClick}
        />
    </>
    );
};

const successfullyRequestedProjectUserAlert: ProjectUserRequestAlert = {
    title: "Success",
    message: "Project access request was successfully sent.",
};

const errorRequestingProjectUserAlert = (details: string): ProjectUserRequestAlert => ({
    title: "Error",
    message: "There was an error sending project access request.",
    details: `Reason: ${details}`,
});

const getNetworkErrorMessage = (error: CustomNetworkError) => {
    const { status } = error;
    if (status === 403)
    {
        return "You are not authorized to view or modify projects.";
    }
    return (
        error.message ??
        "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
    );
};
