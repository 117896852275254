import { Divider, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

export const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export const StyledTextField = styled(TextField)({
  width: "100%",
});

export const SubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "10px",
});

export const SubSectionTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});

export const defaultProductDevelopmentRelease = {
  parentProductName: null,
  isModeledAfterExistingProduct: null,
  existingTechnicalSpec: null,
  isNewSDS: null,
  isCommercialFlagshipProduct: null,
  ecrNumber: null,
  syntheticIndicatorName: null,
  pH6Code: null,
};

export const defaultProductDevelopmentRevision = {
  existingTechnicalSpec: null,
  ecrNumber: null,
};

export const defaultShipment = {
  sdsShipmentName: null,
  existingTechnicalSpec: null,
  componentsExist: null,
  containsNewOrExperimentalComponents: null,
};

export const defaultBlindCoding = {
  sdsBlindCodeName: null,
  blindCodeForCommericalProduct: null,
  commericalProductDetails: null,
  blindCodeForExperimentalProduct: null,
  experimentalDetails: null,
  blindCodeForNonEM: null,
};

export const defaultSDSRequest = {
  projectID: 1,
  requestTypeName: null,
  sdsRequestType: null,
  requestStatusName: "Draft",
  sdsRequestStatus: null,
  description: "",
  requestedByEmail: "",
  createdByEmail: null,
  createdDate: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  assignedToEmail: "",
  assignedDate: null,
  completedDate: null,
  completedByEmail: null,
  priority: "Normal",
  sdsRequestPriority: null,
  priorityReason: "",
  businessUnitName: null,
  businessUnit: null,
  comments: null,
  intendedUseName: null,
  isNorthAmerica: null,
  isSouthAmerica: null,
  isEurope: null,
  isAfrica: null,
  isAsiaPacific: null,
  countrySpecificInfo: null,
  ...defaultProductDevelopmentRelease,
  ...defaultProductDevelopmentRevision,
  ...defaultShipment,
  ...defaultBlindCoding,
};

export const defaultErrors = {
  description: null,
  projectID: false,
  requestTypeName: null,
  businessUnitName: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  requestedByEmail: null,
  intendedUseName: null,
};

export const labelProp = {
  projectID: "Project",
  description: "Description",
  requestTypeName: "Request Type",
  businessUnitName: "Business Unit",
  requestedCompletionDate: "Requested Completion Date",
  lastAcceptableCompletionDate: "Last Acceptable Date",
  requestedByEmail: "Requester Email",
  ecrNumber: "ECR #",
  pH6Code: "PH6 Code",
  priorityReason: "Priority Reason",
  intendedUseName: "Intended Use",
  requestStatusName: "Status",
  assignedToEmail: "Assigned To",
  id: "Request ID",
  commericalProductDetails: "ProMIS Technical Spec. Code",
  experimentalDetails: "Experimental SDS created by EMBSI",
  existingTechnicalSpec: "Provide Closest ProMIS Technical Spec",
};
