import { TextField, Autocomplete } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePickerKeyDownEvent } from "../../../global";

export const autoCompleteFontSize = 16;

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const WorkRequestFormInput = ({
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  validation,
  placeholder,
  disabled,
  helperText,
  maxLength,
  required = false,
  type = 'text',
  size = 'small',
  margin = 'normal',
}) => {

  return <TextField
    placeholder={placeholder ?? label}
    type={'text'}
    value={data[property] ?? ''}
    disabled={disabled}
    onChange={(event) => {
      const value = type === 'number' ? event.target.value.replace(/\D/g, '') : event.target.value;
      setData({ ...data, [property]: value });
      if (validation || required) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [property]: (validation !== undefined && !validation(value)) || !value || value === ''
        }));
      }
    }}
    error={errors[property]}
    helperText={helperText}
    size={size}
    margin={margin}
    label={required ? `* ${label}` : label}
    fullWidth
    variant="outlined"
    InputLabelProps={{ shrink: true }}
    inputProps={{ maxLength: maxLength }}
  />
}

export const WorkRequestFormAutocomplete = ({
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  helperText,
  noOptionsText,
  propertyLabel,
  options = [],
  required = false,
  size = 'small',
  margin = 'normal',
  styled,
  disabled,
  autoSelect = true,
}) => {

  function AutoCompleteChangeAndValidate(value) {
    if (required) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [property]: !value || value === ''
      }));
    }
    const copyData = structuredClone(data);

    copyData[property] = value;

    setData(copyData);
  }

  const propertyLabelToUse = propertyLabel ?? property;

  return <StyledAutocomplete
    disabled={disabled}
    disablePortal
    style={{ width: "100%", ...styled }}
    noOptionsText={noOptionsText}
    options={options}
    onChange={(e, value) =>
      AutoCompleteChangeAndValidate(value?.[property])
    }
    getOptionLabel={(option) =>
      option?.[propertyLabelToUse]
        ? option[propertyLabelToUse]
        : options.length > 0
          ? options.find((f) => f?.[property] === option)
          ?.[propertyLabelToUse] ?? data?.[propertyLabelToUse]
          : ""
    }
    autoHighlight
    autoSelect={autoSelect}
    value={data?.[property]}
    isOptionEqualToValue={(option, value) =>
      value?.[property] === option?.[property]
    }
    renderInput={(params) => (
      <TextField
        fullWidth
        {...params}
        variant="outlined"
        size={size}
        margin={margin}
        label={required ? `* ${label}` : label}
        error={errors[property]}
        helperText={helperText}
        InputLabelProps={{ shrink: true }}
        InputProps={{ ...params.InputProps }}
      />
    )}
  />
}

export const WorkRequestFormDate = ({
  setData,
  data,
  setErrors,
  errors,
  label,
  property,
  helperText,
  minDate,
  maxDate,
  onKeyDown,
  required = false,
  size = 'small',
  margin = 'normal',
  disabled,
}) => {

  return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
      onKeyDown={onKeyDown}
      size={size}
      inputFormat="MM/dd/yyyy"
      label={required ? `* ${label}` : label}
      onChange={(e) => {
        setData({
          ...data,
          [property]: e,
        })
        if (required) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [property]: !e || e === ''
          }));
        }
      }}
      minDate={minDate}
      maxDate={maxDate}
      value={data[property]}
      disabled={disabled}
      renderInput={(params) => (
        <StyledShortTextField
          onKeyDown={DatePickerKeyDownEvent}
          {...params}
          margin={margin}
          variant="outlined"
          size={size}
          error={errors[property]}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...params.inputProps }}
          InputProps={{ ...params.InputProps }}
          style={{ width: "100%" }}
        />
      )}
    />
  </LocalizationProvider>
}