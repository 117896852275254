import MoreOptions from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { pdf } from "@react-pdf/renderer";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import WorkRequest from "../../api/WorkRequest/WorkRequest";
import ModalSimpleButton from "../../components/Modal/ModalSimpleButton";
import {
  StyledTableBody,
  StyledTableCell,
  StyledTableCellLink,
  StyledTableRow,
  UXDataTableWithoutBody,
} from "../../components/UXDataTable";
import { convertDateFormat, featureToggle, hasRole, Roles } from "../../global";
import Summary from "./PrintableComponents/Summary";
import User from "../../api/Admin/User";
import PrintLabel from "../../api/LIMS/PrintLabel";
import PrintInformation from "../../components/PrintInformation";
import AssignTechnicianModal from "./AssignTechnicianModal";
import UserContext from "../../context/UserContext";

const MyWorkRequestCols = [
  "ID",
  "Category",
  "Priority",
  "Description",
  "Requester",
  "Assigned To",
  "Requested Date",
  "Requested Completion",
  "Last Acceptable Date",
  "Facility",
  "Business Unit",
  "Status",
  "Parent WR ID",
  "Submitted By",
];

const TableAllRequests = ({
  filters,
  filteredAllRequests,
  userEmail,
  isDataLoading,
  isProcessing,
  billingList,
  reloadWorkRequest,
  copyWorkRequest,
}) => {
  const [assignTechnicianModal, setAssignTechnicianModal] = useState(false);
  const [printLabelInfo, setPrintLabelInfo] = useState(null);
  const [printInfoOpen, setPrintInfoOpen] = useState(false);
  const [clickedMenuId, setClickedMenuId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = useState(null);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageData, setPageData] = useState([]);

  const currentUser = useContext(UserContext);

  const userRoles = currentUser?.idTokenClaims;
  const isDeveloperRole = hasRole(Roles.Developer, userRoles?.roles);
  const isAssignerRole = hasRole(Roles.WorkRequestAssigner, userRoles?.roles) || isDeveloperRole;

  useEffect(() => {
    setPageData(filteredAllRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [page, rowsPerPage, filteredAllRequests]);

  useEffect(() => {
    setPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }
  const openModalMessage = (title, message, btnText) => {
    setModalMessagesOpen(true);
    setModalMessagesText(message);
    setModalMessagesButtonText(btnText);
    setModalMessagesTitle(title);
  };

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest);

    setAnchorEl(e.currentTarget);
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null);

    setAnchorEl(null);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const assignTechnician = (assignTechnicianEmail) => {
    WorkRequest.updateAssignTo(clickedMenuId, assignTechnicianEmail)
      .then((res) => {
        if (res?.message !== "Success") {
          openModalMessage(
            "Fail",
            `There was an error updating technician. ${res?.message ? res?.message : ""}`,
            "Ok",
          );
        } else {
          reloadWorkRequest(clickedMenuId);
          openModalMessage("Success", "Technician successfully updated", "Close");
        }
      })
      .catch((err) => {
        openModalMessage("Error", "There was an error updating technician", "Close");
      });
  };
  const currentRequest = pageData?.find((x) => x.id === clickedMenuId) ?? null;

  async function getSummary() {
    if (moreOptionsSelected && moreOptionsSelected.id) {
      let businessPhones = "";

      const userPreferences = await User.validateEmailAddress(userEmail);

      if (
        userPreferences &&
        userPreferences.businessPhones &&
        userPreferences.businessPhones.length > 0
      ) {
        businessPhones = userPreferences.businessPhones.join(", ");
      }

      WorkRequest.getById(moreOptionsSelected.id).then((response) => {
        if (response) {
          const asPdf = pdf([]);
          asPdf.updateContainer(
            <Summary
              workRequest={response}
              businessPhones={businessPhones}
              billingList={billingList}></Summary>,
          );

          asPdf.toBlob().then((blob) => {
            var url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }
      });
    }
  }

  function openPrintInfo(sample) {
    let arrayPrintInfo = [];
    sample.forEach((sample) => {
      let newPrintInfo = new PrintLabel({
        sampleName: sample.sampleName,
        containerNumbers: [sample.existingContainer.containerNumber],
        includeAdditionalSampleInformation: false,
        chemIDOnly: false,
        printLabelSizeType: null,
        isBlindCoded: false,
        blindDescription: null,
        blindSampleName: null,
        isShelfLabel: false,
        shelfLabelText: null,
        includeShelfBarcode: false,
      });

      arrayPrintInfo.push(newPrintInfo);
    });

    setPrintLabelInfo(arrayPrintInfo);
    setPrintInfoOpen(true);
  }

  const reprintLabel = (id) => {
    WorkRequest.getById(id).then((result) => {
      if (!result.workRequestSamples.length) {
        openModalMessage(
          "No samples to print",
          "The selected request has no samples to be printed",
          "Close",
        );
        return;
      }

      openPrintInfo(result.workRequestSamples);
    });
  };
  function closePrintInformation() {
    setPrintInfoOpen(false);
  }

  return (
    <div>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={MyWorkRequestCols.filter(
          (f) => (!isProcessing && f !== "Business Unit") || isProcessing
        )}
        blankFirstHeader={true}
        tableId="allStudies"
        rowLength={filteredAllRequests?.length}
        enablePaging={true}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage="No Work Requests Found"
        isDataLoading={isDataLoading}>
        <StyledTableBody key={"allRequestTablebody"}>
          {pageData.map((workRequest, index) => {
            return (
              <StyledTableRow key={`table-row-${index}`}>
                <StyledTableCell style={{ width: "10px" }}>
                  <MoreOptions
                    onClick={(e) => {
                      setClickedMenuId(workRequest.id);
                      handleOpenMoreOptions(e, workRequest);
                    }}
                    color="black"
                  />
                </StyledTableCell>
                <StyledTableCellLink
                  scope="row"
                  component={Link}
                  to={{
                    pathname: `${featureToggle.WorkRequestRedesign
                      ? "/newWorkRequestRedesign/"
                      : "/viewWorkRequests/"
                      }${workRequest.id}`,
                    state: { tab: isProcessing ? "process" : "submit" },
                  }}>
                  {workRequest.id}
                </StyledTableCellLink>
                <StyledTableCell align="right">{workRequest.typeName}</StyledTableCell>
                <StyledTableCell align="right">{workRequest.priorityName}</StyledTableCell>
                <StyledTableCell align="right">{workRequest.description}</StyledTableCell>
                <StyledTableCell align="right">{workRequest.requestedByEmail}</StyledTableCell>
                <StyledTableCell align="right">{workRequest.assignedToEmail}</StyledTableCell>
                <StyledTableCell align="right">
                  {convertDateFormat(workRequest.createdDate, false)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {convertDateFormat(workRequest.requestedCompletionDate, false)}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {convertDateFormat(workRequest.lastAcceptableCompletionDate, false)}
                </StyledTableCell>
                <StyledTableCell align="right">{workRequest.facilityName}</StyledTableCell>
                {isProcessing && (
                  <StyledTableCell align="right">{workRequest.businessUnitName}</StyledTableCell>
                )}
                <StyledTableCell align="right">{workRequest.statusName}</StyledTableCell>
                <StyledTableCellLink
                  scope="row"
                  component={Link}
                  to={{
                    pathname: `${featureToggle.WorkRequestRedesign
                      ? "/newWorkRequestRedesign/"
                      : "/viewWorkRequests/"
                    }${workRequest.parentWorkRequestID}`,
                    state: { tab: isProcessing ? "process" : "submit" },
                  }}>
                  {workRequest.parentWorkRequestID}
                </StyledTableCellLink>
                <StyledTableCell align="right">{workRequest.createdByEmail}</StyledTableCell>
              </StyledTableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions()}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}>
        {isProcessing && (
          <>
            <MenuItem
              onClick={() => {
                getSummary();
                handleCloseMoreOptions();
              }}>
              Print
            </MenuItem>
            <MenuItem
              onClick={() => {
                reprintLabel(currentRequest.id);
                handleCloseMoreOptions();
              }}
              disabled={false}>
              Reprint Work Request Label
            </MenuItem>
            <MenuItem
              onClick={() => {
                setAssignTechnicianModal(true);
                handleCloseMoreOptions();
              }}
              disabled={
                (!(
                  currentRequest?.statusName === "Submitted" &&
                  currentRequest?.createdByEmail === userEmail
                ) && !(currentRequest?.assignedToEmail === userEmail)) || !isAssignerRole
              }>
              Assign
            </MenuItem>
          </>
        )}
        <MenuItem
          onClick={() => {
            copyWorkRequest(currentRequest.id);
            handleCloseMoreOptions();
          }}
          // disabled={featureToggle.WorkRequestRedesign}
        >
          Copy Work Request
        </MenuItem>
      </Menu>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}>
        <label>{modalMessagesText}</label>
      </ModalSimpleButton>
      {clickedMenuId > 0 && (
        <AssignTechnicianModal
          workRequestId={clickedMenuId}
          assignTechnician={assignTechnician}
          assignTechnicianModal={assignTechnicianModal}
          setAssignTechnicianModal={setAssignTechnicianModal}
        />
      )}
      <PrintInformation
        open={printInfoOpen}
        setOpen={setPrintInfoOpen}
        button2Action={closePrintInformation}
        button2Text="Close"
        printLabelInfo={printLabelInfo}
        isShelfLabel={false}
      />
    </div>
  );
};

export default TableAllRequests;
