import { Box, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FileIcon from "@mui/icons-material/DescriptionOutlined";
import { FieldProps } from "formik";
import FileDragAndDrop from "../../../../../components/FileDragAndDrop";

export type FileFieldProps = FieldProps<File> & {
  label: string;
  disabled: boolean;
  onDownloadClick: () => void;
  isSaved: boolean;
};

export const FileField: React.FC<FileFieldProps> = ({
  field,
  form,
  label,
  disabled,
  onDownloadClick,
  isSaved,
}) => {

  const { name, value } = field;

  const handleFilesChanged = (files: File[]) => {
    const file = files[0];
    form.setFieldValue(name, file, true);
  };

  const handleDeleteClick = () => {
    form.setFieldValue(name, undefined, true);
  };

  const handleFileDownloadClick = () => {
    onDownloadClick();
  };

  return (
    <>
      <Grid item xs={12}>
        {label}
      </Grid>
      {value?.name ? (
        <Grid item xs={12}>
          <FileCard
            fileName={value.name}
            onDownloadClick={handleFileDownloadClick}
            onDeleteClick={handleDeleteClick}
            disabled={disabled}
            isSaved={isSaved}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <FileDragAndDrop
            handleFiles={handleFilesChanged}
            files={value ? [value] : []}
            handleDeleteFile={undefined}
            container={undefined}
            handleFileDownload={undefined}
            showFiles={false}
            enableMultiSelect={false}
            disabled={disabled}
          />
        </Grid>
      )}
    </>
  );
};

type FileCardProps = {
  fileName: string;
  onDownloadClick: () => void;
  onDeleteClick: () => void;
  disabled?: boolean;
  isSaved: boolean;
};

export const FileCard = ({
  fileName,
  onDownloadClick,
  onDeleteClick,
  disabled = false,
  isSaved
}: FileCardProps) => {
  
  const name = fileName;

  const handleDownloadClick = () => {
    onDownloadClick();
  };

  const handleDeleteClick = () => {
    onDeleteClick();
  };

  return (
    <Box
      display="flex"
      sx={{
        padding: 1,
        justifyContent: "space-between",
        border: "1px solid",
        borderColor: "grey.400",
        borderRadius: 1,
      }}>
      <Box display="flex" sx={{ alignItems: "center" }}>
        <FileIcon color="action" fontSize="large" sx={{ marginRight: 1 }} />
        <b>{name}</b>
      </Box>
      <Box sx={{ justifyContent: "flex-end" }}>
        <IconButton onClick={ isSaved ? handleDownloadClick: undefined } disabled={ !isSaved || disabled}>
          <DownloadIcon color={!isSaved || disabled ? "disabled" : "primary"} />
        </IconButton>
        <IconButton onClick={handleDeleteClick} disabled={disabled}>
          <DeleteIcon sx={{ color: disabled ? "disabled" : "red" }} />
        </IconButton>
      </Box>
    </Box>
  );
};
