import API from "..";
import { checkAPIResponse } from "../helpers";

export default class ProjectUser {
    constructor({
      projectId,
      userEmail
    }) {
      this.projectId = projectId;
      this.userEmail = userEmail;
    }

    static async getUsersByProjectId(projectID) {
      const api = await API();
      const response = await api.get(`/userprojectmapping/${projectID}`);
      checkAPIResponse(response);
      const result = response.data.result;
      return result ? result.map((d) => new ProjectUser(d)) : [];
    }

    static async AddUserByProjectId(projectID, userEmail) {
      const api = await API();
      const response = await api.post(`/userprojectmapping/${projectID}`, userEmail);
      checkAPIResponse(response);
      return response.data.result;
    }

    static async DeleteUserByProjectId(projectID, userEmail) {
      const api = await API();
      const response = await api.delete(`/userprojectmapping/${projectID}/${userEmail}`);
      checkAPIResponse(response);
      return response.data.result;
    }
}
