import { useState } from "react";
import { Form, Formik } from "formik";
import {  Grid } from "@mui/material";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import Project from "../../../../api/Admin/Project";

export type ProjectUserAddFormProps = {
  project: Project | undefined;
  onSave?: ((userEmail: string) => void) | undefined;
  onCancel: () => void;    
};

type ProjectUserAddFormCriteria = {
  projectId: number;
  userEmail: string;
};

export const ProjectUserAddForm = ({
  project,
  onSave,
  onCancel
}: ProjectUserAddFormProps) => {

  const [userEmailError, setUserEmailError] = useState<boolean>(false);
  
  const initialValues = {
    projectId: project?.id ?? 0,
    userEmail: ""
  };

  const handleSaveClick = (values: ProjectUserAddFormCriteria) => {
    onSave?.(values.userEmail);
  };

  const handleCancelClick = () => {        
      onCancel?.();        
  };

  return (
    <Formik
      initialValues={initialValues}         
      enableReinitialize={true}          
      onSubmit={handleSaveClick}>
      {({
        values,            
        setFieldValue,
      }) => (
        <Form>
          <Grid container spacing={2} style={{ padding: 5 }}>
            <Grid item xs={12} justifyContent="center">
              <EmailAddressTextField
                key="userEmail"
                validatedUserEmail={values.userEmail}
                setValidatedUserEmail={(value: string) => {
                  setFieldValue("userEmail", value);
                }}
                setHasErrors={setUserEmailError}
                hasErrors={userEmailError}
                isDisabled={false}
                labelText="* User Email"
                placeholderText="User Email"
                size={"small"}
                margin={"none"}
                fontSize={16}
                fieldWidth={"85%"}
                showPlusMeButton={false}
              />
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
                <GlobalButton
                type="submit"
                variant="contained"      
                disabled={values.userEmail === "" || userEmailError}                  
                style={{ marginRight: 10 }}>  
                Add
                </GlobalButton>
            </Grid>
            <Grid item xs={6}>
                <GlobalSecondaryButton
                variant="contained"
                onClick={() => {
                    handleCancelClick();
                }}>
                Cancel
                </GlobalSecondaryButton>
            </Grid>                  
          </Grid>                         
        </Form>
      )}
    </Formik>
  );
};