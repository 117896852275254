import { useEffect, useState } from "react";
import { Field, FieldMetaProps, Form, Formik } from "formik";
import { Box, Grid, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import ModalTwoButtons from "../../../../components/Modal/ModalTwoButtons";
import { ProjectFormValidationSchema } from "./ProjectFormValidationSchema";
import Project from "../../../../api/Admin/Project";
import { FileField } from "./formFields/FileField";
import { downloadFile } from "./helpers";
import { Alert } from "./components/Alert";
import { DatePickerField } from "./formFields/DatePickerField";
import { CustomNetworkError } from "../../../../api/helpers";
import CustomModal from "../../../../components/Modal";
import { ProjectRejectForm } from "../ProjectReview/ProjectRejectForm"
import { ProgressSpinner } from "../../../../components/ProgressSpinner";
import { ProjectResubmitForm } from "../ProjectManagement/ProjectResubmitForm"

enum ProjectFormField {
  Name = "name",
  Description = "description",
  OwnerEmail = "ownerEmail",
  SecondaryOwnerEmail = "secondaryOwnerEmail",
  ExpirationDate = "expirationDate",
  RestrictAtEndOfLife = "RestrictAtEndOfLife",
  Status = "isActive",
  NdaAgreementFile = "ndaAgreementFile",
  RejectionMessage = "rejectionMessage"
}

export type ProjectFormCriteria = {
  id: number;
  name: string;
  description: string;
  ownerEmail: string;
  secondaryOwnerEmail: string;
  expirationDate?: Date;
  restrictAtEndOfLife: boolean;
  isActive: boolean;
  ndaAgreementFile?: File;
  reviewStatus: string;
  rejectionMessage: string;
};

export type ProjectFormProps = {
  project: Project | undefined;
  invalidProjectNames?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  onSave?: ((values?: ProjectFormCriteria) => void) | undefined;
  onCancel?: () => void;
  onRejectSave?: () => void;
  onResubmitSave?: () => void;
};

export const ProjectForm = ({
  project,
  invalidProjectNames,
  disabled = false,
  readOnly = false,
  onSave,
  onCancel,
  onRejectSave,
  onResubmitSave
}: ProjectFormProps) => {
  
  const [ownerEmailError, setOwnerEmailError] = useState<boolean>(false);
  const [secondaryOwnerEmailError, setSecondaryOwnerEmailError] = useState<boolean>(false);
  const [displayLostChangesAlert, setDisplayLostChangesAlert] = useState<boolean>(false);
  const [disableFileCard, setDisableFileCard] = useState<boolean>(false);
  const [alert, setAlert] = useState<ProjectFormAlert | undefined>(undefined);
  const today = new Date();
  const defaultDateTime = new Date(today.getFullYear() + 3, today.getMonth(), today.getDate());
  const [processLoading, setProcessLoading] = useState<boolean>(false);
  const [showProjectApprovalFormModal, setShowProjectApprovalFormModal] = useState(false);
  const [showProjectResubmitFormModal, setShowProjectResubmitFormModal] = useState(false);
  const [fileSaved, setFileSaved] = useState(false);

  const initialValues = {
    id: project?.id ?? 0,
    name: project?.name ?? "",
    description: project?.description ?? "",
    ownerEmail: project?.ownerEmail ?? "",
    secondaryOwnerEmail: project?.secondaryOwnerEmail ?? "",
    expirationDate: project?.projectEndDate ?? defaultDateTime,
    restrictAtEndOfLife: project?.restrictAtEndOfLife ?? false,
    isActive: project?.isActive ?? false,
    ndaAgreementFileName: project?.ndaAgreementFileName ?? undefined,
    ndaAgreementFile: project?.ndaAgreementFile ?? undefined,
    reviewStatus: project?.reviewStatus ?? "",
    rejectionMessage: project?.rejectionMessage ?? ""
  };

  useEffect(() => {
    if(initialValues.ndaAgreementFile){
      setFileSaved(true);
    }
  }, [initialValues.ndaAgreementFile]);

  const canModifyIsActive : boolean = initialValues.reviewStatus.toLowerCase() === "approved" 
    || (initialValues.reviewStatus.toLowerCase() === "rejected" && initialValues.isActive);

  const fieldErrorMessage = (fieldMetaProps: FieldMetaProps<ProjectFormCriteria>) => {
    const { error, touched } = fieldMetaProps;
    return touched && Boolean(error) ? error : undefined;
  };

  const handleSaveClick = (values: ProjectFormCriteria) => {
      onSave?.(values);
      setFileSaved(true);
  };

  const handleCancelClick = (formIsDirty: boolean) => {
    if (formIsDirty) {
      setDisplayLostChangesAlert(true);
    } else {
      onCancel?.();
    }
  };

  const handleFileDownloadClick = () => {
    setDisableFileCard(true);
    Project.downloadNdaAgreementFile(project?.id)
      .then((response) => {
        downloadFile(response, project?.ndaAgreementFileName);
      })
      .catch((err) => {
        setAlert(errorDownloadFileAlert(err));
      })
      .finally(() => {
        setDisableFileCard(false);
      });
  };

  const handleLostChangesOkClick = () => {
    setDisplayLostChangesAlert(false);
    onCancel?.();
  };

  const handleLostChangesCancelClick = () => {
    setDisplayLostChangesAlert(false);
  };

  const handleAlertOkClick = () => {
    setAlert(undefined);
    if (onSave){
      onSave();
    }
    onCancel?.();
  };

  const handleApprovalClick = () =>
  {    
    setProcessLoading(true);
    Project.reviewPendingProjects(project?.id, true)
      .then((response: Project) => {          
      if(response.reviewStatus === "Approved"){
          setAlert(successfullyApprovedProjectAlert);
        }            
      })
      .catch((err) => {            
        setAlert(errorReviewingProjectAlert(getNetworkErrorMessage(err)));
      })
      .finally(() => {
        setProcessLoading(false);            
      });
  };   

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={() => ProjectFormValidationSchema(invalidProjectNames ?? [])}
      onSubmit={handleSaveClick}>
      {({
        values,
        isValid,
        dirty,
        errors,
        touched,
        handleBlur,
        handleChange,
        getFieldMeta,
        setFieldValue,
      }) => {
        return (
        <Form>
          <Grid container spacing={2} style={{ padding: 5 }}>
            <Grid item xs={10}>
              <TextField
                id={ProjectFormField.Name}
                name={ProjectFormField.Name}
                label="* Name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled || readOnly}
                size="small"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={Boolean(fieldErrorMessage(getFieldMeta(ProjectFormField.Name)))}
                helperText={fieldErrorMessage(getFieldMeta(ProjectFormField.Name)) ?? " "}
                aria-readonly={true}
              />
            </Grid>
            <Grid
              item
              xs={2}
              container
              sx={{
                justifyContent: "flex-end",
                alignItems: "flex-start",
              }}>
              <FormControlLabel
                label="Active"
                labelPlacement="end"
                control={
                  <Switch
                    id={ProjectFormField.Status}
                    name={ProjectFormField.Status}
                    defaultChecked={initialValues.isActive}
                    value={values.isActive}
                    onChange={handleChange}
                    color="success"
                    disabled={disabled || readOnly || !canModifyIsActive}
                  />
                }
              />              
            </Grid>                       
            <Grid item xs={12}>
              <TextField
                id={ProjectFormField.Description}
                name={ProjectFormField.Description}
                label="Description"
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={disabled || readOnly}
                size="small"
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
                error={Boolean(fieldErrorMessage(getFieldMeta(ProjectFormField.Description)))}
                helperText={fieldErrorMessage(getFieldMeta(ProjectFormField.Description)) ?? " "}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailAddressTextField
                key={ProjectFormField.OwnerEmail}
                validatedUserEmail={values.ownerEmail}
                setValidatedUserEmail={(value: string) => {
                  setFieldValue(ProjectFormField.OwnerEmail, value);
                }}
                setHasErrors={setOwnerEmailError}
                hasErrors={ownerEmailError}
                isDisabled={disabled || readOnly}
                labelText="* Owner Email"
                placeholderText="Owner Email"
                size={"small"}
                margin={"none"}
                fontSize={16}
                fieldWidth={"85%"}
                showPlusMeButton={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EmailAddressTextField
                key={ProjectFormField.SecondaryOwnerEmail}
                validatedUserEmail={values.secondaryOwnerEmail}
                setValidatedUserEmail={(value: string) => {
                  setFieldValue(ProjectFormField.SecondaryOwnerEmail, value);
                }}
                setHasErrors={setSecondaryOwnerEmailError}
                hasErrors={secondaryOwnerEmailError}
                isDisabled={disabled || readOnly}
                labelText="Secondary Owner Email"
                placeholderText="Secondary Owner Email"
                size={"small"}
                margin={"none"}
                fontSize={16}
                fieldWidth={"85%"}
                showPlusMeButton={true}
              />
              {typeof errors.secondaryOwnerEmail === 'string' &&
               <ErrorDiv >{errors.secondaryOwnerEmail}</ErrorDiv>
              }
            </Grid>
            <Grid item xs={6}>
              <Field
                name={ProjectFormField.ExpirationDate}
                label="* Expiration Date/Time"
                value={values.expirationDate}
                component={DatePickerField}
                setFieldValue={setFieldValue}
                disabled={disabled || readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    id={ProjectFormField.RestrictAtEndOfLife}
                    name={ProjectFormField.RestrictAtEndOfLife}
                    checked={values.restrictAtEndOfLife}
                    value={values.restrictAtEndOfLife}
                    onChange={(e) => setFieldValue("restrictAtEndOfLife", e.target.checked)}
                    disabled={disabled || readOnly}
                  />
                }
                label="Restrict At End Of Life"
                labelPlacement="end"
              />
            </Grid>
            <Field
              name={ProjectFormField.NdaAgreementFile}
              label="* NDA/Agreement Number File"
              value={values.ndaAgreementFile}
              component={FileField}
              setFieldValue={setFieldValue}
              disabled={disableFileCard || disabled || readOnly}
              onDownloadClick={handleFileDownloadClick}
              isSaved={fileSaved}
            />
            {values.rejectionMessage && (
              <Grid item xs={12}>
                <TextField
                  id={ProjectFormField.RejectionMessage}
                  name={ProjectFormField.RejectionMessage}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  label="Reason for rejection"
                  multiline
                  rows={5}
                  value ={values.rejectionMessage ?? ""} 
                  disabled={true}          
                />
              </Grid>
            )}
            <><br></br></>
            {readOnly && values.reviewStatus.toLowerCase() === "rejected" && (
              <>
                <Grid item xs={6} container justifyContent="flex-end">
              <GlobalButton
                variant="contained"
                onClick={() => {
                  setShowProjectResubmitFormModal(true);
                }}>
                Resubmit
              </GlobalButton>
            </Grid>
            <Grid item xs={6}>
                  <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => {
                      handleCancelClick(dirty);
                    }}>
                    Cancel
                  </GlobalSecondaryButton>
                </Grid>
              </>
              
            )}            
            {readOnly && values.reviewStatus.toLowerCase() === "pending" && (
              <>
                <Grid container item gap={2} justifyContent="center">                  
                    <GlobalButton
                      variant="contained"
                      onClick={() => {
                        handleApprovalClick();
                      }}>
                      Approve
                    </GlobalButton>                
                  
                    <GlobalSecondaryButton
                      variant="contained"
                      onClick={() => {
                        setShowProjectApprovalFormModal(true);
                      }}>
                      Reject
                    </GlobalSecondaryButton>
                  
                
                  <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => {
                      handleCancelClick(dirty);
                    }}>
                    Cancel
                  </GlobalSecondaryButton>
                </Grid>             
              </>              
            )}
            {!readOnly && (
              <>
                <Grid item xs={6} container justifyContent="flex-end">
                  <GlobalButton
                    type="submit"
                    variant="contained"
                    disabled={
                      !isValid ||
                      !dirty ||
                      disabled ||
                      (values.ndaAgreementFile === undefined && !touched.ndaAgreementFile)
                    }
                    style={{ marginRight: 10 }}>
                    Save
                  </GlobalButton>
                </Grid>
                <Grid item xs={6}>
                  <GlobalSecondaryButton
                    variant="contained"
                    onClick={() => {
                      handleCancelClick(dirty);
                    }}>
                    Cancel
                  </GlobalSecondaryButton>
                </Grid>
              </>
              
            )}
          </Grid>
          <CustomModal
            title="Add Reason For Rejection"
            open={showProjectApprovalFormModal}
            setOpen={setShowProjectApprovalFormModal}
            setClose={undefined}>
            <ProjectRejectForm
                project={project}
                onSave={onRejectSave}
                onCancel={() => {setShowProjectApprovalFormModal(false)}}
            />
          </CustomModal>
          <CustomModal
            title="Resubmit Project"
            open={showProjectResubmitFormModal}
            setOpen={setShowProjectResubmitFormModal}
            setClose={undefined}>
            <ProjectResubmitForm
                project={project}
                onSave={onResubmitSave}
                onCancel={() => {setShowProjectResubmitFormModal(false)}}
            />
          </CustomModal>
          <LostChangesAlert
            title="Unsaved Changes"
            button1Text="OK"
            button1Action={handleLostChangesOkClick}
            isButton1Disabled={false}
            button2Text="Cancel"
            button2Action={handleLostChangesCancelClick}
            isButton2Disabled={false}
            open={displayLostChangesAlert}
            setOpen={undefined}
            setClose={undefined}>
            <Box>You have unsaved changes. If you leave, your changes will be lost.</Box>
          </LostChangesAlert>
          <Alert
            title={alert?.title}
            message={alert?.message}
            details={alert?.details}
            display={alert !== undefined}
            onOk={handleAlertOkClick}
          />
          <ProgressSpinner display={processLoading} />
        </Form>
      )}}
    </Formik>
  );
};

const ErrorDiv = styled('div')({
  color: 'red', 
  marginTop:5, 
  marginLeft:20, 
  fontSize: 'small' 
});

type ProjectFormAlert = {
  title: string;
  message: string;
  details?: string | undefined;
};

type ProjectApprovalAlert = {
  title: string;
  message: string;
  details?: string | undefined;
  onOk?: () => void;
};

const errorDownloadFileAlert = (details: string): ProjectFormAlert => ({
  title: "Error",
  message: "There was an error downloading the NDA Agreement file.",
  details: `Reason: ${details}`,
});

const successfullyApprovedProjectAlert: ProjectApprovalAlert = {
  title: "Approved",
  message: "Project was successfully approved.",
};

const errorReviewingProjectAlert = (details: string): ProjectApprovalAlert => ({
  title: "Error",
  message: "There was an error reviewing project.",
  details: `Reason: ${details}`,
});

const getNetworkErrorMessage = (error: CustomNetworkError) => {
  const { status } = error;
  if (status === 403)
  {
      return "You are not authorized to perform this action";
  }
  return (
      error.message ??
      "The application has encountered an unknown error and cannot complete the action. Please contact a system administrator for help."
  );
};

const LostChangesAlert = styled(ModalTwoButtons)({});
