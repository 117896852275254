import { Autocomplete, Divider, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledTableCell } from "../../../components/UXDataTable";
import { convertCelsiusToFahrenheit, convertFlashPoint } from "../../../global";

export const autoCompleteFontSize = 16;

export const StyledDivider = styled(Divider)({
  marginTop: "10px",
});

export const StyledDiv = styled("div")({
  display: "flex",
  marginTop: "5px",
});

export const StyledTextField = styled(TextField)({
  width: "100%",
});

export const StyledTableCellSmall = styled(StyledTableCell)({
  width: 30,
});

export const StyledTableCellMedium = styled(StyledTableCell)({
  width: 130,
});

export const StyledTableCellLarge = styled(StyledTableCell)({
  width: 260,
});

export const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
    fontSize: autoCompleteFontSize,
  },
  width: "15%",
  marginRight: "15px",
  marginBottom: "20px",
});

export const SubTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "10px",
});

export const SubSectionTitle = styled("p")({
  fontFamily: "EMprint",
  fontWeight: "600",
  fontSize: "16px",
  color: "#545459",
  textTransform: "none",
  textAlign: "left",
  paddingTop: "20px",
});

export const defaultWorkRequest = Object.freeze({
  statusName: "Draft",
  description: "",
  requestedByEmail: "",
  createdByEmail: null,
  createdDate: null,
  lastModifiedByEmail: null,
  lastModifiedDate: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  assignedToEmail: "",
  assignedDate: null,
  completedDate: null,
  completedByEmail: null,
  priorityName: "Normal",
  priorityReason: "",
  businessUnitName: null,
  comments: "",
  facilityName: null,
  isNonChemical: false,
  chargeCode: "",
  submissionDate: null,
  hasContainer: true,
});

export const defaultErrorsInformation = Object.freeze({
  description: null,
  businessUnitName: null,
  requestedCompletionDate: null,
  lastAcceptableCompletionDate: null,
  requestedByEmail: null,
})

export const defaultShippingAddress = Object.freeze({
  recipientName: '',
  company: '',
  department: '',
  address1: '',
  city: '',
  state: '',
  country: '',
  postalCode: '',
  phoneNumber1: '',
  emailAddress: '',
});

export const defaultShippingRequest = Object.freeze({
  requestedDeliveryDate: null,
  shippingCarrierID: null,
  shippingAddress: null,
  is3PTesting: false,
  shippingReasonName: null,
  isPrepaid: null,
  isAcknowledged: false,
  isTSCAReview: false,
  shippingItems: [],
  containsSamples: false,
});

export const defaultErrorsShippingInformation = Object.freeze({
  recipientName: null,
  phoneNumber1: null,
  country: null,
  postalCode: null,
  state: null,
  city: null,
  address1: null,
  requestedDeliveryDate: null,
  shippingCarrierID: null,
  shippingReasonName: null,
  isPrepaid: null,
});

export const defaultShippingItem = Object.freeze({
  thisRequest: null,
  containerID: null,
  thisContainer: null,
  description: null,
  dotHazardClass: null,
  exportValue: null,
  flashPointInC: null,
  quantity: null,
  quantityUoMName: null,
  quantityUoM: null,
  comments: null,
  sampleName: null,
  blindCodeDescription: null,
});

export const labelProp: Record<string, string> = {
  description: 'Description',
  facilityName: 'Facility',
  businessUnitName: 'Business Unit',
  requestedCompletionDate: 'Requested Completion Date',
  lastAcceptableCompletionDate: 'Last Acceptable Date',
  requestedByEmail: 'Requester Email',
  priorityReason: 'Priority Reason',
  recipientName: 'Recipient\'s Name',
  phoneNumber1: 'Phone #',
  country: 'Country',
  postalCode: 'Zip',
  state: 'State',
  city: 'City',
  address1: 'Street Address',
  requestedDeliveryDate: 'Delivery Required By',
  shippingCarrierID: 'Ship Via',
  shippingReasonName: 'Reason for Shipment',
  isPrepaid: 'Shipment Payment',
  statusName: 'Status',
}

export const CONTAINERING_METHOD_ENUM = {
  MIDAS: "midas",
  NON_SPLIT: "nonsplit",
};

export const RECOMMENDATIONS_ERROR_MESSAGES = {
  NO_CONTAINERS: 'No containers found. Please hit "Get Recommendations" button',
  INVALID_AMOUNT:
    "Invalid amount available. Container does not have enough volume for all tests.",
  REASK_RECOMMENDATIONS:
    "Container and/or Methods has been changed. Please get new recommendations.",
};


export const getFacilitiesExternal = (samples: any[]) => {
  const facilitiesExternal: any[] = [];

  samples.forEach(sample => {
    const sampleFinal = sample.sample ?? sample;
    const container = sampleFinal.containers.find((f: any )=> f.id === sample.existingContainerID);
    if (sample.testSubmissionJSON) {
      const parsedValue = JSON.parse(sample.testSubmissionJSON);
      const methodsExternal = parsedValue.methods.filter((m: any) => m.externalLaboratory);

      if (methodsExternal.length) {
        methodsExternal.forEach((external: any) => {
          facilitiesExternal.push({
            sampleName: sampleFinal.sampleName,
            containerNumber: container?.containerNumber,
            existingContainerID: sample.existingContainerID,
            method: external?.method?.name,
            methodDescription: external?.method?.description,
            facility: external?.methodFacility?.testFacilityAbv,
            workRequestId: null,
            laboratoryName: external?.externalLaboratory?.laboratoryName,
          })
        })
      }
    }
  })

  const groupFacilitiesExternal = [...facilitiesExternal.reduce((a, b) => {
    if (!a.has(b.laboratoryName)) {
      a.set(b.laboratoryName, b);
    } else {
      a.get(b.laboratoryName).method += `, ${b.method}`;
    }
    return a;
  }, new Map()).values()]

  return groupFacilitiesExternal;
}

export const statusToDisableForm = [
 'Completed',
 'Cancelled',
];

export const statusAllowedForSubmissionForm = [
 'Draft',
 'OnHold',
 'Submitted',
];

export const copyWorkRequest = (workRequestToCopy: any) => {
  delete workRequestToCopy.id;
  delete workRequestToCopy.specialRequest?.id;
  delete workRequestToCopy.specialRequest?.workRequestID;

  const workRequest = {
    ...workRequestToCopy,
    statusName: defaultWorkRequest.statusName,
    createdByEmail: defaultWorkRequest.createdByEmail,
    createdDate: null,
    shippingRequest: null,
    comments: null,
    assignedToEmail: null,
    assignedDate: null,
    requestedCompletionDate: defaultWorkRequest.requestedCompletionDate,
    lastAcceptableCompletionDate: defaultWorkRequest.lastAcceptableCompletionDate,
    lastModifiedDate: defaultWorkRequest.lastModifiedDate,
    lastModifiedByEmail: defaultWorkRequest.lastModifiedByEmail,
    completedDate: defaultWorkRequest.completedDate,
    completedByEmail: defaultWorkRequest.completedByEmail,
    workRequestSamples: workRequestToCopy.workRequestSamples.map((wrSample: any) => {
      delete wrSample.id;
      delete wrSample.workRequestID;
      return {
        ...wrSample,
        newContainerID: null,
        newContainer: null,
        existingContainer: null,
      }
    })

  }
  return workRequest;
}
export const copyShippingRequest = (shippingRequestToCopy: any) => {
  delete shippingRequestToCopy.id;
  delete shippingRequestToCopy.workRequestID;
  delete shippingRequestToCopy.shippingAddress?.id;
  delete shippingRequestToCopy.tscaReview;
  const shippingRequest = {
    ...shippingRequestToCopy,
    shippingItems: shippingRequestToCopy.shippingItems.map((item: any, index: number) => {
      delete item.id;
      delete item.shippingRequestID;
      return {
        ...item,
        flashPointInC: convertFlashPoint(convertCelsiusToFahrenheit, item.flashPointInC)
      }
    }),
    requestedDeliveryDate: defaultShippingRequest.requestedDeliveryDate,
    shippingTrackings: []
  }
  return shippingRequest;
}

export const FILE_ATTACHMENT_TABLE_COLUMNS = [
  "File Name",
  "Send with Shipment",
  "File is SDS",
];

export const USALabelInShippingAddress = [
  ' USA',
  'U.S.A.',
  'UNITED',
  'UNITED STATES',
  'United States of America',
  'Unites States',
  'US',
  'USA',
  'USA`',
]

export const IsInternationalShipping  = (address: any) => address?.country ? !USALabelInShippingAddress.includes(address.country) : false;